import styled from "styled-components";
import { Device, Spacing } from "@styles/constants";
import { EyebrowCSS, Heading2CSS, LargeBodyRegular, SmallBodyRegularCSS } from "@styles/typography";

export const Header = styled.header`
  margin-bottom: 2.5rem;
  text-align: center;

  @media ${Device.largeUp} {
    max-width: 50%;
    margin-bottom: ${Spacing.xSmall};
    text-align: initial;
  }
`;

export const Eyebrow = styled.small`
  ${EyebrowCSS}
`;

export const Heading = styled.h2`
  ${Heading2CSS}
  text-align: inherit;
`;
export const Subheading = styled(LargeBodyRegular)`
  @media ${Device.largeUp} {
    ${SmallBodyRegularCSS};
  }
`;

export const ItemGrid = styled.div`
  display: grid;
  gap: 1.25rem;

  @media screen and (min-width: 1000px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
