import { useState } from "react";
import FlagsDropdown from "./flags-dropdown";
import StateFilter from "./state-filter";
import StoreLocations from "./store-locations";
import { FilteredLocationData, LocationsFilterSchema } from "./LocationsFilter.model";
import * as Styled from "./LocationsFilter.styled";

export interface LocationFilterProps {
  sanity: LocationsFilterSchema;
}

const LocationsFilter = ({ sanity }: LocationFilterProps) => {
  const { heading, country_selection_options, store_locations } = sanity;
  const [countryCode, setCountryCode] = useState(`${process.env.NEXT_PUBLIC_COUNTRY_CODE}`);
  const [state, setState] = useState("");

  const filterLocationData = () => {
    const data: FilteredLocationData = {};

    store_locations.forEach((location) => {
      const { country_code, state } = location;

      // If the country code doesn't exist as a key in data, set empty object
      if (!data[country_code]) {
        data[country_code] = {};
      }

      // Get the first character of the state name
      const stateInitial = state.charAt(0);

      // If the key for the first letter of the state name doesn't exist in the country data, set empty array
      if (!data[country_code][stateInitial]) {
        data[country_code][stateInitial] = [];
      }

      if (country_code && state) {
        data[country_code][stateInitial]
          ? data[country_code][stateInitial].push(location)
          : (data[country_code][stateInitial] = [location]);
      }
    });

    return data;
  };

  const data = filterLocationData();
  const activeStates = data[countryCode];
  const selectedState = activeStates[state];
  const allActiveStates = Object.values(activeStates).flat();

  const handleStateSelect = (e: React.MouseEvent<HTMLButtonElement>) => {
    const target = e.target as HTMLButtonElement;
    setState(target.innerText);
  };

  const handleSelectAll = () => {
    setState("");
  };

  const handleCountrySelect = (countryCode: string) => {
    setCountryCode(countryCode);
  };

  return (
    <>
      <Styled.MenuBar>
        <Styled.FlexContainer className="grid-container">
          <h3>{heading}</h3>

          {country_selection_options.length > 1 && (
            <FlagsDropdown
              handleChange={handleCountrySelect}
              optionValues={country_selection_options}
              selected={countryCode}
            />
          )}
        </Styled.FlexContainer>
      </Styled.MenuBar>
      <StateFilter
        onClickState={handleStateSelect}
        onClickSelectAll={handleSelectAll}
        activeStates={activeStates}
        state={state}
      />
      <StoreLocations locations={selectedState || allActiveStates} />
    </>
  );
};

export default LocationsFilter;
