import * as React from "react";
import { CloudinaryAsset } from "@models/cloudinary-asset.model";
import CloudinaryFormat from "@components/cloudinary";
import * as Styled from "../HeroFifty.styled";

type HeroIphoneProps = {
  media: CloudinaryAsset;
  iPhoneImg: CloudinaryAsset;
};

const HeroIphone: React.FC<HeroIphoneProps> = ({ media, iPhoneImg }) => {
  const [videoLoaded, setVideoLoaded] = React.useState<boolean>(false);
  const videoEl = React.useRef<HTMLVideoElement | undefined>();

  React.useEffect(() => {
    if (!videoEl.current) return;
    videoEl.current.addEventListener("loadeddata", onVideoLoaded);
    videoEl.current.load();
  }, []);

  const onVideoLoaded = () => {
    setVideoLoaded(videoEl.current.readyState >= 2);
  };

  return (
    <Styled.ImageContainer
      className="iphone-container"
      aria-hidden={!videoLoaded}
      videoLoaded={videoLoaded}>
      <Styled.CenteredPhone>
        {media?.url && (
          <>
            <CloudinaryFormat media={media} widthOnScreenValues={100} mediaRef={videoEl} />
            <img src={iPhoneImg.url} alt="" />
          </>
        )}
      </Styled.CenteredPhone>
    </Styled.ImageContainer>
  );
};

export default HeroIphone;
