import { MutableRefObject } from "react";
import { Class, CLASS_PROJECTION } from "@models/class.model";
import { Partnership, PARTNERSHIP_PROJECTION } from "@models/partnership.model";
import { Sanity } from "@models/sanity.model";

export const GalleryType = "gallery";

export interface Item {
  id: string | number;
  el: HTMLElement;
  bounds: DOMRect;
  x: number;
  offset: number;
  translate: number;
  isBefore: boolean;
  isAfter: boolean;
  isBottom: boolean;
}

export type FeaturedGalleryItem = Class | Partnership;

export interface GalleryWrapProps {
  cardType?: string;
  items: Array<Class | Partnership>;
  inView: boolean;
}

export interface GalleryCardProps {
  type: string;
  createItem: (el: HTMLElement, id: number, isBottom: boolean) => void;
  idx: number;
  isBottom: boolean;
  bottomItems: MutableRefObject<FeaturedGalleryItem[]>;
  handleLinkEnter: () => void;
  handleLinkLeave: () => void;
}

export interface GalleryProps {
  inView?: boolean;
  sanity: GallerySchema;
}

export interface GallerySchema extends Sanity.Document {
  theme_style?: Sanity.ThemeColor;
  eyebrow?: string;
  heading?: string;
  cta?: Sanity.Cta;
  secondary_cta?: Sanity.Cta;
  copy_blocks?: Array<{
    heading: string;
    copy: Sanity.PortableText;
  }>;
  featured_items: Array<Class | Partnership>;
}

export const GALLERY_PROJECTION = `{
  theme_style,
  eyebrow,
  heading,
  cta,
  secondary_cta,
  copy_blocks,
  featured_items[]-> {
    _type,
    _type == 'workout' => ${CLASS_PROJECTION},
    _type == 'partnership' => ${PARTNERSHIP_PROJECTION}
  }
}`;
