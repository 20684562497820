import { CloudinaryAsset, CLOUDINARY_ASSET_PROJECTION } from "@models/cloudinary-asset.model";
import { Sanity } from "@models/sanity.model";

export const ComparePlansType = "compare_plans";

export interface CompareProps {
  comparePlans: Array<ComparePlan>;
  compareSections: Array<CompareSection>;
}

export interface CompareItem {
  item_headline: string;
  icon?: CloudinaryAsset;
  info?: string;
  plans: {
    plan_digital: {
      compare_active: boolean;
      compare_description?: string;
    };
    plan_hardware: {
      compare_active: boolean;
      compare_description?: string;
    };
  };
}

export interface CompareSection {
  section_headline: string;
  compare_items: Array<CompareItem>;
}

export interface ComparePlan {
  image: CloudinaryAsset;
  plan_name: string;
  monthly_price?: number;
  plan_description?: string;
  plan_cta?: Sanity.Cta;
}

export interface ComparePlansSchema extends Sanity.Document {
  eyebrow?: string;
  headline?: string;
  copy?: string;
  compare_plan_items: Array<ComparePlan>;
  compare_items_sections: Array<CompareSection>;
  compare_plans_footnotes: Array<{
    copy?: Sanity.PortableText;
    reference_mark?: string;
  }>;
}

export const COMPARE_PLANS_PROJECTION = `{
  eyebrow,
  headline,
  copy,
  compare_plan_items[] {
    image ${CLOUDINARY_ASSET_PROJECTION},
    plan_name,
    monthly_price,
    plan_description,
    plan_cta,
  },
  compare_items_sections[] {
    section_headline,
    compare_items[] {
      item_headline,
      icon ${CLOUDINARY_ASSET_PROJECTION},
      info,
      plans {
        plan_digital {
          compare_active,
          compare_description,
        },
        plan_hardware {
          compare_active,
          compare_description,
        }
      }
    }
  },
  compare_plans_footnotes[] {
    copy,
    reference_mark,
  },
}`;
