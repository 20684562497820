import styled from "styled-components";
import { HeadingSize } from ".";
import { Device, Spacing } from "@styles/constants";
import { rem } from "@styles/helpers";
import { Heading2CSS, Heading3CSS } from "@styles/typography";

export const Intro = styled.div`
  position: relative;
`;

export const IntroVideo = styled.div`
  position: relative;
  margin: ${Spacing.small} 0;

  @media ${Device.mediumUp} {
    margin: ${Spacing.large} 0;
  }
`;

export const Eyebrow = styled.div`
  display: flex;
  align-items: center;

  img {
    width: auto;
    height: ${rem(32)};
    margin-bottom: 40px;
  }
  * + img {
    margin-left: ${rem(16)};
  }
`;

export const Text = styled.p<{ size?: HeadingSize }>`
  ${({ size }) => (size === HeadingSize.H2 ? Heading2CSS : Heading3CSS)}
  max-width: 25ch;
  margin-bottom: 0;
`;

export const Buttons = styled.div`
  margin-top: 40px;
`;
