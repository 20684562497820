import { Heading4, XSmallBodyMedium, XSmallBodyRegular } from "@styles/typography";
import { ReactComponent as CheckSVG } from "@images/checkmark_compare.svg";
import Image from "@components/primitives/image";
import ComparePlan from "../compare-plan";
import { CompareProps } from "../ComparePlans.model";
import * as ComparePlans from "../ComparePlans.styled";
import * as Styled from "./CompareTable.styled";

const CompareTable = ({ comparePlans, compareSections }: CompareProps) => {
  return (
    <Styled.CompareTable aria-labelledby="compareHeadline">
      <thead>
        <tr>
          {comparePlans.map((plan, index) => (
            <Styled.PlanDetails key={index} scope="col">
              <ComparePlan plan={plan} />
            </Styled.PlanDetails>
          ))}
        </tr>
      </thead>
      {compareSections.map((section, index) => {
        const { section_headline, compare_items } = section;
        const sectionId = String(section.section_headline)
          .toLowerCase()
          .replace(/[^a-z0-9]/gi, "-");

        return (
          <Styled.Section aria-labelledby={sectionId} key={index}>
            <tr>
              <Styled.SectionHeadline colSpan={3} scope="col">
                <Heading4 as="h2" id={sectionId}>
                  {section_headline}
                </Heading4>
              </Styled.SectionHeadline>
            </tr>
            {compare_items.map((item, index) => {
              const plans = [item.plans.plan_digital, item.plans.plan_hardware];

              return (
                <Styled.CompareListItem key={index}>
                  <Styled.ItemHeadline scope="row">
                    {item.icon?.url && (
                      <ComparePlans.Icon>
                        <Image
                          asset={item.icon}
                          alt={item.icon.alt}
                          widthOnScreen={[100]}
                          aria-hidden="true"
                        />
                      </ComparePlans.Icon>
                    )}
                    <span>{item.item_headline}</span>
                    {item.info && (
                      <ComparePlans.InfoWrap>
                        <XSmallBodyRegular>{item.info}</XSmallBodyRegular>
                      </ComparePlans.InfoWrap>
                    )}
                  </Styled.ItemHeadline>
                  {plans.map((plan, index) => {
                    return (
                      <Styled.TableCell key={index}>
                        {plan.compare_description ? (
                          <XSmallBodyMedium>{plan.compare_description}</XSmallBodyMedium>
                        ) : (
                          plan.compare_active && (
                            <ComparePlans.Checkmark>
                              <CheckSVG />
                            </ComparePlans.Checkmark>
                          )
                        )}
                      </Styled.TableCell>
                    );
                  })}
                </Styled.CompareListItem>
              );
            })}
          </Styled.Section>
        );
      })}
    </Styled.CompareTable>
  );
};

export default CompareTable;
