import styled from "styled-components";
import { rem } from "@styles/helpers";
import { Color, Device } from "@styles/constants";
import GlobalFootnotes from "@components/footnotes/Footnotes";

export const ComparePlans = styled.div`
  @media ${Device.mediumDown} {
    && {
      padding: 0;
    }
  }
`;

export const HeadingWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 auto;

  @media ${Device.mediumDown} {
    padding: 0 ${rem(21)};
  }
`;

export const CompareWrap = styled.div`
  position: relative;
  margin-top: ${rem(140)};

  @media ${Device.mediumDown} {
    max-width: var(--compare-plans-container);
    margin: ${rem(16)} auto 0;
  }
`;

export const Copy = styled.div`
  text-align: center;
  padding: 0 ${rem(21)};

  @media ${Device.largeUp} {
    position: absolute;
    width: 35%;
    max-width: ${rem(380)};
    text-align: left;
    padding: 0;
  }

  p {
    margin-bottom: 0;
  }
`;

export const Icon = styled.div`
  display: flex;
  width: ${rem(24)};
  margin-right: ${rem(10)};
`;

export const InfoWrap = styled.div`
  color: ${Color.grayBrand};
  grid-column: 1 / -1;

  @media ${Device.largeUp} {
    grid-column: 2;
  }

  p {
    margin-bottom: 0;
    line-height: 1.33;
  }
`;

export const Checkmark = styled.div`
  width: ${rem(19)};
  height: ${rem(14)};
`;

export const Footnotes = styled(GlobalFootnotes)`
  padding-top: ${rem(60)};
  padding-bottom: 0;

  @media ${Device.mediumUp} {
    padding-top: ${rem(70)};
  }
`;
