import styled, { css } from "styled-components";
import { Device, Color, Ease } from "@styles/constants";
import { AbsoluteCoverCSS } from "@styles/layout";
import { Eyebrow as GlobalEyebrow, Heading4CSS, SmallBodyRegularCSS } from "@styles/typography";
import { PartnershipType } from "@models/partnership.model";
import { Sanity } from "@models/sanity.model";
import { rem } from "@styles/helpers";

const galleryBreakpoint = "only screen and (min-width: 55.625em)";
const galleryBreakpointDown = "only screen and (max-width: 55.624em)";

export const GallerySection = styled.div<{ themeColor: Sanity.ThemeColor }>`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  ${({ themeColor }) => `
    --foreground: ${themeColor === Sanity.ThemeColor.DARK ? Color.white : Color.blackBrand};
  `}

  color: var(--foreground);
`;

export const Eyebrow = styled(GlobalEyebrow)<{ dark: boolean }>`
  ${({ dark }) => dark && `color: ${Color.white};`}
`;

export const Content = styled.div<{ cardType?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${({ cardType }) =>
    cardType === PartnershipType &&
    `
      @media ${galleryBreakpointDown} {
        align-items: flex-start;
        text-align: left;
      }
  `}
`;

export const Gallery = styled.div<{ cardType?: string }>`
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;

  & + & {
    margin-top: ${({ cardType }) => (cardType === PartnershipType ? rem(8) : rem(10))};

    @media ${Device.largeUp} {
      margin-top: ${({ cardType }) => (cardType === PartnershipType ? rem(32) : rem(10))};
    }
  }
`;

export const GalleryItem = styled.div<{ $type: string }>`
  --border-radius: 10px;
  --flex-width: ${({ $type }) => ($type === PartnershipType ? "41.8vw" : "65.6vw")};
  flex: 0 0 var(--flex-width);
  width: var(--flex-width);
  padding: var(--item-padding, 0 ${rem(5)});
  border-radius: var(--border-radius);

  @media ${Device.mediumUp} {
    --border-radius: ${({ $type }) => ($type === PartnershipType ? "20px" : "10px")};
    --flex-width: ${({ $type }) => ($type === PartnershipType ? "20.55vw" : "30vw")};
    flex: 0 0 var(--flex-width);
    width: var(--flex-width);
  }

  ${({ $type }) =>
    $type === PartnershipType &&
    `
      @media ${Device.largeUp} {
        --item-padding: 0 ${rem(16)};
      }
    `}

  a {
    ${AbsoluteCoverCSS}
  }

  img {
    border-radius: var(--border-radius);
  }

  ${({ $type }) =>
    $type === PartnershipType &&
    css`
      > :not(a) {
        pointer-events: none;
      }
    `}
`;

export const GalleryItemInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: ${Color.grayFill};

  &:after {
    ${AbsoluteCoverCSS}
    content: '';
    border-radius: 10px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  }
`;

export const GalleryItemCaption = styled.div`
  position: absolute;
  bottom: 10px;
  right: 15px;
  display: flex;
  align-items: center;
  color: ${Color.white};
  z-index: 1;

  @media ${Device.mediumUp} {
    bottom: 30px;
    right: 30px;
  }

  img {
    width: 20px;
    height: 20px;
    border-radius: 50%;

    @media ${Device.mediumUp} {
      width: 35px;
      height: 35px;
    }
  }
`;

export const GalleryItemTitle = styled.span`
  ${Heading4CSS}
  display: block;
  margin-left: ${rem(10)};
  margin-bottom: 0;

  @media ${Device.mediumUp} {
    margin-left: ${rem(15)};
  }
`;

export const Cursor = styled.button`
  position: absolute;
  top: -40px;
  left: -40px;
  width: 80px;
  height: 80px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(14.5217px);
  border: 1px solid ${Color.white};
  pointer-events: none;
  border-radius: 50%;
  z-index: 1;
  opacity: 0;
  transition: opacity ${Ease.duration} ${Ease.out} 0s, background ${Ease.duration} ${Ease.out};

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 28px;
    height: 13px;
  }

  &.is-down {
    background: rgba(255, 255, 255, 0.4);
  }

  &.is-hidden {
    opacity: 0;
    transition: opacity ${Ease.duration} ${Ease.out} 0s, background ${Ease.duration} ${Ease.out};
  }
`;

export const GalleryContainer = styled.div<{ cardType?: string }>`
  position: relative;
  margin-top: ${rem(40)};
  user-select: none;

  &:hover {
    cursor: ${({ cardType }) => cardType !== PartnershipType && "none"};

    ${Cursor} {
      opacity: 1;
      transition: opacity ${Ease.duration} ${Ease.out} 0.2s, background ${Ease.duration} ${Ease.out};
    }
  }

  @media ${galleryBreakpoint} {
    margin-top: ${rem(60)};
  }
`;

export const ButtonContainer = styled.div<{ cardType?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.25rem;
  margin-top: ${({ cardType }) => (cardType === PartnershipType ? rem(60) : rem(40))};
  width: 100%;

  @media ${Device.mediumUp} {
    align-items: center;
    flex-direction: row;
    width: auto;
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  row-gap: ${rem(30)};
  margin-top: ${rem(30)};

  @media ${galleryBreakpoint} {
    flex-direction: row;
    column-gap: min(7vw, ${rem(100)});
    margin-top: ${rem(40)};
  }
`;

export const CopyBlock = styled.div`
  color: var(--foreground);
  flex: 1;

  @media ${galleryBreakpoint} {
    text-align: center;
    max-width: ${rem(480)};
  }
`;

export const BlockHeading = styled.h3`
  ${Heading4CSS}
  margin-bottom: ${rem(16)};
`;

export const BlockCopy = styled.div`
  max-width: ${rem(400)};

  p {
    ${SmallBodyRegularCSS}
  }
`;
