import ReactFlagsSelect from "react-flags-select";
import { CountrySelectionOption } from "../LocationsFilter.model";

export interface FlagsDropdownProps {
  optionValues: CountrySelectionOption[];
  handleChange: (countryCode: string) => void;
  selected: string;
}

const FlagsDropdown = ({ handleChange, optionValues, selected }: FlagsDropdownProps) => {
  const formatOptionValues = () => {
    return optionValues.map((option) => option.value);
  };

  const formatDisplayValues = () => {
    return optionValues.reduce((obj: Record<string, string>, option) => {
      obj[option.value] = option.display_value;
      return obj;
    }, {});
  };

  return (
    <ReactFlagsSelect
      selected={selected}
      onSelect={handleChange}
      countries={formatOptionValues()}
      blacklistCountries={false}
      customLabels={formatDisplayValues()}
    />
  );
};

export default FlagsDropdown;
