import React from "react";
import { Form, Formik, FormikProps } from "formik";
import Ratings from "react-ratings-declarative";
import DropZone from "@components/primitives/drop-zone";
import { Input, Select, Textarea } from "@components/primitives/input";
import { reviewSchema } from "@utils/validations";
import { US_ID } from "@utils/constants";

interface ReviewFormProps {
  onSubmit: (values: ReviewValues) => void;
  onCancel: () => void;
}

interface ReviewFormState {
  file?: File;
}

const initialValues = {
  name: "",
  email: "",
  city: "",
  state: "",
  title: "",
  review: "",
};

export interface ReviewValues {
  name: string;
  email: string;
  city: string;
  state: string;
  title: string;
  review: string;
  rating: number;
  "review_images_attributes[][attachment]"?: File;
}

class ReviewForm extends React.Component<ReviewFormProps, ReviewFormState> {
  countryId = US_ID; // Default to US. TODO Shouldn't be hardcoded
  state: ReviewFormState = {};

  setAttachment = (files: FileList) => {
    this.setState({ file: files[0] });
  };

  onSubmit = (values: ReviewValues) => {
    this.props.onSubmit(
      // Can't use formik's setFieldValue as it will decompose the string key into object/arrays
      this.state.file
        ? {
            ...values,
            "review_images_attributes[][attachment]": this.state.file,
          }
        : values
    );
  };

  render() {
    const { onCancel } = this.props;

    return (
      <div id="product-review-form">
        <button className="close" onClick={onCancel}>
          Close
        </button>
        <header className="row">
          <div className="column small-12">
            <h2>Review your Mirror</h2>
          </div>
        </header>
        <Formik
          initialValues={initialValues}
          onSubmit={this.onSubmit}
          validateOnBlur={false}
          validationSchema={reviewSchema}>
          {({
            setFieldValue,
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
          }: FormikProps<ReviewValues>) => {
            const setRating = (rating: number) => setFieldValue("rating", rating);
            return (
              <Form noValidate className="row">
                <div className="column small-12">
                  <h6>*Required Fields</h6>
                </div>
                <div className="column small-12 medium-6">
                  <Input
                    label="Name *"
                    name="name"
                    type="text"
                    required
                    maxLength={60}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    error={errors.name && touched.name && errors.name}
                  />
                </div>
                <div className="column small-12 medium-6">
                  <Input
                    label="Email *"
                    type="email"
                    required
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    error={errors.email && touched.email && errors.email}
                  />
                </div>
                <div className="column small-12 medium-6">
                  <Input
                    label="City *"
                    name="city"
                    type="text"
                    required
                    maxLength={30}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.city}
                    error={errors.city && touched.city && errors.city}
                  />
                </div>
                <div className="column small-12">
                  <hr />
                  <label>Overall Rating *</label>
                  <div className="field">
                    <div className={`product-rater rating-${values.rating}`}>
                      <Ratings
                        rating={values.rating}
                        widgetRatedColors="black"
                        changeRating={setRating}>
                        {[1, 2, 3, 4, 5].map((i) => (
                          <Ratings.Widget key={i} widgetHoverColor="black" widgetDimension="40px" />
                        ))}
                      </Ratings>
                    </div>
                    {errors.rating && <div className="error">{errors.rating}</div>}
                  </div>
                </div>
                <div className="column small-12 medium-6">
                  <Input
                    label="Title *"
                    name="title"
                    type="text"
                    required
                    maxLength={60}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title}
                    error={errors.title && touched.title && errors.title}
                  />
                </div>
                <div className="column small-12">
                  <Textarea
                    label="Review * - How does it feel to use the Mirror? How do you like the classes and instructors? Has the MIRROR helped you reach your goals?"
                    labelFilled="Review *"
                    style={{ marginBottom: 0 }}
                    name="review"
                    maxLength={2000}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.review}
                    error={errors.review && touched.review && errors.review}
                  />
                </div>
                <div className="column small-12">
                  <label>Add a Photo of Your Mirror</label>
                  <DropZone
                    dropZoneText="Upload"
                    dropZoneClass="button"
                    hideButton={true}
                    buttonText="Select Photo"
                    onUpload={this.setAttachment}
                  />
                </div>
                <div className="column small-12 submit">
                  <input
                    className="button solid"
                    type="submit"
                    value="Submit"
                    disabled={isSubmitting}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

export default ReviewForm;
