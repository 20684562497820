import { useRef, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { Transition, Variants } from "framer-motion";
import { HighlightsProps } from "../LifestyleTabs.model";
import * as Styled from "./Highlights.styled";
import PortableText from "@components/portable-text";

const descriptionVariants: Variants = {
  active: {
    opacity: 1,
    y: 0,
    height: "auto",
  },
  inactive: {
    opacity: 0,
    y: -10,
    height: 0,
  },
};

const defaultTransition: Transition = {
  duration: 0.8,
  ease: [0.04, 0.62, 0.23, 0.98],
};

const Highlights = ({
  items,
  parentRef,
  duration,
  activeIndex,
  onActiveIndex,
  showHighlights,
}: HighlightsProps) => {
  const { ref, inView } = useInView();

  const durationRef = useRef<number>(duration ? duration * 1000 : 6000);
  const timerRef = useRef<number>(0);

  const startTimer = () => {
    timerRef.current = window.setInterval(() => {
      onActiveIndex((c) => (c + 1) % items.length);
    }, durationRef.current);
  };

  const stopTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = 0;
    }
  };

  const handleFocus = () => {
    if (!parentRef.current) return;
    const parentBounds = parentRef.current.getBoundingClientRect();
    window.scrollTo(0, parentBounds.top + window.scrollY + window.innerHeight);
  };

  useEffect(() => {
    if (showHighlights && inView) {
      startTimer();
    } else {
      onActiveIndex(0);
      stopTimer();
    }

    return () => {
      stopTimer();
    };
  }, [inView, showHighlights]);

  return (
    <Styled.Highlights ref={ref} visible={showHighlights}>
      <Styled.Container>
        <div className="grid-container">
          <Styled.List>
            {items.map((highlight, index) => {
              const isActive = index === activeIndex;
              return (
                <Styled.Item
                  key={index}
                  onFocus={() => {
                    handleFocus();
                  }}
                  onClick={() => {
                    stopTimer();
                    onActiveIndex(index);
                    startTimer();
                  }}
                  aria-selected={isActive}>
                  <Styled.Progress
                    className={isActive && showHighlights && inView ? "active" : "inactive"}
                    duration={durationRef.current}>
                    <span></span>
                  </Styled.Progress>

                  <Styled.Heading>{highlight.heading}</Styled.Heading>

                  <Styled.HighlightBody
                    initial={false}
                    animate={isActive && showHighlights && inView ? "active" : "inactive"}
                    variants={descriptionVariants}
                    transition={defaultTransition}>
                    <PortableText value={highlight.body} />
                  </Styled.HighlightBody>
                </Styled.Item>
              );
            })}
          </Styled.List>
        </div>
      </Styled.Container>
    </Styled.Highlights>
  );
};

export default Highlights;
