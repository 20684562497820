import { StoreLocation } from "@components/locations-filter";
import Button from "@components/ui/button";
import { nl2br } from "@utils/format";
import * as Styled from "./LocationCard.styled";

interface LocationCardProps {
  location: StoreLocation;
}

const LocationCard = ({ location }: LocationCardProps) => {
  const { heading, sub_heading, full_address, get_directions_url, phone, hours, cta } = location;
  return (
    <Styled.Cell className="cell smal1-12 medium-6 large-4">
      <Styled.Content>
        <Styled.Header>
          <h4>{heading}</h4>
          <h4>{sub_heading}</h4>
        </Styled.Header>
        <Styled.AddressPhone>
          <address>{nl2br(full_address)}</address>
          {get_directions_url && (
            <p>
              <a href={get_directions_url}>Get Directions</a>
            </p>
          )}
          {phone && (
            <p>
              <a href={`tel:${phone}`}>{phone}</a>
            </p>
          )}
        </Styled.AddressPhone>
        {hours && (
          <Styled.Hours>
            <h5>Hours</h5>
            {nl2br(hours)}
          </Styled.Hours>
        )}
        {cta?.url && (
          <Styled.ButtonContainer>
            <Button text={cta.text} href={cta.url} />
          </Styled.ButtonContainer>
        )}
      </Styled.Content>
    </Styled.Cell>
  );
};

export default LocationCard;
