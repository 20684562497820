import { Sanity } from "@models/sanity.model";

export const SectionFaqType = "section_faq";

export interface Question {
  question: string;
  answer: Sanity.PortableText;
}

export interface SectionFAQSchema extends Sanity.Document {
  theme_style?: Sanity.ThemeColor;
  heading: string;
  questions: Array<Question>;
  cta?: Sanity.Cta;
}

export const SECTION_FAQ_PROJECTION = `{
  theme_style,
  heading,
  questions[] {
    question,
    answer,
  },
  cta
}`;
