import { BundlesType } from "@components/bundles";
import { GlobalCtaType } from "@components/global-cta";
import { SectionFaqType } from "@components/section-faq";
import { GalleryType } from "@components/gallery";
import { FiftyFiftyType, Theme as FiftyFiftyTheme } from "@components/fifty-fifty";
import { StoreBannerType } from "@components/store-banner";
import { TimedTabsType } from "@components/timed-tabs";
import { ValuePropsType } from "@components/value-props";
import { Sanity } from "@models/sanity.model";
import { LifestyleTabsType } from "@components/lifestyle-tabs";
/**
 * @desc Returns the proper ThemeColor value on a per-schema basis,
 *     allowing for differentiation of schema structure where necessary.
 * @param {Sanity.Document} schema
 * @returns {Sanity.ThemeColor}
 */
export const surfaceThemeSetting = (schema: Sanity.Document | undefined) => {
  if (!schema) return null;
  const { _type } = schema;

  switch (_type) {
    /**
     * Cases only need to exist in order to avoid changing existing schemas.
     */
    case FiftyFiftyType: {
      const dark = [FiftyFiftyTheme.DARK_BLEED, FiftyFiftyTheme.HARDWARE_INTRO];
      return dark.includes(schema.theme) ? Sanity.ThemeColor.DARK : Sanity.ThemeColor.LIGHT;
    }
    case GlobalCtaType: {
      const dark = [Sanity.ThemeColor.DARK, Sanity.ThemeColor.DARK_IMAGE];
      return dark.includes(schema.theme_style) ? Sanity.ThemeColor.DARK : Sanity.ThemeColor.LIGHT;
    }
    case SectionFaqType:
    case GalleryType:
    case StoreBannerType: {
      return schema.theme_style || Sanity.ThemeColor.LIGHT;
    }
    case BundlesType:
    case TimedTabsType:
    case ValuePropsType: {
      return schema.theme?.theme_style || Sanity.ThemeColor.LIGHT;
    }
    case LifestyleTabsType: {
      return Sanity.ThemeColor.NONE;
    }
    /**
     * @README We probably use fallthrough cases to cover all
     *     future modules using the `themeStyle.ts` shared object:
     *
     * case ValuePropsType:
     * case BundlesType:
     * case HeroPrimaryType: {
     *   return schema.theme_style
     * }
     *
     * This _should_ allow us to default to a light theme and saves
     * us from retrofitting old modules.
     *
     */
    default: {
      return Sanity.ThemeColor.LIGHT;
    }
  }
};
