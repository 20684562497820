import { Grid, Row } from "@styles/grid";
import { Color } from "@styles/constants";
import { Sanity } from "@models/sanity.model";
import Button from "@components/ui/button";
import * as Styled from "./Jumbotron.styled";

interface JumbotronProps {
  className?: string;
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  eyebrow?: string;
  cta?: Sanity.Cta;
  background?: string;
  color?: string;
  image?: string;
  columns?: string;
  gap?: string;
  margin?: string;
  padding?: string;
  children?: React.ReactNode;
  id?: string;
}

const Jumbotron = ({
  className,
  title,
  subtitle,
  eyebrow,
  cta,
  background = "#333",
  color = Color.white,
  image,
  columns = "65% 25%",
  gap = "10%",
  margin = "140px 0",
  padding,
  children,
  id,
}: JumbotronProps) => (
  <div className="grid-container">
    <Styled.Jumbotron
      id={id}
      center
      className={className}
      margin={margin}
      style={{
        background: !image ? background : "transparent",
        backgroundImage: image ? `url(${image})` : "none",
        padding: padding || null,
      }}>
      <Grid columns={columns} gap={gap} center $wrap>
        <Styled.LeftColumn>
          {eyebrow && <Styled.EyebrowText>{eyebrow}</Styled.EyebrowText>}
          {title && <Styled.Title style={{ color }}>{title}</Styled.Title>}
          {subtitle && <Styled.SubTitle style={{ color }}>{subtitle}</Styled.SubTitle>}
        </Styled.LeftColumn>
        <Styled.BodyColumn style={{ color }}>{children}</Styled.BodyColumn>
      </Grid>
      {cta?.url && (
        <Row>
          <Styled.ButtonWrapper>
            <Button text={cta.text} href={cta.url} invert />
          </Styled.ButtonWrapper>
        </Row>
      )}
    </Styled.Jumbotron>
  </div>
);

export default Jumbotron;
