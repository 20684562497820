import { Class, CLASS_PROJECTION } from "@models/class.model";
import { CloudinaryAsset, CLOUDINARY_ASSET_PROJECTION } from "@models/cloudinary-asset.model";
import { Instructor, INSTRUCTOR_PROJECTION } from "@models/instructor.model";
import { Partnership, PARTNERSHIP_PROJECTION } from "@models/partnership.model";
import { Sanity } from "@models/sanity.model";

export const CatalogType = "catalog";

export interface CatalogSchema extends Sanity.Document {
  title: string;
  background_video?: CloudinaryAsset;
  background_image?: CloudinaryAsset;
  mobile_background_image?: CloudinaryAsset;
  content_collection: Array<Instructor | Class | Partnership>;
}

export const CATALOG_PROJECTION = `{
  title,
  background_image-> ${CLOUDINARY_ASSET_PROJECTION},
  background_video-> ${CLOUDINARY_ASSET_PROJECTION},
  mobile_background_image ${CLOUDINARY_ASSET_PROJECTION},
  content_collection[]->{
    _type == 'instructor' => ${INSTRUCTOR_PROJECTION},
    _type == 'workout' => ${CLASS_PROJECTION},
    _type == 'partnership' => ${PARTNERSHIP_PROJECTION}
  }
}`;
