import { Color, Device, Ease, Spacing } from "@styles/constants";
import { AbsoluteCoverCSS } from "@styles/layout";
import { BodyMediumCSS, EyebrowCSS, Heading2CSS } from "@styles/typography";
import styled from "styled-components";

export const Instructors = styled.div`
  position: relative;
  overflow: hidden;
`;

export const Eyebrow = styled.small`
  ${EyebrowCSS}
`;

export const Heading = styled.h2`
  ${Heading2CSS}
  text-align: left;
  max-width: 19ch;
  margin: 0 auto 0 0;
`;

export const Carousel = styled.div`
  margin-top: 40px;

  @media ${Device.mediumUp} {
    margin-top: 80px;
  }

  .swiper-container {
    overflow: visible;
  }

  .swiper-wrapper {
    display: flex;
    align-items: flex-start;
    padding-bottom: 60px;

    @media ${Device.mediumUp} {
      padding-bottom: 80px;
    }
  }

  .swiper-slide {
    overflow: hidden;

    a {
      ${AbsoluteCoverCSS}
      z-index: 1;
    }

    img {
      transition: transform ${Ease.duration} ${Ease.out};
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
    position: absolute;
    top: 37.5%;
    width: 56px;
    height: 56px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    z-index: 1;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(14.5217px);
    border: 1px solid ${Color.white};
    transform: translateY(-50%);
    transition: opacity ${Ease.duration} ${Ease.out}, background ${Ease.duration} ${Ease.out};

    svg {
      fill: ${Color.white};
      width: 14px;
      height: 14px;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.4);
    }

    @media ${Device.mediumUp} {
      display: flex;
    }
  }

  .swiper-button-next {
    right: 24px;

    svg {
      margin-right: 3px;
      transform: rotate(-90deg);
    }
  }

  .swiper-button-prev {
    left: 24px;

    svg {
      margin-left: 3px;
      transform: rotate(90deg);
    }
  }

  .swiper-button-disabled {
    opacity: 0;
    pointer-events: none;
  }
`;

export const SlideImage = styled.div`
  overflow: hidden;
  border-radius: 10px;
`;

export const SlideTitle = styled.h3`
  ${BodyMediumCSS}
  font-size: 1.125rem;
  line-height: 1.44;
  letter-spacing: -0.02em;
  text-transform: none;
`;

export const SlideInfo = styled.div`
  margin-top: 15px;

  h3 {
    text-align: left;
    margin-bottom: 0;

    + ul {
      margin-top: 5px;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: inline;
    color: ${Color.grayBrand};

    &:not(:last-child)::after {
      content: ", ";
    }
  }
`;
