import { useRef, useState, useLayoutEffect, RefObject } from "react";
import { useTransform, useScroll } from "framer-motion";
import useMediaQuery from "@hooks/useMediaQuery";
import useWindowSize from "@hooks/useWindowSize";
import getRootOffset from "@utils/getRootOffset";
import * as Styled from "./ScrollScaleHero.styled";

export type ScrollScaleHeroProps = {
  heroVideoUrl: string;
  heroImageUrl: string;
  heroImageAlt: string;
  scrollContainer: RefObject<HTMLDivElement>;
  isActive: number | undefined;
};

const ScrollScaleHero = ({
  heroVideoUrl,
  heroImageUrl,
  heroImageAlt = "",
  scrollContainer,
  isActive,
}: ScrollScaleHeroProps) => {
  const isMinHeight = useMediaQuery("only screen and (min-height: 850px)");
  const { scrollY } = useScroll();
  const windowSize = useWindowSize();

  const mirrorWidth = isMinHeight ? 350 : 320;
  const mirrorPadding = isMinHeight ? 146 : 126;

  const heroVideoRef = useRef<HTMLVideoElement | undefined>();
  const [position, setPosition] = useState({ start: 0, end: 0 });

  const scale = useTransform(
    scrollY,
    [position.start, position.end],
    [windowSize.width / (mirrorWidth - mirrorPadding), 1]
  );

  useLayoutEffect(() => {
    if (!scrollContainer.current) return;

    const bounds = getRootOffset(scrollContainer.current);

    setPosition({
      start: bounds.top,
      end: bounds.top + windowSize.height,
    });
  }, [windowSize]);

  useLayoutEffect(() => {
    if (!heroVideoRef.current) return;

    const unsubscribeScrollY = scrollY.onChange((y) => {
      if (y > position.start - windowSize.height && typeof isActive === "undefined") {
        heroVideoRef.current.play();
      } else {
        heroVideoRef.current.pause();
      }
    });

    return () => {
      unsubscribeScrollY();
    };
  }, [position, isActive]);

  return (
    <Styled.StyledMirrorHeroContainer style={{ scale, x: "-50%" }}>
      <Styled.StyledMirrorHero visible={typeof isActive === "undefined"}>
        {heroVideoUrl && (
          <video
            ref={heroVideoRef}
            src={heroVideoUrl}
            muted
            playsInline
            loop
            disablePictureInPicture
          />
        )}
        {!heroVideoUrl && <img src={heroImageUrl} loading="lazy" alt={heroImageAlt} />}
      </Styled.StyledMirrorHero>
    </Styled.StyledMirrorHeroContainer>
  );
};

export default ScrollScaleHero;
