import * as React from "react";
import styled, { css } from "styled-components";

import { Color } from "@styles/constants";
import { truncate } from "@utils/index";

export interface ReadMoreProps {
  children: string;
  less?: string;
  more?: string;
  charLimit?: number;
  ellipsis?: boolean;
  lineClamp?: boolean;
}

export interface ReadMoreState {
  expanded: boolean;
}

const Container = styled.div<{ expanded?: boolean; clamped?: boolean }>`
  color: ${Color.black80};

  ${({ expanded }) =>
    !expanded &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    `}
`;

const Toggle = styled.button`
  font-weight: bold;
  text-decoration: underline;
`;

class ReadMore extends React.Component<ReadMoreProps, ReadMoreState> {
  state = {
    expanded: false,
  };

  toggleLines = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const {
      children,
      more = "Read More",
      less = "Read Less",
      charLimit = 200,
      ellipsis = false,
      lineClamp = false,
    } = this.props;

    const { expanded } = this.state;
    const [preview, rest] = truncate(children, charLimit);

    return lineClamp ? (
      <>
        <Container expanded={expanded} clamped>
          <span>{children}</span>
        </Container>
        <Toggle onClick={this.toggleLines}>{expanded ? less : more}</Toggle>
      </>
    ) : (
      <Container>
        <span>{expanded ? children : preview}</span>
        {rest && (
          <span>
            {ellipsis && !expanded ? " ... " : " "}
            <Toggle onClick={this.toggleLines}>{expanded ? less : more}</Toggle>
          </span>
        )}
      </Container>
    );
  }
}

export default ReadMore;
