// - Supported YouTube URL formats:
//   - http://www.youtube.com/watch?v=My2FRPA3Gf8
//   - http://youtu.be/My2FRPA3Gf8
//   - https://youtube.googleapis.com/v/My2FRPA3Gf8
// - Supported Vimeo URL formats:
//   - http://vimeo.com/25451551
//   - http://player.vimeo.com/video/25451551
// - Also supports relative URLs:
//   - //player.vimeo.com/video/25451551
export function parseVideo(url: string): { type: string; id: string } {
  let type: string = "";
  /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/.exec(
    url
  );

  if (RegExp.$3.includes("youtu")) {
    type = "youtube";
  } else if (RegExp.$3.includes("vimeo")) {
    type = "vimeo";
  }

  return {
    type: type,
    id: RegExp.$6,
  };
}
