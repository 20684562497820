import Video from "@components/primitives/video";
import QueryModalTrigger from "@components/query-modal/query-modal-trigger";
import * as Styled from "./DigitalTrialCategories.styled";
import { DigitalTrialCategoriesSchema } from "./DigitalTrialCategories.model";
import { QueryModalTriggerProps } from "@components/query-modal/query-modal-trigger";

import { useState } from "react";

export interface DigitalTrialCategoriesProps {
  sanity: DigitalTrialCategoriesSchema;
  queryModalProps?: QueryModalTriggerProps;
}

const DigitalTrialCategories = ({ sanity, queryModalProps }: DigitalTrialCategoriesProps) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [hover, setHover] = useState<boolean>(false);

  return (
    <Styled.Categories>
      <Styled.Container className="grid-container">
        <Styled.Grid className="grid-x">
          <Styled.HeadingContainer className="cell small-12 medium-8 large-8">
            {sanity?.heading && <Styled.Heading2>{sanity.heading}</Styled.Heading2>}
            {queryModalProps && (
              <Styled.TrialTrigger desktopOnly>
                <QueryModalTrigger {...queryModalProps} light />
              </Styled.TrialTrigger>
            )}
          </Styled.HeadingContainer>

          <Styled.Links className="cell small-12 medium-4 large-4">
            <ul
              onMouseLeave={() => {
                setHover(false);
              }}>
              {sanity?.classes?.map(({ title }, idx) => {
                return (
                  <li key={idx}>
                    <Styled.Link
                      hover={hover}
                      onClick={() => setActiveIndex(idx)}
                      active={activeIndex}>
                      {title}
                    </Styled.Link>
                  </li>
                );
              })}
            </ul>
          </Styled.Links>
          {queryModalProps && (
            <Styled.ButtonWrapper>
              <QueryModalTrigger {...queryModalProps} light />
            </Styled.ButtonWrapper>
          )}
        </Styled.Grid>
      </Styled.Container>

      <Styled.Background>
        {sanity?.classes?.map(({ workout_clip }, idx: number) => {
          if (workout_clip) {
            return (
              <Styled.Media key={idx} className={activeIndex === idx ? "active" : "inactive"}>
                <Video src={workout_clip.url} stop={activeIndex !== idx ? true : false} />
              </Styled.Media>
            );
          }

          return null;
        })}
      </Styled.Background>
    </Styled.Categories>
  );
};

export default DigitalTrialCategories;
