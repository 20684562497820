import CloudinaryFormat from "@components/cloudinary";
import { ColumnPosition, FiftyFiftySchema } from "../FiftyFifty.model";
import * as Styled from "./ImageColumn.styled";
import Device from "../device";
import { MaskInMedia } from "@components/ui/animations";
import { ComponentResolverAnimationProps } from "@components/component-resolver";

export interface ImageColumnProps extends FiftyFiftySchema {
  className?: string;
  position: ColumnPosition;
}

const ImageColumn = ({
  image,
  device_graphic,
  className,
  position,
  inView,
}: ImageColumnProps & ComponentResolverAnimationProps) => {
  return (
    <Styled.ImageColumn className={className || undefined} position={position}>
      <MaskInMedia visible={inView}>
        <Styled.ImageContainer>
          <CloudinaryFormat media={image} widthOnScreenValues={[100, 60]} />
          {device_graphic?.url && <Device graphic={device_graphic} />}
        </Styled.ImageContainer>
      </MaskInMedia>
    </Styled.ImageColumn>
  );
};

export default ImageColumn;
