import { Color, Device } from "@styles/constants";
import { rem } from "@styles/helpers";
import { Heading3 } from "@styles/typography";
import styled from "styled-components";

export const CompareMobile = styled.div`
  @media ${Device.largeUp} {
    display: none;
  }
`;

export const PlanDetails = styled.div`
  padding: 0 ${rem(21)};
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${rem(17)};
`;

export const SectionHeadline = styled(Heading3)`
  margin-top: ${rem(60)};
  padding-bottom: ${rem(32)};
  text-align: center;
  border-bottom: 1px solid ${Color.goldAsh};
`;

export const ItemHeadline = styled.div`
  background-color: ${Color.blueAsh};
  padding: ${rem(17)} ${rem(21)};
  display: grid;
  grid-template-columns: ${rem(24)} 1fr;
  grid-gap: ${rem(5)} ${rem(10)};
  align-items: center;
`;

export const CompareStatus = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${rem(17)};
  text-align: center;
  padding: ${rem(23)} ${rem(21)};

  & > div {
    display: flex;
    justify-content: center;
    justify-self: center;
    width: 60%;
  }

  p {
    margin-bottom: 0;
  }
`;
