import { CloudinaryAsset, CLOUDINARY_ASSET_PROJECTION } from "@models/cloudinary-asset.model";
import { Sanity } from "@models/sanity.model";

export const PurchaseBlockType = "purchase_block";

export interface PurchaseBlockSchema extends Sanity.Document {
  eyebrow?: string;
  heading: string;
  body: Sanity.PortableText;
  cta: Sanity.Cta;
  image: CloudinaryAsset;
}

export const PURCHASE_BLOCK_PROJECTION = `{
  eyebrow,
  heading,
  body,
  cta,
  image ${CLOUDINARY_ASSET_PROJECTION}
}`;
