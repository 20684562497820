import * as React from "react";
import AspectRatio from "@components/primitives/aspect-ratio";
import Image from "@components/primitives/image";
import PortableText from "@components/portable-text";
import { ListCard } from "../ListCards.model";
import * as Styled from "./Card.styled";

const Card = ({ heading, image, list_items }: ListCard) => (
  <Styled.Card>
    <Styled.Figure>
      {image?.url && (
        <AspectRatio ratio={1.9}>
          <Image asset={image} widthOnScreen={[100, 100, 50]} loading="lazy" alt="" />
        </AspectRatio>
      )}
      {heading && <Styled.Caption>{heading}</Styled.Caption>}
    </Styled.Figure>
    <Styled.List>
      {list_items?.length > 0 &&
        list_items.map((item, i) => (
          <Styled.ListItem key={i}>
            <Styled.ItemLiner>
              <Styled.Icon>
                {item.icon?.url && (
                  <Image asset={item.icon} widthOnScreen={16} loading="lazy" alt="" />
                )}
              </Styled.Icon>
              {item.heading && <Styled.ItemHeading>{item.heading}</Styled.ItemHeading>}
              {item.copy && (
                <Styled.ItemCopy>
                  <PortableText value={item.copy} />
                </Styled.ItemCopy>
              )}
            </Styled.ItemLiner>
          </Styled.ListItem>
        ))}
    </Styled.List>
  </Styled.Card>
);

export default Card;
