import { Grid, Column } from "@styles/grid";
import { Sanity } from "@models/sanity.model";
import Accordion from "./accordion";
import { AccordionRepeaterSchema } from "./AccordionRepeater.model";
import { RepeaterHeading, Table } from "./AccordionRepeater.styled";
import PortableText from "@components/portable-text";

interface AccordionRepeaterProps {
  sanity: AccordionRepeaterSchema;
}

const AccordionRepeater = ({ sanity }: AccordionRepeaterProps) => (
  <div className="grid-container">
    {sanity.heading && <RepeaterHeading>{sanity.heading}</RepeaterHeading>}
    {sanity.sections.map((section: Sanity.AccordionSection, idx: number) => (
      <Accordion title={section.title} key={idx}>
        <Grid columns="2fr 4fr" gap="10px" $wrap>
          <Column>
            {section.image?.url && (
              <img src={section.image.url} alt={section?.image?.alt} loading="lazy" />
            )}
          </Column>
          <Column className="verticalTable">
            <Table>
              <tbody>
                {section.children.map((row, idx: number) => (
                  <tr key={idx}>
                    <td>{row.heading}</td>
                    <td>
                      <PortableText value={row.detail_text} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Column>
        </Grid>
      </Accordion>
    ))}
  </div>
);

export default AccordionRepeater;
