import * as React from "react";

const { createContext, useContext } = React;

interface SiteStateContextValue {
  isReady: boolean;
}

const initialState: SiteStateContextValue = {
  isReady: true,
};

const SiteStateContext = createContext<SiteStateContextValue>(initialState);
const useSiteState = (): SiteStateContextValue => useContext(SiteStateContext);
const { Provider, Consumer: SiteStateConsumer } = SiteStateContext;

const SiteStateProvider: React.FC<{
  isReady: boolean;
  children: React.ReactNode;
}> = ({ children, isReady }) => {
  const value = {
    isReady,
  };

  return <Provider value={value}>{children}</Provider>;
};

export { SiteStateProvider, useSiteState, SiteStateContext, SiteStateConsumer };
