import AspectRatio from "@components/primitives/aspect-ratio";
import Video from "@components/primitives/video";
import { CloudinaryAsset } from "@models/cloudinary-asset.model";
import { Device } from "@styles/constants";

import * as Styled from "../InfoBlock.styled";

export interface InfoBlockMediaProps {
  video: CloudinaryAsset;
  image: CloudinaryAsset;
  mobileImage?: CloudinaryAsset;
  headline: string;
  mediumUp?: boolean;
  videoProps: {
    loop: boolean;
  };
  imageProps: {
    contained?: boolean;
    ratio: {
      mobile: number;
      desktop: number;
    };
  };
}

const InfoBlockMedia = ({
  video,
  image,
  mobileImage,
  mediumUp,
  headline,
  videoProps,
  imageProps,
}: InfoBlockMediaProps) => {
  if (video?.url) {
    return (
      <div className="info-block-video">
        <Video src={video.url} loop={videoProps.loop} aspectRatioMobile={1} />
      </div>
    );
  }

  if (image?.url) {
    return (
      <Styled.ImageWrapper>
        <AspectRatio ratio={mediumUp ? imageProps.ratio.desktop : imageProps.ratio.mobile}>
          <picture>
            <source media={Device.mediumDown} srcSet={mobileImage ? mobileImage.url : image.url} />
            <source media={Device.largeUp} srcSet={image.url} />
            <img src={image.url} loading="lazy" alt={headline} />
          </picture>
        </AspectRatio>
      </Styled.ImageWrapper>
    );
  }

  return null;
};

export default InfoBlockMedia;
