import * as React from "react";
import styled from "styled-components";
import { AbsoluteCenterCSS } from "@styles/layout";
import { Color } from "@styles/constants";

export interface FilterProps {
  category: string;
  handleClick: (filter: string) => void;
  defaultChecked?: boolean;
}

const Filter: React.FC<FilterProps> = ({ category, handleClick, defaultChecked }) => {
  return (
    <FilterItem>
      <input
        type="radio"
        name="catalog"
        value={category}
        id={`catalog-${category}`}
        defaultChecked={defaultChecked}
        onClick={() => {
          handleClick(category);
        }}
      />
      <label
        htmlFor={`catalog-${category}`}
        onClick={() => {
          handleClick(category);
        }}>
        {category}
      </label>
    </FilterItem>
  );
};

export default Filter;

export const FilterItem = styled.li`
  label {
    color: ${Color.white};
  }

  input[type="radio"] {
    position: relative;
    appearance: none;
    background-color: transparent;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 10px;
    height: 10px;
    border: 1px solid ${Color.grayBrand};
    border-radius: 50%;

    &::before {
      ${AbsoluteCenterCSS}
      content: '';
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: ${Color.white};
      opacity: 0;
    }

    &:checked::before {
      opacity: 1;
    }
  }
`;
