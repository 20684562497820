import styled from "styled-components";
import { Device, Ease, Color } from "@styles/constants";
import { AbsoluteCoverCSS } from "@styles/layout";
import { rem } from "@styles/helpers";
import { BodyMediumCSS, SmallBodyRegularCSS } from "@styles/typography";

export const CardCarousel = styled.div`
  position: relative;
  overflow: hidden;
`;

export const Heading = styled.h2`
  max-width: 22ch;
  margin: 0;
`;

export const Copy = styled.div`
  margin-top: ${rem(16)};
  max-width: ${rem(480)};
`;

export const Button = styled.div`
  margin-top: ${rem(40)};
`;

export const Carousel = styled.div`
  margin-top: ${rem(40)};
  position: relative;

  @media ${Device.mediumUp} {
    margin-top: ${rem(60)};
  }

  .swiper-container {
    overflow: visible;
  }

  .swiper-wrapper {
    align-items: flex-start;
    padding-bottom: ${rem(40)};

    @media ${Device.mediumUp} {
      padding-bottom: ${rem(80)};
    }
  }

  .swiper-slide {
    overflow: hidden;

    a {
      ${AbsoluteCoverCSS}
      z-index: 1;
    }

    img {
      transition: transform ${Ease.duration} ${Ease.out};
    }

    &:hover {
      img {
        transform: scale(1.05);
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    --size: ${rem(56)};
    display: none;
    position: absolute;
    top: 37.5%;
    width: var(--size);
    height: var(--size);
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    z-index: 1;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(14.5217px);
    border: 1px solid ${Color.white};
    transform: translateY(-50%);
    transition: opacity ${Ease.duration} ${Ease.out}, background ${Ease.duration} ${Ease.out};

    svg {
      fill: ${Color.white};
      width: 14px;
      height: 14px;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.4);
    }

    @media ${Device.mediumUp} {
      display: flex;
    }
  }

  .swiper-button-next {
    right: 24px;

    svg {
      margin-right: ${rem(3)};
      transform: rotate(-90deg);
    }
  }

  .swiper-button-prev {
    left: 24px;

    svg {
      margin-left: ${rem(3)};
      transform: rotate(90deg);
    }
  }

  .swiper-button-disabled {
    opacity: 0;
    pointer-events: none;
  }
`;

export const SlideImage = styled.div`
  overflow: hidden;
  border-radius: 10px;
  transform: translateZ(0);
`;

export const SlideInfo = styled.div`
  margin-top: ${rem(20)};

  h3 {
    ${BodyMediumCSS}
    margin-bottom: 0;

    + p,
    + ul {
      margin-top: ${rem(5)};
      margin-bottom: 0;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: inline;

    &:not(:last-child)::after {
      content: ", ";
    }
  }

  p,
  ul {
    ${SmallBodyRegularCSS};
    color: ${Color.grayBrand};
  }
`;
