import { PortableTextBlock } from "@portabletext/types";
import { CloudinaryAsset } from "./cloudinary-asset.model";
import { Globals } from "./globals.model";

export namespace Sanity {
  export enum HeadlineSize {
    H1 = "h1",
    H2 = "h2",
    H3 = "h3",
  }

  export enum HeadlinePosition {
    LEFT = "left",
    CENTER = "center",
  }

  export enum ThemeColor {
    LIGHT = "light",
    DARK = "dark",
    DARK_IMAGE = "dark-image",
    NONE = "none", // for lifestyle tabs
  }

  export interface SectioningContext {
    _type: string;
    index: number;
    previousTheme: ThemeColor | null;
    currentTheme: ThemeColor;
    nextTheme: ThemeColor | null;
  }

  export interface Document {
    [x: string]: any;
    _type: string;
    _id: string;
  }

  export interface Slug {
    _type: string;
    current: string;
  }

  export interface Seo {
    title: string;
    description?: string;
    keywords?: string;
    canonical_url?: string;
    og_site_name?: string;
    og_title?: string;
    og_description?: string;
    og_image?: CloudinaryAsset;
    itemprop_title?: string;
    itemprop_desc?: string;
    no_index?: boolean;
  }

  export interface Page extends Document {
    title: string;
    seo: Seo;
    slug: Slug;
    hide_header_banner: boolean;
    transparent_header: boolean;
    show_sticky_subnav: boolean;
    sticky_subnav_heading: string;
    sticky_subnav_links: LinkList;
  }

  export interface PageProps {
    globals: Globals;
  }

  export interface Serializers {
    types?: Record<string, (props: any) => JSX.Element | null>;
    marks?: Record<string, (props: any) => JSX.Element | null>;
    list?: React.Component;
    listItem?: React.Component;
    hardBreak?: React.Component;
    container?: React.Component;
  }

  export type PortableText = PortableTextBlock[];

  export interface Social {
    facebook_url?: string;
    instagram_url?: string;
    twitter_url?: string;
  }

  export interface LinkList {
    title: string;
    contact_links: boolean;
    navigation_links: boolean;
    links: Array<Links>;
  }

  export interface Links {
    _key: string;
    eyebrow: string;
    heading: string;
    sub_heading: string;
    body: string;
    text: string;
    url: string;
    item_class: string;
    external_url: boolean;
    open_intercom: boolean;
    ctas: Cta[];
    media: {
      cloudinary?: CloudinaryAsset;
    };
  }

  export interface Cta {
    text: string;
    url: string;
  }

  export interface AccordionSectionChild {
    heading: string;
    detail_text: PortableText;
    image: CloudinaryAsset;
  }

  export interface AccordionSection {
    title: string;
    text: PortableText;
    image: CloudinaryAsset;
    children: AccordionSectionChild[];
  }

  export interface Accordion extends Document {
    sections: AccordionSection[];
  }
}
