import { useEffect, useState } from "react";
import cn from "classnames";
import { Column } from "@styles/grid";
import { ReactComponent as ExpandSVG } from "@images/expand.svg";
import { ReactComponent as CollapseSVG } from "@images/collapse.svg";
import { ReactComponent as ExpandLargeSVG } from "@images/expand-large.svg";
import { ReactComponent as CollapseLargeSVG } from "@images/collapse-large.svg";
import * as Styled from "./Accordion.styled";

export enum AccordionVariant {
  DEFAULT = "default",
  FAQ = "faq",
}

export interface AccordionProps {
  className?: string;
  variant?: AccordionVariant;
  open?: boolean;
  title: string;
  titleComponent?: React.ReactElement | null;
  titleIcon?: React.ReactElement | null;
  dark?: boolean;
  children?: React.ReactNode;
}

export interface AccordionIconProps {
  variant?: AccordionVariant;
  open: boolean;
}

const AccordionIcon = ({ open = false }: AccordionIconProps) =>
  !open ? <ExpandLargeSVG /> : <CollapseLargeSVG />;

export const Accordion = ({
  className,
  variant,
  open,
  title,
  titleComponent,
  titleIcon,
  dark,
  children,
}: AccordionProps) => {
  const [isOpen, setOpen] = useState(!!open);
  const [panelHeight, setPanelHeight] = useState<number>(0);
  const accoridonId = String(title)
    .replace(/[^a-z0-9]/gi, "-")
    .replace("--", "-")
    .toLowerCase();
  const accordionIdHeader = `accordion-head-${accoridonId}`;
  const accordionIdPanel = `accordion-panel-${accoridonId}`;

  const getPanelHeight = () => {
    var panel = document.getElementById(accordionIdPanel);
    return panel.scrollHeight;
  };

  const togglePanel = () => {
    if (isOpen) {
      setPanelHeight(0);
    } else {
      setPanelHeight(getPanelHeight());
    }

    setOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      setPanelHeight(getPanelHeight());
    }
  }, [accordionIdPanel]);

  return (
    <Column as="section" className={cn(className, "accordion", { isOpen })}>
      <Styled.HeaderButton
        className="accordionHeader"
        id={accordionIdHeader}
        aria-controls={accordionIdPanel}
        aria-expanded={isOpen}
        onClick={togglePanel}
        dark={dark}
        variant={variant}>
        {titleComponent || title}
        <Styled.IconWrap className="iconWrapper" dark={dark} variant={variant}>
          {titleIcon ? titleIcon : <AccordionIcon open={isOpen} />}
        </Styled.IconWrap>
      </Styled.HeaderButton>
      <Styled.AccordionPanel
        panelHeight={panelHeight}
        className="accordionPanel"
        id={accordionIdPanel}
        role="region"
        aria-labelledby={accordionIdHeader}>
        {children}
      </Styled.AccordionPanel>
    </Column>
  );
};

export default Accordion;
