import { useRef, useEffect } from "react";
import SwiperCore from "swiper";
import PortableText from "@components/portable-text";
import AspectRatio from "@components/primitives/aspect-ratio";
import Image from "@components/primitives/image";
import Video from "@components/primitives/video";
import { TimerStatus, UserInteractionDispatch, TimerInteractionDispatch } from "../TimedTabs";
import { Ratio, TabType, FadeOptionsProps } from "../TimedTabs.model";
import { BackgroundWrapper, BackgroundImage } from "../TimedTabs.styled";
import * as Styled from "./Tabs.styled";
import Tab from "./tab";

export interface TabsProps {
  dark: boolean;
  ratio: Ratio;
  tabs: TabType[];
  activeIndex: number;
  timerStatus: TimerStatus;
  animation_duration: number;
  onUserInteraction: UserInteractionDispatch;
  onTimerInteraction: TimerInteractionDispatch;
}

const portableTextVariants = {
  active: {
    opacity: 1,
    y: 0,
    height: "auto",
  },
  inactive: {
    opacity: 0,
    y: "-25%",
    height: 0,
  },
};

const transition = {
  duration: 0.8,
  ease: [0.04, 0.62, 0.23, 0.98],
};

const Tabs = ({
  dark,
  ratio,
  tabs,
  activeIndex,
  timerStatus,
  animation_duration,
  onUserInteraction,
  onTimerInteraction,
}: TabsProps) => {
  const imageCarousel = useRef<HTMLDivElement>();
  const carouselInstance = useRef(null);
  const ratioCalc = ratio.width / ratio.height;

  useEffect(() => {
    if (!imageCarousel.current) return;

    const fadeOptions: FadeOptionsProps = dark
      ? {
          effect: "fade",
          fadeEffect: {
            crossFade: true,
          },
        }
      : {};

    carouselInstance.current = new SwiperCore(imageCarousel.current, {
      allowTouchMove: false,
      speed: dark ? 1000 : 800,
      ...fadeOptions,
    });

    return () => {
      if (carouselInstance.current) {
        carouselInstance.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (!carouselInstance.current) return;
    carouselInstance.current.slideTo(activeIndex);
  }, [activeIndex]);

  if (tabs?.length === 0) return null;
  return (
    <>
      {dark && (
        <BackgroundWrapper>
          {tabs.map(({ background_image }: TabType, i: number) => {
            return (
              background_image?.url && (
                <BackgroundImage key={i} active={i === activeIndex} index={i} length={tabs.length}>
                  <Image asset={background_image} alt={background_image?.alt} widthOnScreen={100} />
                </BackgroundImage>
              )
            );
          })}
        </BackgroundWrapper>
      )}
      <div className="grid-container">
        <Styled.FlexContainer>
          <Styled.ContentWrapper>
            {tabs.map((tab: TabType, i: number) => {
              const active = i === activeIndex;
              return (
                <Tab
                  active={active}
                  position={i}
                  timerStatus={timerStatus}
                  duration={animation_duration}
                  onUserInteraction={onUserInteraction}
                  onTimerInteraction={onTimerInteraction}
                  key={i}>
                  <Styled.TabHeading active={active}>{tab.heading}</Styled.TabHeading>
                  <Styled.AnimatedTextWrapper
                    initial={false}
                    animate={active ? "active" : "inactive"}
                    variants={portableTextVariants}
                    transition={transition}>
                    <PortableText value={tab.body} />
                  </Styled.AnimatedTextWrapper>
                </Tab>
              );
            })}
          </Styled.ContentWrapper>
          <Styled.ImageWrapper>
            <div className="swiper-container" ref={imageCarousel}>
              <div
                className="swiper-wrapper"
                style={{
                  transitionTimingFunction: `cubic-bezier(${transition.ease.map((val) => val)})`,
                }}>
                {tabs.map(({ image, video }: TabType, i: number) => (
                  <div className="swiper-slide" key={i}>
                    {image?.url && (
                      <AspectRatio ratio={ratioCalc}>
                        <Image asset={image} widthOnScreen={50} alt={image?.alt} />
                      </AspectRatio>
                    )}
                    {!image?.url && video?.url && (
                      <Video
                        src={video.url}
                        aspectRatio={ratioCalc}
                        aspectRatioMobile={ratioCalc}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </Styled.ImageWrapper>
        </Styled.FlexContainer>
      </div>
    </>
  );
};

export default Tabs;
