import { SmallBodyMedium, SmallBodyRegular } from "@styles/typography";
import { ReactComponent as CheckSVG } from "@images/checkmark_compare.svg";
import { ReactComponent as CaretSVG } from "@images/caret_black.svg";
import Image from "@components/primitives/image";
import { CompareProps } from "../GlobalCta.model";
import ComparePlan from "../compare-plan/ComparePlan";
import * as GlobalCta from "../GlobalCta.styled";
import * as Styled from "./CompareAccordion.styled";

const CompareAccordion = ({ compare_headline, compare_plans, compare_items }: CompareProps) => {
  return (
    <>
      <Styled.CompareItems>
        {compare_plans.map((plan, index) => (
          <ComparePlan key={index} plan={plan} />
        ))}
      </Styled.CompareItems>
      {compare_headline && (
        <Styled.AccordionHeadline as="h2">{compare_headline}</Styled.AccordionHeadline>
      )}
      <Styled.AccordionWrap>
        {compare_items.map((item, index) => {
          const plans = [item.plans.plan_digital, item.plans.plan_hardware];

          const TitleComponent = () => (
            <Styled.CompareHeadline>
              {item.icon?.url && (
                <GlobalCta.Icon>
                  <Image
                    asset={item.icon}
                    alt={item.icon.alt}
                    widthOnScreen={[20]}
                    aria-hidden="true"
                  />
                </GlobalCta.Icon>
              )}
              <SmallBodyMedium>{item.item_headline}</SmallBodyMedium>
            </Styled.CompareHeadline>
          );

          return (
            <Styled.Accordion
              key={index}
              title={item.item_headline}
              titleComponent={<TitleComponent />}
              titleIcon={<CaretSVG />}
              open>
              <Styled.AccordionContent>
                {plans.map((plan, index) => (
                  <div key={index}>
                    {plan.compare_description ? (
                      <SmallBodyRegular>{plan.compare_description}</SmallBodyRegular>
                    ) : (
                      plan.compare_active && (
                        <GlobalCta.Checkmark>
                          <CheckSVG />
                        </GlobalCta.Checkmark>
                      )
                    )}
                  </div>
                ))}
              </Styled.AccordionContent>
            </Styled.Accordion>
          );
        })}
      </Styled.AccordionWrap>
    </>
  );
};

export default CompareAccordion;
