import styled from "styled-components";
import { rem } from "@styles/helpers";
import { Color, Ease } from "@styles/constants";
import { XSmallBodyRegular, SmallBodyMediumCSS } from "@styles/typography";

export const CompareTable = styled.table`
  margin-bottom: 0;

  tr {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: ${rem(20)};
  }

  th {
    text-align: left;
    font-weight: normal;
  }

  thead,
  tbody {
    background-color: transparent;
    border: 0;

    tr {
      background-color: transparent;
    }
  }
`;

export const TableHeadline = styled.th`
  display: block;
  align-self: flex-end;
  padding-bottom: ${rem(16)};
`;

export const InfoWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const InfoModal = styled(XSmallBodyRegular)`
  width: ${rem(245)};
  color: ${Color.grayBrand};
  background-color: ${Color.white};
  padding: ${rem(12)} ${rem(16)};
  border: 1px solid ${Color.goldAsh};
  border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 50%;
  left: calc(100% + 10px);
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity ${Ease.duration} ${Ease.out};
  z-index: 1;
`;

export const InfoTrigger = styled.button`
  display: block;
  margin-left: ${rem(10)};
  cursor: pointer;
  border-radius: 100%;

  svg {
    path {
      transition: fill ${Ease.duration} ${Ease.out};
      fill: ${Color.grayBorder};
    }
  }

  &:hover,
  &:focus {
    svg {
      path {
        fill: ${Color.grayBrand};
      }
    }

    & + ${InfoModal} {
      opacity: 1;
    }
  }
`;

export const ItemHeadline = styled.th`
  ${SmallBodyMediumCSS}
  padding: ${rem(22)} 0;
  display: flex;
  align-items: center;
`;

export const PlanDetails = styled.th`
  padding: 0;
  border-radius: 10px 10px 0 0;
  border: 1px solid var(--item-color);
  border-bottom: none;

  &:nth-of-type(2) {
    --item-color: ${Color.blueAsh};
    background-color: var(--item-color);
  }

  &:nth-of-type(3) {
    --item-color: ${Color.goldAsh};
  }
`;

export const TableCell = styled.td`
  --border: 1px solid var(--item-color);
  border-left: var(--border);
  border-right: var(--border);
  display: flex;
  justify-content: center;
  align-items: center;

  &:first-of-type {
    --item-color: ${Color.blueAsh};
    background-color: var(--item-color);
  }

  &:last-of-type {
    --item-color: ${Color.goldAsh};
  }

  p {
    margin-bottom: 0;
  }
`;

export const CompareListItem = styled.tr`
  &:nth-of-type(n + 2) {
    border-top: 1px solid ${Color.goldAsh};
  }

  &:last-of-type {
    ${TableCell} {
      border-bottom: 1px solid var(--item-color);
      border-radius: 0 0 10px 10px;
    }
  }
`;
