import { useRef, useState } from "react";
import useMediaQuery from "@hooks/useMediaQuery";
import { Device } from "@styles/constants";
import { LifestyleTabsSchema } from "./LifestyleTabs.model";
import Title from "./title";
import Background from "./background";
import Highlights from "./highlights";
import HighlightsMobile from "./highlights/highlights-mobile";
import { Section } from "./LifestyleTabs.styled";

export interface LifestyleTabsProps {
  sanity: LifestyleTabsSchema;
}

const LifestyleTabs = ({ sanity }: LifestyleTabsProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const isDesktop = useMediaQuery(Device.mediumUp);

  const [activeIndex, setActiveIndex] = useState(0);
  const [showHighlights, setShowHighlights] = useState(false);

  return (
    <Section ref={ref}>
      <Title text={sanity.heading} showHighlights={showHighlights} />
      <Background
        items={sanity.tabs}
        video={sanity.background_video?.url}
        activeIndex={activeIndex}
        showHighlights={showHighlights}
        onShowHighlights={setShowHighlights}
      />
      {isDesktop ? (
        <Highlights
          parentRef={ref}
          items={sanity.tabs}
          duration={sanity.timer_duration}
          activeIndex={activeIndex}
          onActiveIndex={setActiveIndex}
          showHighlights={showHighlights}
        />
      ) : (
        <HighlightsMobile
          items={sanity.tabs}
          duration={sanity.timer_duration}
          activeIndex={activeIndex}
          onActiveIndex={setActiveIndex}
          showHighlights={showHighlights}
        />
      )}
    </Section>
  );
};

export default LifestyleTabs;
