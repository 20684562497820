import { CloudinaryAsset, CLOUDINARY_ASSET_PROJECTION } from "@models/cloudinary-asset.model";
import { Sanity } from "@models/sanity.model";

export const QuoteCarouselType = "quote_carousel";

export type QuoteType = {
  _key: string;
  source: string;
  quote: string;
  logo: CloudinaryAsset;
};

export interface QuoteCarouselSchema extends Sanity.Document {
  timer_duration: number;
  quotes: Array<QuoteType>;
}

export const QUOTE_CAROUSEL_PROJECTION = `{
  ...,
  quotes[] {
    ...,
    logo ${CLOUDINARY_ASSET_PROJECTION}
  }
}`;
