import { nl2br } from "@utils/format";
import useMediaQuery from "@hooks/useMediaQuery";
import AspectRatio from "@components/primitives/aspect-ratio";
import Image from "@components/primitives/image";
import Button from "@components/ui/button";
import { ComparePlan } from "../GlobalCta.model";
import { globalCtaMobile } from "../GlobalCta.styled";
import * as Styled from "./ComparePlan.styled";

export interface CompareItemProps {
  plan: ComparePlan;
}

const ComparePlan = ({ plan }: CompareItemProps) => {
  const { tag, image, plan_name, monthly_price, plan_description, plan_cta } = plan;

  const itemSlug = plan_name.toLowerCase().replace(/[^a-z0-9]/gi, "-");
  const isMobile = useMediaQuery(globalCtaMobile);

  return (
    <Styled.ComparePlan aria-labelledby={itemSlug}>
      {tag && <Styled.Tag>{tag}</Styled.Tag>}
      {image?.url && (
        <Styled.ImageWrap>
          <AspectRatio ratio={isMobile ? 1 : 380 / 223}>
            <Image asset={image} alt={image?.alt} widthOnScreen={[100]} />
          </AspectRatio>
        </Styled.ImageWrap>
      )}
      <Styled.ContentWrap>
        <Styled.PlanName id={itemSlug} as="h3">
          {plan_name}
        </Styled.PlanName>
        {monthly_price && (
          <Styled.Price aria-label={`$${monthly_price} per month`}>
            {`$${monthly_price}`}
            <span>/mo</span>
          </Styled.Price>
        )}
        {plan_description && <Styled.Description>{nl2br(plan_description)}</Styled.Description>}
        {plan_cta?.url && (
          <Styled.CtaWrap>
            <Button href={plan_cta.url}>{plan_cta.text}</Button>
          </Styled.CtaWrap>
        )}
      </Styled.ContentWrap>
    </Styled.ComparePlan>
  );
};

export default ComparePlan;
