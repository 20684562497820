import { useRef, useState, useEffect } from "react";
import { Sanity } from "@models/sanity.model";
import { InfoBlockSchema } from "./InfoBlock.model";
import { Device } from "@styles/constants";
import ContainedContent from "./contained-content";
import FullWidthContent from "./full-width-content";
import InfoBlockMedia from "./info-block-media";
import Button from "@components/ui/button";

export interface InfoBlockProps extends InfoBlockSchema {
  className?: string;
  copy?: Sanity.PortableText;
  children?: React.ReactNode;
}

export const InfoBlock = (props: InfoBlockProps) => {
  const {
    foreground_video,
    foreground_image,
    foreground_image_mobile,
    headline,
    background_video,
    background_image,
    background_image_mobile,
    children,
  } = props;

  const media = useRef(window.matchMedia(Device.mediumUp));
  const [mediumUp, setMediumUp] = useState<boolean>(media.current.matches);

  const setDevice = () => {
    setMediumUp(media.current.matches);
  };

  useEffect(() => {
    let currentMedia = media.current;
    currentMedia.addEventListener("change", setDevice);
    return () => {
      currentMedia.removeEventListener("change", setDevice);
    };
  }, []);

  const sharedProps = {
    ...props,
    mediumUp,
    text_alignment: props.text_alignment || Sanity.HeadlinePosition.CENTER,
  };

  /**
   * Foreground media fields are intended for full-bleed content.
   */
  const foregroundMediaConfig = {
    video: foreground_video,
    videoProps: {
      loop: true,
    },
    image: foreground_image,
    imageProps: {
      ratio: {
        mobile: 1,
        desktop: 16 / 9,
      },
    },
    mobileImage: foreground_image_mobile,
    headline,
    mediumUp,
  };

  /**
   * Background media fields are intended for content contained to the 12-column grid.
   */
  const backgroundMediaConfig = {
    video: background_video,
    videoProps: {
      loop: false,
    },
    image: background_image,
    imageProps: {
      contained: true,
      ratio: {
        mobile: 1,
        desktop: 16 / 9,
      },
    },
    mobileImage: background_image_mobile,
    headline,
    mediumUp,
  };

  const foregroundContent = foreground_image?.url || foreground_video?.url;
  const backgroundContent = background_image?.url || background_video?.url;

  return foregroundContent ? (
    <FullWidthContent {...sharedProps}>
      <InfoBlockMedia {...foregroundMediaConfig} />
    </FullWidthContent>
  ) : (
    <ContainedContent {...sharedProps}>
      {backgroundContent && <InfoBlockMedia {...backgroundMediaConfig} />}
      {children}
    </ContainedContent>
  );
};

export default InfoBlock;
