import styled, { css } from "styled-components";
import { Device } from "@styles/constants";
import { HeadingDisplayCSS, Heading1CSS } from "@styles/typography";

export const Ticker = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: ${({ large }: { large: boolean }) => (large ? `15px` : `5px`)};
  overflow: hidden;
`;

export const Container = styled.div<{ ref?: any; large?: boolean }>`
  display: flex;
  align-items: center;
  width: fit-content;
  animation: ticker 15s linear infinite;
  animation-play-state: paused;
  will-change: transform;

  ${({ large }) =>
    large
      ? css`
          @media ${Device.mediumUp} {
            animation: ticker 30s linear infinite;
          }
        `
      : css`
          animation: ticker 15s linear infinite;
        `}

  @keyframes ticker {
    100% {
      transform: translate3d(-25%, 0, 0);
    }
  }
`;

export const Text = styled.div<{ key?: number; large?: boolean; largeMobile?: boolean }>`
  margin-bottom: 0;
  text-transform: none;

  ${({ large, largeMobile }) =>
    large && !largeMobile
      ? css`
          ${HeadingDisplayCSS}

          @media ${Device.mediumUp} {
            padding: 0 30px;
            white-space: nowrap;
            width: 25%;
            flex: 0 0 25%;
          }
        `
      : css`
          ${HeadingDisplayCSS}
          padding: 0 10px;
          white-space: nowrap;
          width: 25%;
          flex: 0 0 25%;

          @media ${Device.mediumUp} {
            ${Heading1CSS}
          }
        `};
`;
