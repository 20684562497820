import { useRef, useEffect } from "react";
import AspectRatio from "@components/primitives/aspect-ratio";
import { CloudinaryAsset } from "@models/cloudinary-asset.model";
import * as Styled from "./Backgrounds.styled";

export interface BackgroundsProps {
  image?: CloudinaryAsset;
  video?: CloudinaryAsset;
  alt?: string;
  shouldAnimate?: boolean;
}

const Backgrounds = ({ image, video, alt, shouldAnimate }: BackgroundsProps) => {
  const videoRef = useRef<HTMLVideoElement | undefined>();

  useEffect(() => {
    let timeout: number | NodeJS.Timeout;
    if (!videoRef.current) return;

    if (shouldAnimate) {
      timeout = setTimeout(() => {
        if (videoRef.current) {
          videoRef.current.currentTime = 0;
          videoRef.current.play();
        }
      }, 250);
    } else {
      if (timeout) clearTimeout(timeout);
      if (videoRef.current) videoRef.current.pause();
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [shouldAnimate]);

  const renderBackground = () => {
    if (video?.url) {
      return (
        <div className="grid-container">
          <AspectRatio ratio={16 / 9}>
            <video ref={videoRef} src={video.url} muted playsInline disablePictureInPicture loop />
          </AspectRatio>
        </div>
      );
    }

    if (image?.url) {
      return (
        <div className="grid-container">
          <AspectRatio ratio={16 / 9}>
            <img src={image.url} loading="lazy" alt={alt} />
          </AspectRatio>
        </div>
      );
    }

    return null;
  };

  return (
    <Styled.Backgrounds
      initial={{ opacity: 0, visibility: "hidden" }}
      animate={
        shouldAnimate ? { opacity: 1, visibility: "visible" } : { opacity: 0, visibility: "hidden" }
      }
      transition={{
        opacity: {
          duration: 0.3,
          delay: shouldAnimate ? 0.25 : 0,
        },
        visibility: {
          duration: 0,
          delay: shouldAnimate ? 0.25 : 0.3,
        },
      }}>
      {renderBackground()}
    </Styled.Backgrounds>
  );
};

export default Backgrounds;
