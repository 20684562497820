import { useRef, useEffect } from "react";
import SwiperCore, { A11y, Scrollbar, Navigation } from "swiper";
import { Eyebrow } from "@styles/typography";
import { ReactComponent as CaretSVG } from "@images/caret.svg";
import Button from "@components/ui/button";
import InstructorSlide from "./instructor-slide/InstructorSlide";
import PartnerSlide from "./partner-slide/PartnerSlide";
import { CardCarouselSchema, CarouselCardType } from "./CardCarousel.model";
import * as Styled from "./CardCarousel.styled";
import { FadeIn, SlideInText } from "@components/ui/animations";

SwiperCore.use([A11y, Scrollbar, Navigation]);

const CardCarousel = ({
  eyebrow,
  heading,
  copy,
  cta,
  cards,
  anchor_link,
  inView,
}: CardCarouselSchema) => {
  const swiperElRef = useRef<HTMLDivElement | undefined>();
  const swiperInstanceRef = useRef(null);

  useEffect(() => {
    if (!swiperElRef.current) return;

    swiperInstanceRef.current = new SwiperCore(swiperElRef.current, {
      a11y: {
        prevSlideMessage: "Previous slide",
        nextSlideMessage: "Next slide",
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      slidesPerView: 1.25,
      spaceBetween: 15,
      scrollbar: {
        el: ".swiper-scrollbar",
        draggable: true,
        dragSize: 101,
      },
      breakpoints: {
        640: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 20,
          scrollbar: {
            el: ".swiper-scrollbar",
            draggable: true,
            dragSize: 358,
          },
        },
      },
    });

    return () => {
      if (swiperInstanceRef.current) {
        swiperInstanceRef.current.destroy();
      }
    };
  }, []);

  return (
    <Styled.CardCarousel id={anchor_link}>
      <div className="grid-container">
        {eyebrow && (
          <Eyebrow>
            <SlideInText visible={inView}>{eyebrow}</SlideInText>
          </Eyebrow>
        )}
        {heading && (
          <Styled.Heading>
            <SlideInText visible={inView} addDelay={[eyebrow]}>
              {heading}
            </SlideInText>
          </Styled.Heading>
        )}
        {copy && (
          <FadeIn visible={inView} addDelay={[eyebrow, heading]}>
            <Styled.Copy>{copy}</Styled.Copy>
          </FadeIn>
        )}
        {cta?.url && (
          <FadeIn visible={inView} addDelay={[eyebrow, heading]}>
            <Styled.Button>
              <Button text={cta.text} href={cta.url} />
            </Styled.Button>
          </FadeIn>
        )}

        {cards?.length > 0 && (
          <FadeIn visible={inView} delay={0.5} addDelay={[eyebrow, heading]}>
            <Styled.Carousel>
              <div className="swiper-container card-carousel-swiper" ref={swiperElRef}>
                <div className="swiper-wrapper">
                  {cards.map((card: CarouselCardType) => {
                    return (
                      <div className="swiper-slide" key={card._id}>
                        {(() => {
                          switch (card._type) {
                            case "instructor":
                              return <InstructorSlide instructor={card} />;
                            case "partnership":
                              return <PartnerSlide partner={card} />;
                            default:
                              return null;
                          }
                        })()}
                      </div>
                    );
                  })}
                </div>

                <button className="swiper-button-prev" aria-label="Move carousel left">
                  <CaretSVG />
                </button>

                <button className="swiper-button-next" aria-label="Move carousel right">
                  <CaretSVG />
                </button>

                <div className="swiper-scrollbar"></div>
              </div>
            </Styled.Carousel>
          </FadeIn>
        )}
      </div>
    </Styled.CardCarousel>
  );
};

export default CardCarousel;
