import { useRef, useState, useEffect } from "react";
import { useTransform, AnimatePresence, useScroll } from "framer-motion";
import useWindowSize from "@hooks/useWindowSize";
import getRootOffset from "@utils/getRootOffset";
import { HighlightsProps } from "../LifestyleTabs.model";
import * as Styled from "./Title.styled";

export interface TitleProps extends HighlightsProps {
  text: string;
}

const Title = ({ text, showHighlights }: TitleProps) => {
  const windowSize = useWindowSize();

  const ref = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState({ start: 0, afterStart: 0, end: 0 });

  const { scrollY } = useScroll();

  const opacity = useTransform(
    scrollY,
    [position.start, position.afterStart, position.end],
    [0, 1, 0]
  );
  const y = useTransform(scrollY, [position.start, position.end], [300, 0]);

  useEffect(() => {
    if (!ref.current) return;

    const bounds = getRootOffset(ref.current);

    setPosition({
      start: bounds.top - windowSize.height,
      afterStart: bounds.top - windowSize.height * 0.25,
      end: bounds.top,
    });
  }, [windowSize]);

  return (
    <>
      <AnimatePresence>
        {!showHighlights && (
          <Styled.Heading
            ref={ref}
            initial={{ opacity: 0 }}
            style={{ opacity, y }}
            exit={{ opacity: 0 }}>
            <h2>{text}</h2>
          </Styled.Heading>
        )}
      </AnimatePresence>
    </>
  );
};

export default Title;
