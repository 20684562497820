import Link from "next/link";
import AspectRatio from "@components/primitives/aspect-ratio";
import Image from "@components/primitives/image";
import { Sanity } from "@models/sanity.model";
import { CloudinaryAsset } from "@models/cloudinary-asset.model";
import { GalleryCardProps } from "../Gallery.model";
import { classesRoute } from "@utils/routes";
import * as Styled from "../Gallery.styled";

interface ClassCardProps extends GalleryCardProps {
  title: string;
  slug: Sanity.Slug;
  routing_media: CloudinaryAsset;
  thumbnail_image: CloudinaryAsset;
}

const ClassCard = ({
  type,
  title,
  slug,
  routing_media,
  thumbnail_image,
  createItem,
  idx,
  isBottom,
  bottomItems,
  handleLinkEnter,
  handleLinkLeave,
}: ClassCardProps) => {
  return (
    <Styled.GalleryItem
      ref={(el: HTMLElement) =>
        createItem(el, isBottom ? idx + bottomItems.current.length : idx, isBottom)
      }
      $type={type}>
      <AspectRatio ratio={580 / 387}>
        <Styled.GalleryItemInner>
          {routing_media?.url && <Image asset={routing_media} widthOnScreen={[100]} alt={title} />}
          <Styled.GalleryItemCaption>
            {/* <Link
              href={{
                pathname: classesRoute,
                query: { slug: slug?.current },
              }}
              onMouseEnter={handleLinkEnter}
              onMouseLeave={handleLinkLeave}
            /> */}
            {thumbnail_image?.url && (
              <Image asset={thumbnail_image} widthOnScreen={[35, 35]} alt={title} />
            )}
            {title && <Styled.GalleryItemTitle>{title}</Styled.GalleryItemTitle>}
          </Styled.GalleryItemCaption>
        </Styled.GalleryItemInner>
      </AspectRatio>
    </Styled.GalleryItem>
  );
};

export default ClassCard;
