import styled from "styled-components";
import { Color, Device, Spacing } from "@styles/constants";
import { Heading3CSS } from "@styles/typography";
import { rem } from "@styles/helpers";

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const ListItem = styled.li`
  --padding: ${rem(25)};
  flex-basis: 100%;
  padding-top: ${Spacing.xSmall};
  padding-bottom: ${Spacing.xSmall};
  padding-right: var(--padding);
  padding-left: var(--padding);
  text-align: center;

  @media ${Device.mediumUp} {
    flex-basis: 50%;
  }

  @media ${Device.largeUp} {
    --padding: ${rem(50)};
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${Color.grayBrand};
  }

  &:nth-child(n + 3) {
    @media ${Device.mediumUp} {
      border-bottom: 0;
    }
  }

  &:nth-child(odd) {
    @media ${Device.mediumUp} {
      border-right: 1px solid ${Color.grayBrand};
    }
  }

  p {
    max-width: 400px;
    margin: 0 auto;
  }
`;

export const Heading = styled.h2`
  ${Heading3CSS}
`;
