import dynamic from "next/dynamic";
import HeroFeatured from "@components/hero-featured";
import HeadingGrid from "@components/heading-grid";
import Intro from "@components/intro";
import Newsletter from "@components/newsletter";
import StoreBanner from "@components/store-banner";
import Gallery from "@components/gallery";
import Bundles from "@components/bundles";
import FullBleedBanner from "@components/full-bleed-banner";
import ProductHero from "@components/product-hero";
import ReviewCarousel from "@components/reviews/review-carousel";
import LifestyleTabs from "@components/lifestyle-tabs";
import AccessoriesLandingHero from "@components/accessories/landing-hero";
import AccessoriesSection from "@components/accessories/section";
import AccordionRepeater from "@components/accordion-repeater";
import CardCarousel from "@components/card-carousel";
import Catalog from "@components/catalog";
import Categories from "@components/categories";
import DigitalTrialCategories from "@components/digital-trial-categories";
import DigitalTrialInstructors from "@components/digital-trial-instructors";
import QuoteCarousel from "@components/quote-carousel";
import FiftyFifty from "@components/fifty-fifty";
import Footnotes from "@components/footnotes";
import HeroFifty from "@components/hero-fifty";
import Jumbotron from "@components/jumbotron";
import ListCards from "@components/list-cards";
import ProgramBlock from "@components/program-blocks";
import PurchaseBlock from "@components/purchase-block";
import PressCarousel from "@components/press-carousel";
import Scroll from "@components/scroll";
import SectionFaq from "@components/section-faq";
import SectionHero from "@components/section-hero";
import ValueProps from "@components/value-props";
import TextBlock from "@components/text-block";
import HeroSlim from "@components/hero-slim";
import FiftyFiftyApp from "@components/fifty-fifty-app";
import HeroPrimary from "@components/hero-primary";
import LocationsFilter from "@components/locations-filter";
import TrialEmailCapture from "@components/trial-email-capture";
import GlobalCta from "@components/global-cta";
import ComparePlans from "@components/compare-plans";

export const modules: { [key: string]: any } = {
  accessories_landing_hero: AccessoriesLandingHero,
  accessories_section: AccessoriesSection,
  accordian: AccordionRepeater,
  // branded_partnerships: BrandedPartnerships,
  bundles: Bundles,
  card_carousel: CardCarousel,
  carousel_fifty: dynamic(async () => await import("@components/carousel-fifty/CarouselFifty"), {
    ssr: false,
  }),
  catalog: Catalog,
  categories: Categories,
  // classes_overview: ClassesOverview,
  // content_blocks: BenefitsOverview,
  compare_plans: ComparePlans,
  digital_trial_categories: DigitalTrialCategories,
  digital_trial_instructors: DigitalTrialInstructors,
  digital_trial_quote_carousel: QuoteCarousel,
  // elevator: OnTheGo,
  fifty_fifty: FiftyFifty,
  fifty_fifty_app: FiftyFiftyApp,
  footnotes: Footnotes,
  full_bleed_banner: FullBleedBanner,
  // full_bleed_ticker: FullBleedImageWithTicker,
  gallery: Gallery,
  global_cta: GlobalCta,
  heading_grid: HeadingGrid,
  hero: ProductHero,
  hero_featured: HeroFeatured,
  hero_fifty: HeroFifty,
  hero_primary: HeroPrimary,
  hero_slim: HeroSlim,
  info_block: dynamic(async () => await import("@components/info-block"), {
    ssr: false,
  }),
  // instructors: Instructors,
  intro: Intro,
  jumbotron: Jumbotron,
  library: dynamic(async () => await import("@components/library"), {
    ssr: false,
  }),
  // library_scroll: LibraryScroll,
  lifestyle_tabs: LifestyleTabs,
  list_cards: ListCards,
  locations_filter: LocationsFilter,
  // madlibs: BenefitsSelector,
  newsletter: Newsletter,
  program_blocks: ProgramBlock,
  purchase_block: PurchaseBlock,
  quote_carousel: PressCarousel,
  // recommendations: Recommendations,
  review_carousel: ReviewCarousel,
  scroll: Scroll,
  section_faq: SectionFaq,
  section_hero: SectionHero,
  specs_cells: dynamic(async () => await import("@components/specs-cells"), {
    ssr: false,
  }),
  store_banner: StoreBanner,
  text_block: TextBlock,
  // ticker: Ticker,
  timed_tabs: dynamic(async () => await import("@components/timed-tabs"), {
    ssr: false,
  }),
  trial_email_capture: TrialEmailCapture,
  value_props: ValueProps,
  workouts: Gallery,
};
