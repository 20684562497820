import styled from "styled-components";
import { Color, Device, Ease, Font } from "@styles/constants";
import { AbsoluteCenterXCSS, AbsoluteCoverCSS } from "@styles/layout";
import { Eyebrow as EyebrowText, XSmallBodyMediumCSS } from "@styles/typography";
import ButtonMedia from "@components/ui/button-media";

export const HeroFeatured = styled.div`
  position: relative;
  width: 100%;
  height: calc(80vh - var(--banner-height));
  overflow: hidden;
  min-height: 380px;

  &::after {
    content: "";
    ${AbsoluteCoverCSS}
    background: ${Color.black};
    opacity: 0.2;
  }

  h1,
  span {
    text-align: center;
    margin-left: auto;
    margin-right: auto;

    @media ${Device.mediumUpLandscape} {
      margin-left: 0;
      text-align: left;
    }
  }
`;

export const Background = styled.div`
  height: 100%;
  width: 100%;

  video,
  image {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export const Container = styled.div`
  ${AbsoluteCenterXCSS}
  width: 100%;
  top: 120px;
  z-index: 1;

  @media ${Device.mediumUpLandscape} {
    top: auto;
    bottom: 46px;
  }
`;

export const Heading = styled.h1`
  max-width: 14ch;
  margin-bottom: 0;
  color: ${Color.white};
`;

export const Eyebrow = styled(EyebrowText)`
  color: ${Color.white};

  + h1 {
    margin-top: 10px;

    @media ${Device.mediumUpLandscape} {
      margin-top: 40px;
    }
  }
`;

export const PlayButton = styled(ButtonMedia)<{ onClick: any }>`
  position: relative;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
  top: initial;
  left: initial;
  transform: none;
  padding: 15px 24px;

  @media ${Device.mediumUpLandscape} {
    margin-top: 30px;
    margin-left: initial;
  }

  > span {
    & {
      font-family: ${Font.semibold};
      font-size: 14px;
      line-height: 1.29;
      text-transform: uppercase;
      letter-spacing: 0.1em;
    }

    &:last-child {
      & {
        padding-top: 0;
        width: 15px;
      }
    }
  }
`;

export const ExploreButton = styled.button<{ onClick: any }>`
  --size: 80px;
  ${XSmallBodyMediumCSS}
  ${AbsoluteCenterXCSS}
  bottom: 30px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);
  color: ${Color.white};
  cursor: pointer;
  z-index: 2;

  @media ${Device.mediumUpLandscape} {
    position: relative;
    margin-top: 40px;
    transform: none;
    left: 0;
    bottom: 0;
    border: 0;
    width: auto;
    height: auto;
  }

  span {
    margin: 0 auto 2px;
  }

  svg {
    fill: ${Color.white};
    width: 14px;
  }
`;

export const Carousel = styled.div`
  height: 100%;
  width: 100%;

  .swiper,
  .swiper-wrapper,
  img {
    height: 100%;
    width: 100%;
  }

  img {
    display: block;
    object-fit: cover;
  }
`;

export const Image = styled.div`
  height: 100%;
  width: 100%;

  img {
    height: 100%;
    width: 100%;
    display: block;
    object-fit: cover;
  }
`;

export const Controls = styled.div`
  position: absolute;
  bottom: 10px;
  right: 15px;
  z-index: 1;

  button {
    width: 8px;
    cursor: pointer;
    transition: opacity ${Ease.duration} ${Ease.out};

    &:hover {
      opacity: 0.5;
    }

    &:focus {
      outline: 1px dotted ${Color.white};
    }
  }

  @media ${Device.mediumUp} {
    bottom: 15px;
    right: 20px;

    button {
      width: 10px;
    }
  }
`;
