import cn from "classnames";
import { Eyebrow, PortableTextWrapper } from "@styles/typography";
import { Sanity } from "@models/sanity.model";
import Button from "@components/ui/button/Button";
import { ContainedProps } from "../InfoBlock.model";
import * as Styled from "../InfoBlock.styled";
import PortableText from "@components/portable-text";

const ContainedContent = ({
  className,
  text_alignment,
  eyebrow,
  headline,
  headline_size,
  subheadline,
  copy,
  cta,
  children,
}: ContainedProps) => {
  const getHeadlineClass = () => {
    switch (headline_size) {
      case Sanity.HeadlineSize.H1:
        return text_alignment === Sanity.HeadlinePosition.LEFT ? "large-9" : "large-12";
      case Sanity.HeadlineSize.H3:
        return text_alignment === Sanity.HeadlinePosition.LEFT ? "large-6" : "large-9";
      default:
        return "large-9";
    }
  };

  return (
    <div className={cn("grid-container", className || null)}>
      <Styled.ContentContainer alignment={text_alignment}>
        {eyebrow && <Eyebrow>{eyebrow}</Eyebrow>}
        {headline && (
          <Styled.Headline
            headlineSize={headline_size}
            alignment={text_alignment}
            className={cn(getHeadlineClass())}>
            {headline}
          </Styled.Headline>
        )}
        {subheadline && (
          <Styled.Subheadline className="subheadline">{subheadline}</Styled.Subheadline>
        )}
        {copy && (
          <Styled.Copy>
            <PortableTextWrapper>
              <PortableText value={copy} />
            </PortableTextWrapper>
          </Styled.Copy>
        )}
        {cta?.url && (
          <Styled.Cta>
            <Button text={cta.text} href={cta.url} />
          </Styled.Cta>
        )}
      </Styled.ContentContainer>
      <Styled.Children>{children}</Styled.Children>
    </div>
  );
};

export default ContainedContent;
