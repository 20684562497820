import { CloudinaryAsset, CLOUDINARY_ASSET_PROJECTION } from "@models/cloudinary-asset.model";
import { Sanity } from "@models/sanity.model";

export const LifestyleTabsType = "lifestyle_tabs";

export interface LifestyleTabsSchema extends Sanity.Document {
  heading: string;
  background_image?: CloudinaryAsset;
  background_video?: CloudinaryAsset;
  timer_duration: number;
  tabs: Array<LifestyleTab>;
}

export type LifestyleTab = {
  heading: string;
  body: Sanity.PortableText;
  image: CloudinaryAsset;
  background_video: CloudinaryAsset;
};

export interface HighlightsProps {
  parentRef?: {
    current: HTMLDivElement;
  };
  items?: LifestyleTab[];
  duration?: number;
  activeIndex?: number;
  onActiveIndex?: React.Dispatch<React.SetStateAction<number>>;
  showHighlights?: boolean;
}

export const LIFESTYLE_TABS_PROJECTION = `{
  heading,
  background_image ${CLOUDINARY_ASSET_PROJECTION},
  background_video ${CLOUDINARY_ASSET_PROJECTION},
  tab_duration,
  tabs[] {
    heading,
    body,
    image ${CLOUDINARY_ASSET_PROJECTION},
    background_video ${CLOUDINARY_ASSET_PROJECTION}
  }
}`;
