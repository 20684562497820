import { ReactNode } from "react";
import styled from "styled-components";
import { EasePage } from "@styles/constants";
import { UIAnimationProps } from ".";

export type MaskInMediaProps = UIAnimationProps & {
  children?: ReactNode;
};

const StyledContainer = styled.div<{ delay?: number; visible?: boolean }>`
  @keyframes maskIn {
    0% {
      clip-path: inset(100% 0 0 0);
    }
    100% {
      clip-path: inset(0 0 0 0);
    }
  }

  clip-path: inset(0 0 100% 0);

  ${({ delay, visible }) =>
    visible && `animation: maskIn ${EasePage.duration} ${EasePage.curve} ${delay}s forwards;`}
`;

export const MaskInMedia = ({ visible, delay = 0, addDelay, children }: MaskInMediaProps) => {
  if (process.env.NEXT_PUBLIC_PAGE_ANIMATIONS !== "true") return <>{children}</>;

  return (
    <StyledContainer
      delay={
        delay +
        (addDelay?.reduce((accumulator, value) => (value ? accumulator + 0.1 : accumulator), 0) ||
          0)
      }
      visible={visible}>
      {children}
    </StyledContainer>
  );
};
