import { Select } from "@components/primitives/input";
import {
  BundleOption,
  ProductConfigurationOption,
} from "@components/product/product-configuration";
import { SanitySyncVariant } from "@models/product.model";
import { Shopify } from "@models/shopify.model";
import { XSmallBodyBold } from "@styles/typography";
import {
  SelectVariantWithBundleOption,
  SelectVariantWithProductOption,
  SelectVariantWithVariants,
} from "./SelectVariant.model";

/**
 * Ensures only one out of `variants`, `productOption`, and `bundleOption`
 * are provided to SelectVariant.
 */
export type SelectVariantProps =
  | SelectVariantWithVariants
  | SelectVariantWithProductOption
  | SelectVariantWithBundleOption;

/**
 * Availability can be determined before a user makes a selection
 * since each option represents a specific variant.
 */
const optionsFromVariants = (variants: Array<SanitySyncVariant | Shopify.ProductVariant>) => {
  if (!variants) return null;
  return variants.map((variant: SanitySyncVariant | Shopify.ProductVariant) => {
    let inStock = false;

    if ("available" in variant) {
      inStock = variant.available;
    } else {
      inStock = variant.availableForSale;
    }

    return (
      <option key={variant.id} value={variant.id} disabled={!inStock}>
        {variant.title}
        {!inStock && " - OUT OF STOCK"}
      </option>
    );
  });
};
/**
 * Availability cannot be determined until all options within the product
 * are selected.
 */
const optionsFromProduct = (productOption: ProductConfigurationOption) => {
  if (!productOption) return null;
  return productOption.values.map((value) => {
    const optionProps = {
      "data-option-id": productOption.id,
      value: typeof value === "string" ? value : value.value,
    };

    return (
      <option key={value} {...optionProps}>
        {optionProps.value}
      </option>
    );
  });
};
/**
 * Availability cannot be determined until all options within the bundle
 * are selected.
 */
const optionsFromBundle = (bundleOption: BundleOption) => {
  if (!bundleOption) return null;
  return bundleOption.values.map((value) => {
    const optionProps = {
      "data-option-id": bundleOption.key,
    };

    return (
      <option key={value} value={value} {...optionProps}>
        {value}
      </option>
    );
  });
};

const SelectVariant = ({
  id,
  title,
  label,
  description,
  variants,
  productOption,
  bundleOption,
  value,
  onChange,
}: SelectVariantProps) => {
  const optionSources = {
    bundle: optionsFromBundle(bundleOption),
    product: optionsFromProduct(productOption),
    variants: optionsFromVariants(variants),
  };
  const renderedOptions = Object.values(optionSources).find((source) => !!source);
  if (!renderedOptions) return null;

  return (
    <>
      {title && (
        <XSmallBodyBold>
          <span style={{ color: "red" }}>* </span>
          {`Select ${title}`}
        </XSmallBodyBold>
      )}

      {description && <p className="variant-select-description">{description}</p>}

      <Select
        label={title ? `Select ${title}` : label}
        name={`variant-${id}`}
        id={id}
        value={value}
        onChange={onChange}
        omitErrors
        disabled={!renderedOptions.length}>
        <option value={""} />
        {renderedOptions}
      </Select>
    </>
  );
};

export default SelectVariant;
