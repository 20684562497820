import { useEffect, useRef, useState } from "react";
import SwiperCore from "swiper";
import useMediaQuery from "@hooks/useMediaQuery";
import useWindowSize from "@hooks/useWindowSize";
import { parseVideo } from "@utils/parseVideo";
import { ReactComponent as PauseSVG } from "@images/pause.svg";
import { ReactComponent as PlaySVG } from "@images/play.svg";
import { ReactComponent as CaretSVG } from "@images/caret.svg";
import { Device } from "@styles/constants";
import ModalVideo from "@components/modals/ModalVideo";
import * as Styled from "./HeroFeatured.styled";
import { HeroFeaturedSchema } from "./HeroFeatured.model";

interface HeroFeaturedProps {
  sanity: HeroFeaturedSchema;
}

const HeroFeatured = ({ sanity }: HeroFeaturedProps) => {
  const {
    eyebrow,
    heading,
    cta_text,
    background_video,
    images,
    video_button_text,
    video,
    video_hash,
  } = sanity || {};
  const isDesktop = useMediaQuery(Device.mediumUpLandscape);
  const windowSize = useWindowSize();
  const [playing, setPlaying] = useState<boolean>(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [videoInfo, setVideoInfo] = useState<{ type: string; id: string }>({
    type: "",
    id: "",
  });
  const videoRef = useRef<HTMLVideoElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const boundsRef = useRef<DOMRect | undefined>();
  const swiperElRef = useRef<HTMLDivElement>(null);
  const swiperInstanceRef = useRef<any | undefined>();

  useEffect(() => {
    if (!containerRef.current) return;
    boundsRef.current = containerRef.current.getBoundingClientRect();
  }, [windowSize]);

  useEffect(() => {
    if (!swiperElRef.current) return;
    if (!swiperInstanceRef.current)
      swiperInstanceRef.current = new SwiperCore(swiperElRef.current, {
        slidesPerView: 1,
        autoplay: true,
        a11y: {
          enabled: true,
          prevSlideMessage: "Previous slide",
          nextSlideMessage: "Next slide",
        },
      });

    return () => {
      if (swiperInstanceRef.current) {
        swiperInstanceRef.current.destroy();
      }
    };
  }, []);

  const handlePlay = () => {
    if (!videoRef.current) return;
    setPlaying(true);
    videoRef.current.play();
  };

  const handlePause = () => {
    if (!videoRef.current) return;
    setPlaying(false);
    videoRef.current.pause();
  };

  const handleExploreButtonClick = (e: MouseEvent) => {
    e.preventDefault();

    if (!boundsRef.current) return;

    window.scrollTo({
      top: boundsRef.current.height + boundsRef.current.top,
      left: 0,
      behavior: "smooth",
    });
  };

  const handlePlayClick = (e: MouseEvent) => {
    e.preventDefault();
    if (!video) return;
    setVideoInfo(parseVideo(video));
    setModalOpen(true);
    handlePause(); // pause bg video
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    handlePlay(); // resume bg video
  };

  const renderVideo = () => {
    const videoAttr = {
      muted: true,
      loop: true,
      playsInline: true,
      autoPlay: true,
      disablePictureInPicture: true,
    };

    return (
      <>
        <video
          ref={videoRef}
          src={background_video?.url}
          {...videoAttr}
          onPlay={() => setPlaying(true)}
        />

        <Styled.Controls>
          {playing ? (
            <button onClick={handlePause}>
              <PauseSVG />
            </button>
          ) : (
            <button onClick={handlePlay}>
              <PlaySVG />
            </button>
          )}
        </Styled.Controls>
      </>
    );
  };

  const renderImageCarousel = () => {
    if (!images?.length) return;

    if (images?.length > 1) {
      return (
        <Styled.Carousel>
          <div className="swiper" ref={swiperElRef}>
            <div className="swiper-wrapper">
              {images.map((image, idx) => (
                <div className="swiper-slide" key={idx}>
                  <img src={image.url} alt={image.alt} />
                </div>
              ))}
            </div>
          </div>
        </Styled.Carousel>
      );
    }

    return <Styled.Image>{images && <img src={images[0].url} alt={images[0].alt} />}</Styled.Image>;
  };

  const exploreBtn = (
    <Styled.ExploreButton onClick={handleExploreButtonClick}>
      <span>{cta_text}</span>
      <CaretSVG />
    </Styled.ExploreButton>
  );

  return (
    <Styled.HeroFeatured ref={containerRef}>
      <Styled.Background>
        {background_video?.url ? renderVideo() : renderImageCarousel()}
      </Styled.Background>

      <Styled.Container className="grid-container">
        {eyebrow && <Styled.Eyebrow>{eyebrow}</Styled.Eyebrow>}
        {heading && <Styled.Heading>{heading}</Styled.Heading>}
        {video_button_text && video && (
          <Styled.PlayButton text={video_button_text} onClick={handlePlayClick} />
        )}
        {isDesktop && cta_text && exploreBtn}
      </Styled.Container>

      {!isDesktop && cta_text && exploreBtn}

      <ModalVideo
        className="hero-featured-modal-video"
        mounted={modalOpen}
        videoId={videoInfo.id}
        videoParams={{
          h: video_hash,
          autoplay: 1,
          autopause: 0,
        }}
        closeModal={handleCloseModal}
        full
      />
    </Styled.HeroFeatured>
  );
};

export default HeroFeatured;
