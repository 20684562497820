import { Eyebrow, Heading1, SmallBodyRegular } from "@styles/typography";
import CompareTable from "./compare-table";
import CompareMobile from "./compare-mobile";
import { ComparePlansSchema } from "./ComparePlans.model";
import * as Styled from "./ComparePlans.styled";

export interface ComparePlansProps {
  sanity: ComparePlansSchema;
}

const ComparePlans = ({ sanity }: ComparePlansProps) => {
  const {
    eyebrow,
    headline,
    copy,
    compare_plan_items,
    compare_items_sections,
    compare_plans_footnotes,
  } = sanity;

  const drilledProps = {
    comparePlans: compare_plan_items,
    compareSections: compare_items_sections,
  };

  return (
    <Styled.ComparePlans className="grid-container">
      <Styled.HeadingWrap className="large-10">
        {eyebrow && <Eyebrow>{eyebrow}</Eyebrow>}
        {headline && <Heading1 as="h2">{headline}</Heading1>}
      </Styled.HeadingWrap>
      <Styled.CompareWrap>
        <Styled.Copy>{copy && <SmallBodyRegular>{copy}</SmallBodyRegular>}</Styled.Copy>
        <CompareTable {...drilledProps} />
        <CompareMobile {...drilledProps} />
        {compare_plans_footnotes?.length > 0 && (
          <Styled.Footnotes footnotes={compare_plans_footnotes} />
        )}
      </Styled.CompareWrap>
    </Styled.ComparePlans>
  );
};

export default ComparePlans;
