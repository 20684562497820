import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import { AbsoluteCenterXCSS } from "@styles/layout";

const sizing = css`
  margin-left: -3px;
  margin-bottom: -4px;
  top: auto;
  bottom: 0;

  @media screen and (min-height: 1024px) {
    bottom: 5vh;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  width: 280px;

  @media screen and (min-height: 850px) {
    width: 320px;
  }

  @media screen and (min-height: 950px) {
    width: 375px;
  }

  video,
  img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  video {
    padding: 66px 60px 66px 66px;

    @media screen and (min-height: 850px) {
      padding: 72px 64px 70px 71px;
    }
  }
`;

export const StyledMirrorOverlaysBackground = styled(motion.div)`
  ${AbsoluteCenterXCSS}

  ${sizing}
`;

export const StyledContainer = styled(motion.div)`
  ${AbsoluteCenterXCSS}
  z-index: 1;

  ${sizing}
`;

export const StyledMirrorOverlay = styled(motion.div)<{ $foreground: boolean }>`
  ${({ $foreground }) =>
    $foreground &&
    css`
      position: absolute;
      top: 35%;
      right: 60%;
      width: 100%;

      img {
        height: 60px;
        width: auto;
        margin-right: 0;
        margin-left: auto;
        display: block;
        border-radius: 0px;
        object-fit: contain;
      }
    `};
`;
