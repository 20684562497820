import { QueryModalTriggerProps } from "@components/query-modal/query-modal-trigger";
import { CloudinaryAsset, CLOUDINARY_ASSET_PROJECTION } from "@models/cloudinary-asset.model";
import { Sanity } from "@models/sanity.model";

export const HeroPrimaryType = "hero_primary";

export enum HorizontalAlignment {
  LEFT = "left",
  CENTER = "center",
}

export enum VerticalAlignment {
  TOP = "top",
  BOTTOM = "bottom",
}

export enum HeadingSize {
  H1 = "h1",
  H2 = "h2",
}

export interface HeroPrimarySchema extends Sanity.Document {
  slim?: boolean;
  horizontal_alignment?: HorizontalAlignment;
  vertical_alignment?: VerticalAlignment;
  darkText: boolean;
  eyebrow?: string;
  heading_size?: HeadingSize;
  heading: string;
  body?: string;
  cta?: Sanity.Cta;
  background?: CloudinaryAsset;
  background_mobile?: CloudinaryAsset;
  foreground?: CloudinaryAsset;
  foreground_mobile?: CloudinaryAsset;
  video_modal?: {
    video_cta_text?: string;
    video_id?: string;
    video_hash?: string;
  };
}

export const HERO_PRIMARY_PROJECTION = `{
  slim,
  horizontal_alignment,
  vertical_alignment,
  darkText,
  eyebrow,
  heading_size,
  heading,
  body,
  cta,
  background ${CLOUDINARY_ASSET_PROJECTION},
  background_mobile ${CLOUDINARY_ASSET_PROJECTION},
  foreground ${CLOUDINARY_ASSET_PROJECTION},
  foreground_mobile ${CLOUDINARY_ASSET_PROJECTION},
  video_modal {
    video_cta_text,
    video_id,
    video_hash
  }
}`;
