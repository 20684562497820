import { useRef, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { HighlightsProps } from "@components/lifestyle-tabs/LifestyleTabs.model";
import * as Styled from "./HighlightsMobile.styled";
import PortableText from "@components/portable-text";

const HighlightsMobile = ({
  items,
  duration,
  activeIndex,
  onActiveIndex,
  showHighlights,
}: HighlightsProps) => {
  const { ref, inView } = useInView();

  const durationRef = useRef<number>(duration ? duration * 1000 : 6000);
  const timerRef = useRef<number>(0);

  const startTimer = () => {
    timerRef.current = window.setInterval(() => {
      onActiveIndex((c) => (c + 1) % items.length);
    }, durationRef.current);
  };

  const stopTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = 0;
    }
  };

  useEffect(() => {
    if (showHighlights && inView) {
      startTimer();
    } else {
      onActiveIndex(0);
      stopTimer();
    }

    return () => {
      stopTimer();
    };
  }, [inView, showHighlights]);

  return (
    <Styled.Highlights ref={ref} visible={showHighlights}>
      <Styled.Container>
        <div className="grid-container">
          <Styled.List>
            {items.map((highlight, index) => {
              const isActive = index === activeIndex;
              return (
                <Styled.Item
                  key={index}
                  initial={false}
                  className={isActive && showHighlights && inView ? "active" : "inactive"}>
                  <Styled.Heading>{highlight.heading}</Styled.Heading>
                  <Styled.HighlightBody>
                    <PortableText value={highlight.body} />
                  </Styled.HighlightBody>
                </Styled.Item>
              );
            })}
          </Styled.List>

          <Styled.MobileProgressWrap>
            {items.map((_, index) => {
              const isActive = index === activeIndex;
              return (
                <Styled.MobileProgress
                  key={index}
                  className={isActive && showHighlights && inView ? "active" : "inactive"}
                  onClick={() => {
                    stopTimer();
                    onActiveIndex(index);
                    startTimer();
                  }}></Styled.MobileProgress>
              );
            })}
          </Styled.MobileProgressWrap>
        </div>
      </Styled.Container>
    </Styled.Highlights>
  );
};

export default HighlightsMobile;
