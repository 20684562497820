import * as React from "react";
import { Device } from "@styles/constants";
import useMediaQuery from "@hooks/useMediaQuery";
import Button from "@components/ui/button";
import QueryModalTrigger from "@components/query-modal/query-modal-trigger";
import * as Styled from "./HeroFifty.styled";
import { HeroFiftySchema } from "./HeroFifty.model";
import HeroIphone from "./hero-iphone";
import HeroIphoneStand from "./hero-iphone-stand";
import { ReactComponent as CaretSVG } from "@images/caret.svg";

const { useRef } = React;

const HeroFifty: React.FC<HeroFiftySchema> = ({
  eyebrow,
  heading,
  cta,
  cta_text,
  background_image,
  background_image_mobile,
  foreground_image,
  foreground_video,
  footnote,
  mirror_foreground,
  queryModalProps,
}) => {
  const isDesktop = useMediaQuery(Device.largeUp);
  const containerEl = useRef<HTMLDivElement | undefined>();

  const scrollToSectionHandler = () => {
    const styles = getComputedStyle(document.body).getPropertyValue("--header-height");
    const headerHeight = Number(styles.replace("px", ""));

    const yOffsetBottom = containerEl.current.offsetHeight - headerHeight;

    window.scrollTo({
      top: yOffsetBottom,
      behavior: "smooth",
    });
  };

  return (
    <Styled.HeroFiftySection ref={containerEl} withMirrorStand={!!mirror_foreground?.url}>
      <Styled.Background>
        {background_image?.url && (
          <picture>
            <source media={Device.small} srcSet={background_image_mobile?.url} />
            <source media={Device.mediumUp} srcSet={background_image.url} />
            <img src={background_image.url} alt={background_image.alt} />
          </picture>
        )}
      </Styled.Background>
      <Styled.Container className="grid-container">
        <Styled.TextSection withMirrorStand={!!mirror_foreground?.url}>
          {eyebrow && <Styled.Eyebrow>{eyebrow}</Styled.Eyebrow>}
          {heading && <Styled.LeftHeading>{heading}</Styled.LeftHeading>}
          {cta?.url && (
            <Styled.Button>
              <Button text={cta.text} href={cta.url} />
            </Styled.Button>
          )}
          {isDesktop && cta_text && (
            <Styled.ExploreButton onClick={scrollToSectionHandler}>
              <span>{cta_text}</span>
              <CaretSVG />
            </Styled.ExploreButton>
          )}
          {queryModalProps && <QueryModalTrigger {...queryModalProps} light />}

          {footnote?.copy && (
            <Styled.Footnote>
              {footnote?.reference_mark && <sup>{footnote.reference_mark}</sup>}
              {footnote.copy}
            </Styled.Footnote>
          )}
        </Styled.TextSection>

        {mirror_foreground?.url ? (
          <HeroIphoneStand
            standMedia={mirror_foreground}
            iphoneBezel={foreground_image.url}
            iphoneMedia={foreground_video}
          />
        ) : (
          <HeroIphone media={foreground_video} iPhoneImg={foreground_image} />
        )}
      </Styled.Container>
    </Styled.HeroFiftySection>
  );
};

export default HeroFifty;
