import { CloudinaryAsset, CLOUDINARY_ASSET_PROJECTION } from "@models/cloudinary-asset.model";
import { Sanity } from "@models/sanity.model";

export const ProgramBlocksType = "program_blocks";

export interface ProgramBlocksSchema extends Sanity.Document {
  eyebrow: string;
  heading: string;
  body: string;
  cta: Sanity.Cta;
  programs: Array<{
    icon: CloudinaryAsset;
    eyebrow: string;
    blurb: string;
    heading: string;
    background_image: CloudinaryAsset;
    background_color: string;
  }>;
}

export const PROGRAM_BLOCKS_PROJECTION = `{
  eyebrow,
  heading,
  body,
  cta,
  programs[] {
    ...,
    icon ${CLOUDINARY_ASSET_PROJECTION},
    background_image ${CLOUDINARY_ASSET_PROJECTION},
    logo ${CLOUDINARY_ASSET_PROJECTION}
  }
}`;
