import React from "react";
import { formatPrice } from "@utils/pricing";
import * as Styled from "./Pricing.styled";
import { Shopify } from "@models/shopify.model";

export interface PricingProps {
  priceRange: Shopify.PriceRange;
}

/**
 * Function to get a formatted string for price. If a range exists, prefix the
 * string with 'From: '.
 *
 */
const getFormattedMinPriceString = (price: number, rangeExists: boolean) => {
  const rangePrefix = rangeExists ? "From " : "";
  return `${rangePrefix}${formatPrice(price)}`;
};

const Pricing = ({ priceRange }: PricingProps) => {
  const { minVariantPrice, maxVariantPrice } = priceRange;
  const rangeExists = minVariantPrice !== maxVariantPrice;

  return (
    <Styled.Pricing>
      <Styled.PriceWrapper className="grid-y">
        <span data-testid="minimum-price">
          {getFormattedMinPriceString(minVariantPrice, rangeExists)}
        </span>
      </Styled.PriceWrapper>
    </Styled.Pricing>
  );
};

export default Pricing;
