import SwiperCore, { A11y, Scrollbar, Navigation } from "swiper";
import AspectRatio from "@components/primitives/aspect-ratio";
import { ReactComponent as CaretSVG } from "@images/caret.svg";
import * as Styled from "./DigitalTrialInstructors.styled";

SwiperCore.use([A11y, Scrollbar, Navigation]);

import { useEffect, useRef } from "react";
import { DigitalTrialInstructorsSchema } from "./DigitalTrialInstructors.model";

const DigitalTrialInstructors = ({
  eyebrow,
  heading,
  cta,
  featured_instructors,
  cards,
}: DigitalTrialInstructorsSchema) => {
  const swiperElRef = useRef<HTMLDivElement | undefined>();
  const swiperInstanceRef = useRef<any | undefined>();

  useEffect(() => {
    if (!swiperElRef.current) return;

    swiperInstanceRef.current = new SwiperCore(swiperElRef.current, {
      a11y: {
        prevSlideMessage: "Previous instructor",
        nextSlideMessage: "Next instructor",
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      slidesPerView: 1.25,
      spaceBetween: 15,
      scrollbar: {
        el: ".swiper-scrollbar",
        draggable: true,
        dragSize: 101,
      },
      breakpoints: {
        640: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 20,
          scrollbar: {
            el: ".swiper-scrollbar",
            draggable: true,
            dragSize: 358,
          },
        },
      },
    });

    return () => {
      if (swiperInstanceRef.current) {
        swiperInstanceRef.current.destroy();
      }
    };
  }, []);

  const items = featured_instructors || cards;

  if (!items.length) return null;

  return (
    <Styled.Instructors>
      <div className="grid-container">
        {eyebrow && <Styled.Eyebrow>{eyebrow}</Styled.Eyebrow>}
        {heading && <Styled.Heading>{heading}</Styled.Heading>}

        <Styled.Carousel>
          <div className="swiper-container" ref={swiperElRef}>
            <div className="swiper-wrapper">
              {items?.length > 0 &&
                items.map(({ title, tags, routing_media }, idx) => (
                  <div className="swiper-slide" key={idx}>
                    {routing_media && (
                      <Styled.SlideImage>
                        <AspectRatio ratio={4 / 5}>
                          <img
                            src={routing_media.url}
                            loading="lazy"
                            alt={routing_media.alt || title}
                          />
                        </AspectRatio>
                      </Styled.SlideImage>
                    )}
                    <Styled.SlideInfo>
                      {title && <Styled.SlideTitle>{title}</Styled.SlideTitle>}
                      <ul>
                        {tags?.length &&
                          tags.map((tag: string, idx: number) => <li key={idx}>{tag}</li>)}
                      </ul>
                    </Styled.SlideInfo>
                  </div>
                ))}
            </div>

            <button className="swiper-button-prev" aria-label="Move carousel left">
              <CaretSVG />
            </button>

            <button className="swiper-button-next" aria-label="Move carousel right">
              <CaretSVG />
            </button>

            <div className="swiper-scrollbar"></div>
          </div>
        </Styled.Carousel>
      </div>
    </Styled.Instructors>
  );
};

export default DigitalTrialInstructors;
