import styled from "styled-components";
import { motion } from "framer-motion";
import { Color, Ease } from "@styles/constants";
import { SmallBodyRegularCSS, Heading4CSS } from "@styles/typography";
import { rem } from "@styles/helpers";
import { Sanity } from "@models/sanity.model";

export const FlexContainer = styled.div`
  display: flex;
  column-gap: ${rem(20)};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 50%;
  padding: 0 ${rem(100)} 0 ${rem(80)};
  z-index: var(--top-content-index);
  order: ${({ theme: { content_position } }) =>
    content_position === Sanity.HeadlinePosition.LEFT ? "1" : "2"};
`;

export const ImageWrapper = styled.div`
  flex-basis: 50%;
  width: 50%;
  order: ${({ theme: { content_position } }) =>
    content_position === Sanity.HeadlinePosition.LEFT ? "2" : "1"};
`;

export const AnimatedTextWrapper = styled(motion.div)`
  ${SmallBodyRegularCSS}
  overflow: hidden;
`;

export const TabHeading = styled.h3<{ active: boolean }>`
  ${Heading4CSS}

  ${({ theme: { theme_style } }) => `
    --active-tab: ${theme_style === Sanity.ThemeColor.DARK ? Color.white : Color.blackBrand};
    --inactive-tab: ${theme_style === Sanity.ThemeColor.DARK ? Color.grayBorder : Color.grayBrand};
  `};
  margin: 1rem 0;
  color: ${({ active }) => (active ? "var(--active-tab)" : "var(--inactive-tab)")};
  transition: color ${Ease.duration} ${Ease.out};
`;

export const ProgressIndicator = styled.svg`
  circle:last-of-type {
    stroke: var(--foreground);
  }
`;
