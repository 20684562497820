import cn from "classnames";
import * as Styled from "./AddToCartButton.styled";

interface AddToCartButtonProps {
  onClick: (event: React.MouseEvent<HTMLElement> | React.FormEvent<HTMLElement>) => void;
  disabled?: boolean;
  productId: string;
  type?: "submit" | "reset" | "button" | undefined;
  children?: React.ReactNode;
}

const AddToCartButton: React.FC<AddToCartButtonProps> = ({
  onClick,
  disabled = false,
  productId,
  type,
  children,
}) => {
  return (
    <Styled.Button
      className={cn("button", disabled && "disabled")}
      onClick={onClick}
      data-testid="add-to-cart"
      data-productid={productId}
      type={type ?? "button"}
      disabled={disabled}>
      {children ?? "Add To Cart"}
    </Styled.Button>
  );
};

export default AddToCartButton;
