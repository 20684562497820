import { Sanity } from "@models/sanity.model";

export const TickerType = "ticker";

export interface TickerSchema extends Sanity.Document {
  text: string;
  large?: boolean;
  largeMobile?: boolean;
}

export const TICKER_PROJECTION = `{
  text,
  large,
  'largeMobile': large_mobile  
}`;
