import { ReactNode } from "react";
import styled from "styled-components";
import { EasePage } from "@styles/constants";
import { UIAnimationProps } from ".";

export type FadeInProps = UIAnimationProps & {
  children?: ReactNode;
};

const StyledContainer = styled.div<{ visible?: boolean; delay?: number }>`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  opacity: 0;

  ${({ visible, delay }) =>
    visible && `animation: fadeIn ${EasePage.duration} ${EasePage.curve} ${delay}s forwards;`}
`;

export const FadeIn = ({ visible = true, delay = 0, addDelay, children }: FadeInProps) => {
  if (process.env.NEXT_PUBLIC_PAGE_ANIMATIONS !== "true") return <>{children}</>;

  return (
    <StyledContainer
      delay={
        delay +
        (addDelay?.reduce((accumulator, value) => (value ? accumulator + 0.1 : accumulator), 0) ||
          0)
      }
      visible={visible}>
      {children}
    </StyledContainer>
  );
};
