import { useRef, useState } from "react";
import * as Styled from "./HeroPrimary.styled";
import { HeroPrimarySchema, HorizontalAlignment, HeadingSize, VerticalAlignment } from ".";
import useMediaQuery from "@hooks/useMediaQuery";
import { Device } from "@styles/constants";
import { Eyebrow, Heading1, Heading2, BodyRegular } from "@styles/typography";
import CloudinaryFormat from "@components/cloudinary";
import Button from "@components/ui/button";
import ModalVideo from "@components/modals/ModalVideo";
import { ReactComponent as PlaySVG } from "@images/play.svg";
import { ReactComponent as CaretSVG } from "@images/caret.svg";
import mirrorDevice from "@images/hero_mirror_device2.png";
import { FadeIn, SlideInText } from "@components/ui/animations";
import { ComponentResolverAnimationProps } from "@components/component-resolver";

export interface HeroPrimaryProps {
  sanity: HeroPrimarySchema;
}

const HeroPrimary = ({
  inView,
  sanity: {
    horizontal_alignment,
    vertical_alignment,
    darkText,
    slim,
    eyebrow,
    heading,
    heading_size,
    body,
    cta,
    background,
    background_mobile,
    foreground,
    foreground_mobile,
    video_modal,
  },
}: HeroPrimaryProps & ComponentResolverAnimationProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const backgroundRef = useRef<HTMLVideoElement>(null);

  const [modalOpen, setModalOpen] = useState(false);

  const hasForeground = !!(foreground_mobile || foreground);
  const isDesktop = useMediaQuery(Device.mediumUp);

  const backgroundPlay = () => {
    if (!backgroundRef.current) return;
    backgroundRef.current.play();
  };

  const backgroundPause = () => {
    if (!backgroundRef.current) return;
    backgroundRef.current.pause();
  };

  const onMediaClick = () => {
    backgroundPause();
    setModalOpen(true);
  };

  const onModalClose = () => {
    backgroundPlay();
    setModalOpen(false);
  };

  const onExploreClick = () => {
    const headerHeight = parseInt(
      getComputedStyle(document.body).getPropertyValue("--header-height")
    );

    window.scrollTo({
      top: containerRef.current.offsetHeight - headerHeight,
      behavior: "smooth",
    });
  };

  const hasVideoBackground =
    (!isDesktop && background_mobile?.format === "mp4") ||
    (isDesktop && background?.format === "mp4");

  const Heading = heading_size === HeadingSize.H2 ? Heading2 : Heading1;

  return (
    <Styled.Container ref={containerRef} foreground={hasForeground}>
      <Styled.Wrapper>
        <FadeIn visible={inView}>
          <Styled.Background darken={hasVideoBackground}>
            {!isDesktop && background_mobile ? (
              <CloudinaryFormat
                media={background_mobile}
                widthOnScreenValues={100}
                mediaRef={backgroundRef}
              />
            ) : background ? (
              <CloudinaryFormat
                media={background}
                widthOnScreenValues={100}
                mediaRef={backgroundRef}
              />
            ) : null}
          </Styled.Background>
        </FadeIn>

        <Styled.Body
          className="grid-container"
          foreground={hasForeground}
          top={vertical_alignment === VerticalAlignment.TOP}
          center={(horizontal_alignment === HorizontalAlignment.CENTER || slim) && !hasForeground}
          slim={slim}>
          <Styled.Text
            center={(horizontal_alignment === HorizontalAlignment.CENTER || slim) && !hasForeground}
            slim={slim}
            foreground={hasForeground}
            darkText={darkText}>
            {eyebrow && (
              <Eyebrow>
                <SlideInText visible={inView} delay={0.5}>
                  {eyebrow}
                </SlideInText>
              </Eyebrow>
            )}

            <Heading>
              <SlideInText
                visible={inView}
                center={!isDesktop || horizontal_alignment === HorizontalAlignment.CENTER || slim}
                delay={0.5}
                addDelay={[eyebrow]}>
                {heading}
              </SlideInText>
            </Heading>

            {body && !slim && (
              <FadeIn visible={inView} delay={1} addDelay={[eyebrow]}>
                <BodyRegular>{body}</BodyRegular>
              </FadeIn>
            )}

            {(video_modal?.video_cta_text || cta?.text) && !slim && (
              <FadeIn visible={inView} delay={1} addDelay={[eyebrow, body]}>
                <Styled.Buttons>
                  {video_modal?.video_cta_text && (
                    <Styled.ButtonMedia onClick={onMediaClick}>
                      <span>{video_modal.video_cta_text}</span>
                      <PlaySVG />
                    </Styled.ButtonMedia>
                  )}
                  {cta?.url && <Button text={cta.text} href={cta.url} dark={!darkText} />}
                </Styled.Buttons>
              </FadeIn>
            )}
          </Styled.Text>

          {(hasForeground || isDesktop) && !slim && (
            <FadeIn visible={inView} delay={1} addDelay={[eyebrow, body]}>
              <Styled.Explore onClick={onExploreClick}>
                <span>Explore</span>
                <CaretSVG />
              </Styled.Explore>
            </FadeIn>
          )}

          {hasForeground && !slim && (
            <FadeIn visible={inView} delay={1} addDelay={[eyebrow, body]}>
              <Styled.Stand>
                <Styled.StandBackground>
                  <img className="stand-bg" src={mirrorDevice.src} alt="" />
                </Styled.StandBackground>
                <Styled.StandForeground>
                  {!isDesktop && foreground_mobile ? (
                    <CloudinaryFormat media={foreground_mobile} widthOnScreenValues={100} />
                  ) : foreground ? (
                    <CloudinaryFormat media={foreground} widthOnScreenValues={100} />
                  ) : null}
                </Styled.StandForeground>
              </Styled.Stand>
            </FadeIn>
          )}
        </Styled.Body>
      </Styled.Wrapper>

      {video_modal?.video_id && (
        <ModalVideo
          mounted={modalOpen}
          videoId={video_modal.video_id}
          videoParams={{
            h: video_modal.video_hash,
            autoplay: 1,
            autopause: 0,
          }}
          closeModal={onModalClose}
          full
        />
      )}
    </Styled.Container>
  );
};

export default HeroPrimary;
