import { useEffect, useRef } from "react";
import AspectRatio from "@components/primitives/aspect-ratio";
import { CloudinaryAsset } from "@models/cloudinary-asset.model";
import * as Styled from "./SideBackgrounds.styled";

export interface SideBackgroundsProps {
  background1: CloudinaryAsset;
  background2: CloudinaryAsset;
  shouldAnimate?: boolean;
}

const SideBackgrounds = ({ background1, background2, shouldAnimate }: SideBackgroundsProps) => {
  const videoRef = useRef<HTMLVideoElement | undefined>();
  const videoRef2 = useRef<HTMLVideoElement | undefined>();

  useEffect(() => {
    let timeout: number | NodeJS.Timeout;

    if (shouldAnimate) {
      timeout = setTimeout(() => {
        if (videoRef.current) videoRef.current.play();
        if (videoRef2.current) videoRef2.current.play();
      }, 250);
    } else {
      if (timeout) clearTimeout(timeout);
      if (videoRef.current) videoRef.current.pause();
      if (videoRef2.current) videoRef2.current.pause();
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [shouldAnimate]);

  return (
    <Styled.SideBackgrounds
      initial={{ opacity: 0, visibility: "hidden" }}
      animate={
        shouldAnimate ? { opacity: 1, visibility: "visible" } : { opacity: 0, visibility: "hidden" }
      }
      transition={{
        opacity: {
          duration: 0.3,
          delay: shouldAnimate ? 0.25 : 0,
        },
        visibility: {
          duration: 0,
          delay: shouldAnimate ? 0.25 : 0.3,
        },
      }}>
      <div className="grid-container">
        {background1?.url && (
          <Styled.BackgroundTop>
            <AspectRatio ratio={1}>
              <video
                ref={videoRef}
                src={background1.url}
                muted
                playsInline
                disablePictureInPicture
                loop
              />
            </AspectRatio>
          </Styled.BackgroundTop>
        )}
        {background2?.url && (
          <Styled.BackgroundBottom>
            <AspectRatio ratio={1}>
              <video
                ref={videoRef2}
                src={background2.url}
                muted
                playsInline
                disablePictureInPicture
                loop
              />
            </AspectRatio>
          </Styled.BackgroundBottom>
        )}
      </div>
    </Styled.SideBackgrounds>
  );
};

export default SideBackgrounds;
