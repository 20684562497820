import styled, { css } from "styled-components";
import infographic from "@images/experience_infographic.svg";
import { Device, Spacing, Color, Font } from "@styles/constants";
import { AbsoluteCoverCSS, AbsoluteCenterCSS } from "@styles/layout";
import { HeadingDisplayCSS } from "@styles/typography";

export const ScrollMobile = styled.div`
  position: relative;
  overflow: hidden;
`;

export const Hero = styled.div`
  position: relative;
  width: 100%;
`;

export const Background = styled.div`
  ${AbsoluteCoverCSS}
  overflow: hidden;

  div {
    padding-bottom: 0;
    height: 100%;
  }

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
`;

export const Foreground = styled.div`
  position: absolute;
  top: 35%;
  right: 60%;
  z-index: 1;
  overflow: hidden;

  img {
    height: 100%;
    object-fit: contain;
    width: 100%;
  }
`;

export const Infographic = styled.div`
  ${AbsoluteCoverCSS}
  background: url(${infographic}) center center / contain no-repeat;
`;

export const FullWidthVideo = styled.div`
  ${AbsoluteCenterCSS}
  width: 100%;
`;

export const FullWidthSplitImages = styled.div`
  ${AbsoluteCenterCSS}
  width: 100%;

  display: flex;

  > div {
    flex: 0 0 50%;
    width: 50%;
  }
`;

export const Mirror = styled.div`
  position: relative;
  width: 200px;
  height: 322px;
  margin: 0 auto;
  z-index: 1;

  @media ${Device.mediumUp} {
    width: 300px;
    height: 480px;
  }

  > div {
    padding-left: 40px;
    padding-right: 36px;
  }

  div {
    padding-bottom: 0;
    height: 100%;
  }

  img {
    ${AbsoluteCoverCSS}
    display: block;
    object-fit: cover;
    border-radius: 10px;
  }
`;

export const Section = styled.div`
  margin: ${Spacing.small} 0;
`;

export const Heading = styled.div`
  position: relative;
  z-index: 1;
  margin-bottom: 100px;

  h2 {
    text-align: left;

    @media ${Device.mediumUp} {
      font-size: 36px;
      max-width: 25ch;
      line-height: 1.21;
    }
  }
`;

export const Middle = styled.div`
  position: relative;
  width: 100%;
  margin-top: 70px;
`;

export const Body = styled.div`
  margin-top: 50px;

  h4 {
    text-align: left;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 0;
  }
`;

export const Outro = styled.div`
  position: relative;
  padding: 50px 0;

  h2 {
    color: ${Color.white};
  }
`;

export const OutroBackground = styled.div`
  ${AbsoluteCoverCSS}
  z-index: 0;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const OutroInfographic = styled.div`
  position: relative;
  margin: 0 auto;
  z-index: 1;
  text-align: center;
  width: 275px;
  height: 275px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background: url(${infographic}) center center / contain no-repeat;

  span {
    color: ${Color.white};

    &:nth-child(1) {
      display: block;
      text-transform: uppercase;
      color: ${Color.white};
      letter-spacing: 3px;
    }

    &:nth-child(2) {
      ${HeadingDisplayCSS}
    }
  }
`;

export const SideBackgrounds = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const SideBackground = styled.div`
  flex: 0 0 33.333333%;
  width: 33.333333%;

  &:nth-child(2) {
    margin-top: 100px;
  }
`;
