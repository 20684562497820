import { CloudinaryAsset, CLOUDINARY_ASSET_PROJECTION } from "@models/cloudinary-asset.model";
import { Sanity } from "@models/sanity.model";

export const FiftyFiftyAppType = "fifty_fifty_app";

export interface AppCTA {
  url: string;
  app_store_badge: CloudinaryAsset;
}

export interface FiftyFiftyAppSchema extends Sanity.Document {
  heading?: string;
  body?: string;
  app_cta_1?: AppCTA;
  app_cta_2?: AppCTA;
  image?: CloudinaryAsset;
  footnote?: Sanity.PortableText;
}

export const FIFTY_FIFTY_APP_PROJECTION = `{
  heading,
  body,
  app_cta_1 {
    url,
    app_store_badge ${CLOUDINARY_ASSET_PROJECTION}  
  },
  app_cta_2 {
    url,
    app_store_badge ${CLOUDINARY_ASSET_PROJECTION}  
  },
  footnote,
  image ${CLOUDINARY_ASSET_PROJECTION}  
}`;
