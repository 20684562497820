import { motion } from "framer-motion";
import styled from "styled-components";
import { Color } from "@styles/constants";

export const Heading = styled(motion.div)`
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 1;
  color: ${Color.white};

  h2 {
    margin: 90px auto;
    padding: 0 20px;
    max-width: 23ch;
  }
`;
