import { Device } from "@styles/constants";
import styled from "styled-components";
import { StyledVideoProps } from "../HeroFifty.styled";

const Controller = {
  iphoneM: "117px",
  standM: "235px",
  iphoneT: "175px",
  standT: "350px",
  iphoneD: "250px",
  standD: "502px",
  iphoneCloudinaryWidth: "5% 7.5% 8%",
  standCloudinaryWidth: "20% 20% 43%",
};

export const IphoneStandWrapper = styled.div<StyledVideoProps>`
  position: relative;
  display: flex;
  justify-content: center;
  max-width: 100%;
  min-height: 560px;
  opacity: ${({ videoLoaded }: { videoLoaded: boolean }) => (videoLoaded ? 1 : 0)};
  transition: opacity 0.2s linear;

  @media ${Device.small} {
    padding-right: 20px;
    padding-left: 60px;
    padding-top: 80px;
  }

  @media ${Device.mediumUp} {
    padding-right: 0;
    padding-left: 40px;
    min-height: 700px;
    margin-right: -40px;
    width: 333px;
  }

  @media ${Device.largeUp} {
    flex: 1 0 25%;
    justify-content: flex-end;
    min-height: 1050px;
    margin-right: -102px;
  }
`;

export const IphoneStandInner = styled.div`
  position: relative;
`;

export const IphoneContainer = styled.div`
  width: ${Controller.iphoneM};
  position: absolute;
  z-index: 2;
  top: 38%;
  left: -17%;

  @media ${Device.mediumUp} {
    width: ${Controller.iphoneT};
  }

  @media ${Device.largeUp} {
    width: ${Controller.iphoneD};
  }
`;

export const IphoneBgWrapper = styled.div`
  position: relative;
  overflow: hidden;

  // iphone-cloudinary-asset
  .cloudinary-format {
    position: absolute;
    z-index: -1;
    height: 98%;
    padding: ${Controller.iphoneCloudinaryWidth};
    border-radius: 8.6%;
  }
`;

export const StandContainer = styled.div`
  width: ${Controller.standM};

  @media ${Device.mediumUp} {
    width: ${Controller.standT};
  }

  @media ${Device.largeUp} {
    width: ${Controller.standD};
  }
`;

export const StandBgWrapper = styled.div`
  position: relative;
  overflow: hidden;

  // stand-cloudinary-asset
  .cloudinary-format {
    position: absolute;
    z-index: 0;
    height: 100%;
    padding: ${Controller.standCloudinaryWidth};
    object-fit: contain;
  }
`;
