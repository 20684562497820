import styled from "styled-components";
import { EyebrowCSS, Heading1, SmallBodyRegularCSS, BodyMediumCSS } from "@styles/typography";
import { AbsoluteCoverCSS } from "@styles/layout";
import { Color, Device } from "@styles/constants";
import { rem } from "@styles/helpers";

export const Section = styled.div`
  position: relative;
  background-color: ${Color.black};
  color: ${Color.white};
`;

export const BackgroundImages = styled.div`
  --mainnav-height: 120px;
  height: calc(100vh - var(--mainnav-height));

  @media ${Device.mediumUp} {
    --mainnav-height: 100px;
  }

  @media screen and (min-height: 960px) {
    height: calc(80vh - var(--mainnav-height));
  }

  &::after {
    content: "";
    ${AbsoluteCoverCSS}
    background: ${Color.black};
    opacity: 0.5;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
`;

export const Content = styled.div`
  ${AbsoluteCoverCSS}
  z-index: 2;

  > .grid-container {
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2.5em;
`;

export const Eyebrow = styled.small`
  ${EyebrowCSS}
  color: ${Color.white};
  margin-bottom: 0;
`;

export const Heading = styled(Heading1)`
  margin-bottom: 0;
  text-align: center;
`;

export const Body = styled.div`
  ${SmallBodyRegularCSS}
  max-width: 60ch;
  text-align: center;

  p:last-of-type {
    margin-bottom: 0;
  }
`;

export const ExploreButton = styled.button`
  ${BodyMediumCSS}
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  color: ${Color.white};
  cursor: pointer;

  svg {
    fill: ${Color.white};
    width: ${rem(14)};
    margin-top: ${rem(10)};
  }
`;
