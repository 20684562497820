import styled, { css } from "styled-components";
import { Color, Device, Ease } from "@styles/constants";
import { LargeBodyMediumCSS, SmallBodyMediumCSS } from "@styles/typography";
import { rem } from "@styles/helpers";
import { Sanity } from "@models/sanity.model";
import { ValuePropsColumns } from "./ValueProps.model";

export const ValueProps = styled.div`
  ${({ theme: { theme_style } }) => `
    --prop-border: ${theme_style === Sanity.ThemeColor.LIGHT ? Color.goldAsh : Color.grayBrand};
    --foreground-color: ${theme_style === Sanity.ThemeColor.LIGHT ? Color.blackBrand : Color.white};
    --background-color: ${theme_style === Sanity.ThemeColor.LIGHT ? Color.white : Color.blackBrand};
    `}
  position: relative;
  color: var(--foreground-color);

  @media ${Device.largeUp} {
    ${({ theme: { column_count } }) =>
      column_count === ValuePropsColumns.THREE && `text-align: center;`}
  }
`;

export const Grid = styled.div`
  @media ${Device.mediumDown} {
    ${({ theme: { column_count } }) =>
      column_count === ValuePropsColumns.THREE && `justify-content: flex-start;`}
  }
`;

export const Heading = styled.h2`
  text-align: center;
  margin-bottom: ${rem(40)};

  ${({ theme: { column_count } }) =>
    column_count === ValuePropsColumns.THREE &&
    `
      max-width: 780px;
      margin-left: auto;
      margin-right: auto;

      @media ${Device.mediumDown} {
        text-align: left;
        margin-left: 0;
      }
    `}
`;

export const PropItem = styled.div`
  @media ${Device.small} {
    &:not(:first-of-type) {
      margin-top: ${rem(40)};
    }
  }

  @media ${Device.medium} {
    &:nth-of-type(n + 3) {
      margin-top: ${rem(40)};
    }
  }
`;

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media ${Device.largeUp} {
    ${({ theme: { column_count } }) =>
      column_count === ValuePropsColumns.THREE &&
      `
        max-width: 270px;
        margin: 0 auto;
        align-items: center;
      `}
  }

  @media ${Device.small} {
    div:not(:last-of-type) > & {
      padding-bottom: ${rem(30)};
      border-bottom: 1px solid var(--prop-border);
    }
  }
`;

export const Title = styled.h3`
  ${LargeBodyMediumCSS}
  text-transform: none;
  text-align: left;
  margin-bottom: 0;

  @media ${Device.largeUp} {
    ${({ theme: { column_count } }) =>
      column_count === ValuePropsColumns.THREE && `text-align: center;`}
  }
`;

const paragraphStyles = css`
  margin-top: ${rem(10)};
  margin-bottom: 0;
  max-width: 30ch;

  @media ${Device.mediumUp} {
    margin-top: ${rem(15)};
    max-width: none;
  }
`;

export const Paragraph = styled.p`
  ${paragraphStyles}
`;

export const PortableTextWrapper = styled.div`
  ${paragraphStyles}

  p {
    margin-bottom: 0;
  }
`;

export const ButtonWrap = styled.div`
  ${SmallBodyMediumCSS}
  display: inline-block;
  margin-top: auto;
  padding-top: ${rem(12)};
  text-decoration: underline;
  color: ${({ theme: { theme_style } }) =>
    theme_style === Sanity.ThemeColor.DARK ? `${Color.grayInactive}` : `${Color.grayBrand}`};

  @media ${Device.largeUp} {
    padding-top: ${({ theme: { column_count } }) =>
      column_count === ValuePropsColumns.THREE ? rem(30) : rem(40)};
  }

  button {
    color: inherit;
    text-decoration: underline;
    cursor: pointer;
    transition: opacity ${Ease.duration} ${Ease.out}, color ${Ease.duration} ${Ease.out};

    ${({ theme: { theme_style } }) =>
      theme_style === Sanity.ThemeColor.DARK && `color: ${Color.white};`}

    &:focus,
    &:hover {
      opacity: 0.5;
    }
  }
`;

export const Icon = styled.div`
  width: ${rem(32)};
  height: ${rem(32)};
  margin-bottom: ${rem(12)};

  ${({ theme: { mobile_accordion } }) =>
    mobile_accordion &&
    `
    @media ${Device.small} {
      margin-bottom: 0;
    }
  `}

  @media ${Device.largeUp} {
    margin-bottom: ${rem(40)};
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    ${({ theme: { theme_style } }) =>
      theme_style === Sanity.ThemeColor.DARK && `filter: invert(1);`}
  }
`;

export const Cta = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${rem(40)};

  @media ${Device.mediumUp} {
    margin-top: ${rem(60)};
  }

  @media ${Device.small} {
    display: block;
  }
`;

export const MobileAccordion = styled.div`
  position: relative;
  margin-top: ${rem(35)};

  &::after {
    content: "";
    position: absolute;
    height: 1px;
    bottom: 0;
    left: ${rem(-20)};
    right: ${rem(-20)};
    background-color: var(--foreground-color);
  }
`;

export const TitleComponent = styled.div`
  display: grid;
  grid-template-columns: 32px 1fr;
  align-items: center;
  padding-right: ${rem(30)};

  ${Icon} {
    display: flex;
    justify-self: center;
  }
`;

export const MobileTitle = styled.span`
  ${LargeBodyMediumCSS}
  display: block;
  margin-left: ${rem(10)};
  text-align: left;

  div.accordion.isOpen > & {
    margin-left: 0;
    grid-column: 1 / -1;
  }
`;

export const AccordionItem = styled.div`
  position: relative;

  &::after {
    content: "";
    position: absolute;
    height: 1px;
    top: 0;
    left: ${rem(-20)};
    right: ${rem(-20)};
    background-color: var(--foreground-color);
  }

  .accordionHeader {
    border-bottom: 0;
    text-decoration: none;
    height: unset;
    padding: ${rem(16)} 0;
    color: var(--foreground-color);
  }

  .accordionPanel {
    button {
      margin-bottom: ${rem(35)};
    }
  }

  .iconWrapper {
    display: flex;
    justify-content: flex-end;
  }

  ${Paragraph},
  ${PortableTextWrapper} {
    margin-top: 0;
  }
`;
