import styled from "styled-components";
import { rem } from "@styles/helpers";
import { Color, Font, Device, EasePage } from "@styles/constants";
import { BodyMediumCSS } from "@styles/typography";
import { AbsoluteCoverCSS } from "@styles/layout";

export const Container = styled.div<{ foreground?: boolean }>`
  overflow: hidden;
  ${({ foreground }) => foreground && `padding-bottom: ${rem(180)};`}

  @media ${Device.largeUp} {
    ${({ foreground }) => foreground && `padding-bottom: ${rem(220)};`}
  }
`;

export const Wrapper = styled.div`
  color: ${Color.white};
  position: relative;
`;

export const Background = styled.div<{ darken?: boolean }>`
  ${AbsoluteCoverCSS}
  background-color: ${Color.blackBrand};

  ${({ darken }) =>
    darken &&
    `
      &:after {
        ${AbsoluteCoverCSS}
        content: "";
        background-color: ${Color.black};
        opacity: 0.4;
      }
  `}

  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media ${Device.largeUp} {
      max-width: ${rem(1900)};
      display: block;
      margin: auto;
      object-position: top center;
    }
  }
`;

export const Body = styled.div<{
  foreground?: boolean;
  top?: boolean;
  center?: boolean;
  slim?: boolean;
}>`
  padding-top: ${rem(60)};
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-end;
  position: relative;

  ${({ foreground }) =>
    !foreground &&
    `
      min-height: ${rem(700)};
      padding-bottom: ${rem(60)};
    `}
  ${({ top }) =>
    top &&
    `
      justify-content: flex-start;
    `}
  ${({ center }) =>
    center &&
    `
      flex-flow: column;
      align-items: center;
      justify-content: center;
    `}
  ${({ slim }) =>
    slim &&
    `
      min-height: ${rem(320)};
      align-items: center;
      justify-content: center;
    `}

  @media ${Device.mediumUp} {
    padding-top: ${rem(80)};
    padding-bottom: ${rem(80)};
    align-items: flex-start;

    ${({ foreground }) =>
      !foreground &&
      `
        flex-flow: row;
        justify-content: space-between;
        align-items: flex-end;
      `}
    ${({ center }) =>
      center &&
      `
        flex-flow: column;
        align-items: center;
        justify-content: center;
      `}
    ${({ slim }) =>
      slim &&
      `
        min-height: ${rem(445)};
        align-items: center;
        justify-content: center;
      `}
  }
  @media ${Device.largeUp} {
    min-height: ${rem(810)};
    padding-top: calc(${rem(80)} + var(--header-height));
    ${({ slim }) => slim && `min-height: ${rem(445)}`}
  }

  span {
    color: inherit;
  }
`;

export const Text = styled.div<{
  center?: boolean;
  slim?: boolean;
  foreground?: boolean;
  darkText?: boolean;
}>`
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  color: ${({ darkText }) => (darkText ? Color.blackBrand : Color.white)};

  @media ${Device.mediumUp} {
    max-width: var(--text-width, ${rem(790)});
    width: 100%;
    align-items: flex-start;
    text-align: left;
    ${({ foreground }) => foreground && `--text-width: ${rem(570)};`}

    ${({ center, slim }) =>
      (center || slim) &&
      `
        align-items: center;
        justify-content: center;
        text-align: center;
      `}

    ${({ slim }) => slim && `--text-width: ${rem(920)};`}
  }

  ${({ foreground }) =>
    foreground &&
    `
      @media ${Device.medium} {
        width: calc(100% - ${rem(280)});
      }
  `}

  h1,
  h2 {
    margin-bottom: ${rem(16)};
  }

  p {
    @media ${Device.mediumUp} {
      max-width: ${rem(380)};
    }
  }
`;

export const Buttons = styled.div`
  margin-top: ${rem(30)};
  display: flex;
  flex-flow: column;
  align-items: flex-start;

  > * {
    width: 100%;

    @media ${Device.mediumUp} {
      width: auto;
    }

    + * {
      margin-top: ${rem(10)};
    }
  }
`;

export const ButtonMedia = styled.button`
  padding: ${rem(15)} ${rem(30)};
  font-family: ${Font.semibold};
  font-size: ${rem(16)};
  line-height: ${rem(17)};
  align-items: center;
  letter-spacing: 0.1em;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  color: inherit;
  border: 1px solid currentColor;
  border-radius: ${rem(27)};
  display: flex;
  justify-content: center;
  font-style: italic;
  cursor: pointer;
  backdrop-filter: blur(60px);

  @media ${Device.mediumUp} {
    justify-content: space-between;
    text-align: left;
  }

  span {
    padding-top: ${rem(5)};
  }
  svg {
    width: ${rem(12)};
    margin-left: ${rem(10)};
  }
`;

export const Explore = styled.button`
  ${BodyMediumCSS}
  color: inherit;
  margin-top: ${rem(30)};
  display: flex;
  flex-flow: column;
  align-items: center;
  cursor: pointer;

  svg {
    width: ${rem(14)};
    margin-top: ${rem(10)};
  }
  path {
    fill: currentColor;
  }
`;

export const Stand = styled.div`
  width: ${rem(240)};
  margin-top: -${rem(180)};
  position: relative;
  top: ${rem(210)};

  @media ${Device.mediumUp} {
    width: ${rem(350)};
    position: absolute;
    left: auto;
    right: -${rem(30)};
    top: auto;
    bottom: -${rem(148)};
  }
  @media ${Device.largeUp} {
    width: ${rem(500)};
    right: -${rem(60)};
    bottom: -${rem(300)};
  }
  @media ${Device.xlargeUp} {
    right: -${rem(90)};
  }
`;

export const StandBackground = styled.div`
  img {
    width: 100%;
  }
`;

export const StandForeground = styled.div`
  width: 59.2%;
  position: absolute;
  left: 20.4%;
  top: 9.5%;

  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
