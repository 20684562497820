import styled from "styled-components";
import { Color, Device } from "@styles/constants";
import { ButtonStyles } from "../button";

export const Button = styled.button`
  ${ButtonStyles}
  width: 50%;
  text-transform: none;
  margin-bottom: 0;
  letter-spacing: unset;

  s {
    margin-left: 10px;
    color: ${Color.black60};
  }

  @media ${Device.mediumUp} {
    text-align: center;
  }
`;
