import * as React from "react";
import { BodyRegular } from "@styles/typography";
import { Column, Row } from "@styles/grid";
import Jumbotron from "./Jumbotron";
import { JumbotronSchema } from "./Jumbotron.model";

interface ImageJumbotronProps {
  sanity: JumbotronSchema;
}

const ImageJumbotron = ({ sanity }: ImageJumbotronProps) => (
  <Column>
    <Row center>
      <Jumbotron
        subtitle={sanity.heading}
        eyebrow={sanity.eyebrow}
        cta={sanity.cta}
        background="transparent"
        color="#1A1A1A"
        columns="67% 23%"
        gap="0 10%"
        margin="0 0 40px">
        {sanity.body && <BodyRegular>{sanity.body}</BodyRegular>}
      </Jumbotron>
    </Row>
    <Row
      center
      background={`url(${sanity.background_image?.url}) no-repeat center`}
      className="row-workouts-page-1-on-1">
      {sanity.foreground_video?.url ? (
        <video
          autoPlay
          muted
          playsInline
          src={sanity.foreground_video?.url}
          height="745"
          width="300"
          style={{ objectFit: "cover" }}
        />
      ) : (
        <img className="image" src={sanity.foreground_image?.url} alt="" />
      )}
    </Row>
  </Column>
);

export default ImageJumbotron;
