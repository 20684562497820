import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import { Device } from "@styles/constants";

type SectionTypes = {
  sectionType: string;
};

export const StyledSection = styled.div.attrs(() => ({
  "data-sticky-anchor": "",
}))`
  position: relative;
  width: 100%;
  height: ${({ sectionType }: SectionTypes) => (sectionType === "Outro start" ? "300vh" : "100vh")};
`;

export const StyledText = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
`;

export const StyledHeading = styled(motion.div)`
  h2 {
    text-align: left;
    max-width: 18ch;
    margin-bottom: 40px;
    margin-top: 100px;
    pointer-events: all;

    @media ${Device.mediumUp} {
      font-size: 48px;
      line-height: 1.21;
    }

    @media screen and (min-height: 850px) {
      margin-top: 120px;
    }

    @media screen and (min-height: 1024px) {
      margin-top: 150px;
    }
  }
`;

export const StyledBody = styled(motion.div)<{ $bodyPosition?: string }>`
  margin-top: 20vh;
  width: 100%;
  z-index: 1;
  pointer-events: all;

  ${({ $bodyPosition }) =>
    $bodyPosition === "Top right" &&
    css`
      .grid-container {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;
      }
    `};
`;

export const StyledBodyContent = styled.div`
  h4,
  p {
    text-align: left;
  }

  h4 {
    max-width: 15ch;
  }

  p {
    max-width: 30ch;
  }
`;
