import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import { Color, Device } from "@styles/constants";
import { AbsoluteCenterCSS, AbsoluteCoverCSS } from "@styles/layout";
import { HeadingDisplayCSS } from "@styles/typography";

export const FullBleedBanner = styled.div`
  position: relative;
  overflow: hidden;
`;

export const Container = styled.div`
  width: 100%;
`;

export const Image = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
`;

export const ParallaxImage = styled(motion.div)<{ offset: number }>`
  position: relative;
  will-change: transform;

  ${({ offset }) =>
    offset &&
    css`
      height: calc(100% + (2 * ${offset}px));
      top: calc(${offset}px * -1);
    `};

  &:after {
    ${AbsoluteCoverCSS}
    content: '';
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  }

  img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

export const MobileImage = styled.div`
  ${AbsoluteCoverCSS}

  &:after {
    ${AbsoluteCoverCSS}
    content: '';
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

export const Buttons = styled.div`
  text-align: center;
  margin-top: 40px;
`;

export const Content = styled.div`
  position: relative;
  padding: 140px 0;
  width: 100%;
  z-index: 1;

  @media ${Device.mediumUp} {
    ${AbsoluteCenterCSS}
    padding: 0;
  }
`;

export const Heading = styled.h2`
  ${HeadingDisplayCSS}
  margin: 0 auto 0;
  max-width: 8ch;
  color: ${Color.white};
  text-align: center;
`;
