import { QueryModalTriggerProps } from "@components/query-modal/query-modal-trigger";
import { CloudinaryAsset, CLOUDINARY_ASSET_PROJECTION } from "@models/cloudinary-asset.model";
import { Sanity } from "@models/sanity.model";

export const CarouselFiftyType = "carousel_fifty";

export interface CarouselFiftySchema extends Sanity.Document {
  slides: Array<{
    heading?: string;
    body?: Sanity.PortableText;
    foreground_image?: CloudinaryAsset;
    foreground_video?: CloudinaryAsset;
    background_image?: CloudinaryAsset;
    background_video?: CloudinaryAsset;
  }>;
  queryModalProps?: QueryModalTriggerProps;
}

export const CAROUSEL_FIFTY_PROJECTION = `{
  slides[] {
    ...,
    'body': copy,
    background_image ${CLOUDINARY_ASSET_PROJECTION},
    foreground_image ${CLOUDINARY_ASSET_PROJECTION}
  }
}`;
