import { CloudinaryAsset, CLOUDINARY_ASSET_PROJECTION } from "@models/cloudinary-asset.model";
import { Sanity } from "@models/sanity.model";

export const ScrollType = "scroll";

export interface ScrollSchema extends Sanity.Document {
  hero_image?: CloudinaryAsset;
  hero_video?: CloudinaryAsset;
  sections: Array<ScrollSection>;
  outro_end_heading?: string;
  outro_end_health_score_eyebrow?: string;
  outro_end_health_score?: number;
  outro_end_background_image?: CloudinaryAsset;
}

export interface ScrollSection {
  type?: string;
  heading?: string;
  body?: Sanity.PortableText;
  body_position?: string;
  mirror_image?: CloudinaryAsset;
  mirror_video?: CloudinaryAsset;
  mirror_foreground_image?: CloudinaryAsset;
  background_image?: CloudinaryAsset;
  background_video?: CloudinaryAsset;
  foreground_image?: CloudinaryAsset;
  side_background_video_1?: CloudinaryAsset;
  side_background_video_2?: CloudinaryAsset;
  anchor_label?: string;
  anchor_link?: string;
}

export const SCROLL_PROJECTION = `{
  hero_image ${CLOUDINARY_ASSET_PROJECTION},
  hero_video ${CLOUDINARY_ASSET_PROJECTION},
  sections[] {
    type,
    heading,
    body,
    body_position,
    mirror_image ${CLOUDINARY_ASSET_PROJECTION},
    mirror_video ${CLOUDINARY_ASSET_PROJECTION},
    mirror_foreground_image ${CLOUDINARY_ASSET_PROJECTION},
    background_image ${CLOUDINARY_ASSET_PROJECTION},
    background_video ${CLOUDINARY_ASSET_PROJECTION},
    foreground_image ${CLOUDINARY_ASSET_PROJECTION},
    side_background_video_1 ${CLOUDINARY_ASSET_PROJECTION},
    side_background_video_2 ${CLOUDINARY_ASSET_PROJECTION},
    anchor_label,
    anchor_link
  },
  outro_end_heading,
  outro_end_health_score_eyebrow,
  outro_end_health_score,
  outro_end_background_image ${CLOUDINARY_ASSET_PROJECTION},
  outro_end_background_video ${CLOUDINARY_ASSET_PROJECTION}
}`;
