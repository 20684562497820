import { MarketingModulesType } from "./module-page.model";
import { Sanity } from "./sanity.model";
import { Shopify } from "./shopify.model";
/**
 * @desc Product documents synced via Sanity Connect custom handler.
 */
export const ProductType = "shopify.product";
/**
 * @desc Variant models synced via Sanity Connect custom handler.
 */
export const SANITY_SYNC_VARIANT_PROJECTION = `{
  available,
  id,
  position,
  price,
  compareAtPrice,
  product,
  selectedOptions[] {
    name,
    value
  },
  sku,
  title,
  vendor
}`;
/**
 * @desc Projection for product documents originating from the Sanity Connect custom handler.
 *     Contains all required fields for the SanitySyncProductDocument interface with the
 *     ability to slot in additional fields such as marketing_modules.
 * @param {string} [slot] - Additional keys to query directly on the product document.
 */
export const sanitySyncProductDocumentProjection = (slot?: string) => `{
  _type,
  _id,
  cart_description,
  description,
  descriptionHtml,
  featuredImage,
  handle,
  id,
  images,
  options[] {
    id,
    name,
    position,
    values
  },
  priceRange,
  compareAtPriceRange,
  productType,
  seo,
  slug,
  status,
  tags,
  title,
  subtitle,
  vendor,
  variants[] ${SANITY_SYNC_VARIANT_PROJECTION},
  ${slot || ""}
}`;
/**
 * @desc Variant models synced via Sanity Connect custom handler.
 */
export interface SanitySyncVariant {
  available: boolean;
  id: Shopify.ProductVariantId;
  position: number;
  price: string;
  compareAtPrice: string;
  product: {
    id: string;
    status: "active" | "archived" | "draft" | "unknown";
  };
  selectedOptions: Array<{
    name: string;
    value: string;
  }>;
  sku: string;
  title: string;
  vendor: string;
}
/**
 * @desc A product included within a bundle.
 */
export interface IncludedProduct {
  title: string;
  cart_description: string;
  descriptionHtml: string;
  options: Array<{
    name: string;
    position: number;
  }>;
}

/**
 * @desc The full product document from Sanity including custom fields
 *     extending beyond the fields synced via Sanity Connect custom handler.
 */
export interface SanitySyncProductDocument {
  _type: string;
  _id: string;
  description: string;
  descriptionHtml: string;
  cart_description: Shopify.Metafield<string>;
  featuredImage: Shopify.Image;
  handle: string;
  id: string;
  images: Shopify.Image[];
  options: Shopify.ProductOption[];
  priceRange: Shopify.PriceRange;
  compareAtPriceRange: Shopify.ProductPriceRange;
  productType: string;
  seo: Sanity.Seo;
  slug: Sanity.Slug;
  status: "active" | "archived" | "draft" | "unknown";
  tags: string[];
  title: string;
  subtitle: Shopify.Metafield<string>;
  vendor: string;
  variants: SanitySyncVariant[];
  marketing_modules: MarketingModulesType;
  bundle_settings?: {
    contents: {
      description: Sanity.PortableText;
      section_heading: string;
      promotion_text: {
        text: string;
        code: string;
      };
    };
    included_products: Array<{
      product: IncludedProduct;
      product_options: string[];
      quantity: number;
    }>;
  };
}

/**
 * TODO: MIR-681 - Integrate products
 * Remove the MockedProduct and its nested interfaces, as it is a
 * mock of a spree product model.
 */
export interface ProductTaxon {
  id: number;
  name: string;
  pretty_name: string;
  permalink: string;
  parent_id: number;
  taxonomy_id: number;
  meta_title: string;
  meta_description: string;
  taxons: ProductTaxon[];
}

export interface ProductClassification {
  taxon_id: number;
  position: number;
  taxon: ProductTaxon;
}

export interface ProductPromotion {
  advertise_price: string;
  code: string;
  description: string;
  id: number;
  name: string;
  usage_limit: number;
  item_adjustment_amount: number;
}

export interface MockedProduct {
  available_on: string;
  classifications: ProductClassification[];
  description: string;
  display_price: string;
  email_image_url?: string;
  cart_image_url?: string;
  list_image_url?: string;
  has_variants: boolean;
  id: number;
  master: MockedVariant;
  meta_description: string;
  meta_keywords: string;
  name: string;
  option_types: Array<{ name: string; position: number; presentation: string }>;
  possible_promotions: ProductPromotion[];
  price: string;
  product_properties: Array<{
    product_id: number;
    property_id: number;
    property_name: string;
    value: string;
  }>;
  shipping_category_id: number;
  slug: string;
  taxon_ids: number[];
  total_on_hand: number;
  variants: MockedVariant[];
  review_count: number;
  review_average: number;
  reviews_title?: string;
  reviews_image_url?: string;
  reviews_background_url?: string;
  seo_title?: string;
  seo_description?: string;
  facebook_description?: string;
  twitter_title?: string;
  twitter_description?: string;
  is_destroyed: boolean;
  is_bundle: boolean;
  cost_price: string;
  cart_description: string;
  subscription_price: string;
}

export interface MockedVariant {
  id: number;
  depth: number | null;
  description: string;
  display_price: string;
  height: number | null;
  images: any[];
  in_stock: boolean;
  is_backorderable: boolean;
  is_destroyed: boolean;
  is_master: boolean;
  is_orderable: boolean;
  name: string;
  option_values: Array<{
    id: number;
    name: string;
    option_type_id: number;
    option_type_name: string;
    option_type_presentation: string;
    presentation: string;
  }>;
  options_text: string;
  price: string;
  sku: string;
  slug: string;
  total_on_hand: number;
  track_inventory: boolean;
  weight: string;
  width: number | null;
  parts_variants: Array<{
    assembly_id: number;
    part_id: number;
    count: number;
    id: number;
    variant_selection_deferred: string;
  }>;
}

export const product: MockedProduct = {
  is_destroyed: false,
  id: 130,
  name: "Mirror",
  description: "lululemon Studio Mirror, Mirror Stand, Mirror Lens Cap, Limited Warranty ",
  price: "1495.0",
  display_price: "$1,495.00",
  available_on: "2021-08-08T00:00:00.000Z",
  slug: "mirror-basic",
  meta_description:
    "Buy The lululemon Studio Mirror today, the smart fitness mirror with real-time heart rate, custom playlists, and weight recommendations & rep counting. ",
  meta_keywords: "",
  shipping_category_id: 4,
  taxon_ids: [5, 7],
  total_on_hand: null,
  reviews_title: "",
  master: {
    id: 146,
    name: "Mirror",
    sku: "mirror-basic",
    price: "1495.0",
    weight: "0.0",
    height: null,
    width: null,
    depth: null,
    is_master: true,
    slug: "mirror-basic",
    description: "lululemon Studio Mirror, Mirror Stand, Mirror Lens Cap, Limited Warranty ",
    track_inventory: false,
    option_values: [],
    images: [
      {
        id: 182,
        position: 1,
        attachment_content_type: null,
        attachment_file_name: null,
        type: null,
        attachment_updated_at: null,
        attachment_width: null,
        attachment_height: null,
        alt: "This isn't just a mirror. It's a cardio class, it's a yoga studio, it's a boxing ring, it's your new personal trainer, and it's so much more.",
        viewable_type: "Spree::Variant",
        viewable_id: 146,
        title: "lululemon Studio Mirror",
        mini_url:
          "https://www.mirror.co/rails/active_storage/representations/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb1lDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--b8445153f59913d6a079b77b639df45f8aabd48f/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCam9MY21WemFYcGxTU0lMTkRoNE5EZytCam9HUlZRPSIsImV4cCI6bnVsbCwicHVyIjoidmFyaWF0aW9uIn19--b2f039f241c3482d20b726b85cbe23b2930a096f/Mirror.png",
        small_url:
          "https://www.mirror.co/rails/active_storage/representations/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb1lDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--b8445153f59913d6a079b77b639df45f8aabd48f/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCam9MY21WemFYcGxTU0lOTVRBd2VERXdNRDRHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--7f7616c1b7803885f985710409e8d9c0eb7697d6/Mirror.png",
        product_url:
          "https://www.mirror.co/rails/active_storage/representations/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb1lDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--b8445153f59913d6a079b77b639df45f8aabd48f/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCam9MY21WemFYcGxTU0lOTWpRd2VESTBNRDRHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--3abd7dfd96140ba423bb9c25bc2757ba4b616064/Mirror.png",
        pdp_thumbnail_url:
          "https://www.mirror.co/rails/active_storage/representations/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb1lDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--b8445153f59913d6a079b77b639df45f8aabd48f/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCam9MY21WemFYcGxTU0lOTVRZd2VESXdNRDRHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--8ba2e3dd6e36959ca0d3e3f22f266f729d7d7494/Mirror.png",
        plp_and_carousel_url:
          "https://www.mirror.co/rails/active_storage/representations/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb1lDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--b8445153f59913d6a079b77b639df45f8aabd48f/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCam9MY21WemFYcGxTU0lOTkRRNGVEWXdNRDRHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--fd2fd69d5d1b35ffda3395f9e1f29ff4975c6a97/Mirror.png",
        plp_and_carousel_xs_url:
          "https://www.mirror.co/rails/active_storage/representations/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb1lDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--b8445153f59913d6a079b77b639df45f8aabd48f/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCam9MY21WemFYcGxTU0lOTWpVMGVETTBNRDRHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--d09f13178df28af94b9ff54779b74b02a5a38195/Mirror.png",
        plp_and_carousel_sm_url:
          "https://www.mirror.co/rails/active_storage/representations/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb1lDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--b8445153f59913d6a079b77b639df45f8aabd48f/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCam9MY21WemFYcGxTU0lOTXpVd2VEUTJPRDRHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--abe439e1bf857058731561134b9e4a89fdcdc0fe/Mirror.png",
        plp_and_carousel_md_url:
          "https://www.mirror.co/rails/active_storage/representations/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb1lDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--b8445153f59913d6a079b77b639df45f8aabd48f/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCam9MY21WemFYcGxTU0lOTWpJeWVESTVOejRHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--fe3f9dc51016fe25dbd87c53ae8fa12c0d56cef7/Mirror.png",
        plp_and_carousel_lg_url:
          "https://www.mirror.co/rails/active_storage/representations/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb1lDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--b8445153f59913d6a079b77b639df45f8aabd48f/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCam9MY21WemFYcGxTU0lOTWpjNGVETTNNVDRHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--54d471ea39298da5814473a5d77a55705a1803bc/Mirror.png",
        large_url:
          "https://www.mirror.co/rails/active_storage/representations/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb1lDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--b8445153f59913d6a079b77b639df45f8aabd48f/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCam9MY21WemFYcGxTU0lOTmpBd2VEWXdNRDRHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--8eb93330cc1107b3f023a5c53e7a0f2b42897674/Mirror.png",
        plp_url:
          "https://www.mirror.co/rails/active_storage/representations/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb1lDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--b8445153f59913d6a079b77b639df45f8aabd48f/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCam9MY21WemFYcGxTU0lOTWpjNGVETTNNVDRHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--54d471ea39298da5814473a5d77a55705a1803bc/Mirror.png",
        zoomed_url:
          "https://www.mirror.co/rails/active_storage/representations/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb1lDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--b8445153f59913d6a079b77b639df45f8aabd48f/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCam9MY21WemFYcGxTU0lOTmpVd2VEZzNNRDRHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--6e3eb8f332c94532e1787a73e277ee3c84fd900f/Mirror.png",
      },
      {
        id: 183,
        position: 2,
        attachment_content_type: null,
        attachment_file_name: null,
        type: null,
        attachment_updated_at: null,
        attachment_width: null,
        attachment_height: null,
        alt: "Your Mirror comes with a one-year warranty. Extended protection plans are also available.",
        viewable_type: "Spree::Variant",
        viewable_id: 146,
        title: "Standard Warranty",
        mini_url:
          "https://www.mirror.co/rails/active_storage/representations/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaFlCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--25bbaf14e3283b92c8fac47820e71bf6a851b118/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCam9MY21WemFYcGxTU0lMTkRoNE5EZytCam9HUlZRPSIsImV4cCI6bnVsbCwicHVyIjoidmFyaWF0aW9uIn19--b2f039f241c3482d20b726b85cbe23b2930a096f/carousel-warranty.png",
        small_url:
          "https://www.mirror.co/rails/active_storage/representations/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaFlCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--25bbaf14e3283b92c8fac47820e71bf6a851b118/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCam9MY21WemFYcGxTU0lOTVRBd2VERXdNRDRHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--7f7616c1b7803885f985710409e8d9c0eb7697d6/carousel-warranty.png",
        product_url:
          "https://www.mirror.co/rails/active_storage/representations/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaFlCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--25bbaf14e3283b92c8fac47820e71bf6a851b118/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCam9MY21WemFYcGxTU0lOTWpRd2VESTBNRDRHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--3abd7dfd96140ba423bb9c25bc2757ba4b616064/carousel-warranty.png",
        pdp_thumbnail_url:
          "https://www.mirror.co/rails/active_storage/representations/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaFlCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--25bbaf14e3283b92c8fac47820e71bf6a851b118/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCam9MY21WemFYcGxTU0lOTVRZd2VESXdNRDRHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--8ba2e3dd6e36959ca0d3e3f22f266f729d7d7494/carousel-warranty.png",
        plp_and_carousel_url:
          "https://www.mirror.co/rails/active_storage/representations/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaFlCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--25bbaf14e3283b92c8fac47820e71bf6a851b118/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCam9MY21WemFYcGxTU0lOTkRRNGVEWXdNRDRHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--fd2fd69d5d1b35ffda3395f9e1f29ff4975c6a97/carousel-warranty.png",
        plp_and_carousel_xs_url:
          "https://www.mirror.co/rails/active_storage/representations/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaFlCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--25bbaf14e3283b92c8fac47820e71bf6a851b118/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCam9MY21WemFYcGxTU0lOTWpVMGVETTBNRDRHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--d09f13178df28af94b9ff54779b74b02a5a38195/carousel-warranty.png",
        plp_and_carousel_sm_url:
          "https://www.mirror.co/rails/active_storage/representations/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaFlCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--25bbaf14e3283b92c8fac47820e71bf6a851b118/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCam9MY21WemFYcGxTU0lOTXpVd2VEUTJPRDRHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--abe439e1bf857058731561134b9e4a89fdcdc0fe/carousel-warranty.png",
        plp_and_carousel_md_url:
          "https://www.mirror.co/rails/active_storage/representations/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaFlCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--25bbaf14e3283b92c8fac47820e71bf6a851b118/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCam9MY21WemFYcGxTU0lOTWpJeWVESTVOejRHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--fe3f9dc51016fe25dbd87c53ae8fa12c0d56cef7/carousel-warranty.png",
        plp_and_carousel_lg_url:
          "https://www.mirror.co/rails/active_storage/representations/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaFlCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--25bbaf14e3283b92c8fac47820e71bf6a851b118/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCam9MY21WemFYcGxTU0lOTWpjNGVETTNNVDRHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--54d471ea39298da5814473a5d77a55705a1803bc/carousel-warranty.png",
        large_url:
          "https://www.mirror.co/rails/active_storage/representations/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaFlCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--25bbaf14e3283b92c8fac47820e71bf6a851b118/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCam9MY21WemFYcGxTU0lOTmpBd2VEWXdNRDRHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--8eb93330cc1107b3f023a5c53e7a0f2b42897674/carousel-warranty.png",
        plp_url:
          "https://www.mirror.co/rails/active_storage/representations/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaFlCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--25bbaf14e3283b92c8fac47820e71bf6a851b118/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCam9MY21WemFYcGxTU0lOTWpjNGVETTNNVDRHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--54d471ea39298da5814473a5d77a55705a1803bc/carousel-warranty.png",
        zoomed_url:
          "https://www.mirror.co/rails/active_storage/representations/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaFlCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--25bbaf14e3283b92c8fac47820e71bf6a851b118/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCam9MY21WemFYcGxTU0lOTmpVd2VEZzNNRDRHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--6e3eb8f332c94532e1787a73e277ee3c84fd900f/carousel-warranty.png",
      },
    ],
    parts_variants: [
      {
        assembly_id: 146,
        part_id: 149,
        count: 1,
        id: 14,
        variant_selection_deferred: null,
      },
    ],
    display_price: "$1,495.00",
    options_text: "",
    in_stock: true,
    is_backorderable: true,
    is_orderable: true,
    total_on_hand: null,
    is_destroyed: false,
  },
  variants: [],
  option_types: [],
  product_properties: [],
  classifications: [
    {
      taxon_id: 5,
      position: 6,
      taxon: {
        id: 5,
        name: "Mirror",
        pretty_name: "Category -> Mirror",
        permalink: "category/mirror",
        parent_id: 1,
        taxonomy_id: 1,
        meta_title: null,
        meta_description: null,
        taxons: [],
      },
    },
    {
      taxon_id: 7,
      position: 9,
      taxon: {
        id: 7,
        name: "Mirror",
        pretty_name: "Brand -> Mirror",
        permalink: "brand/mirror",
        parent_id: 6,
        taxonomy_id: 3,
        meta_title: null,
        meta_description: null,
        taxons: [],
      },
    },
  ],
  possible_promotions: [
    {
      advertise_price: "750",
      code: "BLACKFRIDAY22",
      description:
        "Additional $50 off The lululemon Studio Mirror for a total savings of $750 + Free Delivery.",
      id: 442970,
      name: "BLACKFRIDAY22",
      usage_limit: null,
      item_adjustment_amount: 750.0,
    },
  ],
  cost_price: "0.0",
  has_variants: false,
  is_bundle: true,
  cart_description: "Mirror\r\nMirror Stand\r\nMirror Lens Cap\r\nLimited Warranty",
  email_image_url: null,
  cart_image_url:
    "https://www.mirror.co/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb2NDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--796427e3738b609bcbad9f9949374768a21523da/Mirror.png",
  list_image_url:
    "https://www.mirror.co/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb2dDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--0a1041a725a7d5434aa6dc4eddb7ce467c21fde7/Mirror.png",
  review_count: 0,
  review_average: 0,
  reviews_image_url: null,
  reviews_background_url: null,
  // subscription_required: true,
  subscription_price: "468.0",
  seo_title: "Buy The lululemon Studio Mirror | lululemon Studio",
  seo_description:
    "Buy The lululemon Studio Mirror today, the smart fitness mirror with real-time heart rate, custom playlists, and weight recommendations & rep counting. ",
  facebook_description: "",
  twitter_title: "",
  twitter_description: "",
};
