import { CloudinaryAsset, CLOUDINARY_ASSET_PROJECTION } from "@models/cloudinary-asset.model";
import { Sanity } from "@models/sanity.model";

export const GlobalCtaType = "global_cta";

export interface CompareProps {
  compare_headline?: string;
  compare_plans: Array<ComparePlan>;
  compare_items: Array<CompareItem>;
}

export interface CompareItem {
  item_headline: string;
  icon?: CloudinaryAsset;
  info?: string;
  plans: {
    plan_digital: {
      compare_active: boolean;
      compare_description?: string;
    };
    plan_hardware: {
      compare_active: boolean;
      compare_description?: string;
    };
  };
}

export interface ComparePlan {
  tag?: string;
  image: CloudinaryAsset;
  plan_name: string;
  monthly_price?: number;
  plan_description?: string;
  plan_cta?: Sanity.Cta;
}

export interface GlobalCtaSchema extends Sanity.Document {
  theme_style?: Sanity.ThemeColor;
  background_image?: CloudinaryAsset;
  eyebrow?: string;
  headline?: string;
  subheadline?: string;
  copy?: string;
  cta?: Sanity.Cta;
  compare_headline?: string;
  compare_plans: Array<ComparePlan>;
  compare_items: Array<CompareItem>;
  global_cta_footnotes: Array<{
    copy?: Sanity.PortableText;
    reference_mark?: string;
  }>;
}

export const GLOBAL_CTA_PROJECTION = `{
  theme_style,
  background_image ${CLOUDINARY_ASSET_PROJECTION},
  eyebrow,
  headline,
  subheadline,
  copy,
  cta,
  compare_headline,
  compare_plans[] {
    tag,
    image ${CLOUDINARY_ASSET_PROJECTION},
    plan_name,
    monthly_price,
    plan_description,
    plan_cta,
  },
  compare_items[] {
    item_headline,
    icon ${CLOUDINARY_ASSET_PROJECTION},
    info,
    plans {
      plan_digital {
        compare_active,
        compare_description,
      },
      plan_hardware {
        compare_active,
        compare_description,
      }
    }
  },
  global_cta_footnotes[] {
    copy,
    reference_mark,
  },
}`;
