import { CloudinaryAsset } from "@models/cloudinary-asset.model";
import * as Styled from "./FullWidthSplitImages.styled";

export interface FullWidthVideoProps {
  imageLeft?: CloudinaryAsset;
  imageRight?: CloudinaryAsset;
  shouldAnimate: boolean;
}

const FullWidthVideo = ({ imageLeft, imageRight, shouldAnimate }: FullWidthVideoProps) => {
  return (
    <Styled.FullWidthSplitImages>
      <Styled.Container className="grid-container">
        {imageLeft?.url && (
          <Styled.Image
            initial={{ opacity: 0, visibility: "hidden" }}
            animate={
              shouldAnimate
                ? { opacity: 1, visibility: "visible" }
                : { opacity: 0, visibility: "hidden" }
            }
            transition={{
              opacity: {
                duration: 0.3,
                delay: shouldAnimate ? 0.25 : 0,
              },
              visibility: {
                duration: 0,
                delay: shouldAnimate ? 0.25 : 0.3,
              },
            }}>
            <img src={imageLeft.url} loading="lazy" alt={imageLeft?.alt} />
          </Styled.Image>
        )}

        {imageRight?.url && (
          <Styled.Image
            initial={{ opacity: 0, visibility: "hidden" }}
            animate={
              shouldAnimate
                ? { opacity: 1, visibility: "visible" }
                : { opacity: 0, visibility: "hidden" }
            }
            transition={{
              opacity: {
                duration: 0.3,
                delay: shouldAnimate ? 0.25 : 0,
              },
              visibility: {
                duration: 0,
                delay: shouldAnimate ? 0.25 : 0.3,
              },
            }}>
            <img src={imageRight.url} loading="lazy" alt={imageRight?.alt} />
          </Styled.Image>
        )}
      </Styled.Container>
    </Styled.FullWidthSplitImages>
  );
};

export default FullWidthVideo;
