import styled from "styled-components";
import { Color, Device } from "@styles/constants";
import { rem } from "@styles/helpers";

export const StateFilterContainer = styled.div`
  margin-top: ${rem(30)};
  margin-bottom: ${rem(30)};
`;

export const FilterContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  @media ${Device.mediumDown} {
    align-items: center;
  }
`;

export const HeadingRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${rem(16)};

  @media ${Device.mediumDown} {
    flex-direction: column;
  }
`;

export const AlphabetRow = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: center;
  max-width: 1000px;
  margin-left: ${rem(-12)};

  @media ${Device.mediumDown} {
    flex-wrap: wrap;
    justify-content: center;
  }

  @media ${Device.medium} {
    margin: 0 ${rem(100)} ${rem(20)};
  }

  @media ${Device.small} {
    margin: 0 0 ${rem(20)};
  }
`;

export const TextLink = styled.button<{ selected: boolean }>`
  text-transform: uppercase;
  color: ${Color.black};
  cursor: pointer;
  font-weight: bold;
  width: ${rem(35)};
  border-bottom: ${(props) => (props.selected ? "2px solid black" : "none")};
`;

export const NonClick = styled.div`
  color: gray;
  cursor: default;
  width: ${rem(35)};
  padding-bottom: ${rem(2)};
`;

export const SeeAllRow = styled.div`
  text-align: center;

  button {
    text-decoration: underline;
    color: ${Color.grayBrand};
    cursor: pointer;

    &:focus,
    &:hover {
      color: ${Color.blackBrand};
    }
  }

  @media ${Device.largeUp} {
    position: absolute;
    right: 0;
    top: ${rem(4)};
  }
`;
