import { useRef, useState, useLayoutEffect, useEffect } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import useWindowSize from "@hooks/useWindowSize";
import { CloudinaryAsset } from "@models/cloudinary-asset.model";
import getRootOffset from "@utils/getRootOffset";
import Counter from "../counter";
import {
  StyledOutro,
  StyledTop,
  StyledOutroGraphic,
  StyledBottom,
  StyledBackground,
  StyledContent,
  StyledHeading,
  StyledScore,
} from "./Outro.styled";

export interface OutroProps {
  heading?: string;
  eyebrow?: string;
  score?: number;
  image?: CloudinaryAsset;
}

const Outro = ({ heading, eyebrow, score, image }: OutroProps) => {
  const containerRef = useRef<HTMLDivElement | undefined>();

  const [shouldAnimate, setShouldAnimate] = useState(false);
  const [shouldCounterAnimate, setShouldCounterAnimate] = useState(false);

  const [position, setPosition] = useState({
    start: 0,
    middle: 0,
    end: 0,
  });

  const windowSize = useWindowSize();

  const { scrollY } = useScroll();

  const opacity = useTransform(scrollY, [position.start + windowSize.height, position.end], [0, 1]);
  const scale = useTransform(
    scrollY,
    [position.start, position.middle, position.end],
    [0.8, 1.5, 1]
  );

  useLayoutEffect(() => {
    if (!containerRef.current) return;

    const bounds = getRootOffset(containerRef.current);

    setPosition({
      start: bounds.top,
      middle: bounds.top + containerRef.current.offsetHeight * 0.4,
      end: bounds.top + containerRef.current.offsetHeight - windowSize.height,
    });
  }, [windowSize]);

  useEffect(() => {
    const unsubscribeScrollY = scrollY.onChange((y) => {
      if (y > position.start) {
        setShouldAnimate(true);
      } else {
        setShouldAnimate(false);
      }

      if (y > position.start + windowSize.height * 1.5) {
        setShouldCounterAnimate(true);
      } else {
        setShouldCounterAnimate(false);
      }
    });

    return () => {
      unsubscribeScrollY();
    };
  }, [position]);

  return (
    <StyledOutro ref={containerRef}>
      {score && (
        <StyledTop>
          <StyledOutroGraphic>
            <motion.div
              style={{
                scale: scale,
              }}
              initial={{ opacity: 0, visibility: "visible" }}
              animate={
                shouldAnimate
                  ? { opacity: 1, visibility: "visible" }
                  : { opacity: 0, visibility: "visible" }
              }></motion.div>
          </StyledOutroGraphic>
        </StyledTop>
      )}

      <StyledBottom>
        {image?.url && (
          <StyledBackground>
            <img src={image.url} alt={heading} />
          </StyledBackground>
        )}
        <StyledContent>
          <div className="grid-container">
            {heading && (
              <StyledHeading
                style={
                  shouldAnimate
                    ? { opacity, visibility: "visible" }
                    : { opacity: 0, visibility: "visible" }
                }>
                <div className="grid-container">
                  <h2>{heading}</h2>
                </div>
              </StyledHeading>
            )}

            {score && (
              <StyledScore style={{ opacity }}>
                {eyebrow && <span>{eyebrow}</span>}
                <Counter
                  from={234}
                  to={parseInt(score.toString()) || 927}
                  shouldAnimate={shouldCounterAnimate}
                />
              </StyledScore>
            )}
          </div>
        </StyledContent>
      </StyledBottom>
    </StyledOutro>
  );
};

export default Outro;
