// Proxy the default model/type, since they are the same thing to Sanity.

import { InstructorsSchema } from "@components/instructors/Instructors.model";
import { Instructor, INSTRUCTOR_PROJECTION } from "@models/instructor.model";

export const DigitalTrialInstructorsType = "digital_trial_instructors";

export interface DigitalTrialInstructorsSchema extends InstructorsSchema {
  cards?: Array<Instructor>;
}

export const DIGITAL_TRIAL_INSTRUCTORS_PROJECTION = INSTRUCTOR_PROJECTION;
