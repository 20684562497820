import cn from "classnames";
import { Heading2 } from "@styles/typography";
import { Sanity } from "@models/sanity.model";
import { PartnershipType } from "@models/partnership.model";
import Button from "@components/ui/button";
import PortableText from "@components/portable-text";
import GalleryWrap from "./gallery-wrap";
import { GalleryProps } from "./Gallery.model";
import * as Styled from "./Gallery.styled";
import { FadeIn, SlideInText } from "@components/ui/animations";

const Gallery = ({
  inView,
  sanity: { theme_style, eyebrow, heading, cta, secondary_cta, copy_blocks, featured_items },
}: GalleryProps) => {
  const cardType = featured_items?.length ? featured_items[0]._type : null;
  const themeColor = theme_style || Sanity.ThemeColor.LIGHT;

  const ButtonContainer = () =>
    cta?.url || secondary_cta?.url ? (
      <Styled.ButtonContainer className="grid-container" cardType={cardType}>
        {cta?.url && (
          <div>
            <Button text={cta.text} href={cta.url} dark={themeColor === Sanity.ThemeColor.DARK} />
          </div>
        )}
        {secondary_cta?.url && (
          <div>
            <Button
              text={secondary_cta.text}
              href={secondary_cta.url}
              dark={themeColor === Sanity.ThemeColor.DARK}
              invert
            />
          </div>
        )}
      </Styled.ButtonContainer>
    ) : null;

  const hasCopy = copy_blocks?.length > 0;

  return (
    <Styled.GallerySection themeColor={themeColor}>
      <div className="grid-container">
        <Styled.Content cardType={cardType}>
          {eyebrow && (
            <Styled.Eyebrow dark={themeColor === Sanity.ThemeColor.DARK}>
              <SlideInText visible={inView}>{eyebrow}</SlideInText>
            </Styled.Eyebrow>
          )}
          {heading && (
            <Heading2 className={cn(cardType === PartnershipType ? "large-8" : null)}>
              <SlideInText visible={inView} center addDelay={[eyebrow]}>
                {heading}
              </SlideInText>
            </Heading2>
          )}
          {hasCopy && (
            <FadeIn visible={inView} delay={0.5} addDelay={[eyebrow]}>
              <Styled.BodyContainer>
                {copy_blocks.map((block, index) => (
                  <Styled.CopyBlock key={index}>
                    {block.heading && <Styled.BlockHeading>{block.heading}</Styled.BlockHeading>}
                    {block.copy && (
                      <Styled.BlockCopy>
                        <PortableText value={block.copy} />
                      </Styled.BlockCopy>
                    )}
                  </Styled.CopyBlock>
                ))}
              </Styled.BodyContainer>
            </FadeIn>
          )}
        </Styled.Content>
      </div>

      {cardType !== PartnershipType && (
        <FadeIn visible={inView} delay={0.5} addDelay={[eyebrow, hasCopy]}>
          <ButtonContainer />
        </FadeIn>
      )}

      <FadeIn visible={inView} delay={0.5} addDelay={[eyebrow, hasCopy]}>
        <GalleryWrap cardType={cardType} items={featured_items} inView={inView} />
      </FadeIn>

      {cardType === PartnershipType && (
        <FadeIn visible={inView} delay={0.5} addDelay={[eyebrow, hasCopy]}>
          <ButtonContainer />
        </FadeIn>
      )}
    </Styled.GallerySection>
  );
};

export default Gallery;
