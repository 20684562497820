import styled from "styled-components";
import { rem } from "@styles/helpers";
import { Heading4 } from "@styles/typography";
import { Color } from "@styles/constants";
import GlobalAccordion from "@components/accordion-repeater/accordion";
import { HeaderButton, IconWrap } from "@components/accordion-repeater/accordion/Accordion.styled";
import { globalCtaMobile } from "../GlobalCta.styled";

export const CompareItems = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${rem(15)};
  padding: var(--mobile-padding);
  margin-top: ${rem(60)};

  @media ${globalCtaMobile} {
    padding: 0;
    grid-template-columns: 43% 43%;
    justify-content: space-evenly;
    column-gap: auto;
  }
`;

export const AccordionHeadline = styled(Heading4)`
  margin-top: ${rem(40)};
  text-align: center;
`;

export const AccordionWrap = styled.div`
  margin-top: ${rem(24)};
`;

export const Accordion = styled(GlobalAccordion)`
  background-color: ${Color.blueAsh};

  ${HeaderButton} {
    height: auto;
    padding: ${rem(22)} ${rem(20)} ${rem(18)} ${rem(14)};
    border: none;
  }

  ${IconWrap} {
    --icon-width: ${rem(13)};
    --icon-height: ${rem(8)};
  }

  &.isOpen {
    ${IconWrap} {
      svg {
        transform: rotate(180deg);
      }
    }
  }
`;

export const CompareHeadline = styled.div`
  display: flex;
  align-items: center;

  p {
    text-align: left;
    margin-bottom: 0;
  }
`;

export const AccordionContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  background: ${Color.white};
  padding: ${rem(20)};

  & > div {
    justify-self: center;
    text-align: center;
  }

  p {
    margin-bottom: 0;
  }
`;
