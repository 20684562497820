import styled from "styled-components";
import { Sanity } from "@models/sanity.model";
import { XSmallBodyMediumCSS, BodyMediumCSS, Heading3CSS, Heading4CSS } from "@styles/typography";
import { Color, Device, Ease, Font } from "@styles/constants";
import { rem } from "@styles/helpers";

export const Newsletter = styled.div<{ theme: Sanity.ThemeColor }>`
  position: relative;
  padding: ${rem(60)} 0 ${rem(80)};
  background-color: ${({ theme }) =>
    theme === Sanity.ThemeColor.DARK ? Color.blackBrand : Color.grayFill};
  ${({ theme }) => theme === Sanity.ThemeColor.DARK && `color: ${Color.white};`};

  @media ${Device.mediumUp} {
    padding: ${rem(80)} 0 ${rem(100)};
  }
`;

export const Form = styled.form<{ theme: Sanity.ThemeColor }>`
  display: flex;
  align-items: center;
  margin-top: ${rem(25)};
  border: ${rem(1)} solid ${Color.greenAsh};
  border-radius: ${rem(94)};
  padding: 0 ${rem(25)};
  transition: border ${Ease.duration} ${Ease.out};

  @media ${Device.mediumUp} {
    margin-top: ${rem(70)};
    padding: 0 ${rem(40)};
    border-radius: ${rem(55)};
    height: ${rem(100)};
  }

  &:focus-within {
    border-color: ${({ theme }) =>
      theme === Sanity.ThemeColor.DARK ? Color.white : Color.blackBrand};
  }
`;

export const Body = styled.p<{ theme?: Sanity.ThemeColor; success?: boolean; error?: boolean }>`
  ${XSmallBodyMediumCSS}
  font-family: ${({ success }) => success && Font.semibold};
  text-align: center;
  color: ${({ theme, error }) =>
    error ? "red" : theme === Sanity.ThemeColor.DARK ? Color.white : Color.blackBrand};
  margin: ${rem(30)} auto 0;
  max-width: 40ch;

  @media ${Device.mediumUp} {
    ${BodyMediumCSS}
    margin: ${rem(40)} auto 0;
  }
`;

export const Input = styled.input<{ theme?: Sanity.ThemeColor }>`
  ${Heading4CSS};
  flex: 1;
  border: none;
  background: transparent;
  box-shadow: none;
  color: ${({ theme }) => (theme === Sanity.ThemeColor.DARK ? Color.white : Color.blackBrand)};
  padding: 0 !important;
  margin: 0 !important;
  line-height: 1 !important;
  height: ${rem(52)};

  @media ${Device.mediumUp} {
    ${Heading3CSS};
    padding: ${rem(10)} 0 0 !important;
  }

  &::placeholder {
    color: ${Color.grayInactive};
  }

  &:focus {
    outline: none;
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
`;

export const Submit = styled.button<{ theme?: Sanity.ThemeColor }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${rem(20)};
  height: ${rem(20)};
  cursor: pointer;
  margin-left: ${rem(10)};

  svg {
    width: 100%;
    height: auto;
  }

  svg path {
    fill: ${Color.greenAsh};
    transition: fill ${Ease.duration} ${Ease.out};
  }

  &:focus,
  &:hover {
    svg path {
      fill: ${({ theme }) => (theme === Sanity.ThemeColor.DARK ? Color.white : Color.blackBrand)};
    }
  }

  @media ${Device.mediumUp} {
    margin-left: ${rem(20)};
    width: ${rem(30)};
    height: ${rem(30)};
  }
`;
