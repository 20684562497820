import { CloudinaryAsset, CLOUDINARY_ASSET_PROJECTION } from "@models/cloudinary-asset.model";
import { Sanity } from "@models/sanity.model";

export const SectionHeroType = "section_hero";

export interface SectionHeroSchema extends Sanity.Document {
  eyebrow?: string;
  heading: string;
  body?: string;
  cta_text?: string;
  background_image: CloudinaryAsset;
  background_image_mobile: CloudinaryAsset;
}

export const SECTION_HERO_PROJECTION = `{
  eyebrow,
  heading,
  body,
  cta_text,
  background_image ${CLOUDINARY_ASSET_PROJECTION},
  background_image_mobile ${CLOUDINARY_ASSET_PROJECTION},
}`;
