import { StateLocations } from "../LocationsFilter.model";
import * as Styled from "./StateFilter.styled";

export interface StateFilterProps {
  onClickState: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onClickSelectAll: (e: React.MouseEvent<HTMLButtonElement>) => void;
  activeStates: StateLocations;
  state: string;
}

const StateFilter = ({ onClickState, onClickSelectAll, activeStates, state }: StateFilterProps) => {
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
  const stateFilterTitle = "Filter Locations";
  const seeAllText = "See all";

  const renderStateAlphabet = () => {
    return alphabet.map((a) =>
      Object.keys(activeStates).includes(a) ? (
        <Styled.TextLink onClick={onClickState} key={a} selected={a === state}>
          {a}
        </Styled.TextLink>
      ) : (
        <Styled.NonClick key={a}>{a}</Styled.NonClick>
      )
    );
  };

  return (
    <Styled.StateFilterContainer>
      <div className="grid-container">
        <Styled.FilterContainer>
          <Styled.HeadingRow>
            <h5>{stateFilterTitle}</h5>
          </Styled.HeadingRow>
          <Styled.AlphabetRow>{renderStateAlphabet()}</Styled.AlphabetRow>
          <Styled.SeeAllRow>
            <button onClick={onClickSelectAll}>{seeAllText}</button>
          </Styled.SeeAllRow>
        </Styled.FilterContainer>
      </div>
    </Styled.StateFilterContainer>
  );
};

export default StateFilter;
