import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import forEach from "lodash-es/forEach";
import Button from "@components/ui/button";
import Form, { ReviewValues } from "@components/reviews/review-form";
import { Device, Color } from "@styles/constants";
import styled from "styled-components";
import { APPLICATION_NODE } from "@utils/constants";
import { useUserProfile } from "@providers/profile";
import { events, identify } from "@providers/analytics/analytics"

const ReviewModal: React.FC<{
  cta_text: string;
}> = ({ cta_text }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const { profileState: { authState } } = useUserProfile();

  useEffect(() => {
    if (typeof document !== "undefined") {
      ReactModal.setAppElement(document.getElementById(APPLICATION_NODE));
    }
  }, []);

  const openModal = () => {
    setOpen(true);
    identify(authState.value)
    events.modal.opened(authState.value, 'Review Your Mirror')
  };

  const closeModal = () => {
    setOpen(false);
    setSubmitted(false);
    setImageUrl(null);
    identify(authState.value)
    events.modal.closed(authState.value, 'Review Your Mirror')
  };

  const onReview = (values: ReviewValues) => {
    const formData = new FormData();
    forEach(values, (v, k) =>
      formData.append(`review[${k}]`, typeof v === "number" ? v.toString() : v)
    );

    setSubmitted(true);
  };

  const renderModalContent = () => {
    if (submitted) {
      return (
        <div className="grid-x">
          <div className="cell small-12">
            <div className="confirmation">
              <h1>Thank you!</h1>
              <p>We will review your submission and reach out with any questions</p>
              <button className="button" onClick={closeModal}>
                Continue
              </button>
            </div>
          </div>
        </div>
      );
    } else if (imageUrl) {
      return (
        <div className="row">
          <div className="grid-x">
            <div className="cell small-12">
              <div className="user-image">
                <header>
                  <div className="column small-12">
                    <button className="close" onClick={closeModal}>
                      Close
                    </button>
                  </div>
                </header>
                <img src={imageUrl} alt="" />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <Form onSubmit={onReview} onCancel={closeModal} />;
    }
  };

  return (
    <>
      {cta_text && <Button text={cta_text} onClick={openModal} />}

      <ReactModal
        isOpen={open}
        onRequestClose={closeModal}
        contentLabel={"Review Your Mirror"}
        overlayClassName={"llls__modal__overlay"}
        className={"llls__modal__content"}>
        <ReviewFormWrapper>
          <ModalContentInner>{renderModalContent()}</ModalContentInner>
        </ReviewFormWrapper>
      </ReactModal>
    </>
  );
};

export default ReviewModal;

const ReviewFormWrapper = styled.div`
  position: relative;
  display: flex;
  height: 100%;

  @media ${Device.mediumUp} {
    align-items: center;
    justify-content: center;
  }
`;

const ModalContentInner = styled.div`
  position: relative;
  max-width: 100%;
  background: ${Color.white};

  header {
    position: relative;
    border-bottom: 2px solid ${Color.black};
    padding-bottom: 2rem;
  }

  .close {
    text-indent: -99rem;
    overflow: hidden;
    z-index: 1;
    outline: 0;
    background: url("/images/close.svg") center center no-repeat;
    width: 1.15rem;
    height: 1.15rem;
    display: inline-block;
    position: absolute;
    left: 1rem;
    top: 1rem;
    cursor: pointer;
  }

  .confirmation {
    padding: 4rem;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;
