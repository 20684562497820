import styled from "styled-components";
import { motion } from "framer-motion";

export const Backgrounds = styled(motion.div)`
  position: absolute;
  left: 0;
  bottom: 0;
  height: auto;
  width: 100%;
  max-height: 70vh;
  overflow: hidden;
`;
