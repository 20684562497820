import styled, { css } from "styled-components";
import { Spacing, Device, Ease, Color } from "@styles/constants";
import { Heading3CSS, LargeBodyMediumCSS } from "@styles/typography";

export const QuoteCarousel = styled.div<{ autoplayDelay?: number; inView?: boolean }>`
  position: relative;

  .swiper-wrapper {
    padding-bottom: 3.75rem;
  }

  .swiper-slide {
    text-align: center;
    opacity: 0 !important;
    transition-timing-function: ${Ease.out};
  }

  .swiper-slide-active {
    opacity: 1 !important;
    transition-delay: 0.3s;
  }

  .swiper-pagination-bullets {
    bottom: -7px;

    @media ${Device.mediumUp} {
      bottom: -9px;
    }
  }

  .swiper-pagination-bullet {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 2px;
    background: transparent;
    border-radius: 10px;
    opacity: 1;
    margin: 0 !important;
    padding: 5px 0;
    overflow: hidden;

    @media ${Device.mediumUp} {
      width: 64px;
    }

    span {
      position: absolute;
      top: 4px;
      left: 0;
      display: block;
      width: 100%;
      height: 2px;
      background: ${Color.blackBrand};
      border-radius: 10px;
      transform: translateX(-100%);
    }

    + .swiper-pagination-bullet {
      margin-left: 8px !important;

      @media ${Device.mediumUp} {
        margin-left: 16px !important;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 4px;
      left: 0;
      width: 100%;
      height: 2px;
      background: #cbcbcb;
    }

    &.swiper-pagination-bullet-active span {
      ${({ inView, autoplayDelay }) =>
        inView &&
        css`
          transform: translateX(0%);
          transition: transform ${autoplayDelay ? `${autoplayDelay}ms` : `6000ms`} linear;
        `};
    }
  }
`;

export const QuoteContainer = styled.div`
  padding: 0 20px;

  @media ${Device.mediumUp} {
    padding: 0;
  }

  cite::before {
    display: none;
  }
`;

export const Quote = styled.blockquote`
  display: block;
  max-width: 35.5ch;
  margin: 0 auto;
  padding: 0;
  border: 0;
  ${Heading3CSS}
  color: ${Color.blackBrand};
`;

export const Reviewer = styled.h3`
  margin-top: 40px;
  ${LargeBodyMediumCSS}
  text-transform: none;
  color: ${Color.blackBrand};
`;
