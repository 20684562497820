import PortableText from "@components/portable-text";
import AspectRatio from "@components/primitives/aspect-ratio";
import Image from "@components/primitives/image";
import Button from "@components/ui/button";
import { FiftyFiftySchema, Theme, ThemeConfig } from "../FiftyFifty.model";
import * as Styled from "./TextColumn.styled";
import {
  BodyMediumCSS,
  BodyRegularCSS,
  Eyebrow,
  SmallBodyMediumCSS,
  SmallBodyRegularCSS,
} from "@styles/typography";
import { FadeIn, SlideInText } from "@components/ui/animations";
import { ComponentResolverAnimationProps } from "@components/component-resolver";

export interface TextColumnProps extends FiftyFiftySchema {
  children?: React.ReactNode;
  className?: string;
  themeConfig: ThemeConfig;
}

const TextColumn = ({
  eyebrow,
  heading,
  heading_size,
  rich_text,
  list_items,
  cta,
  secondary_cta,
  children,
  className,
  themeConfig,
  inView,
}: TextColumnProps & ComponentResolverAnimationProps) => (
  <div className={className || undefined}>
    <Styled.TextSection>
      {eyebrow && (
        <Eyebrow>
          <SlideInText visible={inView} delay={0.5}>
            {eyebrow}
          </SlideInText>
        </Eyebrow>
      )}
      {heading && (
        <Styled.Heading size={heading_size}>
          <SlideInText visible={inView} delay={0.5} addDelay={[eyebrow]}>
            {heading}
          </SlideInText>
        </Styled.Heading>
      )}
      <FadeIn visible={inView} delay={1} addDelay={[eyebrow]}>
        {children}
      </FadeIn>
      {rich_text && (
        <Styled.TextWrapper>
          <FadeIn visible={inView} delay={1} addDelay={[eyebrow]}>
            <PortableText
              value={rich_text}
              style={
                themeConfig.name === Theme.HARDWARE_INTRO ? BodyRegularCSS : SmallBodyRegularCSS
              }
            />
          </FadeIn>
        </Styled.TextWrapper>
      )}
      {list_items && list_items?.length > 0 && (
        <FadeIn visible={inView} delay={1} addDelay={[eyebrow, rich_text]}>
          <Styled.List>
            {list_items.map((item, index) => (
              <Styled.ListItem key={index}>
                <Styled.IconContainer>
                  <AspectRatio ratio={1}>
                    <Image
                      asset={item.icon}
                      alt={item.icon?.alt}
                      widthOnScreen={5}
                      loading="lazy"
                    />
                  </AspectRatio>
                </Styled.IconContainer>
                <Styled.ItemText>
                  <PortableText
                    value={item.text}
                    styleMobile={SmallBodyMediumCSS}
                    styleDesktop={BodyMediumCSS}
                  />
                </Styled.ItemText>
              </Styled.ListItem>
            ))}
          </Styled.List>
        </FadeIn>
      )}
      <FadeIn
        visible={inView}
        delay={1}
        addDelay={[eyebrow, rich_text, list_items && list_items?.length]}>
        <Styled.ButtonContainer>
          {cta?.url && <Button href={cta.url} text={cta.text} dark={themeConfig.isDark} />}
          {secondary_cta?.url && (
            <Button
              href={secondary_cta.url}
              text={secondary_cta.text}
              dark={themeConfig.isDark}
              invert
            />
          )}
        </Styled.ButtonContainer>
      </FadeIn>
    </Styled.TextSection>
  </div>
);

export default TextColumn;
