import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import { Formik } from "formik";
import { isIOS, isAndroid } from "react-device-detect";
// TODO - MIR-692 Digital Trial API
// import api from "src/api/mirror_digital_trial";
import { emailSchema } from "@utils/validations";
// TODO MIR-691 Integrate Sentry
// import { log } from "src/services/logging";
// TODO MIR-677 - Integrate Segment
// import { events } from "src/services/segment";
import { getQueryTrialParam } from "@utils/getQueryTrialParam";

import appStore from "@images/AppStore.svg";
import googlePlay from "@images/GooglePlay.svg";
import { ModalProps } from "../modals/Modal.model";
import { ModalContent } from "@components/query-modal";

import Loading from "@components/ui/loading";
import PortableText from "@components/portable-text";

import { APP_STORE_URL, GOOGLE_STORE_URL } from "@utils/constants";
import ModalContainer from "../modals/ModalContainer";

import * as Styled from "./QueryModal.styled";

interface Values {
  email: string;
}

export interface DigitalTrialModalProps extends ModalProps {
  toggleModal: () => void;
  state?: string | null;
  modalContent: ModalContent;
  emailPlaceholder?: string;
}

interface DownloadProps {
  showAlternate: boolean;
  modalContent: ModalContent;
}

// Download confirmation state
const Download = ({ showAlternate = true, modalContent }: DownloadProps) => {
  const { userFlow } = modalContent;

  return (
    <>
      {showAlternate ? (
        <>
          {userFlow.eyebrow_alternate && (
            <Styled.Eyebrow>{userFlow.eyebrow_alternate}</Styled.Eyebrow>
          )}
          {userFlow.heading_alternate && (
            <Styled.Heading>{userFlow.heading_alternate}</Styled.Heading>
          )}
          {userFlow.copy_alternate && (
            <Styled.Body>
              <PortableText value={userFlow.copy_alternate} />
            </Styled.Body>
          )}
        </>
      ) : (
        <>
          <Styled.Eyebrow>{userFlow.eyebrow_success}</Styled.Eyebrow>
          <Styled.Heading>{userFlow.heading_success}</Styled.Heading>
          {userFlow.copy_success && (
            <Styled.Body>
              <PortableText value={userFlow.copy_success} />
            </Styled.Body>
          )}
        </>
      )}
      <Styled.QRcodeWrapper>
        <Styled.QRcode>
          <img
            src={appStore}
            alt="A QR Code leading to the Mirror Workout Companion App on the App Store"
          />
          <Styled.QRcodeLink href={APP_STORE_URL} rel="external">
            {userFlow.app_store_link_text || "App Store Download"}
          </Styled.QRcodeLink>
        </Styled.QRcode>
        <Styled.QRcode>
          <img
            src={googlePlay}
            alt="A QR Code leading to the Mirror Workout Companion App on the Play Store"
          />
          <Styled.QRcodeLink href={GOOGLE_STORE_URL} rel="external">
            {userFlow.play_store_link_text || "Play Store Download"}
          </Styled.QRcodeLink>
        </Styled.QRcode>
      </Styled.QRcodeWrapper>
    </>
  );
};

// Mobile Redirect w/ Countdown timer
const MobileConfirmation = ({ trialLength }: { trialLength: string }) => {
  const [timeLeft, setTimeLeft] = useState(5);
  const router = useRouter();

  useEffect(() => {
    if (timeLeft > 1) {
      setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
    } else {
      if (isIOS) {
        window.location.href = APP_STORE_URL;
      } else if (isAndroid) {
        window.location.href = GOOGLE_STORE_URL;
      } else {
        router.push("/app");
      }
    }
  });

  return (
    <>
      <Styled.Heading>
        Your free trial starts now. You will be redirected in {timeLeft}...
      </Styled.Heading>
      <Styled.Body>
        Download the <Link href="/app">MIRROR App</Link> (if you haven&apos;t already!) to access
        unlimited workouts for the next {trialLength} days.
      </Styled.Body>
    </>
  );
};

const QueryModal = ({
  toggleModal,
  // TODO MIR-630 - Integrate Users
  // auth,
  modalContent,
  mounted,
  state,
  emailPlaceholder,
}: DigitalTrialModalProps) => {
  const router = useRouter();
  const isMobile = isIOS || isAndroid;
  const [isError, setIsError] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [showDownload, setShowDownload] = useState(true);
  const [showAlternate] = useState(state === "download" ? true : false);
  const [showForm, setShowForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [trialLength, settrialLength] = useState(getQueryTrialParam(router));
  const { userFlow, userFlowMobile } = modalContent;

  const createTrial = async (values: Values) => {
    try {
      // TODO - MIR-692 - Digital Trial API
      // const { body } = await api.createMirrorDigitalTrial(values.email, trialLength);
      // settrialLength(body.trialDuration);
      setSubmitting(false);
      setShowForm(false);
      setShowDownload(true);
      // events.freeTrial.start(auth, location.pathname, values.email);
    } catch (e) {
      // log(e);
      setIsError(true);
      setSubmitting(false);
      if (e.status === 409) {
        setErrorMessage("Please sign in to access your existing account.");
      } else {
        const customMessage = JSON.parse(e.response.text).message;
        const errorMessage = customMessage
          ? customMessage + "Please try again."
          : "Please try again.";
        setErrorMessage(errorMessage);
      }
    }
  };

  const handleSubmit = (values: Values, { resetForm }: any) => {
    setSubmitting(true);
    createTrial(values);
    resetForm({});
  };

  return (
    <ModalContainer toggleModal={toggleModal} mounted={mounted} name={"Free Trial"}>
      <Styled.Content>
        {showForm && (
          <>
            {isMobile ? (
              <>
                <Styled.Eyebrow>{userFlowMobile.eyebrow}</Styled.Eyebrow>
                <Styled.Heading>{userFlowMobile.heading}</Styled.Heading>
                <Styled.Body>
                  <PortableText value={userFlowMobile.copy} />
                </Styled.Body>
              </>
            ) : (
              <>
                <Styled.Eyebrow>{userFlow.eyebrow}</Styled.Eyebrow>
                <Styled.Heading>{userFlow.heading}</Styled.Heading>
                <Styled.Body>
                  <PortableText value={userFlow.copy} />
                </Styled.Body>
              </>
            )}
            <Formik
              enableReinitialize
              initialValues={{ email: "" }}
              onSubmit={handleSubmit}
              validationSchema={emailSchema}>
              {({ values, handleChange }) => (
                <Styled.StyledForm>
                  <Styled.InputWrapper>
                    <Styled.StyledField
                      type="email"
                      name="email"
                      required
                      onChange={handleChange}
                      placeholder={emailPlaceholder || "Email address"}
                      value={values.email}
                      disabled={isSubmitting}
                    />
                    <Styled.StyledButton disabled={isSubmitting} data-testid="redeem-gift">
                      <Styled.StyledArrow />
                      <span className="visually-hidden">Submit</span>
                    </Styled.StyledButton>
                  </Styled.InputWrapper>
                  <Styled.StyledSubText>
                    {isError && <Styled.StyledError>{errorMessage}</Styled.StyledError>}
                  </Styled.StyledSubText>
                  <Loading visible={isSubmitting} />
                </Styled.StyledForm>
              )}
            </Formik>
          </>
        )}

        {showDownload && (
          <>
            {isMobile ? (
              <MobileConfirmation trialLength={trialLength} />
            ) : (
              <Download showAlternate={showAlternate} modalContent={modalContent} />
            )}
          </>
        )}
      </Styled.Content>
    </ModalContainer>
  );
};

export default QueryModal;
