import styled, { css } from "styled-components";
import { Color, Device } from "@styles/constants";
import {
  BodyRegular,
  Eyebrow as GlobalEyebrow,
  Heading3,
  XSmallBodyRegular,
} from "@styles/typography";
import { rem } from "@styles/helpers";

export const ProgramGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${rem(16)} ${rem(10)};

  @media ${Device.mediumUp} {
    grid-template-columns: repeat(3, 1fr);
    gap: 1.25rem;
  }
`;

export const JumbotronText = styled(BodyRegular)`
  @media ${Device.small} {
    max-width: calc(100% - ${rem(60)});
  }
`;

export const ProgramBlock = styled.article`
  position: relative;
  display: grid;

  > * {
    position: relative;
    grid-area: 1 / -1;
    align-self: flex-end;
    max-height: 380px;
  }
`;

const BackgroundCSS = css`
  width: 100%;
  padding-bottom: 100%;
  border-radius: 10px;
`;

export const Background = styled.div`
  ${BackgroundCSS}
`;

export const Gradient = styled.div<{ gradientColor?: string }>`
  ${BackgroundCSS}
  background-image: linear-gradient(38.92deg, ${({ gradientColor }) =>
    gradientColor || "black"}, rgba(255, 255, 255, 0.1) 62.96%, transparent 85%, transparent);
`;

export const Liner = styled.div`
  display: grid;
  padding-bottom: 100%;
`;

export const ImageWrapper = styled.div`
  align-self: start;
  max-height: none;
`;

export const Container = styled.div`
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${rem(20)} ${rem(11)} ${rem(11)};

  @media ${Device.mediumUp} {
    padding: ${rem(42)} ${rem(11)} ${rem(11)};
  }

  @media ${Device.largeUp} {
    padding: ${rem(102)} ${rem(24)} ${rem(24)};
  }
`;

export const Icon = styled.div`
  width: clamp(${rem(32)}, 8.5vw, ${rem(60)});

  img {
    display: block;
  }
`;

export const Eyebrow = styled(GlobalEyebrow)`
  color: ${Color.white};
  margin-bottom: 0;
`;

export const Title = styled(Heading3)`
  margin: ${rem(20)} 0 ${rem(16)};
  color: ${Color.white};
  letter-spacing: -0.03em;

  @media ${Device.mediumUp} {
    margin-top: ${rem(12)};
    font-size: clamp(${rem(28)}, 3vw, ${rem(40)});
  }
`;

export const Caption = styled(XSmallBodyRegular)`
  margin: auto 0 0;
  padding: ${rem(1)} ${rem(4)};
  background: ${Color.white};
  color: ${Color.blackBrand};
  font-size: ${rem(10)};
  border-radius: 1.2px;

  @media ${Device.largeUp} {
    padding: ${rem(4)} ${rem(8)};
    border-radius: 3px;
  }
`;
