import { ThemeProvider } from "styled-components";
import useMediaQuery from "@hooks/useMediaQuery";
import { Device } from "@styles/constants";
import {
  ColumnPosition,
  FiftyFiftySchema,
  ImagePosition,
  Theme,
  ThemeConfig,
} from "./FiftyFifty.model";
import ImageColumn from "./image-column";
import TextColumn from "./text-column";
import * as Styled from "./FiftyFifty.styled";
import LegacyFiftyFifty from "./legacy";

const FiftyFifty = (props: FiftyFiftySchema) => {
  const { inView, theme, heading_size, mobile_image_position, image_position, list_items } = props;

  const themeConfig: ThemeConfig = {
    name: theme || Theme.LEGACY,
    image_position,
    heading_size,
    isDark: [Theme.DARK_BLEED, Theme.HARDWARE_INTRO].includes(theme),
    increaseRichTextSpacing: !list_items?.length,
  };

  const useMobilePosition = useMediaQuery(Device.mediumDown);

  if (themeConfig.name === Theme.LEGACY) return <LegacyFiftyFifty {...props} />;

  const currentPosition = useMobilePosition ? mobile_image_position : image_position;
  const imageFirst =
    currentPosition === ImagePosition.LEFT || currentPosition === ImagePosition.TOP;

  const sharedProps = {
    ...props,
    inView,
    themeConfig,
  };

  return (
    <ThemeProvider theme={themeConfig}>
      <Styled.Section>
        <Styled.Container className="grid-container">
          {imageFirst && (
            <ImageColumn {...sharedProps} className="large-6" position={ColumnPosition.FIRST} />
          )}
          <TextColumn
            {...sharedProps}
            className={
              themeConfig.name === Theme.HARDWARE_INTRO ? "large-4" : "large-5"
            }></TextColumn>
          {!imageFirst && (
            <ImageColumn {...sharedProps} className="large-6" position={ColumnPosition.LAST} />
          )}
        </Styled.Container>
      </Styled.Section>
    </ThemeProvider>
  );
};

export default FiftyFifty;
