import React from "react";

export interface DropZoneProps {
  dropZoneText?: string;
  dropZoneClass?: string;
  buttonText?: string;
  hideButton?: boolean;
  accept?: string;
  onUpload?: (fs: FileList) => void | Promise<any>;
}

export interface DropZoneState {
  files: FileList | null;
  fileData: Array<{ name: string; img?: string }>;
}

const defaultAccept = "image/png, image/jpg, image/jpeg, image/gif";

class DropZone extends React.Component<DropZoneProps, DropZoneState> {
  state: DropZoneState = { files: null, fileData: [] };
  fileInput: HTMLInputElement | null;

  handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const { files } = e.dataTransfer;
    this.setState({ files }, () => {
      this.handleFileUpload();
    });
  };

  clickFileInput = () => this.fileInput?.click();

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      this.setState({ files: e.target.files }, () => {
        this.handleFileUpload();
      });
    }
  };

  handleFileUpload = () => {
    const { onUpload } = this.props;
    const { files } = this.state;
    this.setState({ fileData: [] });
    if (files?.length) {
      this.setState({ fileData: [] }, () => {
        Array.from(files).forEach((file: File) => {
          const { fileData } = this.state;
          if (/^image\/(gif|png|jpeg)$/i.test(file.type)) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            // https://github.com/Microsoft/TypeScript/issues/4163
            reader.onload = (e: ProgressEvent<FileReader> & { target: { result: string } }) => {
              this.setState({ fileData: [...fileData, { name: file.name, img: e.target.result }] });
            };
          } else {
            this.setState({ fileData: [...fileData, { name: file.name }] });
          }
        });
      });
      if (onUpload) onUpload(files);
    }
  };

  render() {
    const { fileData } = this.state;
    const {
      buttonText = "Select File",
      dropZoneText = "Drop File here",
      dropZoneClass = "drop-area",
      hideButton = false,
      accept = defaultAccept,
    } = this.props;
    const text = dropZoneText;

    return (
      <div className="drop-zone">
        <div className="file-select">
          <input
            type="file"
            accept={accept}
            onChange={this.handleChange}
            ref={(input) => (this.fileInput = input)}
          />
          {!hideButton && (
            <button className="button" disabled={false} onClick={this.clickFileInput}>
              {buttonText}
            </button>
          )}
        </div>
        <div
          className={dropZoneClass}
          onClick={this.clickFileInput}
          onDrop={this.handleDrop}
          onDragOver={(e) => e.preventDefault()}>
          {fileData.length
            ? fileData.map((f, i) =>
                f.img ? (
                  <img key={i} className="preview image" src={f.img} />
                ) : (
                  <span key={i} className="preview text">
                    {f.name}
                  </span>
                )
              )
            : text}
        </div>
      </div>
    );
  }
}

export default DropZone;
