// Proxy the default model/type, since they are the same thing to Sanity.

import {
  QuoteCarouselSchema,
  QUOTE_CAROUSEL_PROJECTION,
} from "@components/quote-carousel/QuoteCarousel.model";

export const DigitalTrialQuoteCarouselType = "digital_trial_quote_carousel";

export interface DigitalTrialQuoteCarouselSchema extends QuoteCarouselSchema {}

export const DIGITAL_TRIAL_QUOTE_CAROUSEL_PROJECTION = QUOTE_CAROUSEL_PROJECTION;
