import { Eyebrow } from "@styles/typography";
import { Sanity } from "@models/sanity.model";
import Button from "@components/ui/button/Button";
import { FullWidthProps } from "../InfoBlock.model";
import * as Styled from "../InfoBlock.styled";

const FullWidthContent = ({
  text_alignment,
  eyebrow,
  headline,
  headline_size,
  subheadline,
  cta,
  children,
}: FullWidthProps) => {
  const headlineClass =
    text_alignment === Sanity.HeadlinePosition.CENTER && headline_size === Sanity.HeadlineSize.H1
      ? "large-12"
      : "large-9";

  return (
    <Styled.FullWidthGrid>
      <Styled.ContentContainer className="grid-container" alignment={text_alignment}>
        {eyebrow && <Eyebrow>{eyebrow}</Eyebrow>}
        {headline && (
          <Styled.Headline
            headlineSize={headline_size}
            alignment={text_alignment}
            className={headlineClass}>
            {headline}
          </Styled.Headline>
        )}
        {subheadline && <Styled.Subheadline>{subheadline}</Styled.Subheadline>}
        {cta?.url && (
          <Styled.Cta>
            <Button href={cta.url}>{cta.text}</Button>
          </Styled.Cta>
        )}
      </Styled.ContentContainer>
      {children && <Styled.Children>{children}</Styled.Children>}
    </Styled.FullWidthGrid>
  );
};

export default FullWidthContent;
