import styled from "styled-components";
import { Color, Device, EasePage } from "@styles/constants";
import { BodyMediumCSS } from "@styles/typography";
import { AbsoluteCoverCSS } from "@styles/layout";
import { rem } from "@styles/helpers";

export const Container = styled.div`
  position: relative;
  overflow: hidden;
`;

export const Image = styled.div`
  position: relative;
  height: 600px;

  img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &::after {
    ${AbsoluteCoverCSS}
    content: '';
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  }

  @media ${Device.largeUp} {
    height: auto;
  }
`;

export const Panel = styled.div<{ inView?: boolean }>`
  --offset: 1.25rem;

  @media ${Device.mediumUp} {
    --offset: 2.5rem;
  }

  @media ${Device.xlargeUp} {
    --offset: 0.625rem;
  }

  @keyframes panelIn {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }

  position: absolute;
  bottom: 0;
  left: var(--offset);
  width: calc(100% - var(--offset) * 2);
  padding: ${rem(40)} 0;
  color: ${Color.white};
  margin: 0 !important;
  transform: translateY(100%);
  ${({ inView }) =>
    inView && `animation: panelIn ${EasePage.duration} ${EasePage.curve} 0.35s forwards;`}

  .cell {
    z-index: 1;
    padding: 0 var(--offset) !important;

    @media ${Device.largeUp} {
      &:nth-child(1) {
        margin-left: 8.33333%;
      }

      &:nth-child(3) {
        display: flex;
        justify-content: flex-end;
        margin-right: 8.33333%;
      }
    }
  }

  &::before {
    ${AbsoluteCoverCSS}

    content: '';
    left: 0;
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(${rem(40)});
    width: 100%;
  }
`;

export const Eyebrow = styled.span`
  ${BodyMediumCSS}
  display: block;
`;

export const Heading = styled.h4`
  margin-top: ${rem(28)};
  text-align: left;
  white-space: nowrap;
  margin-bottom: 0;

  @media ${Device.largeUp} {
    margin-top: 0;
  }
`;

export const Body = styled.p`
  margin-bottom: 0;
  margin-top: ${rem(5)};
  max-width: 25ch;

  @media ${Device.largeUp} {
    margin-top: ${rem(16)};
  }
`;

export const Logo = styled.div`
  margin-top: ${rem(10)};

  @media ${Device.largeUp} {
    margin-top: ${rem(17)};
  }

  img {
    width: ${rem(153)};

    @media ${Device.largeUp} {
      width: ${rem(224)};
    }
  }
`;

export const Button = styled.div`
  margin-top: ${rem(40)};

  @media ${Device.largeUp} {
    margin-top: 0;
  }
`;
