import styled from "styled-components";
import { Color, Device, Ease } from "@styles/constants";
import { Heading2CSS, Heading3CSS, BodyMediumCSS, SmallBodyMediumCSS } from "@styles/typography";
import { AbsoluteCoverCSS } from "@styles/layout";
import { rem } from "@styles/helpers";

export const Breadcrumb = styled.div`
  margin-bottom: ${rem(80)};
  display: flex;
  align-items: center;

  @media ${Device.largeUp} {
    margin-bottom: ${rem(-20)};
  }

  svg {
    width: ${rem(8)};
    height: ${rem(8)};
    margin: 0 ${rem(10)};
    fill: currentColor;
    stroke: currentColor;
    stroke-width: 2px;
    transform: rotateZ(-90deg);
  }
`;

export const BreadcrumbItem = styled.span<{ active?: boolean }>`
  ${SmallBodyMediumCSS}
  text-transform: uppercase;
  color: ${({ active }) => (active ? Color.blackBrand : Color.grayInactive)};
`;

export const Step = styled.div<{ active?: boolean }>`
  width: 100%;
  display: flex;
  flex-flow: column;

  @media ${Device.largeUp} {
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const Text = styled.div`
  width: 100%;

  @media ${Device.largeUp} {
    padding-right: ${rem(110)};
  }

  p {
    max-width: ${rem(420)};
  }
`;

export const Heading = styled.h2`
  ${Heading2CSS}

  @media ${Device.mediumUp} {
    ${Heading3CSS}
  }
`;

export const Form = styled.form`
  margin-top: ${rem(40)};
  display: flex;
  align-items: center;
  border: 1px solid ${Color.greenAsh};
  border-radius: ${rem(35)};
  padding: 0 ${rem(35)};
  transition: border ${Ease.duration} ${Ease.out};

  &:focus-within {
    border-color: ${Color.blackBrand};
  }
`;

export const Input = styled.input`
  ${BodyMediumCSS};
  flex: 1;
  border: none;
  background: transparent;
  box-shadow: none;
  color: ${Color.blackBrand};
  padding: 0;
  margin: 0;
  line-height: ${rem(68)};
  height: ${rem(68)};

  &::placeholder {
    color: ${Color.grayInactive};
  }

  &:focus {
    outline: none;
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
`;

export const Submit = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${rem(20)};
  height: ${rem(20)};
  cursor: pointer;
  margin-left: ${rem(10)};

  svg {
    width: 100%;
    height: auto;

    path {
      fill: ${Color.greenAsh};
      transition: fill ${Ease.duration} ${Ease.out};
    }
  }

  &:focus,
  &:hover {
    svg path {
      fill: ${Color.blackBrand};
    }
  }
`;

export const Error = styled.div`
  ${SmallBodyMediumCSS}
  color: ${Color.redError};
  margin-top: ${rem(10)};

  @media ${Device.largeUp} {
    position: absolute;
  }
`;

export const Routing = styled.div`
  margin-top: ${rem(30)};
  max-width: ${rem(360)};
  display: flex;

  @media ${Device.largeUp} {
    display: none;
  }

  a {
    display: block;

    + a {
      margin-left: ${rem(16)};
    }
  }
`;

export const Asset = styled.div`
  margin-top: ${rem(80)};
  position: relative;

  @media ${Device.largeUp} {
    width: calc(50% - ${rem(10)});
    flex-shrink: 0;
    margin-top: 0;
  }
`;

export const QrCode = styled.div`
  display: none;

  @media ${Device.largeUp} {
    ${AbsoluteCoverCSS}
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  canvas {
    width: 65%;
    aspect-ratio: 1/1;
    filter: invert(1);
    mix-blend-mode: lighten;
  }
`;
