import { useRef, useState, useCallback } from "react";
// TODO: MIR-677
// import { connect } from "react-redux";
// import { first, Resource } from "redux-api-resources";

// import { events, identify } from "@services/segment";
// import { State } from "src/store";
// import { AuthState } from "src/store/auth";
// import { NodeModel, NodeType } from "src/store/nodes/model";

import Ticker from "@components/ticker";
import useInViewAnimation from "@hooks/useInViewAnimation";
import { FadeIn } from "@components/ui/animations";

import { ReactComponent as ArrowSubmitSVG } from "@images/arrow_submit.svg";

import * as Styled from "./Newsletter.styled";
import { NewsletterSchema } from "./Newsletter.model";
import { events, identify } from "@providers/analytics/analytics";
import { LocalStorageKeys } from "@models/storage.model";
import { ComponentResolverAnimationProps } from "@components/component-resolver";

export interface NewsletterProps {
  sanity: NewsletterSchema;
  invert?: boolean;
  body?: string;
  email_failure?: string;
  email_placeholder?: string;
  email_success?: string;
  ticker_text?: string;
}

const Newsletter = ({
  inView,
  sanity,
  invert,
}: NewsletterProps & ComponentResolverAnimationProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);

  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const computedData = {
    title: sanity.ticker_text || "Stay connected.",
    text:
      sanity.body ||
      "Get product updates and news from MIRROR right in your inbox.\nWe won't spam you, promise.",
    placeholder: sanity.email_placeholder || "Enter your email address",
    errorMessage: sanity.email_failure || "Please enter a valid email.",
    thankYou: sanity.email_success || "Thank you for joining our list!",
  };

  const onSubmit = useCallback(
    (e: React.SyntheticEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (email && regex.test(email)) {
        // TODO: MIR-677
        // unable to pull in state from profileProvider here so need to access localStorage directly
        const authState = JSON.parse(window.localStorage.getItem(LocalStorageKeys.AUTH_STATE));
        identify(authState.value, { email });
        events.email.submitted(authState.value, email);
        setEmail("");
        setSubmitted(true);
        setError(false);
      } else if (inputRef.current) {
        setSubmitted(false);
        setError(true);
      }
    },
    [email]
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value.trim());
  };

  return (
    <Styled.Newsletter theme={sanity.theme_style}>
      <FadeIn visible={inView}>
        <Ticker
          text={computedData.title}
          large={sanity.ticker_large || null}
          largeMobile={sanity.ticker_large_mobile || null}
        />
      </FadeIn>

      <FadeIn visible={inView} delay={0.1}>
        <div className="grid-container">
          <div className="grid-x align-center">
            <div className="cell medium-11 large-8">
              <Styled.Form theme={sanity.theme_style} onSubmit={onSubmit} noValidate>
                <Styled.Input
                  ref={inputRef}
                  type="email"
                  placeholder={computedData.placeholder}
                  value={email}
                  onChange={onChange}
                  theme={sanity.theme_style}
                />
                <Styled.Submit type="submit" theme={sanity.theme_style}>
                  <ArrowSubmitSVG />
                  <span className="visually-hidden">Submit</span>
                </Styled.Submit>
              </Styled.Form>
              {submitted && (
                <Styled.Body theme={sanity.theme_style} success>
                  {computedData.thankYou}
                </Styled.Body>
              )}
              {error && (
                <Styled.Body theme={sanity.theme_style} error>
                  {computedData.errorMessage}
                </Styled.Body>
              )}
              <Styled.Body theme={sanity.theme_style}>
                {sanity.body || computedData.text}
              </Styled.Body>
            </div>
          </div>
        </div>
      </FadeIn>
    </Styled.Newsletter>
  );
};

export default Newsletter;
