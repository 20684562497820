import { useEffect, useRef } from "react";
import {
  TimerStatus,
  UserInteractionDispatch,
  TimerInteractionDispatch,
} from "@components/timed-tabs/TimedTabs";
import * as Styled from "./Tab.styled";

export interface TabProps {
  active: boolean;
  position: number;
  timerStatus: TimerStatus;
  duration: number;
  onUserInteraction: UserInteractionDispatch;
  onTimerInteraction: TimerInteractionDispatch;
  children?: React.ReactNode;
}

const Tab = ({
  active,
  position,
  timerStatus,
  duration,
  onUserInteraction,
  onTimerInteraction,
  children,
}: TabProps) => {
  const progress = useRef<HTMLDivElement>();

  useEffect(() => {
    if (!active || !progress.current) return;
    if (timerStatus !== TimerStatus.INITIAL) {
      progress.current.style.transform = "scaleY(1)";
    }
  }, [active, timerStatus]);

  return (
    <Styled.TabContainer aria-selected={active} onClick={() => onUserInteraction(position)}>
      {children}
      <Styled.ProgressTrack>
        <Styled.ProgressLiner>
          {active && (
            <Styled.ProgressThumb
              ref={progress}
              $duration={timerStatus === TimerStatus.ENABLED ? duration : 0}
              onTransitionEnd={timerStatus === TimerStatus.ENABLED ? onTimerInteraction : null}
            />
          )}
        </Styled.ProgressLiner>
      </Styled.ProgressTrack>
    </Styled.TabContainer>
  );
};

export default Tab;
