import { CLOUDINARY_ASSET_PROJECTION } from "@models/cloudinary-asset.model";
import { Sanity } from "@models/sanity.model";

export const AccordionRepeaterType = "accordian";

export interface AccordionRepeaterSchema extends Sanity.Document {
  heading: string;
  sections: Sanity.AccordionSection[];
}

export const ACCORDION_REPEATER_PROJECTION = `{
  heading,
  sections[] {
    title,
    text,
    image ${CLOUDINARY_ASSET_PROJECTION},
    children[] {
      title,
      heading,
      detail_text,
      image ${CLOUDINARY_ASSET_PROJECTION}
    }
  }
}`;
