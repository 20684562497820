import { Color, Device } from "@styles/constants";
import { rem } from "@styles/helpers";
import { Heading2CSS, XSmallBodyRegularCSS } from "@styles/typography";
import styled from "styled-components";

export const Section = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  @media ${Device.mediumDown} {
    padding: 0;
    background-color: ${Color.blueAsh};
  }

  @media (min-width: 40em) and (max-width: 64em) {
    &.grid-container {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media ${Device.mediumUp} {
    flex-direction: row;
  }

  @media ${Device.largeUp} {
    background-color: ${Color.blueAsh};
  }
`;

export const TextColumn = styled.div`
  flex: 1 1 0%;

  @media ${Device.largeUp} {
    display: flex;
    align-items: center;
  }
`;

export const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: ${rem(60)} ${rem(20)} ${rem(0)};
  text-align: left;

  @media ${Device.mediumUp} {
    align-items: flex-start;
    padding-left: ${rem(40)};
  }

  @media ${Device.largeUp} {
    padding-left: ${rem(60)};
  }
`;

export const Heading = styled.h2`
  ${Heading2CSS}
  text-align: center;
  color: ${Color.black};
  line-height: 1.25;
  margin-bottom: ${rem(40)};

  @media ${Device.mediumUp} {
    text-align: initial;
  }
`;

export const TriggerWrapper = styled.div`
  width: 100%;
  margin-bottom: ${rem(40)};
`;

export const ImageColumn = styled.div`
  flex: 1 1 0%;

  @media ${Device.largeUp} {
    max-width: min(49.15%, 36.25rem);
  }
`;

export const Footnote = styled.div`
  ${XSmallBodyRegularCSS}
  color: ${Color.black};
  display: flex;
  margin-bottom: ${rem(40)};

  sup {
    top: -0.25em;
    align-self: center;
    margin-right: 0.5ch;
  }

  p {
    margin-bottom: 0;
  }

  @media ${Device.small} {
    align-self: center;
    font-size: ${rem(14)};
  }
`;

export const ImageContainer = styled.div`
  height: 100%;

  .cloudinary-format {
    overflow: hidden;
    min-height: 100%;
  }

  img {
    object-fit: cover;
  }

  video {
    display: block;
    width: 100%;
  }
`;
