import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import { Color, Device } from "@styles/constants";
import { AbsoluteCoverCSS, AbsoluteCenterXCSS } from "@styles/layout";
import infographic from "@images/experience_infographic.svg";

export const StyledOutro = styled(motion.div)`
  position: relative;
  top: 0;
  left: 0;
  margin-top: -300vh;
  height: 300vh;
  width: 100%;
  z-index: 1;

  h2 {
    color: ${Color.white};
    max-width: 15ch;
  }
`;

export const StyledTop = styled(motion.div)`
  position: sticky;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-end;
  z-index: 1;
`;
export const StyledBottom = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 100vh;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
`;

export const StyledOutroGraphic = styled(motion.div)`
  width: 500px;
  height: 500px;
  margin: 0 auto 5vh;
  transform-origin: center;

  @media screen and (min-height: 850px) {
    margin: 0 auto 12vh;
  }

  div {
    width: 100%;
    height: 100%;
    background: url(${infographic}) center center / contain no-repeat;
  }
`;

export const StyledBackground = styled.div`
  ${AbsoluteCoverCSS}

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const StyledHeading = styled(motion.div)`
  position: relative;
  z-index: 2;

  h2 {
    text-align: left;
    max-width: 20ch;
    margin-bottom: 0;
    color: ${Color.white};

    @media ${Device.mediumUp} {
      font-size: 48px;
      line-height: 1.21;
    }
  }
`;

export const StyledContent = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding: 80px 0;
  overflow: hidden;
`;

export const StyledScore = styled(motion.div)`
  ${AbsoluteCenterXCSS}
  bottom: 5vh;
  text-align: center;
  width: 475px;
  height: 475px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  // background: url(${infographic}) center center / contain no-repeat;

  @media screen and (min-height: 850px) {
    bottom: 12vh;
  }

  span {
    display: block;
    text-transform: uppercase;
    color: ${Color.white};
    letter-spacing: 3px;
  }
`;
