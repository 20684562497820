import styled from "styled-components";
import { Device } from "@styles/constants";

export const Background = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media ${Device.small} {
    height: 150px;
  }
`;

export const Image = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translateX(-50%);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Heading = styled.h1`
  position: relative;
  font-size: 3rem;
  text-transform: uppercase;
  letter-spacing: 0.4rem;
  text-align: center;
  margin-bottom: 2rem;
  color: white;
  margin-bottom: 0;
  max-width: 650px;
  line-height: 1.5em;

  @media ${Device.small} {
    font-size: 1.75rem;
    letter-spacing: 0.2rem;
  }
`;
