// TODO: MIR-678 Connect DigitalTrialModalTrigger to Segment
import { useRouter } from "next/router";
import { useState, useRef, useEffect } from "react";
import { isIOS, isAndroid } from "react-device-detect";
import { QueryModalSchema } from "@components/query-modal/QueryModal.model";
import { getQueryTrialParam } from "@utils/getQueryTrialParam";
import QueryModal from "../QueryModal";
import * as Styled from "./QueryModalTrigger.styled";

const getTriggerText = (trialLength: string, buttonText: string | undefined): string | null => {
  if (!buttonText) return null;
  return buttonText.includes("[length]") ? buttonText.replace("[length]", trialLength) : buttonText;
};

export interface QueryModalTriggerProps extends Omit<QueryModalSchema, "_id"> {
  email_placeholder: string;
  // TODO MIR-630 Integrate Shopify users
  // auth: AuthState
  // eventTrackingAuth?: AuthState;
  button_text?: string;
  light?: boolean;
  small?: boolean;
  eyebrow?: string;
}

const QueryModalTrigger = (props: QueryModalTriggerProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [queryParam, setQueryParam] = useState<string | null>();
  const router = useRouter();
  const trialLength = getQueryTrialParam(router);
  const triggerText = useRef<string>(getTriggerText(trialLength, props?.button_text));
  const isMobile = isIOS || isAndroid;
  // TODO MIR-630 - Integrate Users
  // const eventTrackingAuth: AuthState = null;
  // const initialRender = useRef(true); // TODO MIR-677 Integrate Segment
  // const eyebrow = "App Quick Link";

  const toggleModal = () => {
    /**
     * 'download' state for mobile devices links out to repective app stores,
     * so nothing needs to happen when the modal trigger is activated.
     */
    if (queryParam === "download" && isMobile) return;

    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    // Get URL query params: 'state'
    const search = new URLSearchParams(window.location.search);
    setQueryParam(search.get("state"));
  }, []);

  // TODO MIR-677 Integrate Segment
  // useEffect(() => {
  //   if (initialRender.current) {
  //     initialRender.current = false;
  //   } else {
  //     // segment events
  //     if (isModalOpen && eventTrackingAuth)
  //       events.modal.opened(eventTrackingAuth, props.eyebrow ? props.eyebrow : eyebrow);
  //     if (!isModalOpen && eventTrackingAuth)
  //       events.modal.closed(eventTrackingAuth, props.eyebrow ? props.eyebrow : eyebrow);
  //   }
  // }, [isModalOpen, props.eyebrow]);

  return (
    <>
      <Styled.ToggleButton
        onClick={toggleModal}
        light={props.light}
        small={props.small}
        aria-pressed={isModalOpen}>
        {triggerText.current}
      </Styled.ToggleButton>

      <QueryModal
        toggleModal={toggleModal}
        mounted={isModalOpen}
        state={queryParam}
        modalContent={{
          userFlow: props.userFlow,
          userFlowMobile: props.userFlowMobile,
        }}
        {...props}
      />
    </>
  );
};

export default QueryModalTrigger;
