import styled from "styled-components";
import { motion } from "framer-motion";

export const Foreground = styled.div``;

export const Image = styled(motion.div)`
  position: absolute;
  left: 0;
  bottom: 17vh;
  height: auto;
  width: 100%;
`;

export const ImageContainer = styled.div``;
