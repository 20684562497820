import {
  SanitySyncProductDocument,
  sanitySyncProductDocumentProjection,
} from "@models/product.model";
import { Sanity } from "@models/sanity.model";

export const AccessoriesSectionType = "accessories_section";

export interface AccessoriesSectionSchema extends Sanity.Document {
  anchor_label: string;
  eyebrow: string;
  heading: string;
  accessories: Array<SanitySyncProductDocument>;
}

export const ACCESSORIES_SECTION_PROJECTION = `{
  anchor_label,
  eyebrow,
  heading,
  accessories[]-> ${sanitySyncProductDocumentProjection()},
}`;
