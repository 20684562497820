import * as React from "react";
import { CloudinaryAsset } from "@models/cloudinary-asset.model";
import CloudinaryFormat from "@components/cloudinary";
import * as Styled from "./HeroIphoneStand.styled";

import mirrorStandBG from "@images/hero_mirror_device2.png";

type HeroIphoneStandProps = {
  standMedia: CloudinaryAsset;
  iphoneBezel: string;
  iphoneMedia: CloudinaryAsset;
};

const HeroIphoneStand: React.FC<HeroIphoneStandProps> = ({
  standMedia,
  iphoneBezel,
  iphoneMedia,
}) => {
  const [videoLoaded, setVideoLoaded] = React.useState<boolean>(false);
  const videoEl = React.useRef<HTMLVideoElement | undefined>();

  React.useEffect(() => {
    if (!videoEl.current) return;
    videoEl.current.addEventListener("loadeddata", onVideoLoaded);
    videoEl.current.load();
  }, []);

  const onVideoLoaded = () => {
    setVideoLoaded(videoEl.current.readyState >= 2);
  };

  return (
    <Styled.IphoneStandWrapper videoLoaded={videoLoaded}>
      <Styled.IphoneStandInner>
        {/* iphone */}
        <Styled.IphoneContainer>
          <Styled.IphoneBgWrapper>
            {iphoneMedia?.url && (
              <>
                <CloudinaryFormat
                  media={iphoneMedia}
                  widthOnScreenValues={100}
                  mediaRef={videoEl}
                />
                <img className="iphone-bg" src={iphoneBezel} alt="" />
              </>
            )}
          </Styled.IphoneBgWrapper>
        </Styled.IphoneContainer>
        {/* stand */}
        <Styled.StandContainer>
          <Styled.StandBgWrapper>
            {standMedia?.url && (
              <>
                <CloudinaryFormat media={standMedia} widthOnScreenValues={100} mediaRef={videoEl} />
                <img className="stand-bg" src={mirrorStandBG.src} alt="" />
              </>
            )}
          </Styled.StandBgWrapper>
        </Styled.StandContainer>
      </Styled.IphoneStandInner>
    </Styled.IphoneStandWrapper>
  );
};

export default HeroIphoneStand;
