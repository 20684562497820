import { useRef, useEffect } from "react";
import SwiperCore, { EffectCreative } from "swiper";
import { SmallBodyRegularCSS } from "@styles/typography";
import PortableText from "@components/portable-text";
import AspectRatio from "@components/primitives/aspect-ratio";
import Image from "@components/primitives/image";
import Video from "@components/primitives/video";
import PaginationButton from "../pagination-button";
import { TimerStatus, UserInteractionDispatch, TimerInteractionDispatch } from "../TimedTabs";
import { Ratio, TabType, FadeOptionsProps } from "../TimedTabs.model";
import { BackgroundWrapper, BackgroundImage } from "../TimedTabs.styled";
import * as Styled from "./CarouselTabs.styled";

export interface CarouselTabsProps {
  dark: boolean;
  ratio: Ratio;
  tabs: TabType[];
  activeIndex: number;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
  timerStatus: TimerStatus;
  setTimerStatus: React.Dispatch<React.SetStateAction<TimerStatus>>;
  animation_duration: number;
  onUserInteraction: UserInteractionDispatch;
  onTimerInteraction: TimerInteractionDispatch;
}

// destroyed property is not typed on the instance, but exists when destroy() is called on it.
interface ExtendedSwiperCore extends SwiperCore {
  destroyed: boolean;
}

const CarouselTabs = ({
  dark,
  ratio,
  tabs,
  activeIndex,
  setActiveIndex,
  animation_duration,
  timerStatus,
  setTimerStatus,
  onUserInteraction,
  onTimerInteraction,
}: CarouselTabsProps) => {
  const imageCarousel = useRef<HTMLDivElement | undefined>();
  const imageInstance = useRef<ExtendedSwiperCore>();

  const textCarousel = useRef<HTMLDivElement | undefined>();
  const textInstance = useRef<ExtendedSwiperCore>();

  const ratioCalc = ratio.width / ratio.height;

  useEffect(() => {
    if (!imageCarousel.current) return;

    const slidesPerView = ratioCalc === 1 ? 1.12 : 1;
    const spaceBetween = ratioCalc === 1 ? 10 : 21;

    imageInstance.current = new SwiperCore(imageCarousel.current, {
      spaceBetween,
      slidesPerView,
      centeredSlides: true,
      speed: dark ? 1000 : 800,
      pagination: {
        el: ".pagination-container",
        type: "custom",
      },
      effect: "creative",
      creativeEffect: {
        prev: {
          translate: ["-15%", 0, 0],
        },
        next: {
          translate: ["15%", 0, 0],
        },
      },
      modules: [EffectCreative],
      on: {
        slideChange(swiper) {
          setActiveIndex(swiper.realIndex);
        },
        sliderMove() {
          setTimerStatus(TimerStatus.DISABLED);
        },
      },
    });

    if (!textCarousel.current) return;

    textInstance.current = new SwiperCore(textCarousel.current, {
      allowTouchMove: true,
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
      on: {
        slideChange(swiper) {
          setActiveIndex(swiper.realIndex);
        },
      },
    });

    return () => {
      if (imageInstance.current) {
        imageInstance.current.destroy();
      }
      if (textInstance.current) {
        textInstance.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (!imageInstance.current || imageInstance.current.destroyed) return;
    imageInstance.current.slideTo(activeIndex);
    if (!textInstance.current || imageInstance.current.destroyed) return;
    textInstance.current.slideTo(activeIndex);
  }, [activeIndex]);

  if (!tabs) return null;
  if (tabs?.length === 0) return null;

  return (
    <>
      {dark && (
        <BackgroundWrapper>
          {tabs.map(({ background_image, background_image_mobile }: TabType, i: number) => {
            const image = background_image_mobile?.url
              ? background_image_mobile
              : background_image?.url
              ? background_image
              : null;

            return (
              image && (
                <BackgroundImage key={i} active={i === activeIndex} index={i} length={tabs.length}>
                  <Image asset={image} alt={image?.alt} widthOnScreen={100} />
                </BackgroundImage>
              )
            );
          })}
        </BackgroundWrapper>
      )}

      <Styled.MobileCarousel>
        <div className="swiper-container" ref={imageCarousel}>
          <div className="swiper-wrapper">
            {tabs.map(({ image: image_desktop, image_mobile, video }: TabType, i: number) => {
              const image = image_mobile?.url ? image_mobile : image_desktop;
              return (
                <div className="swiper-slide" key={i} id={`slide-${i}`}>
                  <Styled.MobileSlide active={i === activeIndex}>
                    {image?.url && (
                      <AspectRatio ratio={ratioCalc}>
                        {image?.url && (
                          <Image asset={image} widthOnScreen={[100]} alt={image?.alt} />
                        )}
                      </AspectRatio>
                    )}
                    {!image?.url && video?.url && (
                      <Video
                        src={video.url}
                        aspectRatio={ratioCalc}
                        aspectRatioMobile={ratioCalc}
                      />
                    )}
                  </Styled.MobileSlide>
                </div>
              );
            })}
          </div>
        </div>
      </Styled.MobileCarousel>
      <Styled.PaginationContainer className="pagination-container">
        {tabs.map((tab: TabType, i: number) => (
          <PaginationButton
            selected={i === activeIndex}
            aria-label={`Go to slide ${i + 1}`}
            aria-controls={`tabpanel-${i} slide-${i}`}
            duration={animation_duration}
            timerStatus={timerStatus}
            onClick={() => onUserInteraction(i)}
            onTimerInteraction={onTimerInteraction}
            key={i}
          />
        ))}
      </Styled.PaginationContainer>
      <div className="grid-container">
        <div className="swiper-container" ref={textCarousel}>
          <div className="swiper-wrapper">
            {tabs.map((tab: TabType, i: number) => (
              <Styled.TabPanelContainer
                className="swiper-slide"
                role="tabpanel"
                aria-roledescription={"slide"}
                aria-label={`slide ${i + 1} of ${tabs.length}`}
                id={`tabpanel-${i}`}
                key={i}>
                {tab.heading && <Styled.MobileTabHeading>{tab.heading}</Styled.MobileTabHeading>}
                {tab.body && (
                  <Styled.MobileCopy>
                    <PortableText value={tab.body} style={SmallBodyRegularCSS} />
                  </Styled.MobileCopy>
                )}
              </Styled.TabPanelContainer>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default CarouselTabs;
