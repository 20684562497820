import { Fragment, ReactElement, useRef, useState, useEffect } from "react";
import styled from "styled-components";
import useWindowSize from "@hooks/useWindowSize";
import getRootOffset from "@utils/getRootOffset";
import { EasePage } from "@styles/constants";
import { UIAnimationProps } from ".";

export type SlideInTextProps = UIAnimationProps & {
  center?: boolean;
  newLine?: number;
  children?: string;
};

export const StyledContainer = styled.div<{ visible?: boolean; center?: boolean }>`
  display: flex;
  flex-flow: wrap;
  width: calc(100% + 0.18em);

  ${({ center }) => center && "justify-content: center;"}

  > div {
    flex-basis: 100%;
    height: 0;
  }
  > span {
    display: inline-block;
    margin-right: 0.18em;
    overflow: hidden;

    @keyframes slideIn {
      0% {
        transform: translateY(110%);
      }
      100% {
        transform: translateY(0);
      }
    }

    > span {
      display: inline-block;
      transform: translateY(110%);
      ${({ visible }) =>
        visible && `animation: slideIn ${EasePage.duration} ${EasePage.curve} forwards;`}
    }
  }
`;

export const SlideInText = ({
  visible,
  center,
  newLine,
  delay = 0,
  addDelay,
  children,
}: SlideInTextProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const [text, setText] = useState<ReactElement[]>([]);

  const { width, height } = useWindowSize();

  const setSpanDelay = () => {
    if (ref.current && text) {
      const additionalDelay =
        addDelay?.reduce((accumulator, value) => (value ? accumulator + 0.1 : accumulator), 0) || 0;
      let lastTop = 0;
      let delayIncrement = 0;
      ref.current.querySelectorAll("span").forEach((el) => {
        const span = el.querySelector("span");
        if (!span) return;
        const { top } = getRootOffset(el);
        if (top > lastTop) {
          delayIncrement += lastTop === 0 ? 0 : 0.1;
          lastTop = top;
        }
        span.style.animationDelay = `${delayIncrement + delay + additionalDelay}s`;
      });
    }
  };

  useEffect(() => {
    if (children) {
      setText(
        children.split(" ").map((s: string, index) => {
          return (
            <Fragment key={index}>
              {newLine && index === newLine && <div />}
              <span>
                <span>{s} </span>
              </span>
            </Fragment>
          );
        })
      );
    }
  }, [children]);

  useEffect(() => {
    if (visible) setSpanDelay();
  }, [text, addDelay, width, height, visible]);

  if (process.env.NEXT_PUBLIC_PAGE_ANIMATIONS !== "true") return <>{children}</>;

  return (
    <StyledContainer ref={ref} visible={visible} center={center}>
      {text}
    </StyledContainer>
  );
};
