import { CloudinaryAsset, CLOUDINARY_ASSET_PROJECTION } from "@models/cloudinary-asset.model";
import { Sanity } from "@models/sanity.model";

export const FullBleedBannerType = "full_bleed_banner";

export interface FullBleedBannerSchema extends Sanity.Document {
  heading?: string;
  image: CloudinaryAsset;
  primary_cta: Sanity.Cta;
  secondary_cta: Sanity.Cta;
}

export const FULL_BLEED_BANNER_PROJECTION = `{
  heading,
  primary_cta,
  secondary_cta,
  image ${CLOUDINARY_ASSET_PROJECTION}
}`;
