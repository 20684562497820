import styled from "styled-components";
import { Color, Device, Spacing } from "@styles/constants";
import { BodyMediumCSS } from "@styles/typography";
import { AbsoluteCoverCSS } from "@styles/layout";
import { rem } from "@styles/helpers";
import { Sanity } from "@models/sanity.model";
import {
  Headline as InfoHeadline,
  Subheadline as InfoSubheadline,
} from "@components/info-block/InfoBlock.styled";
import GlobalInfoBlock from "@components/info-block";

export const Section = styled.div`
  ${({ theme: { theme_style } }) => `
    --background: ${theme_style === Sanity.ThemeColor.DARK ? Color.blackBrand : Color.white};
    --foreground: ${theme_style === Sanity.ThemeColor.DARK ? Color.white : Color.blackBrand};
    --inactive-button: ${theme_style === Sanity.ThemeColor.DARK ? Color.white : Color.greenAsh};
    ${
      theme_style === Sanity.ThemeColor.DARK &&
      `
      padding: var(--dark-spacing) 0;
    `
    }
  `}
  --dark-spacing: ${Spacing.small};
  position: relative;
  background-color: var(--background);
  color: var(--foreground);

  @media ${Device.mediumUp} {
    --dark-spacing: ${rem(Spacing.sectionDesktop * 2)};
  }
`;

export const InfoBlock = styled(GlobalInfoBlock)`
  position: relative;
  margin-bottom: ${rem(30)};
  z-index: 1;

  @media ${Device.largeUp} {
    margin-bottom: ${rem(80)};
  }

  ${InfoHeadline} {
    max-width: ${rem(780)};
  }

  ${InfoSubheadline} {
    ${BodyMediumCSS}
  }

  ${({ theme: { theme_style } }) =>
    theme_style === Sanity.ThemeColor.DARK && `span { color: var(--foreground);}`}
`;

export const ButtonWrapper = styled.div`
  position: relative;
  margin-top: ${rem(30)};
  text-align: center;

  @media ${Device.largeUp} {
    margin-top: ${Spacing.xSmall};
  }
`;

export const BackgroundWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;

  &::after {
    content: "";
    ${AbsoluteCoverCSS}
    background: ${Color.black};
    opacity: 0.6;
  }
`;

export const BackgroundImage = styled.div<{ active: boolean; index: number; length: number }>`
  ${AbsoluteCoverCSS}
  --transition: 1000ms cubic-bezier(0.5, 0, 0, 1);
  transform-origin: center;
  opacity: ${({ active }) => (active ? "1" : "0")};
  transform: ${({ active }) => (active ? "scale(1)" : "scale(1.2)")};
  transition: opacity var(--transition), transform var(--transition);

  ${({ active, index }) => {
    const previousIdx = index === 0 ? length - 1 : index - 1;

    return (
      active &&
      `
      // Previous image
      &:nth-of-type(${previousIdx}) {
        opacity: 1;
        transform: scale(1);
      }
    `
    );
  }}

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
  }
`;
