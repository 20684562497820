import mirrorBackground from "@images/mirror-background.png";
import { ScrollSection } from "../Scroll.model";
import AspectRatio from "@components/primitives/aspect-ratio";
import Video from "@components/primitives/video";
import Image from "@components/primitives/image";
import { CloudinaryAsset } from "@models/cloudinary-asset.model";
import * as Styled from "./ScrollMobile.styled";
import PortableText from "@components/portable-text";

export interface ScrollMobileProps {
  sections: Array<ScrollSection>;
  outro_end_heading: string;
  outro_end_health_score_eyebrow: string;
  outro_end_health_score: number;
  outro_end_background_image: CloudinaryAsset;
}

const ScrollMobile = ({
  sections,
  outro_end_heading,
  outro_end_health_score_eyebrow,
  outro_end_health_score,
  outro_end_background_image,
}: ScrollMobileProps) => {
  const renderBackground = (section: ScrollSection) => {
    if (section.type === "Full-width background video") {
      return (
        <Styled.FullWidthVideo>
          {section.background_video?.url && (
            <AspectRatio ratio={1180 / 538}>
              <video
                autoPlay
                loop
                muted
                disablePictureInPicture
                playsInline
                src={section.background_video.url}
              />
            </AspectRatio>
          )}
        </Styled.FullWidthVideo>
      );
    }

    if (section.type === "Full-width split images") {
      return (
        <Styled.FullWidthSplitImages>
          <div>
            <AspectRatio ratio={590 / 538}>
              {section.background_image?.url && (
                <img
                  src={section.background_image.url}
                  loading="lazy"
                  alt={section?.background_image?.alt}
                />
              )}
            </AspectRatio>
          </div>

          <div>
            <AspectRatio ratio={590 / 538}>
              {section.foreground_image?.url && (
                <img
                  src={section.foreground_image.url}
                  loading="lazy"
                  alt={section?.foreground_image?.alt}
                />
              )}
            </AspectRatio>
          </div>
        </Styled.FullWidthSplitImages>
      );
    }

    if (section.type === "Text description with side backgrounds") {
      return (
        <Styled.SideBackgrounds>
          {section.side_background_video_1?.url && (
            <Styled.SideBackground>
              <AspectRatio ratio={1}>
                <video
                  autoPlay
                  loop
                  muted
                  disablePictureInPicture
                  playsInline
                  src={section.side_background_video_1.url}
                />
              </AspectRatio>
            </Styled.SideBackground>
          )}
          {section.side_background_video_2?.url && (
            <Styled.SideBackground>
              <AspectRatio ratio={1}>
                <video
                  autoPlay
                  loop
                  muted
                  disablePictureInPicture
                  playsInline
                  src={section.side_background_video_2.url}
                />
              </AspectRatio>
            </Styled.SideBackground>
          )}
        </Styled.SideBackgrounds>
      );
    }

    return (
      <Styled.Background>
        {section.background_video?.url ? (
          <Video src={section.background_video.url} />
        ) : section.background_image?.url ? (
          <Image
            asset={section.background_image}
            widthOnScreen={[100]}
            alt={section?.background_image?.alt}
          />
        ) : (
          <></>
        )}
      </Styled.Background>
    );
  };

  return (
    <Styled.ScrollMobile>
      {sections.length > 0 &&
        sections.map((section, idx) => {
          return (
            <Styled.Section id={section.anchor_link || ""} key={idx}>
              <Styled.Heading>
                <div className="grid-container">
                  {section.heading && <h2>{section.heading}</h2>}
                </div>
              </Styled.Heading>

              <Styled.Middle>
                <Styled.Mirror>
                  <img src={mirrorBackground.src} loading="lazy" alt="" />

                  {section.mirror_video?.url ? (
                    <Video src={section.mirror_video.url} />
                  ) : section.mirror_image?.url ? (
                    <Image
                      asset={section.mirror_image}
                      widthOnScreen={[100]}
                      alt={section?.mirror_image?.alt}
                    />
                  ) : (
                    <></>
                  )}
                </Styled.Mirror>

                {renderBackground(section)}

                {section.type === "Layered foreground image" && (
                  <Styled.Foreground className="small-4">
                    {section.foreground_image?.url && (
                      <Image
                        asset={section.foreground_image}
                        widthOnScreen={[100]}
                        alt={section?.foreground_image?.alt}
                      />
                    )}
                    {section.mirror_foreground_image?.url && (
                      <Image
                        asset={section.mirror_foreground_image}
                        widthOnScreen={[100]}
                        alt={section?.mirror_foreground_image?.alt}
                      />
                    )}
                  </Styled.Foreground>
                )}

                {section.type === "Outro start" && <Styled.Infographic />}
              </Styled.Middle>

              {section.body && (
                <Styled.Body>
                  <div className="grid-container">
                    <PortableText value={section.body} />
                  </div>
                </Styled.Body>
              )}
            </Styled.Section>
          );
        })}

      {outro_end_heading && (
        <Styled.Outro>
          <Styled.Heading>
            <div className="grid-container">
              <h2>{outro_end_heading}</h2>
            </div>
          </Styled.Heading>

          {outro_end_background_image?.url && (
            <Styled.OutroBackground>
              <Image
                asset={outro_end_background_image}
                widthOnScreen={[100]}
                alt={outro_end_background_image?.alt}
              />
            </Styled.OutroBackground>
          )}

          {outro_end_health_score && (
            <Styled.OutroInfographic>
              <span>{outro_end_health_score_eyebrow}</span>
              <span>{outro_end_health_score}</span>
            </Styled.OutroInfographic>
          )}
        </Styled.Outro>
      )}
    </Styled.ScrollMobile>
  );
};

export default ScrollMobile;
