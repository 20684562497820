import { Class, CLASS_PROJECTION } from "@models/class.model";
import { Sanity } from "@models/sanity.model";

export const WorkoutsType = "workouts";

export interface Workouts extends Sanity.Document {
  eyebrow: string;
  heading: string;
  cta: Sanity.Cta;
  featured_workouts: Class[];
}

export const WORKOUTS_PROJECTION = `{
  eyebrow,
  heading,
  cta,
  featured_workouts[]-> {
    _type,
    _type == 'workout' => ${CLASS_PROJECTION},
  }  
}`;
