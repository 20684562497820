import { useEffect } from "react";
import { useRouter } from "next/router";
import { ThemeProvider } from "styled-components";
import affirm from "@services/affirm";
import { Eyebrow } from "@styles/typography";
import { Sanity } from "@models/sanity.model";
import PortableText from "@components/portable-text";
import Bundle from "./bundle";
import { BundlesSchema } from "./Bundles.model";
import * as Styled from "./Bundles.styled";
import { FadeIn, SlideInText } from "@components/ui/animations";

const Bundles = ({
  inView,
  theme,
  eyebrow,
  heading,
  body,
  bundle_products,
  bundle_item,
  footnote,
}: BundlesSchema) => {
  // Different heading used in this module on the packages page.
  const { asPath } = useRouter();
  const isPackagesPage = asPath.includes("packages");

  const themeConfig = {
    theme_style: theme?.theme_style || Sanity.ThemeColor.LIGHT,
    headline_size: theme?.headline_size?.toLowerCase() || Sanity.HeadlineSize.H3,
    content_position: theme?.content_position || Sanity.HeadlinePosition.CENTER,
  };

  useEffect(() => {
    if (!affirm) return;
    affirm.refreshUI();
  }, []);

  return (
    <ThemeProvider theme={themeConfig}>
      <Styled.Bundles id="packages" isPackages={isPackagesPage}>
        <div className="grid-container">
          <Styled.Header>
            {eyebrow && (
              <Eyebrow>
                <SlideInText visible={inView}>{eyebrow}</SlideInText>
              </Eyebrow>
            )}
            {heading && (
              <Styled.Heading as={isPackagesPage ? "h1" : "h2"}>
                <SlideInText
                  visible={inView}
                  center={themeConfig.content_position === Sanity.HeadlinePosition.CENTER}
                  addDelay={[eyebrow]}>
                  {heading}
                </SlideInText>
              </Styled.Heading>
            )}
            {body && (
              <Styled.Body>
                <FadeIn visible={inView} addDelay={[eyebrow, heading]}>
                  <p>{body}</p>
                </FadeIn>
              </Styled.Body>
            )}
          </Styled.Header>
          <Styled.List className="grid-x grid-padding-x">
            {bundle_products?.length > 0 &&
              bundle_products.map((item, index) => (
                <Bundle
                  key={item._key}
                  bundle={item}
                  global_items={bundle_item}
                  visible={inView}
                  delay={0.5 + 0.1 * index}
                />
              ))}
          </Styled.List>
          {footnote && (
            <FadeIn visible={inView} delay={1}>
              <Styled.Footnote>
                <Styled.FootnoteHeading as={isPackagesPage ? "h2" : "h3"}>
                  {footnote.heading}
                </Styled.FootnoteHeading>
                <Styled.FootnoteBody>
                  <PortableText value={footnote.body} />
                </Styled.FootnoteBody>
              </Styled.Footnote>
            </FadeIn>
          )}
        </div>
      </Styled.Bundles>
    </ThemeProvider>
  );
};

export default Bundles;
