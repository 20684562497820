import trim from "lodash-es/trim";
import takeWhile from "lodash-es/takeWhile";

export const isBlank = (obj: string | any[]) => {
  if (!obj) return true;
  return typeof obj === "object" ? obj.length === 0 : trim(obj) === "";
};

// Split a string into two chunks, first string will be based on the desired
// character length (but won't break words). Second chunk is the remainder,
// null if the entire string was consumed
export const truncate = (str: string, charLimit = 280) => {
  let count = 0;
  const chunks = str.split(" ");
  const limit = charLimit;
  const truncated = takeWhile(chunks, (c) => (count += c.length) < limit);
  const rest = chunks.slice(truncated.length, chunks.length);
  return [truncated.join(" "), rest.length > 0 ? rest.join(" ") : null];
};
