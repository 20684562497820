import { Sanity } from "@models/sanity.model";

export const ClassesOverviewType = "classes_overview";

export interface ClassesOverviewSchema extends Sanity.Document {
  headings: string[];
}

export const CLASSES_OVERVIEW_PROJECTION = `{
  headings
}`;
