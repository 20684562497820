import { NextRouter } from "next/router";

export const getQueryTrialParam = (router: NextRouter) => {
  const { len: duration } = router.query;
  if (duration === `${process.env.NEXT_PUBLIC_MIRROR_DIGITAL_TRIAL_PERIOD_LONG}`) {
    return `${process.env.NEXT_PUBLIC_MIRROR_DIGITAL_TRIAL_PERIOD_LONG}`;
  } else if (duration === `${process.env.NEXT_PUBLIC_MIRROR_DIGITAL_TRIAL_PERIOD_SHORT}`) {
    return `${process.env.NEXT_PUBLIC_MIRROR_DIGITAL_TRIAL_PERIOD_SHORT}`;
  } else return `${process.env.NEXT_PUBLIC_MIRROR_DIGITAL_TRIAL_PERIOD_DEFAULT}`;
};
