import styled from "styled-components";
import { Field, Form } from "formik";
import ArrowSubmitSVG from "@images/arrow_submit_white.svg";
import { Device, Color } from "@styles/constants";
import { CoverLink } from "@styles/helpers";
import { EyebrowCSS, Heading2CSS, SmallBodyRegular, BodyRegularCSS } from "@styles/typography";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 80px 21px 80px;
  max-width: 580px;
  margin: 0 auto;

  @media ${Device.mediumUp} {
    padding: 60px 0 80px;
  }
`;

export const Eyebrow = styled.span`
  ${EyebrowCSS}
  text-align: center;
`;

export const Heading = styled.span`
  ${Heading2CSS}
  display: block;
  margin-bottom: 20px;
  text-align: center;
`;

export const Body = styled.div`
  ${BodyRegularCSS}
  margin: auto;
  margin-bottom: 30px;
  max-width: 24rem;
  text-align: center;
`;

export const QRcodeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  justify-content: center;
`;

export const QRcode = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-left: 1.84375rem;
  margin-right: 1.84375rem;
  max-width: 7.5rem;
  position: relative;
  width: 100%;

  & svg {
    margin-bottom: 1rem;
  }
`;

export const QRcodeLink = styled(CoverLink)`
  margin-top: 1rem;
  text-align: center;
  font-size: 12px;
  line-height: 1.33333;
`;

export const StyledForm = styled(Form)`
  margin: auto;
  max-width: 29.125rem;
  width: 100%;
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const StyledField = styled(Field)`
  border: 1px solid ${Color.grayInactive};
  border-radius: 4rem;
  box-shadow: none;
  height: 3.75rem;
  line-height: 1.444444;
  margin: 0;
  padding: 1rem 1.75rem 1rem 1.25rem;
`;

export const StyledButton = styled.button`
  cursor: pointer;
  background-color: ${Color.blackBrand};
  border-radius: 100%;
  height: 100%;
  max-height: 36px;
  max-width: 36px;
  min-width: 0;
  padding: 0;
  position: absolute;
  right: 0.75rem;
  top: 0.75rem;
  width: 100%;

  &:focus,
  &:hover {
    background-color: #4d4d4f;
  }
`;

export const StyledArrow = styled.div`
  width: 0.8125rem;
  height: 0.75rem;
  margin: 0 auto;
  stroke: ${Color.white};
  stroke-width: 1px;
  background-image: url("${ArrowSubmitSVG}");
  background-size: cover;
  background-repeat: no-repeat;
`;

export const StyledSubText = styled.div`
  font-size: 8px;
  padding: 10px 0;
`;

export const StyledError = styled(SmallBodyRegular)`
  color: ${Color.redError};
  text-align: right;
`;
