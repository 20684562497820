import { useRef, useLayoutEffect } from "react";
import { Color } from "@styles/constants";
import { TimerStatus, TimerInteractionDispatch } from "../TimedTabs";
import * as Styled from "../tabs/Tabs.styled";

export interface ProgressIndicatorProps {
  duration: number;
  timerStatus: TimerStatus;
  radius: number;
  strokeWidth: number;
  onTimerInteraction: TimerInteractionDispatch;
}

const ProgressIndicator = ({
  duration,
  timerStatus,
  radius,
  strokeWidth,
  onTimerInteraction,
}: ProgressIndicatorProps) => {
  const path = useRef<SVGCircleElement>(null);
  const length = useRef<number>(0);

  useLayoutEffect(() => {
    if (!path.current) return;
    if (timerStatus !== TimerStatus.ENABLED) return;
    length.current = path.current.getTotalLength();
    path.current.style.strokeDasharray = `${length.current} ${length.current}`;
    path.current.style.strokeDashoffset = `${length.current}`;
    path.current.getBoundingClientRect();
    path.current.style.transition = `stroke-dashoffset ${duration}s linear`;
    path.current.style.strokeDashoffset = "0";
  }, [timerStatus]);

  const sharedAttrs = {
    fill: "transparent",
    strokeWidth: strokeWidth,
    r: radius - strokeWidth / 2,
    cx: radius,
    cy: radius,
  };

  return (
    <Styled.ProgressIndicator
      width={radius * 2}
      height={radius * 2}
      style={{ transform: "rotate(-0.25turn)" }}>
      <circle stroke={Color.greenAsh} {...sharedAttrs} />
      <circle
        ref={path}
        stroke={Color.blackBrand}
        {...sharedAttrs}
        onTransitionEnd={timerStatus === TimerStatus.ENABLED ? onTimerInteraction : null}
      />
    </Styled.ProgressIndicator>
  );
};

export default ProgressIndicator;
