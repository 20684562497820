import { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";

import { Device } from "@styles/constants";
import useMediaQuery from "@hooks/useMediaQuery";

import * as Styled from "./Ticker.styled";
import { TickerSchema } from "./Ticker.model";

const Ticker = ({ text, large, largeMobile }: Omit<TickerSchema, "_id" | "_type">) => {
  const isDesktop = useMediaQuery(Device.mediumUp);
  const { ref, inView } = useInView();
  const containerRef = useRef<HTMLElement | undefined>();

  // Run animation once in view
  useEffect(() => {
    if (!containerRef.current) return;

    if (inView) {
      containerRef.current.style.animationPlayState = "running";
    } else {
      containerRef.current.style.animationPlayState = "paused";
    }
  }, [inView]);

  return (
    <Styled.Ticker ref={ref} large={large}>
      <h2 className="visually-hidden">{text}</h2>
      <Styled.Container ref={containerRef} large={large}>
        {/* Large version - clone text for infinite scroll on desktop only */}
        {large ? (
          isDesktop || largeMobile ? (
            new Array(4).fill("").map((_, i) => (
              <Styled.Text key={i} large={large} largeMobile={largeMobile} aria-hidden>
                {text}
              </Styled.Text>
            ))
          ) : (
            <Styled.Text large={large}>{text}</Styled.Text>
          )
        ) : (
          new Array(4).fill("").map((_, i) => (
            <Styled.Text key={i} large={large} aria-hidden>
              {text}
            </Styled.Text>
          ))
        )}
      </Styled.Container>
    </Styled.Ticker>
  );
};

export default Ticker;
