export interface CloudinaryAsset {
  alt: string;
  format: string;
  url: string;
  width: number;
  height: number;
}

export const CLOUDINARY_ASSET_PROJECTION = `{
  'alt': alt_text,
  'format': cloudinary.format,
  'url': coalesce(cloudinary.derived[0].secure_url, cloudinary.secure_url),
  'width': cloudinary.width,
  'height': cloudinary.height
}`;
