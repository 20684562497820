import styled from "styled-components";
import { Device, Spacing } from "@styles/constants";
import { Heading2, Heading3CSS } from "@styles/typography";

export const RepeaterHeading = styled(Heading2)`
  ${Heading3CSS}
  text-align: center;
  margin: 0 auto 1.875rem;

  @media ${Device.largeUp} {
    margin-bottom: 3.75rem;
  }
`;

export const Table = styled.table`
  width: 100%;

  td {
    width: 50%;

    p {
      font-size: 16px;
      margin-bottom: 0;
    }
  }
`;
