import styled from "styled-components";
import { Sanity } from "@models/sanity.model";
import { Color, Device } from "@styles/constants";
import { rem } from "@styles/helpers";
import {
  Heading3CSS,
  PortableTextWrapper,
  XSmallBodyRegularCSS,
  BodyRegularCSS,
} from "@styles/typography";

export const Container = styled.div<{ theme_style?: Sanity.ThemeColor }>`
  color: ${({ theme_style }) =>
    theme_style === Sanity.ThemeColor.DARK ? Color.white : Color.blackBrand};
`;

export const Heading = styled.h2`
  ${Heading3CSS}
  text-align: center;
  margin-bottom: ${rem(30)};

  @media ${Device.mediumUp} {
    margin-bottom: ${rem(40)};
  }
`;

export const PortableTextContainer = styled(PortableTextWrapper)`
  padding-bottom: ${rem(20)};

  @media ${Device.mediumUp} {
    padding-bottom: ${rem(40)};
  }

  p {
    ${XSmallBodyRegularCSS}
    line-height: 16px;

    @media ${Device.mediumUp} {
      ${BodyRegularCSS}
    }
  }
`;

export const Button = styled.div`
  padding-top: ${rem(50)};
  border-top: 1px solid currentColor;
  display: flex;
  justify-content: center;

  @media ${Device.mediumUp} {
    padding-top: ${rem(60)};
  }

  > * {
    width: 100%;

    @media ${Device.mediumUp} {
      width: auto;
    }
  }
`;
