import AspectRatio from "@components/primitives/aspect-ratio";
import { CloudinaryAsset } from "@models/cloudinary-asset.model";
import * as Styled from "./Foreground.styled";

export interface ForegroundProps {
  shouldAnimate?: boolean;
  image?: CloudinaryAsset;
  alt?: string;
}

const Foreground = ({ shouldAnimate, image, alt }: ForegroundProps) => {
  return (
    <Styled.Foreground>
      {image && (
        <Styled.Image
          initial={{ opacity: 0, visibility: "hidden" }}
          animate={
            shouldAnimate
              ? { opacity: 1, visibility: "visible" }
              : { opacity: 0, visibility: "hidden" }
          }>
          <div className="grid-container">
            <Styled.ImageContainer className="small-4 large-offset-1">
              <AspectRatio ratio={1}>
                <img src={image.url} alt={alt} />
              </AspectRatio>
            </Styled.ImageContainer>
          </div>
        </Styled.Image>
      )}
    </Styled.Foreground>
  );
};

export default Foreground;
