import * as React from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";

import { Color, Device, Ease } from "@styles/constants";
import AspectRatio from "../aspect-ratio";

import { ReactComponent as PlaySVG } from "@images/play.svg";
import { ReactComponent as PauseSVG } from "@images/pause.svg";

const { useEffect, useRef, useState } = React;

const StyledVideo = styled.div`
  position: relative;
`;

const StyledControls = styled.div`
  position: absolute;
  bottom: 10px;
  right: 15px;

  button {
    width: 8px;
    cursor: pointer;
    transition: opacity ${Ease.duration} ${Ease.out};

    &:hover {
      opacity: 0.5;
    }

    &:focus {
      outline: 1px dotted ${Color.white};
    }
  }

  @media ${Device.mediumUp} {
    bottom: 15px;
    right: 20px;

    button {
      width: 10px;
    }
  }
`;

const Video: React.FC<{
  src: string;
  aspectRatio?: number;
  aspectRatioMobile?: number;
  stop?: boolean;
  loop?: boolean;
  noAutoplay?: boolean;
  muted?: boolean;
}> = ({
  src,
  aspectRatio,
  aspectRatioMobile,
  stop,
  loop = true,
  noAutoplay = false,
  muted = true,
}) => {
  const { ref, inView } = useInView({
    rootMargin: "0% 0% 30% 0%",
  });
  const videoRef = useRef<HTMLVideoElement | undefined>();

  const [loaded, setLoaded] = useState<boolean>(false);
  const [playing, setPlaying] = useState<boolean>(false);

  const props = {
    muted: muted,
    loop,
    playsInline: true,
    autoPlay: true,
    disablePictureInPicture: true,
  };

  const play = () => {
    setPlaying(true);
    videoRef.current.play();
  };

  const pause = () => {
    setPlaying(false);
    videoRef.current.pause();
  };

  const handlePlayClick = () => {
    if (playing) return;
    play();
  };

  const handlePauseClick = () => {
    if (!playing) return;
    pause();
  };

  useEffect(() => {
    // Lazy load video when in view
    if (inView && !loaded) {
      videoRef.current.src = src;
      setLoaded(true);
      if (noAutoplay) {
        setPlaying(false);
        videoRef.current.pause();
      } else {
        setPlaying(true);
      }
      // Pause a playing video if no longer in view
    } else if (!inView && loaded && playing) {
      pause();
      // Resume video if in view and not stopped by parent
    } else if (inView && !stop) {
      play();
    }
  }, [inView]);

  useEffect(() => {
    // If modal open, pause
    if (stop) {
      pause();
    } else {
      play();
    }
  }, [stop]);

  return (
    <StyledVideo ref={ref}>
      <AspectRatio ratio={aspectRatio || 16 / 9} ratioMobile={aspectRatioMobile}>
        <video ref={videoRef} {...props} />
      </AspectRatio>

      <StyledControls>
        {playing ? (
          <button onClick={handlePauseClick} aria-label="pause video">
            <PauseSVG />
          </button>
        ) : (
          <button onClick={handlePlayClick}>
            <PlaySVG />
          </button>
        )}
      </StyledControls>
    </StyledVideo>
  );
};

export default Video;
