import { useEffect, useId, useRef, useState } from "react";
import Collapsible from "react-collapsible";
import { ReactComponent as ExpandSVG } from "@images/expand.svg";
import { ReactComponent as CollapseSVG } from "@images/collapse.svg";
import { Device, Ease } from "@styles/constants";
import { XSmallBodyMedium } from "@styles/typography";
import useMediaQuery from "@hooks/useMediaQuery";
import { shopRoute } from "@utils/routes";
import AspectRatio from "@components/primitives/aspect-ratio";
import Button from "@components/ui/button";
import Pricing from "@components/pricing";
import AffirmCallout from "@components/affirm-callout/AffirmCallout";
import TextModal from "@components/modals/TextModal";
import { BundleProduct } from "../Bundles.model";
import { BundleGlobalItems } from "./Bundle.model";
import * as Styled from "./Bundle.styled";
import { FadeIn } from "@components/ui/animations";

export interface BundleProps {
  bundle: BundleProduct;
  global_items?: BundleGlobalItems;
  visible?: boolean;
  delay?: number;
}

const Bundle = ({ bundle, global_items, delay = 0, visible = true }: BundleProps) => {
  const { bundled_product: product, product_tag } = bundle;
  const { priceRange } = product;
  const isDesktop = useMediaQuery(Device.largeUp);

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [includesOpen, setIncludesOpen] = useState<boolean>(false);
  const includesRef = useRef<HTMLElement | undefined>();
  const contentRef = useRef<HTMLElement | undefined>();
  const genId = useId();

  const accordionId = `accordion-includes-${product?._id}`;
  const includesId = `includes-${product?._id}`;

  const AccordionIcon = () => (includesOpen ? <CollapseSVG /> : <ExpandSVG />);

  // Have to add a11y attributes to accordion content manually
  // since plugin doesn't give access to it
  useEffect(() => {
    if (!includesRef.current) return;

    contentRef.current = includesRef.current.querySelector(".Collapsible__contentOuter");

    if (contentRef.current) {
      contentRef.current.id = includesId;
      contentRef.current.dataset.ariaLabelledBy = accordionId;
      contentRef.current.dataset.role = "region";
    }
  }, [accordionId, includesId]);

  return (
    <Styled.Bundle className="cell small-12 medium-6 large-3">
      <FadeIn delay={delay} visible={visible}>
        <Styled.Wrap>
          {!!product_tag && <Styled.Badge>{product_tag}</Styled.Badge>}
          <Styled.Top>
            {product?.featuredImage?.src && (
              <Styled.Image>
                <AspectRatio ratio={130 / 143}>
                  <img
                    src={product.featuredImage.src}
                    alt={product?.featuredImage?.altText || ""}
                    loading="lazy"
                  />
                </AspectRatio>
              </Styled.Image>
            )}

            <Styled.ProductInfo>
              <Styled.ProductName>{product?.title}</Styled.ProductName>

              <Styled.ProductPrice>
                <Pricing priceRange={priceRange} />
                <Styled.AffirmWrapper>
                  <AffirmCallout
                    className="affirm-as-low-as"
                    pageType="product"
                    total={priceRange.minVariantPrice}
                    learnmore={true}
                  />
                </Styled.AffirmWrapper>
              </Styled.ProductPrice>
            </Styled.ProductInfo>
          </Styled.Top>

          {product?.cart_description?.value && (
            <Styled.ProductIncludes>
              {isDesktop ? (
                <>
                  <Styled.IncludesHeading>Includes</Styled.IncludesHeading>
                  <Styled.IncludesList>{product.cart_description.value}</Styled.IncludesList>
                </>
              ) : (
                <Collapsible
                  contentElementId={`collapsible-content-${genId}`}
                  trigger={
                    <>
                      <Styled.CollapsedLabel>Includes</Styled.CollapsedLabel>
                      <AccordionIcon />
                    </>
                  }
                  triggerTagName="button"
                  triggerElementProps={{
                    id: accordionId,
                    "aria-controls": includesId,
                    "aria-expanded": includesOpen,
                  }}
                  transitionTime={200}
                  easing={Ease.out}
                  onOpening={() => {
                    setIncludesOpen(true);
                  }}
                  onClosing={() => {
                    setIncludesOpen(false);
                  }}>
                  <Styled.IncludesList>{product.cart_description.value}</Styled.IncludesList>
                </Collapsible>
              )}
            </Styled.ProductIncludes>
          )}

          <Styled.BundleFooter>
            <Button
              text="Shop Package"
              href={{ pathname: shopRoute, query: { slug: product?.slug?.current } }}
            />

            {global_items?.membership_copy && (
              <Styled.MembershipWrapper>
                <XSmallBodyMedium>{global_items.membership_copy}</XSmallBodyMedium>

                {global_items?.modal_button_text && global_items?.modal_content && (
                  <>
                    <button className="button-modal" onClick={() => setModalOpen(!modalOpen)}>
                      {global_items.modal_button_text}
                    </button>

                    <TextModal
                      toggleModal={() => setModalOpen(!modalOpen)}
                      mounted={modalOpen}
                      cmsNode={{
                        title: global_items.modal_title,
                        portableText: global_items.modal_content,
                      }}
                    />
                  </>
                )}
              </Styled.MembershipWrapper>
            )}
          </Styled.BundleFooter>
        </Styled.Wrap>
      </FadeIn>
    </Styled.Bundle>
  );
};

export default Bundle;
