import { useState } from "react";
import { ThemeProvider } from "styled-components";
import { Eyebrow } from "@styles/typography";
import { Device } from "@styles/constants";
import useMediaQuery from "@hooks/useMediaQuery";
import { Sanity } from "@models/sanity.model";
import { SanitySyncProductDocument } from "@models/product.model";
import TextModal from "@components/modals/TextModal";
import PaymentOptionsTrigger from "@components/modals/payment-options/PaymentOptionsTrigger";
import Button from "@components/ui/button";
import { Accordion } from "@components/accordion-repeater/accordion";
import * as Styled from "./ValueProps.styled";
import { ValuePropsColumns, ValueProp, ValuePropsSchema } from "./ValueProps.model";
import PortableText from "@components/portable-text";
import { FadeIn, SlideInText } from "@components/ui/animations";
import { ComponentResolverAnimationProps } from "@components/component-resolver";

export interface IValueProps {
  sanity: ValuePropsSchema;
  product?: SanitySyncProductDocument;
}

const ValueProps = ({
  inView,
  sanity,
  product,
}: IValueProps & ComponentResolverAnimationProps) => {
  const { theme, eyebrow, heading, props: prop_items, cta } = sanity;

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [activeContent, setActiveContent] = useState<{
    title: string;
    text?: string;
    portableText?: Sanity.PortableText;
  }>({
    title: "",
    text: "",
  });

  const isMobile = useMediaQuery(Device.small);

  const themeConfig = {
    theme_style: theme?.theme_style || Sanity.ThemeColor.LIGHT,
    mobile_accordion: !!theme?.mobile_accordion,
    column_count: prop_items.length,
  };

  const cellWidthClass =
    themeConfig.column_count === ValuePropsColumns.FOUR ? "large-3" : "large-4";

  const headingSizeClass = themeConfig.column_count === ValuePropsColumns.FOUR ? "h3" : "h2";

  const PropContent = ({ item, idx }: { item: ValueProp; idx: number }) => {
    const { title, content, modal_button_text, modal_content } = item;

    return (
      <>
        {typeof content === "string" ? (
          <Styled.Paragraph>{content}</Styled.Paragraph>
        ) : (
          <Styled.PortableTextWrapper>
            <PortableText value={content} />
          </Styled.PortableTextWrapper>
        )}
        <Styled.ButtonWrap>
          {<button
            onClick={() => {
              const contentType = typeof modal_content === "string" ? "text" : "portableText";
              setActiveContent({
                title,
                [contentType]: modal_content,
              });
              setModalOpen(!modalOpen);
            }}>
            {modal_button_text}
          </button>}
        </Styled.ButtonWrap>
      </>
    );
  };

  return (
    <ThemeProvider theme={themeConfig}>
      <Styled.ValueProps id="risk-free">
        <div className="grid-container">
          {eyebrow && (
            <Eyebrow>
              <SlideInText
                visible={inView}
                center={themeConfig.column_count === ValuePropsColumns.FOUR || !isMobile}>
                {eyebrow}
              </SlideInText>
            </Eyebrow>
          )}
          {heading && (
            <Styled.Heading className={headingSizeClass}>
              <SlideInText
                visible={inView}
                center={themeConfig.column_count === ValuePropsColumns.FOUR || !isMobile}
                addDelay={[eyebrow]}>
                {heading}
              </SlideInText>
            </Styled.Heading>
          )}
          {prop_items.length > 0 && (
            <>
              {(!isMobile || (isMobile && !themeConfig.mobile_accordion)) && (
                <Styled.Grid className="grid-x grid-padding-x align-center">
                  {prop_items.map((item, idx) => (
                    <Styled.PropItem key={idx} className={`cell medium-6 ${cellWidthClass}`}>
                      <FadeIn visible={inView} delay={0.5 + idx * 0.1}>
                        <Styled.Content>
                          {item.image && (
                            <Styled.Icon aria-hidden={true}>
                              <img src={item.image.url} alt={item.image.alt} />
                            </Styled.Icon>
                          )}
                          <Styled.Title>{item.title}</Styled.Title>
                          <PropContent item={item} idx={idx} />
                        </Styled.Content>
                      </FadeIn>
                    </Styled.PropItem>
                  ))}
                </Styled.Grid>
              )}

              {themeConfig.mobile_accordion && isMobile && (
                <Styled.MobileAccordion>
                  {prop_items.map((item, idx) => {
                    const TitleComponent = () => (
                      <Styled.TitleComponent>
                        {item.image && (
                          <Styled.Icon aria-hidden={true}>
                            <img src={item.image.url} alt={item.image.alt} />
                          </Styled.Icon>
                        )}
                        <Styled.MobileTitle>{item.title}</Styled.MobileTitle>
                      </Styled.TitleComponent>
                    );

                    return (
                      <Styled.AccordionItem key={idx}>
                        <FadeIn visible={inView} delay={0.5 + 0.1 * idx}>
                          <Accordion
                            title={item.title}
                            titleComponent={<TitleComponent />}
                            dark={themeConfig.theme_style === Sanity.ThemeColor.DARK}>
                            <PropContent item={item} idx={idx} />
                          </Accordion>
                        </FadeIn>
                      </Styled.AccordionItem>
                    );
                  })}
                </Styled.MobileAccordion>
              )}
            </>
          )}

          {cta?.url && (
            <Styled.Cta>
              <FadeIn visible={inView} delay={0.6}>
                <Button href={cta.url} dark={themeConfig.theme_style === Sanity.ThemeColor.DARK}>
                  {cta.text}
                </Button>
              </FadeIn>
            </Styled.Cta>
          )}
        </div>

        <TextModal
          toggleModal={() => setModalOpen(!modalOpen)}
          mounted={modalOpen}
          cmsNode={{
            title: activeContent.title,
            text: activeContent.text,
            portableText: activeContent.portableText,
          }}
        />
      </Styled.ValueProps>
    </ThemeProvider>
  );
};

export default ValueProps;
