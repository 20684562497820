import styled from "styled-components";
import { Color } from "@styles/constants";
import { rem } from "@styles/helpers";
import { Sanity } from "@models/sanity.model";
import { TabHeading } from "../Tabs.styled";

export const TabContainer = styled.button`
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding-left: ${rem(20)};
  text-align: initial;
  cursor: pointer;
  color: var(--foreground);

  &:not(:last-of-type) {
    margin-bottom: ${rem(40)};
  }

  &:hover ${TabHeading} {
    color: var(--foreground);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: inherit;
  }
`;

export const ProgressTrack = styled.div`
  position: absolute;
  top: 0;
  left: -2px;
  width: 2px;
  height: 100%;
  background-color: ${({ theme: { theme_style } }) =>
    theme_style === Sanity.ThemeColor.DARK ? Color.grayBrand : Color.grayBorder};
`;

export const ProgressLiner = styled.div`
  position: relative;
  height: 100%;
`;

export const ProgressThumb = styled.div<{ $duration: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--foreground);
  transition: transform ${({ $duration }) => $duration}s linear;
  transform: scaleY(0);
  transform-origin: top left;
`;
