import * as React from "react";
import { Color } from "@styles/constants";
import { CloudinaryAsset } from "@models/cloudinary-asset.model";
import AspectRatio from "@components/primitives/aspect-ratio";
import Image from "@components/primitives/image";
import * as Styled from "./ProgramBlocks.styled";

export interface ProgramBlockProps {
  className?: string;
  background?: string;
  icon?: CloudinaryAsset;
  eyebrow?: string;
  title: string | React.ReactNode;
  caption?: string;
  image?: CloudinaryAsset;
}

export const ProgramBlock = ({
  background = Color.blackBrand,
  icon,
  eyebrow,
  title,
  caption,
  image,
}: ProgramBlockProps) => (
  <Styled.ProgramBlock>
    <Styled.Background style={{ backgroundColor: background }} />
    {image?.url && (
      <Styled.ImageWrapper>
        <AspectRatio ratio={158 / 198}>
          <Image asset={image} widthOnScreen={50} loading="lazy" alt="" />
        </AspectRatio>
      </Styled.ImageWrapper>
    )}
    <Styled.Gradient gradientColor={background} />
    <Styled.Liner>
      <Styled.Container>
        {icon?.url && (
          <Styled.Icon>
            <img src={icon.url} alt={icon.alt} />
          </Styled.Icon>
        )}
        {eyebrow && <Styled.Eyebrow className="eyebrow">{eyebrow}</Styled.Eyebrow>}
        <Styled.Title>{title}</Styled.Title>
        {caption && <Styled.Caption className="caption">{caption}</Styled.Caption>}
      </Styled.Container>
    </Styled.Liner>
  </Styled.ProgramBlock>
);

export default ProgramBlock;
