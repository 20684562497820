import React from "react";
import { StateLocations, StoreLocation } from "../LocationsFilter.model";
import LocationCard from "./location-card";
import * as Styled from "./StoreLocations.styled";

interface StoreLocationsProps {
  locations: StoreLocation[];
}

const StoreLocations = ({ locations }: StoreLocationsProps) => {
  const formattedLocations = [...locations]
    .sort((a, b) => (a.heading.toUpperCase() > b.heading.toUpperCase() ? 1 : -1))
    .reduce((formattedLocations: StateLocations, location) => {
      if (formattedLocations[location.state]) {
        formattedLocations[location.state].push(location);
      } else {
        formattedLocations[location.state] = [location];
      }

      return formattedLocations;
    }, {});

  const renderByState = () => {
    return Object.keys(formattedLocations)
      .sort()
      .map((state) => (
        <React.Fragment key={state}>
          <Styled.StateRow>
            <h4>{state}</h4>
          </Styled.StateRow>
          {formattedLocations[state].map((location: StoreLocation) => (
            <LocationCard key={location._id} location={location} />
          ))}
        </React.Fragment>
      ));
  };

  return (
    <Styled.StoreLocationContainer>
      <div className="grid-container">
        <div className="grid-x grid-margin-x">{renderByState()}</div>
      </div>
    </Styled.StoreLocationContainer>
  );
};

export default StoreLocations;
