import Link from "next/link";
import Image from "@components/primitives/image";
import { FiftyFiftyAppSchema } from "./FiftyFiftyApp.model";
import * as Styled from "./FiftyFiftyApp.styled";
import PortableText from "@components/portable-text";

const FiftyFiftyApp = ({ sanity }: FiftyFiftyAppSchema) => {
  const { heading, body, app_cta_1, app_cta_2, footnote, image } = sanity;

  return (
    <div className="grid-container">
      <Styled.Grid className="grid-x">
        <Styled.ContentWrapper className="cell small-12 medium-6">
          <Styled.Content className="content">
            <h2>{heading}</h2>
            <p>{body}</p>
            <Styled.AppBadges className="grid-x">
              {app_cta_1?.url && (
                <Link href={app_cta_1.url} className="small-5">
                  {app_cta_1.app_store_badge?.url && (
                    <img
                      src={app_cta_1.app_store_badge?.url}
                      alt={app_cta_1.app_store_badge?.alt}
                    />
                  )}
                </Link>
              )}
              {app_cta_2?.url && (
                <Link href={app_cta_2.url} className="small-5 small-offset-2">
                  {app_cta_2.app_store_badge?.url && (
                    <img src={app_cta_2.app_store_badge.url} alt={app_cta_2.app_store_badge.alt} />
                  )}
                </Link>
              )}
            </Styled.AppBadges>

            {footnote && (
              <Styled.Footnote className="subtext">
                <PortableText value={footnote} />
              </Styled.Footnote>
            )}
          </Styled.Content>
        </Styled.ContentWrapper>
        {image?.url && (
          <Styled.Image className="image-cell medium-6">
            <Image asset={image} widthOnScreen={[100]} alt={image.alt} />
          </Styled.Image>
        )}
      </Styled.Grid>
    </div>
  );
};

export default FiftyFiftyApp;
