import { CloudinaryAsset, CLOUDINARY_ASSET_PROJECTION } from "@models/cloudinary-asset.model";
import { Sanity } from "@models/sanity.model";

export const HeroSlimType = "hero_slim";

export interface HeroSlimSchema extends Sanity.Document {
  heading?: string;
  background_image?: CloudinaryAsset;
}

export const HERO_SLIM_PROJECTION = `{
  heading,
  background_image ${CLOUDINARY_ASSET_PROJECTION},
}`;
