import { Device } from "@styles/constants";
import useMediaQuery from "@hooks/useMediaQuery";
import * as Styled from "./StoreBanner.styled";
import { StoreBannerSchema } from "./StoreBanner.model";
import AspectRatio from "@components/primitives/aspect-ratio";
import Image from "@components/primitives/image";
import Button from "@components/ui/button";
import { FadeIn } from "@components/ui/animations";
import { ComponentResolverAnimationProps } from "@components/component-resolver";

export interface StoreBannerProps {
  sanity: StoreBannerSchema;
}

const StoreBanner = ({ inView, sanity }: StoreBannerProps & ComponentResolverAnimationProps) => {
  const { logo, eyebrow, heading, body, cta, background_image, background_image_mobile } = sanity;

  const isMobile = useMediaQuery(Device.small);

  const activeImage =
    isMobile && background_image_mobile?.url ? background_image_mobile : background_image;

  return (
    <Styled.Container className="grid-container">
      <FadeIn visible={inView}>
        <Styled.Image>
          {isMobile ? (
            <Image asset={activeImage} widthOnScreen={[100]} alt={heading} />
          ) : (
            <AspectRatio ratio={16 / 9}>
              <Image asset={activeImage} widthOnScreen={[100]} alt={heading} />
            </AspectRatio>
          )}
        </Styled.Image>
      </FadeIn>
      <Styled.Panel inView={inView} className="grid-x grid-padding-x align-middle">
        <div className="cell large-4">
          {eyebrow && (
            <Styled.Eyebrow>
              <span>{eyebrow}</span>
            </Styled.Eyebrow>
          )}
          {logo?.url && (
            <Styled.Logo>
              <Image asset={logo} widthOnScreen={100} alt={logo.alt || "lululemon Studio"} />
            </Styled.Logo>
          )}
        </div>

        <div className="cell large-2">
          <Styled.Heading>{heading}</Styled.Heading>
          {body && <Styled.Body>{body}</Styled.Body>}
        </div>

        <div className="cell large-4">
          {cta?.url && (
            <Styled.Button>
              <Button text={cta.text} href={cta.url} dark />
            </Styled.Button>
          )}
        </div>
      </Styled.Panel>
    </Styled.Container>
  );
};

export default StoreBanner;
