import styled, { css } from "styled-components";
import {
  Heading2CSS,
  Heading3CSS,
  Heading4CSS,
  PortableTextWrapper,
  SmallBodyRegularCSS,
} from "@styles/typography";
import { Color, Device, Spacing } from "@styles/constants";
import { rem } from "@styles/helpers";
import { Sanity } from "@models/sanity.model";

export const Bundles = styled.div<{ isPackages?: boolean }>`
  ${({ theme: { theme_style } }) => `
    --foreground-color: ${theme_style === Sanity.ThemeColor.LIGHT ? Color.blackBrand : Color.white};
    --background-color: ${theme_style === Sanity.ThemeColor.LIGHT ? Color.white : Color.blackBrand};
  `}
  position: relative;

  ${({ isPackages }) =>
    isPackages &&
    `
    &::after {
      --border-position: ${rem(Spacing.sectionMobile)};
      content: "";
      background-color: ${Color.goldAsh};
      height: 1px;
      position: absolute;
      right: 0;
      left: 0;
      bottom: calc(var(--border-position) * -1);

      @media ${Device.largeUp} {
        --border-position: ${rem(Spacing.sectionDesktop)};
      }
    }
  `}
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  margin-bottom: ${rem(40)};

  ${({ theme: { content_position } }) =>
    content_position === Sanity.HeadlinePosition.CENTER &&
    css`
      align-items: center;
    `};

  @media ${Device.largeUp} {
    margin-bottom: ${Spacing.xSmall};
  }
`;

export const Heading = styled.h2`
  ${({ theme: { headline_size } }) =>
    headline_size === Sanity.HeadlineSize.H3 ? Heading3CSS : Heading2CSS}

  max-width: ${({ theme: { content_position } }) =>
    content_position === Sanity.HeadlinePosition.CENTER ? rem(580) : rem(680)};
  margin-bottom: 0;
  color: var(--foreground-color);
  text-align: ${({ theme: { content_position } }) =>
    content_position === Sanity.HeadlinePosition.CENTER ? "center" : "left"};
`;

export const Body = styled.div`
  max-width: ${rem(480)};
  margin-top: ${rem(16)};

  ${({ theme: { content_position } }) =>
    content_position === Sanity.HeadlinePosition.CENTER &&
    css`
      text-align: center;
    `};

  p {
    ${SmallBodyRegularCSS}
    color: var(--foreground-color);

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const Footnote = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${rem(40)};
  color: var(--foreground-color);

  @media ${Device.largeUp} {
    margin-top: ${Spacing.xSmall};
  }
`;

export const FootnoteHeading = styled.h3`
  ${Heading4CSS}
  max-width: 30ch;
  margin-bottom: 0;
  text-align: center;
`;

export const FootnoteBody = styled(PortableTextWrapper)`
  max-width: 90ch;
  text-align: center;
  margin-top: ${rem(20)};

  p {
    ${SmallBodyRegularCSS}
  }
`;

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  flex-wrap: wrap;
  align-items: stretch;
  row-gap: 30px;

  @media ${Device.largeUp} {
    row-gap: unset;
    justify-content: center;
    align-items: stretch;
  }
`;
