import styled from "styled-components";
import { motion } from "framer-motion";

export const SideBackgrounds = styled(motion.div)`
  position: absolute;
  left: 0;
  bottom: 0;
  height: auto;
  width: 100%;
`;

export const BackgroundTop = styled.div`
  width: 33.333333%;
  height: 33.333333%;
  margin-right: 0;
  margin-left: auto;
  margin-bottom: 8vw;
`;

export const BackgroundBottom = styled.div`
  top: 0;
  right: 0;
  width: 16.666666%;
  height: 16.666666%;
  margin-right: 12vw;
  margin-left: auto;
  margin-bottom: 3vw;
`;
