import styled from "styled-components";
import { rem } from "@styles/helpers";

export const StoreLocationContainer = styled.div`
  padding-top: ${rem(20)};
`;

export const StateRow = styled.div`
  width: 100%;
  padding-left: ${rem(15)};
  padding-bottom: ${rem(8)};
`;
