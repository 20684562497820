import { useState, useRef, useLayoutEffect } from "react";
import useMediaQuery from "@hooks/useMediaQuery";
import { Device } from "@styles/constants";
import { ScrollSchema, ScrollSection } from "./Scroll.model";
import ScrollScaleHero from "./scroll-scale-hero";
import MirrorOverlays from "./mirror-overlays";
import FullWidthVideo from "./full-width-video";
import FullWidthSplitImages from "./full-width-split-images";
import SideBackgrounds from "./side-backgrounds";
import Backgrounds from "./backgrounds";
import Foreground from "./foreground";
import Section from "./section";
import Outro from "./outro";
import ScrollMobile from "./scroll-mobile";
import * as Styled from "./Scroll.styled";
import { useTransform, useScroll } from "framer-motion";
import useWindowSize from "@hooks/useWindowSize";
import getRootOffset from "@utils/getRootOffset";

export interface ScrollProps {
  sanity: ScrollSchema;
}

const Scroll = ({
  sanity: {
    hero_image,
    hero_video,
    sections,
    outro_end_heading,
    outro_end_health_score_eyebrow,
    outro_end_health_score,
    outro_end_background_image,
  },
}: ScrollProps) => {
  const isDesktop = useMediaQuery(Device.largeUp);
  const [activeIndex, setActiveIndex] = useState<number | undefined>();
  const containerRef = useRef<HTMLDivElement>(null);
  const heroVideoUrl = hero_video?.url;
  const heroImageUrl = hero_image?.url;
  const heroImageAlt = hero_image?.alt;

  const [position, setPosition] = useState({ start: 0, end: 0 });

  const { height } = useWindowSize();
  const { scrollY } = useScroll();
  const y = useTransform(scrollY, [position.start, position.end], ["35%", "0%"]);

  useLayoutEffect(() => {
    if (!containerRef.current) return;
    const { top } = getRootOffset(containerRef.current);
    setPosition({
      start: top,
      end: top + height,
    });
  }, [height]);

  return (
    <>
      {isDesktop ? (
        <Styled.StyledScroll ref={containerRef}>
          <Styled.StyledMirrorContainer
            heroIsActive={!!heroVideoUrl || !!heroImageUrl}
            style={{ y }}>
            {(heroVideoUrl || heroImageUrl) && (
              <ScrollScaleHero
                scrollContainer={containerRef}
                isActive={activeIndex}
                heroVideoUrl={heroVideoUrl}
                heroImageUrl={heroImageUrl}
                heroImageAlt={heroImageAlt}
              />
            )}

            {/*  mirror video/images from the middle area */}
            <MirrorOverlays sections={sections} activeIndex={activeIndex} />
          </Styled.StyledMirrorContainer>

          {/* all the full width bg images */}
          {sections.length > 0 && (
            <Styled.StyledBackgrounds>
              {sections.map((section: ScrollSection, idx: number) => {
                switch (section.type) {
                  case "Full-width background video":
                    return (
                      <FullWidthVideo
                        key={idx}
                        backgroundVideo={section.background_video}
                        shouldAnimate={activeIndex === idx}
                      />
                    );
                  case "Full-width split images":
                    return (
                      <FullWidthSplitImages
                        key={idx}
                        imageLeft={section.background_image}
                        imageRight={section.foreground_image}
                        shouldAnimate={activeIndex === idx}
                      />
                    );
                  case "Text description with side backgrounds":
                    return (
                      <SideBackgrounds
                        key={idx}
                        background1={section.side_background_video_1}
                        background2={section.side_background_video_2}
                        shouldAnimate={activeIndex === idx}
                      />
                    );
                  default:
                    return (
                      <Backgrounds
                        key={idx}
                        image={section.background_image}
                        video={section.background_video}
                        alt={section.heading}
                        shouldAnimate={activeIndex === idx}
                      />
                    );
                }
              })}
            </Styled.StyledBackgrounds>
          )}

          {/* all the small images surrounding the heading area */}
          {sections.length > 0 && (
            <Styled.StyledForegrounds>
              {sections.map((section: ScrollSection, idx: number) => {
                switch (section.type) {
                  case "Layered foreground image":
                    return (
                      <Foreground
                        key={idx}
                        image={section.foreground_image}
                        alt={section.heading}
                        shouldAnimate={activeIndex === idx}
                      />
                    );
                  default:
                    return null;
                }
              })}
            </Styled.StyledForegrounds>
          )}

          {/* all the headings for each section */}
          {sections.length > 0 && (
            <div>
              {sections.map((section: ScrollSection, idx: number) => {
                return (
                  <Section
                    key={idx}
                    id={section.anchor_link}
                    index={idx}
                    type={section.type}
                    heading={section.heading}
                    body={section.body}
                    bodyPosition={section.body_position}
                    shouldAnimate={activeIndex === idx}
                    setActiveIndex={setActiveIndex}
                  />
                );
              })}
            </div>
          )}

          {outro_end_heading && (
            <Outro
              heading={outro_end_heading}
              eyebrow={outro_end_health_score_eyebrow}
              score={outro_end_health_score}
              image={outro_end_background_image}
            />
          )}
        </Styled.StyledScroll>
      ) : (
        <ScrollMobile
          sections={sections}
          outro_end_heading={outro_end_heading}
          outro_end_health_score_eyebrow={outro_end_health_score_eyebrow}
          outro_end_health_score={outro_end_health_score}
          outro_end_background_image={outro_end_background_image}
        />
      )}
    </>
  );
};

export default Scroll;
