import styled, { css } from "styled-components";
import { motion } from "framer-motion";

const innerSections = css`
  position: sticky;
  top: 0;
  width: 100%;
  height: 100vh;
`;

export const StyledScroll = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledMirrorContainer = styled(motion.div)<{ heroIsActive: boolean }>`
  ${innerSections}
  ${({ heroIsActive }) =>
    heroIsActive &&
    css`
      margin-top: initial;
    `}
  overflow: hidden;
  z-index: 1;
`;

export const StyledBackgrounds = styled.div`
  ${innerSections}
`;

export const StyledForegrounds = styled(StyledBackgrounds)`
  z-index: 1;
  pointer-events: none;
`;
