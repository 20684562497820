import { TextBlockSchema } from "./TextBlock.model";
import * as Styled from "./TextBlock.styled";
import PortableText from "@components/portable-text";

export interface TextBlockProps {
  sanity: TextBlockSchema;
}

const TextBlock = ({ sanity }: TextBlockProps) => (
  <Styled.TextBlockWrapper
    className={`grid-container${sanity.page_padding ? " content-page" : ""}`}>
    <div className="grid-x grid-margin-x">
      <div className="cell small-12">
        <PortableText value={sanity.content} />
      </div>
    </div>
  </Styled.TextBlockWrapper>
);

export default TextBlock;
