import styled from "styled-components";
import { EyebrowCSS } from "@styles/typography";
import { Device, Spacing } from "@styles/constants";

export const Eyebrow = styled.small`
  ${EyebrowCSS}
`;

export const Heading = styled.h2`
  text-align: left;
  margin-bottom: ${Spacing.xSmall};
  margin-left: 0;
`;

export const CardsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 1.25rem;
`;
