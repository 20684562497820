import Link from "next/link";
import AspectRatio from "@components/primitives/aspect-ratio";
import Image from "@components/primitives/image";
import { Instructor } from "@models/instructor.model";
import { instructorRoute } from "@utils/routes";

import * as Styled from "../CardCarousel.styled";

type InstructorSlideProps = {
  instructor: Instructor;
};

const InstructorSlide = ({ instructor }: InstructorSlideProps) => {
  return (
    <>
      <Link
        href={{
          pathname: instructorRoute,
          query: { slug: instructor?.slug?.current },
        }}
        aria-label={instructor?.title}
      />
      {instructor?.routing_media?.url && (
        <Styled.SlideImage>
          <AspectRatio ratio={4 / 5}>
            <Image
              asset={instructor.routing_media}
              widthOnScreen={[100]}
              alt={instructor.routing_media.alt}
            />
          </AspectRatio>
        </Styled.SlideImage>
      )}
      <Styled.SlideInfo>
        {instructor?.title && <h3>{instructor.title}</h3>}
        {instructor?.tags?.length > 0 && (
          <ul>
            {instructor.tags.map((tag: string, idx: number) => (
              <li key={idx}>{tag}</li>
            ))}
          </ul>
        )}
      </Styled.SlideInfo>
    </>
  );
};

export default InstructorSlide;
