import { FormEvent, useRef, useState, useEffect } from "react";
import * as Styled from "./TrialEmailCapture.styled";
import { TrialEmailCaptureSchema } from "./TrialEmailCapture.model";
import { SmallBodyRegular } from "@styles/typography";
import CloudinaryFormat from "@components/cloudinary";
import { ReactComponent as CaretSVG } from "@images/caret.svg";
import { ReactComponent as ArrowSubmitSVG } from "@images/arrow_submit.svg";
import appStore from "@images/app-store.png";
import googlePlay from "@images/google-play.png";
import { EMAIL_REGEX } from "@utils/constants";
import { CloudinaryAsset } from "@models/cloudinary-asset.model";
import { events, identify } from "@providers/analytics/analytics";
import { useUserProfile } from "@providers/profile";
import useMediaQuery from "@hooks/useMediaQuery";
import { Device } from "@styles/constants";

export enum FormState {
  INITIAL,
  SUBMITTING,
  ERROR,
  SUCCESS,
}

const QR_CODE_ELEMENT_ID = "trial-email-capture-qr-code";

const FormSubmitted = ({
  heading,
  body,
  mobileBody,
  appStoreUrl,
  googlePlayURl,
  media,
  qrCodeRef,
}) => {
  const isDesktop = useMediaQuery(Device.largeUp);
  const bodyText = isDesktop ? body : mobileBody || body;

  return (
    <Styled.Step>
      <Styled.Text>
        <Styled.Heading data-testid="heading">{heading}</Styled.Heading>
        <SmallBodyRegular data-testid="body">{bodyText}</SmallBodyRegular>
        <Styled.Routing data-testid="playStore-cta">
          <a href={appStoreUrl} target="_blank" rel="noreferrer">
            <img src={appStore.src} alt="Download on the App Store" />
          </a>
          <a href={googlePlayURl} target="_blank" rel="noreferrer">
            <img src={googlePlay.src} alt="Get it on Google Play" />
          </a>
        </Styled.Routing>
      </Styled.Text>
      <Styled.Asset>
        {media.url && <CloudinaryFormat media={media} widthOnScreenValues={100} />}
        <Styled.QrCode data-testid="qr-code" ref={qrCodeRef} id={QR_CODE_ELEMENT_ID} />
      </Styled.Asset>
    </Styled.Step>
  );
};

interface FormProps {
  heading: string;
  body: string;
  email: string;
  errorMessage: string;
  formState: FormState;
  media: CloudinaryAsset;
  onSubmit: (e: FormEvent) => void;
  setEmail: (x: string) => void;
}

const Form = ({
  heading,
  body,
  email,
  errorMessage,
  formState,
  media,
  onSubmit,
  setEmail,
}: FormProps) => {
  return (
    <Styled.Step>
      <Styled.Text>
        <Styled.Heading data-testid="form-heading">{heading}</Styled.Heading>
        <SmallBodyRegular data-testid="form-body">{body}</SmallBodyRegular>
        <Styled.Form onSubmit={onSubmit} noValidate>
          <Styled.Input
            data-testid="email-input"
            type="email"
            placeholder="Enter Your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value.trim())}
          />
          <Styled.Submit
            data-testid="submit-button"
            type="submit"
            title="Submit"
            disabled={formState === FormState.SUBMITTING}>
            <ArrowSubmitSVG />
          </Styled.Submit>
        </Styled.Form>
        {formState === FormState.ERROR && errorMessage && (
          <Styled.Error data-testid="error-message">{errorMessage}</Styled.Error>
        )}
      </Styled.Text>
      <Styled.Asset>
        {media?.url && <CloudinaryFormat media={media} widthOnScreenValues={100} />}
      </Styled.Asset>
    </Styled.Step>
  );
};

const renderQrCode = (generatedOnelinkUrl, qrCodeRef) => {
  if (!generatedOnelinkUrl || !window.AF_SMART_SCRIPT || !qrCodeRef.current) return;
  qrCodeRef.current.innerHTML = "";
  window.AF_SMART_SCRIPT.displayQrCode(QR_CODE_ELEMENT_ID);
};

interface TrialEmailCaptureProps {
  sanity: TrialEmailCaptureSchema;
}

const OneLinkParameters = {
  mediaSource: { keys: ["utm_source"], defaultValue: "lululemonstudio" },
  channel: { keys: ["utm_medium"], defaultValue: "organic" },
  campaign: { keys: ["utm_campaign"] },
  afCustom: [{ paramKey: "af_ss_ui", defaultValue: "true" }],
};

const generateOneLinkUrl = (oneLinkUrl): string => {
  if (!oneLinkUrl || !window.AF_SMART_SCRIPT) return "";
  const result = window.AF_SMART_SCRIPT.generateOneLinkURL({
    oneLinkURL: oneLinkUrl,
    afParameters: OneLinkParameters,
  });
  return result.clickURL;
};

const TrialEmailCapture = ({ sanity }: TrialEmailCaptureProps) => {
  const { set_account_email: emailCTA, download_the_app: downloadCTA } = sanity;
  // OneLink
  const [onelinkUrl, setOneLinkUrl] = useState("");
  const qrCodeRef = useRef<HTMLDivElement>(null);

  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [formState, setFormState] = useState(FormState.INITIAL);
  const {
    profileState: { authState },
  } = useUserProfile();

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    setFormState(FormState.SUBMITTING);
    if (email && EMAIL_REGEX.test(email)) {
      // Identify & Track event
      identify(authState.value);
      events.paidDigital.trialSignUp(authState.value, email);
      setErrorMessage(null);
      setFormState(FormState.SUCCESS);
    } else {
      setErrorMessage("Please enter a valid email.");
      setFormState(FormState.ERROR);
    }
  };

  useEffect(() => {
    if (!onelinkUrl) {
      const clickUrl = generateOneLinkUrl(downloadCTA.onelink_url);
      setOneLinkUrl(clickUrl);
    }
    renderQrCode(onelinkUrl, qrCodeRef);
  }, [formState, onelinkUrl, downloadCTA.onelink_url]);

  return (
    <div className="grid-container">
      <Styled.Breadcrumb>
        <Styled.BreadcrumbItem active={formState !== FormState.SUCCESS}>
          {emailCTA?.title}
        </Styled.BreadcrumbItem>
        <CaretSVG />
        <Styled.BreadcrumbItem active={formState === FormState.SUCCESS}>
          {emailCTA?.title}
        </Styled.BreadcrumbItem>
      </Styled.Breadcrumb>

      {formState !== FormState.SUCCESS ? (
        <Form
          body={emailCTA.body}
          email={email}
          errorMessage={errorMessage}
          formState={formState}
          heading={emailCTA.heading}
          media={emailCTA.media}
          onSubmit={onSubmit}
          setEmail={setEmail}
        />
      ) : (
        <FormSubmitted
          appStoreUrl={onelinkUrl}
          body={downloadCTA.body}
          mobileBody={downloadCTA.mobile_body}
          googlePlayURl={onelinkUrl}
          heading={downloadCTA.heading}
          media={downloadCTA.media}
          qrCodeRef={qrCodeRef}
        />
      )}
    </div>
  );
};

export default TrialEmailCapture;

export { Form, FormSubmitted };
