import { CloudinaryAsset, CLOUDINARY_ASSET_PROJECTION } from "@models/cloudinary-asset.model";
import { Sanity } from "@models/sanity.model";

export const StoreBannerType = "store_banner";

export interface StoreBannerSchema extends Sanity.Document {
  title: string;
  theme_style: Sanity.ThemeColor;
  eyebrow?: string;
  heading: string;
  body?: string;
  cta?: Sanity.Cta;
  logo?: CloudinaryAsset;
  background_image: CloudinaryAsset;
  background_image_mobile?: CloudinaryAsset;
}

export const STORE_BANNER_PROJECTION = `{
  title,
  theme_style,
  eyebrow,
  heading,
  body,
  cta,
  logo ${CLOUDINARY_ASSET_PROJECTION},
  background_image ${CLOUDINARY_ASSET_PROJECTION},
  background_image_mobile ${CLOUDINARY_ASSET_PROJECTION}
}`;
