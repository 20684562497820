import { useState, useRef, useEffect } from "react";
import Link from "next/link";
import AspectRatio from "@components/primitives/aspect-ratio";
import useMediaQuery from "@hooks/useMediaQuery";
import { Device } from "@styles/constants";
import { ReactComponent as CaretSVG } from "@images/caret.svg";
import { ProductHeroSchema } from "./ProductHero.model";
import Button from "@components/ui/button";

import * as Styled from "./ProductHero.styled";

export interface ProductHeroProps {
  sanity: ProductHeroSchema;
}

const ProductHero = ({
  sanity: {
    heading,
    body,
    cta_text,
    anchor_link,
    internal_cta,
    anchor_label,
    video_intro,
    video_intro_mobile,
    video_loop,
    background_image,
    foreground_image,
    animation_delay,
  },
}: ProductHeroProps) => {
  const isDesktop = useMediaQuery(Device.largeUp);
  const [introPlay, setIntroPlay] = useState<boolean>(false);
  const [introEnded, setIntroEnded] = useState<boolean>(false);

  const videoLoopRef = useRef<HTMLVideoElement | undefined>();

  const onExploreClick = () => {
    window.scrollTo({
      top: window.innerHeight,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (introEnded && videoLoopRef.current) {
      videoLoopRef.current.play();
    }
  }, [introEnded]);

  const renderBackground = () => {
    if (isDesktop && video_intro?.url) {
      return (
        <video
          muted
          autoPlay={true}
          disablePictureInPicture
          playsInline
          src={video_intro.url}
          onCanPlay={() => {
            if (!isDesktop) return;
            setIntroPlay(true);
          }}
          onEnded={() => {
            if (!isDesktop) return;
            setIntroPlay(false);
            setIntroEnded(true);
          }}
        />
      );
    }

    if (!isDesktop && video_intro_mobile?.url) {
      return (
        <video
          muted
          autoPlay={true}
          disablePictureInPicture
          playsInline
          src={video_intro_mobile.url}
        />
      );
    }

    if (background_image?.url) {
      return (
        <img
          src={background_image.url}
          alt={heading || "Experience Mirror"}
          onLoad={() => {
            setIntroPlay(true);

            setTimeout(() => {
              setIntroPlay(false);
              setIntroEnded(true);
            }, 6000);
          }}
        />
      );
    }

    return null;
  };

  const exploreBtn = (
    <Styled.Explore delay={animation_delay || 4} onClick={onExploreClick}>
      <span>{anchor_label}</span>
      <CaretSVG />
    </Styled.Explore>
  );

  return (
    <Styled.ProductHeroSection>
      {isDesktop && <Styled.VideoIntro play={introPlay}>{renderBackground()}</Styled.VideoIntro>}

      <Styled.VideoLoop introEnded={introEnded}>
        {video_loop?.url ? (
          <video
            ref={videoLoopRef}
            muted
            loop
            disablePictureInPicture
            playsInline
            src={video_loop.url}
            onCanPlay={() => {}}
          />
        ) : (
          foreground_image?.url && (
            <img src={foreground_image?.url} alt={heading || "Experience Mirror"} />
          )
        )}
      </Styled.VideoLoop>

      {isDesktop && anchor_label && exploreBtn}

      <Styled.Foreground>
        <div className="grid-container">
          <div className="grid-x grid-padding-x align-justify align-middle">
            <Styled.Content className="cell large-7" delay={animation_delay || 4}>
              {heading && (
                <Styled.Heading>
                  <h1>{heading}</h1>
                </Styled.Heading>
              )}

              {body && (
                <Styled.Body>
                  <p>{body}</p>
                </Styled.Body>
              )}

              <Styled.ButtonGroup>
                {anchor_link && (
                  <Styled.Button>
                    <Button text={cta_text} href={anchor_link} invert />
                  </Styled.Button>
                )}
                {internal_cta?.url && <Link href={internal_cta.url}>{internal_cta.text}</Link>}
              </Styled.ButtonGroup>
            </Styled.Content>

            {!isDesktop && (
              <Styled.VideoLoop className="cell large-7" introEnded={introEnded}>
                <AspectRatio ratio={1}>
                  {video_loop?.url ? (
                    <video
                      muted
                      loop
                      disablePictureInPicture
                      playsInline
                      autoPlay={true}
                      src={video_loop.url}
                      onCanPlay={() => {}}
                    />
                  ) : (
                    foreground_image?.url && (
                      <img src={foreground_image?.url} alt={heading || "Experience Mirror"} />
                    )
                  )}
                </AspectRatio>
              </Styled.VideoLoop>
            )}
          </div>
        </div>
      </Styled.Foreground>
    </Styled.ProductHeroSection>
  );
};

export default ProductHero;
