import * as React from "react";
import { Device } from "@styles/constants";
import { ReactComponent as CaretSVG } from "@images/caret.svg";
import { SectionHeroSchema } from "./SectionHero.model";
import * as Styled from "./SectionHero.styled";
import getRootOffset from "@utils/getRootOffset";

export interface SectionHeroProps {
  sanity: SectionHeroSchema;
}

const SectionHero = ({ sanity }: SectionHeroProps) => {
  const { eyebrow, heading, body, cta_text, background_image, background_image_mobile } = sanity;
  const containerEl = React.useRef<HTMLDivElement | undefined>();

  const scrollToSectionHandler = () => {
    const styles = getComputedStyle(document.body).getPropertyValue("--header-height");
    const headerHeight = Number(styles.replace("px", ""));

    const yOffsetBottom =
      getRootOffset(containerEl.current).top + containerEl.current.offsetHeight - headerHeight;

    window.scrollTo({
      top: yOffsetBottom,
      behavior: "smooth",
    });
  };

  return (
    <Styled.Section ref={containerEl}>
      <Styled.BackgroundImages>
        {background_image?.url && (
          <picture>
            <source media={Device.small} srcSet={background_image_mobile?.url} />
            <source media={Device.mediumUp} srcSet={background_image.url} />
            <img src={background_image.url} alt={background_image.alt} />
          </picture>
        )}
      </Styled.BackgroundImages>
      <Styled.Content>
        <div className="grid-container">
          <Styled.Flex>
            {eyebrow && <Styled.Eyebrow>{eyebrow}</Styled.Eyebrow>}
            {heading && <Styled.Heading>{heading}</Styled.Heading>}
            {body && (
              <Styled.Body>
                <p>{body}</p>
              </Styled.Body>
            )}
            {cta_text && (
              <Styled.ExploreButton onClick={scrollToSectionHandler}>
                <span>{cta_text}</span>
                <CaretSVG />
              </Styled.ExploreButton>
            )}
          </Styled.Flex>
        </div>
      </Styled.Content>
    </Styled.Section>
  );
};

export default SectionHero;
