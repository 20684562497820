import iphone from "@images/iphone-template-no-info.png";
import CloudinaryFormat from "@components/cloudinary/CloudinaryFormat";
import { CloudinaryAsset } from "@models/cloudinary-asset.model";
import { useEffect, useRef, useState } from "react";
import * as Styled from "./Device.styled";

export interface DeviceProps {
  graphic: CloudinaryAsset;
}

const Device = ({ graphic }: DeviceProps) => {
  const [refLoaded, setRefLoaded] = useState<boolean>(false);
  const refElement = useRef<HTMLVideoElement | HTMLImageElement>();

  const onVideoLoaded = () => {
    if (!(refElement.current instanceof HTMLVideoElement)) return;
    setRefLoaded(refElement.current.readyState >= 2);
  };

  const onImageLoaded = () => {
    if (!(refElement.current instanceof HTMLImageElement)) return;
    setRefLoaded(refElement.current.complete);
  };

  useEffect(() => {
    if (!refElement.current) return;

    if (refElement.current instanceof HTMLVideoElement) {
      refElement.current.addEventListener("loadeddata", onVideoLoaded);
      refElement.current.load();
      return;
    }

    if (!refElement.current.complete) {
      refElement.current.addEventListener("load", onImageLoaded);
      return;
    }

    onImageLoaded();

    return () => {
      if (!refElement.current) return;
      refElement.current.removeEventListener("loadeddata", onVideoLoaded);
      refElement.current.removeEventListener("loaded", onImageLoaded);
    };
  }, []);

  return (
    <Styled.Container aria-hidden={!refLoaded} refLoaded={refLoaded}>
      <Styled.CenteredDevice>
        <CloudinaryFormat media={graphic} widthOnScreenValues={100} mediaRef={refElement} />
        <Styled.Bezel src={iphone.src} alt="" />
      </Styled.CenteredDevice>
    </Styled.Container>
  );
};

export default Device;
