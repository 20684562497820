import styled from "styled-components";
import { Color, Device, Ease } from "@styles/constants";
import { AbsoluteCenterXCSS, AbsoluteCoverCSS } from "@styles/layout";
import { Eyebrow, SmallBodyBoldCSS, XSmallBodyRegularCSS } from "@styles/typography";
import { Sanity } from "@models/sanity.model";
import { rem } from "@styles/helpers";

export const Bundle = styled.li`
  position: relative;
  padding: ${rem(2)} 0;

  @media ${Device.mediumDown} {
    align-self: start;
  }

  > * {
    height: 100%;
  }
`;

export const Badge = styled(Eyebrow)`
  position: absolute;
  top: -20px;
  left: -2px;
  color: ${Color.white};
  padding: ${rem(10)} ${rem(16)} ${rem(5)};
  margin-bottom: 0;
  white-space: nowrap;
  z-index: 10;
  border-radius: 2px;
  background-color: ${({ theme: { theme_style } }) =>
    theme_style === Sanity.ThemeColor.DARK ? Color.grayBrand : Color.blackBrand};

  @media ${Device.largeUp} {
    ${AbsoluteCenterXCSS}
  }

  @media ${Device.mediumDown} {
    top: -12px;
    font-size: ${rem(12)};
    padding: ${rem(7)} ${rem(12)} ${rem(4)};
  }
`;

export const Wrap = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;
  background-color: ${Color.white};
  box-shadow: 0 0 0 1px ${Color.grayInactive};
  transition: box-shadow ${Ease.duration} ${Ease.out};

  &::before {
    ${AbsoluteCoverCSS}
    content: '';
    opacity: 0;
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.25);
    transition: opacity ${Ease.duration} ${Ease.out};
    pointer-events: none;
  }

  &:hover {
    box-shadow: 0 0 0 2px var(--foreground-color);

    &::before {
      opacity: 1;
    }
  }
`;

export const Top = styled.div`
  display: flex;
  width: 100%;

  @media ${Device.largeUp} {
    flex-direction: column;
  }
`;

export const Image = styled.div`
  position: relative;
  width: 41.66667%;
  flex: 0 0 41.66667%;
  border-bottom: 1px solid ${Color.goldAsh};
  z-index: 5;

  @media ${Device.mediumDown} {
    > div {
      height: 100%;
      padding-bottom: 0;
    }
  }

  @media ${Device.largeUp} {
    width: 100%;
    flex: 1;
    border-bottom: 0;
  }

  img {
    @media ${Device.mediumDown} {
      object-fit: contain;
      object-position: top left;
    }
  }
`;

export const ProductInfo = styled.div`
  width: 100%;

  @media ${Device.mediumDown} {
    width: 58.33333%;
    flex: 0 0 58.33333%;
  }
`;

export const ProductName = styled.h3`
  ${SmallBodyBoldCSS}
  text-align: center;
  padding: ${rem(20)};
  align-self: center;
`;

export const ProductPrice = styled.div`
  padding: ${rem(20)} ${rem(20)} ${rem(25)};
  border-bottom: 1px solid ${Color.goldAsh};
  position: relative;
  z-index: 2;

  @media ${Device.largeUp} {
    border-bottom: 0;
  }

  &::before {
    content: "";
    height: 1px;
    background-color: ${Color.goldAsh};
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    @media ${Device.mediumDown} {
      left: -50%;
    }
  }
`;

export const AffirmWrapper = styled.div`
  text-align: center;
  margin-top: ${rem(5)};

  p {
    ${XSmallBodyRegularCSS}
    color: ${Color.grayBrand};
    margin-bottom: 0;
  }

  && {
    p {
      line-height: 1.33;
    }
  }

  .affirm-modal-trigger {
    color: ${Color.blackBrand};
    display: block;
    margin-top: ${rem(5)};

    @media ${Device.largeUp} {
      color: ${Color.grayBrand};
    }
  }
`;

export const ProductIncludes = styled.div`
  text-align: center;

  @media ${Device.largeUp} {
    padding-bottom: ${rem(40)};
  }

  .Collapsible {
    border-bottom: 0;
    position: relative;
    z-index: 1;

    button {
      span {
        color: ${Color.blackBrand};
      }
    }
  }

  .Collapsible__trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: ${rem(20)} ${rem(15)};
    text-align: left;
    cursor: pointer;

    &:focus-visible {
      outline: -webkit-focus-ring-color auto 1px;
    }

    svg {
      transition: transform ${Ease.duration} ${Ease.out};
    }

    &.is-open {
      + .Collapsible__contentOuter {
        visibility: visible;
        transition: height 200ms ease-out, visibility 0s linear 0s !important;
      }
    }

    &::after {
      display: none;
    }
  }

  .Collapsible__contentOuter {
    visibility: hidden;
    transition: height 200ms ease-out, visibility 0s linear 200ms !important;
  }
`;

export const IncludesHeading = styled.span`
  ${SmallBodyBoldCSS}
  display: block;

  @media ${Device.largeUp} {
    margin-bottom: ${rem(5)};
  }
`;

export const IncludesList = styled.p`
  ${XSmallBodyRegularCSS}
  color: ${Color.grayBrand};
  text-align: left;
  white-space: pre;
  margin-bottom: 0;
  padding: 0 ${rem(15)};

  && {
    line-height: 1.33;
  }

  @media ${Device.largeUp} {
    text-align: center;
    padding: 0;
  }
`;

export const CollapsedLabel = styled.span`
  ${SmallBodyBoldCSS}
  text-align: left;
`;

export const BundleFooter = styled.div`
  text-align: center;
  padding: ${rem(20)};
  border-top: 1px solid ${Color.goldAsh};
  margin-bottom: 0;
  margin-top: auto;

  @media ${Device.mediumUp} {
    padding: ${rem(20)} 0;
  }
`;

export const MembershipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: ${rem(10)};
  margin-top: ${rem(10)};

  p {
    margin-bottom: 0;
    text-align: center;

    @media ${Device.largeUp} {
      max-width: 20ch;
    }
  }

  .button-modal {
    ${XSmallBodyRegularCSS}
    display: inline-block;
    text-decoration: underline;
    cursor: pointer;
    transition: opacity ${Ease.duration} ${Ease.out};
    color: ${Color.grayBrand};

    &:focus,
    &:hover {
      opacity: 0.5;
    }
  }
`;
