import styled, { css } from "styled-components";
import { Ease, Font, Color, Device } from "@styles/constants";
import {
  Heading4CSS,
  BodyBoldCSS,
  XSmallBodyRegularCSS,
  Heading2,
  Heading3CSS,
  XSmallBodyBoldCSS,
  SmallBodyRegularCSS,
  Heading3,
} from "@styles/typography";
import { rem } from "@styles/helpers";

export const ReviewsCarousel = styled.div`
  position: relative;
  overflow: hidden;

  p {
    margin-bottom: 0;
  }
`;

export const CarouselSlide = styled.div`
  padding: 0 ${rem(5)};
`;

export const CarouselSlideInner = styled.div`
  width: 100%;
  padding: ${rem(20)};
  background: ${Color.grayFill};

  @media ${Device.xlargeUp} {
    padding: ${rem(40)};
  }
`;

export const Header = styled.div`
  margin-bottom: ${rem(30)};

  @media ${Device.mediumUp} {
    margin-bottom: ${rem(60)};
  }
`;

export const Heading = styled(Heading2)`
  ${Heading3CSS}
  margin-bottom: 0;
  text-align: center;
`;

export const CategoriesWrap = styled.div<{ ref?: any }>`
  width: fit-content;

  @media ${Device.largeUp} {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const Categories = styled.div`
  position: relative;
  margin: 0 auto ${rem(40)};
  overflow-x: scroll;
  width: 100%;
  text-align: center;
  margin-right: ${rem(20)};

  @media ${Device.mediumUp} {
    margin-right: ${rem(40)};
  }

  @media ${Device.largeUp} {
    margin-right: 0;
  }

  ul {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li.is-active button {
    opacity: 1;
  }

  button {
    ${Heading4CSS}
    display: block;
    padding: 0 ${rem(10)};
    white-space: nowrap;
    cursor: pointer;
    opacity: 0.2;
    transition: opacity ${Ease.duration} ${Ease.out};
    color: ${Color.blackBrand};

    @media ${Device.mediumUp} {
      padding: 0 ${rem(30)};
    }

    &:focus,
    &:hover {
      opacity: 0.5;
    }
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const Buttons = styled.div`
  text-align: center;

  &:not(:empty) {
    margin-top: ${rem(40)};

    @media ${Device.mediumUp} {
      margin-top: ${rem(80)};
    }
  }

  button {
    width: 100%;

    @media ${Device.mediumUp} {
      width: auto;
    }
  }
`;

const ClampCSS = css`
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
`;

export const Title = styled(Heading3)`
  ${BodyBoldCSS}
  ${ClampCSS}
  margin-top: ${rem(15)};
  -webkit-line-clamp: 1;
`;

export const Body = styled.div`
  margin-top: ${rem(5)};

  span {
    ${XSmallBodyRegularCSS}
    color: ${Color.blackBrand};
  }

  button {
    ${XSmallBodyBoldCSS}
    transition: opacity ${Ease.duration} ${Ease.out};

    @media ${Device.mediumDown} {
      font-size: ${rem(16)};
    }

    &:focus,
    &:hover {
      opacity: 0.5;
    }
  }
`;

export const Caption = styled.p`
  ${SmallBodyRegularCSS}
  margin-top: ${rem(10)};
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${Device.mediumUp} {
    margin-top: ${rem(18)};
  }

  span {
    font-family: ${Font.semibold};
  }
`;

export const Image = styled.div`
  background: ${Color.blackBrand};
  margin-top: ${rem(19)};
`;

export const Tabs = styled.div`
  position: relative;
  width: 100%;
  height: 2px;
  margin-top: ${rem(10)};
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  @media ${Device.mediumUp} {
    margin-top: ${rem(20)};
  }
`;

export const Tab = styled.div<{ ref?: any }>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${Color.blackBrand};
  transform-origin: left;
  transition: transform ${Ease.duration} ${Ease.out};
  border-radius: 10px;
`;
