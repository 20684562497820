import { QueryModalTriggerProps } from "@components/query-modal/query-modal-trigger";
import { CloudinaryAsset, CLOUDINARY_ASSET_PROJECTION } from "@models/cloudinary-asset.model";
import { Sanity } from "@models/sanity.model";

export const HeroFiftyType = "hero_fifty";

export interface HeroFiftySchema extends Sanity.Document {
  eyebrow?: string;
  heading?: string;
  cta?: Sanity.Cta;
  cta_text?: string;
  background_image?: CloudinaryAsset;
  background_image_mobile?: CloudinaryAsset;
  foreground_image?: CloudinaryAsset;
  foreground_video?: CloudinaryAsset;
  footnote?: {
    copy?: string;
    reference_mark?: string;
  };
  mirror_foreground?: CloudinaryAsset;
  queryModalProps?: QueryModalTriggerProps;
}

export const HERO_FIFTY_PROJECTION = `{
  eyebrow,
  heading,
  cta,
  cta_text,
  footnote,
  background_image ${CLOUDINARY_ASSET_PROJECTION},
  background_image_mobile ${CLOUDINARY_ASSET_PROJECTION},
  foreground_image ${CLOUDINARY_ASSET_PROJECTION},
  foreground_video ${CLOUDINARY_ASSET_PROJECTION},
  mirror_foreground ${CLOUDINARY_ASSET_PROJECTION},
}`;
