import * as React from "react";
import { BodyRegular } from "@styles/typography";
import { JumbotronSchema } from "./Jumbotron.model";
import VideoJumbotron from "./VideoJumbotron";
import ImageJumbotron from "./ImageJumbotron";
import Jumbotron from "./Jumbotron";

export interface JumbotronProps {
  sanity: JumbotronSchema;
}

const JumbotronResolver = ({ sanity }: JumbotronProps) => {
  const { background_image, foreground_video, foreground_image } = sanity;
  const hasAssets = !!background_image || !!foreground_video || !!foreground_image;
  const allAssets = background_image && foreground_video && foreground_image;

  if (allAssets) {
    return <VideoJumbotron sanity={sanity} />;
  } else if (hasAssets) {
    return <ImageJumbotron sanity={sanity} />;
  }

  return (
    <Jumbotron subtitle={sanity.heading} eyebrow={sanity.eyebrow} cta={sanity.cta}>
      {sanity.body && <BodyRegular>{sanity.body}</BodyRegular>}
    </Jumbotron>
  );
};

export default JumbotronResolver;
