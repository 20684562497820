import { Class, CLASS_PROJECTION } from "@models/class.model";
import { Sanity } from "@models/sanity.model";

export const CategoriesType = "categories";

export interface CategoriesSchema extends Sanity.Document {
  title: string;
  heading: string;
  cta: Sanity.Cta;
  show_lifestyle_clips: boolean;
  classes: Class[];
}

export const CATEGORIES_PROJECTION = `{
  heading,
  cta,
  show_lifestyle_clips,
  classes[]-> ${CLASS_PROJECTION}
}`;
