import styled from "styled-components";
import { Color, Device, Ease, Font } from "@styles/constants";

export const ToggleButton = styled.button<{ small?: boolean; light?: boolean }>`
  background-color: ${({ light }) => (light ? Color.white : Color.blackBrand)};
  border: 1px solid ${({ light }) => (light ? Color.white : Color.blackBrand)};
  border-radius: 2rem;
  border-radius: calc(3.625rem / 2);
  color: ${({ light }) => (light ? Color.blackBrand : Color.white)};
  cursor: pointer;
  display: inline-block;
  font-family: ${Font.medium};
  font-size: ${({ small }) => (small ? "0.75rem" : "1.125rem")};
  font-weight: 500;
  line-height: 1.444444;
  padding: ${({ small }) => (small ? "0.5rem 0.75rem" : "0.875rem 1.75rem")};
  text-align: center;
  text-decoration: none;
  transition: background ${Ease.out} ${Ease.duration};
  width: ${({ small }) => (small ? "auto" : "100%")};

  @media ${Device.largeUp} {
    padding: ${({ small }) => (small ? "7px 15px 9px" : "0.875rem 1.75rem")};
  }

  &:focus {
    color: ${({ light }) => (light ? Color.blackBrand : Color.white)};
  }

  &:hover {
    background: ${({ light }) => (light ? Color.blackBrand : Color.grayBrand)};
    border-color: ${({ light }) => (light ? Color.white : Color.grayBrand)};
    color: ${Color.white};
  }

  @media ${Device.mediumUp} {
    width: auto;
  }
`;
