import { useInView } from "react-intersection-observer";

const useInViewAnimation = () => {
  return useInView({
    rootMargin: "-20%",
    triggerOnce: true,
  });
};

export default useInViewAnimation;
