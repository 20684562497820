import styled from "styled-components";
import { Color, Device, Font } from "@styles/constants";

export const Grid = styled.div`
  display: flex;
  align-items: center;
`;

export const ContentWrapper = styled.div`
  padding-right: 30px;

  @media ${Device.small} {
    order: 2;
    padding-right: 0;
  }
`;

export const Content = styled.div`
  margin: auto;
  max-width: 320px;
  text-align: center;

  h2 {
    font-size: 2rem;
  }
`;

export const AppBadges = styled.div`
  margin: 2rem 0 0.5rem 0;
`;

export const Footnote = styled.div`
  color: ${Color.grayInactive};
  font-size: 0.9rem;
  margin: 20px 0 30px;

  a {
    font-family: ${Font.semibold};
    color: ${Color.grayInactive};
  }
`;

export const Image = styled.div`
  @media ${Device.small} {
    order: 1;
    margin-bottom: 50px;
  }
`;
