import { CloudinaryAsset, CLOUDINARY_ASSET_PROJECTION } from "@models/cloudinary-asset.model";
import { Sanity } from "@models/sanity.model";

export const ProductHeroType = "hero";

export interface ProductHeroSchema extends Sanity.Document {
  heading?: string;
  body?: string;
  cta_text?: string;
  anchor_link?: string;
  internal_cta?: Sanity.Cta;
  anchor_label?: string;
  video_intro?: CloudinaryAsset;
  video_intro_mobile?: CloudinaryAsset;
  video_loop?: CloudinaryAsset;
  background_image?: CloudinaryAsset;
  foreground_image?: CloudinaryAsset;
  heading_animation_delay?: number;
}

export const PRODUCT_HERO_PROJECTION = `{
  heading,
  animation_delay,
  body,
  internal_cta,
  anchor_label,
  video_intro ${CLOUDINARY_ASSET_PROJECTION},
  video_intro_mobile ${CLOUDINARY_ASSET_PROJECTION},
  video_loop ${CLOUDINARY_ASSET_PROJECTION},
  background_image ${CLOUDINARY_ASSET_PROJECTION},
  foreground_image ${CLOUDINARY_ASSET_PROJECTION},
  cta_text,
  anchor_link
}`;
