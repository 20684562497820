import CloudinaryFormat from "@components/cloudinary/CloudinaryFormat";
import QueryModalTrigger from "@components/query-modal/query-modal-trigger";
import PortableText from "@components/portable-text";
import { Eyebrow } from "@styles/typography";
import { FiftyFiftySchema } from "../FiftyFifty.model";
import * as Legacy from "./LegacyFiftyFifty.styled";

const LegacyFiftyFifty = ({
  eyebrow,
  heading,
  queryModalProps,
  footnote,
  image,
}: FiftyFiftySchema) => (
  <Legacy.Section className="grid-container">
    <Legacy.Container>
      <Legacy.TextColumn>
        <Legacy.TextSection>
          {eyebrow && <Eyebrow>{eyebrow}</Eyebrow>}
          {heading && <Legacy.Heading>{heading}</Legacy.Heading>}
          {queryModalProps && (
            <Legacy.TriggerWrapper>
              <QueryModalTrigger {...queryModalProps} />
            </Legacy.TriggerWrapper>
          )}
          {footnote?.copy && (
            <Legacy.Footnote>
              <sup>{footnote.reference_mark}</sup>
              <PortableText value={footnote.copy} />
            </Legacy.Footnote>
          )}
        </Legacy.TextSection>
      </Legacy.TextColumn>
      <Legacy.ImageColumn>
        <Legacy.ImageContainer>
          <CloudinaryFormat media={image} widthOnScreenValues={[100, 60]} />
        </Legacy.ImageContainer>
      </Legacy.ImageColumn>
    </Legacy.Container>
  </Legacy.Section>
);

export default LegacyFiftyFifty;
