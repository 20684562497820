import styled from "styled-components";
import { Sanity } from "@models/sanity.model";
import { XSmallBodyRegularCSS } from "@styles/typography";
import { Color, Device } from "@styles/constants";

export const FootnoteSection = styled.div<{ theme_style?: Sanity.ThemeColor }>`
  background-color: ${Color.white};
  color: ${Color.grayBrand};
  position: relative;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  ${({ theme_style }) =>
    [Sanity.ThemeColor.DARK, Sanity.ThemeColor.DARK_IMAGE].includes(theme_style) &&
    `
    background-color: ${Color.blackBrand};
    color: ${Color.white};
  `}

  @media ${Device.mediumUp} {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }

  #mirrordigital-trial & {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 2.5rem;
    background-color: ${Color.white};

    @media ${Device.mediumUp} {
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: 3.75rem;
    }
  }
`;

export const UnstyledList = styled.ul`
  list-style: none;
  margin: 0;
`;

export const Footnote = styled.li`
  ${XSmallBodyRegularCSS}
  display: flex;
  max-width: 48rem;
  line-height: calc(16 / 12);

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  a,
  sup,
  p {
    color: inherit;
    line-height: inherit;
  }

  sup {
    top: 0;
    margin-right: 0.5rem;
    font-size: 0.75rem;
  }

  p {
    margin: 0;
  }
`;
