import { XSmallBodyMedium, XSmallBodyRegular } from "@styles/typography";
import { ReactComponent as CheckSVG } from "@images/checkmark_compare.svg";
import Image from "@components/primitives/image";
import { CompareProps } from "../ComparePlans.model";
import ComparePlan from "../compare-plan";
import * as ComparePlans from "../ComparePlans.styled";
import * as Styled from "./CompareMobile.styled";

const CompareMobile = ({ comparePlans, compareSections }: CompareProps) => {
  return (
    <Styled.CompareMobile>
      <Styled.PlanDetails>
        {comparePlans.map((plan, index) => (
          <ComparePlan plan={plan} key={index} />
        ))}
      </Styled.PlanDetails>

      {compareSections.map((section, index) => {
        const { section_headline, compare_items } = section;

        return (
          <section key={index}>
            <Styled.SectionHeadline>{section_headline}</Styled.SectionHeadline>

            {compare_items.map((item, index) => {
              const plans = [item.plans.plan_digital, item.plans.plan_hardware];

              return (
                <div key={index}>
                  <Styled.ItemHeadline>
                    {item.icon?.url && (
                      <ComparePlans.Icon>
                        <Image
                          asset={item.icon}
                          alt={item.icon.alt}
                          widthOnScreen={[100]}
                          aria-hidden="true"
                        />
                      </ComparePlans.Icon>
                    )}
                    <span>{item.item_headline}</span>
                    {item.info && (
                      <ComparePlans.InfoWrap>
                        <XSmallBodyRegular>{item.info}</XSmallBodyRegular>
                      </ComparePlans.InfoWrap>
                    )}
                  </Styled.ItemHeadline>
                  <Styled.CompareStatus>
                    {plans.map((plan, index) => {
                      return (
                        <div key={index}>
                          {plan.compare_description ? (
                            <XSmallBodyMedium>{plan.compare_description}</XSmallBodyMedium>
                          ) : (
                            plan.compare_active && (
                              <ComparePlans.Checkmark>
                                <CheckSVG />
                              </ComparePlans.Checkmark>
                            )
                          )}
                        </div>
                      );
                    })}
                  </Styled.CompareStatus>
                </div>
              );
            })}
          </section>
        );
      })}
    </Styled.CompareMobile>
  );
};

export default CompareMobile;
