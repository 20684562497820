import { QueryModalTriggerProps } from "@components/query-modal/query-modal-trigger";
import { CloudinaryAsset, CLOUDINARY_ASSET_PROJECTION } from "@models/cloudinary-asset.model";
import { Sanity } from "@models/sanity.model";

export const FiftyFiftyType = "fifty_fifty";

export enum Theme {
  DARK_BLEED = "dark_bleed",
  LIGHT = "light",
  HARDWARE_INTRO = "hardware_intro",
  LEGACY = "",
}

export enum ImagePosition {
  LEFT = "left",
  RIGHT = "right",
  TOP = "top",
  BOTTOM = "bottom",
}

export enum ColumnPosition {
  FIRST = "first",
  LAST = "last",
}

export enum HeadingSize {
  H1 = "H1",
  H2 = "H2",
  H3 = "H3",
}

export interface ThemeConfig {
  name: Theme;
  image_position: ImagePosition;
  heading_size: HeadingSize;
  isDark: boolean;
  increaseRichTextSpacing: boolean;
}

export interface StyledTheme {
  theme: ThemeConfig;
}

export interface FiftyFiftySchema extends Sanity.Document {
  eyebrow?: string;
  heading: string;
  heading_size: HeadingSize;
  cta?: Sanity.Cta;
  secondary_cta?: Sanity.Cta;
  image: CloudinaryAsset;
  device_graphic?: CloudinaryAsset;
  mobile_image_position: ImagePosition;
  image_position: ImagePosition;
  footnote?: {
    copy?: Sanity.PortableText;
    reference_mark?: string;
  };
  queryModalProps?: QueryModalTriggerProps;
  theme: Theme;
  rich_text?: Sanity.PortableText;
  list_items?: Array<{
    _key: string;
    icon: CloudinaryAsset;
    text: Sanity.PortableText;
  }>;
}

export const FIFTY_FIFTY_PROJECTION = `{
  eyebrow,
  heading,
  footnote,
  theme,
  heading_size,
  mobile_image_position,
  image_position,
  rich_text,
  list_items[] {
    _key,
    icon ${CLOUDINARY_ASSET_PROJECTION},
    text
  },
  cta,
  secondary_cta,
  image ${CLOUDINARY_ASSET_PROJECTION},
  device_graphic ${CLOUDINARY_ASSET_PROJECTION}
}`;
