import styled from "styled-components";
import { Color, Device, Ease } from "@styles/constants";
import { AbsoluteCenterCSS } from "@styles/layout";
import { Heading2CSS } from "@styles/typography";
import { ReactComponent as PlaySVG } from "@images/play.svg";

const StyledButtonMedia = styled.button<{ className?: string; onClick?: any }>`
  ${AbsoluteCenterCSS}

  display: flex;
  align-items: center;
  padding: 9px 30px 15px;
  background: rgba(255, 255, 255, 0.01);
  border: 1px solid ${Color.white};
  backdrop-filter: blur(60px);
  border-radius: 52px;
  color: ${Color.white};
  cursor: pointer;
  transition: background ${Ease.duration} ${Ease.out};

  span {
    ${Heading2CSS}

    &:last-child {
      padding-top: 5px;
      margin-left: 15px;
      width: 15px;
    }
  }

  &:focus,
  &:hover {
    background: rgba(255, 255, 255, 0.4);
  }

  @media ${Device.mediumUp} {
    padding: 10px 47px 24px;

    span {
      &:last-child {
        padding-top: 10px;
        margin-left: 20px;
        width: 23px;
      }
    }
  }
`;

const ButtonMedia: React.FC<{
  text: string;
  onClick?: any;
  className?: string;
}> = ({ text, onClick, className }) => {
  return (
    <StyledButtonMedia className={className} onClick={onClick}>
      <span>{text}</span>
      <span>
        <PlaySVG />
      </span>
    </StyledButtonMedia>
  );
};

export default ButtonMedia;
