import { Heading1, Heading4 } from "@styles/typography";
import { Sanity } from "@models/sanity.model";
import useMediaQuery from "@hooks/useMediaQuery";
import Image from "@components/primitives/image";
import AspectRatio from "@components/primitives/aspect-ratio";
import Button from "@components/ui/button";
import CompareTable from "./compare-table";
import CompareAccordion from "./compare-accordion";
import { GlobalCtaSchema } from "./GlobalCta.model";
import * as Styled from "./GlobalCta.styled";
import { FadeIn, SlideInText } from "@components/ui/animations";
import { ComponentResolverAnimationProps } from "@components/component-resolver";

export interface GlobalCtaProps {
  sanity: GlobalCtaSchema;
  context: Sanity.SectioningContext;
}

const GlobalCta = ({
  inView,
  sanity,
  context,
}: GlobalCtaProps & ComponentResolverAnimationProps) => {
  const {
    theme_style,
    background_image,
    eyebrow,
    headline,
    subheadline,
    copy,
    cta,
    compare_headline,
    compare_plans,
    compare_items,
    global_cta_footnotes,
  } = sanity;

  const drilledProps = {
    compare_headline,
    compare_plans,
    compare_items,
  };

  const themeColor = theme_style || Sanity.ThemeColor.LIGHT;
  const darkThemes = [Sanity.ThemeColor.DARK, Sanity.ThemeColor.DARK_IMAGE];
  const isDark = darkThemes.includes(themeColor);
  const isMobile = useMediaQuery(Styled.globalCtaMobile);
  const hasBackground = themeColor === Sanity.ThemeColor.DARK_IMAGE && background_image?.url;

  return (
    <Styled.GlobalCta
      darkImage={themeColor === Sanity.ThemeColor.DARK_IMAGE}
      previousDarkTheme={context.previousTheme === Sanity.ThemeColor.DARK}>
      {hasBackground && (
        <FadeIn visible={inView}>
          <Styled.BackgroundWrap
            darkImage={themeColor === Sanity.ThemeColor.DARK_IMAGE}
            previousDarkTheme={context.previousTheme === Sanity.ThemeColor.DARK}>
            <AspectRatio ratio={isMobile ? 375 / 406 : 16 / 9}>
              <Image asset={background_image} alt={background_image.alt} widthOnScreen={[100]} />
            </AspectRatio>
          </Styled.BackgroundWrap>
        </FadeIn>
      )}
      <div className="grid-container">
        <Styled.HeadingWrap dark={isDark}>
          {eyebrow && (
            <Styled.Eyebrow dark={isDark}>
              <SlideInText visible={inView} center={isMobile} delay={hasBackground ? 0.5 : 0}>
                {eyebrow}
              </SlideInText>
            </Styled.Eyebrow>
          )}
          {headline && (
            <Heading1 as="h2">
              <SlideInText
                visible={inView}
                center={isMobile}
                delay={hasBackground ? 0.5 : 0}
                addDelay={[eyebrow]}>
                {headline}
              </SlideInText>
            </Heading1>
          )}
        </Styled.HeadingWrap>
        <FadeIn visible={inView} delay={hasBackground ? 1 : 0.5} addDelay={[eyebrow]}>
          <Styled.CompareWrap>
            <Styled.CompareHeader>
              {subheadline && <Heading4 as="h2">{subheadline}</Heading4>}
              {copy && <Styled.Copy>{copy}</Styled.Copy>}
              {cta?.url && (
                <Styled.CtaWrap>
                  <Button href={cta.url} invert>
                    {cta.text}
                  </Button>
                </Styled.CtaWrap>
              )}
            </Styled.CompareHeader>
            {isMobile ? <CompareAccordion {...drilledProps} /> : <CompareTable {...drilledProps} />}

            {isMobile && cta?.url && (
              <Styled.CtaWrap isMobileCta>
                <Button href={cta.url} invert>
                  {cta.text}
                </Button>
              </Styled.CtaWrap>
            )}
          </Styled.CompareWrap>
        </FadeIn>

        {global_cta_footnotes?.length > 0 && (
          <Styled.Footnotes theme_style={theme_style} footnotes={global_cta_footnotes} />
        )}
      </div>
    </Styled.GlobalCta>
  );
};

export default GlobalCta;
