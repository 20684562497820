import styled from "styled-components";
import { Device, Spacing } from "@styles/constants";
import { EyebrowCSS, Heading3CSS, SmallBodyRegularCSS } from "@styles/typography";

export const ItemGrid = styled.div`
  --grid-item-inline-spacing: min(1.66vw, 1.25rem);
  display: flex;
  flex-flow: row wrap;
`;

export const ImageContainer = styled.figure`
  @media ${Device.xlargeUp} {
    padding-right: var(--grid-item-inline-spacing);
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${Device.mediumDown} {
    margin-top: 2.5rem;
  }

  @media ${Device.largeUp} {
    padding-left: var(--grid-item-inline-spacing);
    margin-left: auto;
  }
`;

export const Header = styled.header`
  margin-bottom: 1rem;
  text-align: initial;
`;

export const Eyebrow = styled.small`
  ${EyebrowCSS}
`;

export const Heading = styled.h2`
  ${Heading3CSS}
  max-width: 23.8ch;
  margin: 0;
  text-align: inherit;
`;

export const PortableTextWrapper = styled.div`
  ${SmallBodyRegularCSS}
  max-width: 60ch;
  margin-bottom: 2.5rem;

  p:last-of-type {
    margin-bottom: 0;
  }
`;
