import * as React from "react";
import { Sanity } from "@models/sanity.model";
import Accordion, { AccordionVariant } from "@components/accordion-repeater/accordion";
import Button from "@components/ui/button";
import { SectionFAQSchema, Question } from "./SectionFaq.model";
import * as Styled from "./SectionFaq.styled";
import PortableText from "@components/portable-text";
import { FadeIn, SlideInText } from "@components/ui/animations";
import { ComponentResolverAnimationProps } from "@components/component-resolver";

export interface SectionFaqProps {
  sanity: SectionFAQSchema;
}

const SectionFaq = ({ inView, sanity }: SectionFaqProps & ComponentResolverAnimationProps) => {
  const { theme_style, heading, questions, cta } = sanity;

  return (
    <Styled.Container className="grid-container" theme_style={theme_style}>
      {heading && (
        <Styled.Heading>
          <SlideInText visible={inView} center>
            {heading}
          </SlideInText>
        </Styled.Heading>
      )}
      {questions?.map((item: Question, index: number) => (
        <FadeIn key={index} visible={inView} delay={0.5}>
          <Accordion
            title={item.question}
            variant={AccordionVariant.FAQ}
            dark={theme_style === Sanity.ThemeColor.DARK}>
            <Styled.PortableTextContainer>
              <PortableText value={item.answer} />
            </Styled.PortableTextContainer>
          </Accordion>
        </FadeIn>
      ))}
      {cta?.text && (
        <FadeIn visible={inView} delay={0.6}>
          <Styled.Button>
            <Button
              text={cta?.text}
              href={cta?.url}
              dark={theme_style === Sanity.ThemeColor.DARK}
            />
          </Styled.Button>
        </FadeIn>
      )}
    </Styled.Container>
  );
};

export default SectionFaq;
