import styled, { css } from "styled-components";
import { AccordionVariant } from ".";
import { Color, Device, Ease } from "@styles/constants";
import { rem } from "@styles/helpers";
import { Heading3CSS, Heading4CSS, BodyMediumCSS } from "@styles/typography";

export const HeaderButton = styled.button<{ dark?: boolean; variant?: AccordionVariant }>`
  ${Heading3CSS}
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid currentColor;
  min-height: ${rem(80)};
  width: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
  color: inherit;

  @media ${Device.mediumUp} {
    ${Heading4CSS}
    min-height: ${rem(100)};
  }

  ${({ variant }) => {
    if (variant === AccordionVariant.FAQ)
      return css`
        ${BodyMediumCSS}
        height: auto;
        text-align: start;
        padding: ${rem(20)} 0;
        border-bottom: 0;
        border-top: 1px solid currentColor;
        align-items: start;

        @media ${Device.mediumUp} {
          ${Heading4CSS}
          padding: ${rem(40)} 0;
        }
      `;
  }}

  &:hover,
  &:focus-visible {
    svg {
      path {
        fill: ${Color.white};
      }
      circle {
        fill: ${Color.blackBrand};
      }

      ${({ dark }) =>
        dark &&
        `
          path {
            fill: ${Color.blackBrand};
          }
          circle {
            fill: ${Color.white};
          }
        `}
    }
  }
`;

export const IconWrap = styled.div<{ dark?: boolean; variant?: AccordionVariant }>`
  --icon-width: ${rem(36)};
  margin-left: ${rem(10)};
  display: inline-block;
  flex-basis: var(--icon-width);
  flex-shrink: 0;

  svg {
    width: var(--icon-width);
    height: var(--icon-height, var(--icon-width));

    path,
    circle {
      transition: fill ${Ease.duration} ${Ease.out};
    }
    circle {
      fill: ${Color.white};
    }

    ${({ dark }) =>
      dark &&
      `
      path {
        fill: ${Color.white};
      }
      circle {
        stroke: ${Color.white};
        fill: ${Color.blackBrand};
      }
    `}
  }
`;

export const AccordionPanel = styled.div<{ panelHeight?: number }>`
  width: 100%;
  transition: max-height ${Ease.duration} ${Ease.out};
  overflow: hidden;
  max-height: ${({ panelHeight }) => `${panelHeight}px`};

  a {
    color: currentColor;
  }

  img {
    display: inline-block;
    margin: 0;
    margin-top: 40px;
  }
`;
