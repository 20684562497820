import { isBlank } from "./index";

export const nl2br = (str?: string) => {
  if (isBlank(str)) return null;
  return str
    .split("\n")
    .filter((l: string) => !isBlank(l))
    .map((s: string, i: number) => (
      <span key={i}>
        {s}
        <br />
      </span>
    ));
};

// formatting stripe error codes to displayable text ex. error_code => Error Code
export const formatStripeCode = (str: string) => {
  if (str) {
    const splitStr = str.replace("_", " ");
    const prettyStr = splitStr.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
    return prettyStr;
  } else return null;
};
