import styled from "styled-components";
import { motion } from "framer-motion";
import { AbsoluteCenterXCSS } from "@styles/layout";

export const FullWidthSplitImages = styled.div``;

export const Container = styled.div`
  ${AbsoluteCenterXCSS}
  bottom: 10vh;
  width: 100%;
  max-height: 55vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 80px;
`;

export const Image = styled(motion.div)`
  width: calc(50% - 40px);
  flex: 0 0 calc(50% - 40px);
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
