import styled from "styled-components";
import { Color, Device } from "@styles/constants";
import { rem } from "@styles/helpers";
import { LargeBodyMediumCSS, XSmallBodyRegularCSS } from "@styles/typography";

export const Pricing = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PriceWrapper = styled.div`
  ${LargeBodyMediumCSS}

  @media ${Device.mediumDown} {
    font-size: ${rem(16)};

    & > span {
      text-align: center;
    }
  }
`;

export const Strikethrough = styled.s`
  color: ${Color.grayInactive};
  margin-left: ${rem(5)};
`;

export const RangePrefix = styled.span`
  ${XSmallBodyRegularCSS}
  display: block;
  color: ${Color.grayBrand};

  &:not(:empty) {
    margin-bottom: ${rem(5)};
  }

  @media ${Device.largeUp} {
    height: ${rem(12)};
    margin-bottom: ${rem(5)};
  }
`;
