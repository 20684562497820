import { useRef, useEffect, useState } from "react";
import { useScroll } from "framer-motion";
import useWindowSize from "@hooks/useWindowSize";
import getRootOffset from "@utils/getRootOffset";
import { HighlightsProps } from "../LifestyleTabs.model";
import * as Styled from "./Background.styled";

export interface BackgroundProps extends HighlightsProps {
  video: string;
  onShowHighlights: React.Dispatch<React.SetStateAction<boolean>>;
}

export type HighlightBackgroundProps = {
  video: string;
  shouldPlay: boolean;
};

const HighlightBackground = ({ video, shouldPlay }: HighlightBackgroundProps) => {
  const videoRef = useRef<HTMLVideoElement | undefined>();
  useEffect(() => {
    if (!videoRef.current) return;
    if (shouldPlay) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
    } else {
      videoRef.current.currentTime = 0;
      videoRef.current.pause();
    }
  }, [shouldPlay]);

  return (
    <Styled.HighlightBackground shouldPlay={shouldPlay}>
      <video ref={videoRef} src={video} muted playsInline disablePictureInPicture />
    </Styled.HighlightBackground>
  );
};

const Background = ({
  video,
  items,
  activeIndex,
  showHighlights,
  onShowHighlights,
}: BackgroundProps) => {
  const windowSize = useWindowSize();

  const [play, setPlay] = useState(false);
  const [position, setPosition] = useState({ start: 0, end: 0 });
  const [visible, setVisible] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const introVideoRef = useRef<HTMLVideoElement | undefined>();

  const { scrollY } = useScroll();

  useEffect(() => {
    if (!ref.current) return;
    const bounds = getRootOffset(ref.current);

    setPosition({
      start: bounds.top - windowSize.height,
      end: bounds.top,
    });
  }, [windowSize]);

  useEffect(() => {
    if (!introVideoRef.current) return;

    const unsubscribeScrollY = scrollY.onChange((y) => {
      if (y > position.end - windowSize.height * 0.25 && y <= position.end + windowSize.height) {
        setPlay(true);
        setVisible(true);
      } else if (y < position.start + windowSize.height || y > position.end + windowSize.height) {
        setPlay(false);
      }
    });

    return () => {
      unsubscribeScrollY();
    };
  }, [position]);

  useEffect(() => {
    if (play) {
      introVideoRef.current.play();
    } else {
      introVideoRef.current.pause();
    }
  }, [play]);

  return (
    <Styled.Background ref={ref}>
      <Styled.Intro>
        <video
          className={visible ? "visible" : null}
          ref={introVideoRef}
          src={video}
          muted
          playsInline
          disablePictureInPicture
          onEnded={() => {
            onShowHighlights(true);
          }}
        />
      </Styled.Intro>

      <Styled.Container>
        {items.map((highlight, index) => {
          return (
            <HighlightBackground
              video={highlight.background_video?.url}
              shouldPlay={showHighlights && index === activeIndex}
              key={index}
            />
          );
        })}
      </Styled.Container>
    </Styled.Background>
  );
};

export default Background;
