import { CloudinaryAsset, CLOUDINARY_ASSET_PROJECTION } from "@models/cloudinary-asset.model";
import { Sanity } from "@models/sanity.model";

export const TimedTabsType = "timed_tabs";

export enum RatioOptions {
  ONE_TO_ONE = "1:1",
  FOUR_TO_FIVE = "4:5",
}

export interface Ratio {
  width: number;
  height: number;
}

export type TabType = {
  heading: string;
  body: Sanity.PortableText;
  image?: CloudinaryAsset;
  image_mobile?: CloudinaryAsset;
  video?: CloudinaryAsset;
  background_image?: CloudinaryAsset;
  background_image_mobile?: CloudinaryAsset;
};

export interface TabThemeConfig {
  theme_style: Sanity.ThemeColor;
  headline_size: Sanity.HeadlineSize;
  content_position: Sanity.HeadlinePosition;
  media_ratio: RatioOptions;
}

export interface FadeOptionsProps {
  effect?: "fade" | "slide" | "cube" | "coverflow" | "flip";
  fadeEffect?: {
    crossFade: boolean;
  };
}

export interface TimedTabsSchema extends Sanity.Document {
  theme?: TabThemeConfig;
  eyebrow: string;
  heading: string;
  copy: Sanity.PortableText;
  animation_duration: number;
  tabs: Array<TabType>;
  anchor_link?: string;
  cta?: Sanity.Cta;
}

export const TIMED_TABS_PROJECTION = `{
  theme,
  eyebrow,
  heading,
  copy,
  animation_duration,
  tabs[] {
    body,
    heading,
    image ${CLOUDINARY_ASSET_PROJECTION},
    image_mobile ${CLOUDINARY_ASSET_PROJECTION},
    video ${CLOUDINARY_ASSET_PROJECTION},
    background_image ${CLOUDINARY_ASSET_PROJECTION},
    background_image_mobile ${CLOUDINARY_ASSET_PROJECTION},
  },
  anchor_link,
  cta
}`;
