import { CLOUDINARY_ASSET_PROJECTION } from "@models/cloudinary-asset.model";
import { Review } from "@models/review.model";
import { Sanity } from "@models/sanity.model";

export const ReviewCarouselType = "review_carousel";

export interface ReviewCarouselSchema extends Sanity.Document {
  heading?: string;
  primary_cta_text?: string;
  secondary_cta?: Sanity.Cta;
  reviews: Review[];
}

export const REVIEW_CAROUSEL_PROJECTION = `{
  heading,
  primary_cta_text,
  secondary_cta,
  reviews[] {
    user,
    location,
    star_rating,
    title,
    content,
    category,
    image ${CLOUDINARY_ASSET_PROJECTION}
  }
}`;
