import { MarketingModulesType } from "@models/module-page.model";
import { Sanity } from "@models/sanity.model";
import { surfaceThemeSetting } from "./surfaceThemeSetting";

export interface ResolverStatus {
  modules: MarketingModulesType;
  index: number;
}
/**
 * Surfaces contextual theme info about the current and surrounding modules.
 * @param {Resolver} resolverStatus - Current index and associated modules within a ComponentResolver
 * @returns {Sanity.SectioningContext}
 */
export const getSectioningContext = ({
  modules,
  index,
}: ResolverStatus): Sanity.SectioningContext => {
  const { _type } = modules[index];
  const previousTheme = surfaceThemeSetting(modules[index - 1]);
  const currentTheme = surfaceThemeSetting(modules[index]);
  const nextTheme = surfaceThemeSetting(modules[index + 1]);

  return {
    _type,
    index,
    previousTheme,
    currentTheme,
    nextTheme,
  };
};
