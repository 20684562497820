import { AnimatePresence } from "framer-motion";
import AspectRatio from "@components/primitives/aspect-ratio";
import { CloudinaryAsset } from "@models/cloudinary-asset.model";
import * as Styled from "./FullWidthVideo.styled";

export interface FullWidthVideoProps {
  backgroundVideo?: CloudinaryAsset;
  shouldAnimate: boolean;
}

const FullWidthVideo = ({ backgroundVideo, shouldAnimate }: FullWidthVideoProps) => {
  return (
    <Styled.FullWidthVideo>
      <Styled.VideoContainer className="grid-container">
        {backgroundVideo && (
          <AnimatePresence>
            {shouldAnimate && (
              <Styled.Video
                initial={{ opacity: 0, visibility: "hidden" }}
                animate={{ opacity: 1, visibility: "visible" }}
                exit={{ opacity: 0 }}>
                <AspectRatio ratio={1180 / 538}>
                  <video
                    autoPlay
                    muted
                    loop
                    disablePictureInPicture
                    playsInline
                    src={backgroundVideo.url}
                  />
                </AspectRatio>
              </Styled.Video>
            )}
          </AnimatePresence>
        )}
      </Styled.VideoContainer>
    </Styled.FullWidthVideo>
  );
};

export default FullWidthVideo;
