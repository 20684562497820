import * as React from "react";
import { Color } from "@styles/constants";
import { BodyRegular } from "@styles/typography";
import { Column, Row } from "@styles/grid";
import Video from "@components/primitives/video";
import { JumbotronSchema } from "./Jumbotron.model";
import Jumbotron from "./Jumbotron";

interface VideoJumbotronProps {
  sanity: JumbotronSchema;
}

const VideoJumbotron = ({ sanity }: VideoJumbotronProps) => (
  <Column center className="video-jumbotron">
    <Jumbotron
      subtitle={sanity.heading}
      eyebrow={sanity.eyebrow}
      cta={sanity.cta}
      background="transparent"
      color={Color.blackBrand}
      gap="0 10%"
      margin="0 0 40px"
      padding="0">
      {sanity.body && <BodyRegular>{sanity.body}</BodyRegular>}
    </Jumbotron>

    <Row center padding="0" className="row-workouts-page-music">
      {sanity.background_image?.url && (
        <div className="video grid-container">
          <Video src={sanity.background_image.url} loop={false} aspectRatioMobile={1} />
        </div>
      )}

      {sanity.foreground_video?.url && (
        <div className="image-container">
          <img className="image" src={sanity.foreground_video?.url} loading="lazy" alt="" />

          {sanity.foreground_image?.url && (
            <img className="ui-image" src={sanity.foreground_image?.url} loading="lazy" alt="" />
          )}
        </div>
      )}
    </Row>
  </Column>
);

export default VideoJumbotron;
