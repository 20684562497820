import { useCallback, useEffect, useRef } from "react";
import { Device } from "@styles/constants";
import { ReactComponent as DragSVG } from "@images/drag.svg";
import { ClassType } from "@models/class.model";
import { PartnershipType } from "@models/partnership.model";
import useMediaQuery from "@hooks/useMediaQuery";
import useWindowSize from "@hooks/useWindowSize";
import ClassCard from "../class-card";
import PartnerCard from "../partner-card";
import * as Styled from "../Gallery.styled";
import { Item, FeaturedGalleryItem, GalleryWrapProps } from "../Gallery.model";

const AUTOPLAY_SPEED = 0.5;
const DRAG_SPEED = 5;
const EASE_DRAG = 0.05;
const EASE_CURSOR = 0.3;

const GalleryWrap = ({ cardType, items, inView }: GalleryWrapProps) => {
  const isDesktop = useMediaQuery(Device.mediumUp);
  const windowSize = useWindowSize();
  const initialWidth = useRef<number>(windowSize.width);

  // Split items into two groups
  const half = Math.floor(items.length / 2);

  /**
   * Since PartnerCards are thinner and more of them fit within a viewport,
   * each row's content is doubled to avoid whitespace.
   *
   * Validation within the CMS ensures all featured_item types match
   * the first item in the array.
   */
  const rows = {
    top:
      cardType === PartnershipType
        ? [...items.slice(0, half), ...items.slice(0, half)]
        : items.slice(0, half),
    bottom:
      cardType === PartnershipType
        ? [...items.slice(half, items.length), ...items.slice(half, items.length)]
        : items.slice(half, items.length),
  };
  const topItems = useRef<FeaturedGalleryItem[]>(rows.top);
  const bottomItems = useRef<FeaturedGalleryItem[]>(rows.bottom);

  // Gallery elements
  const galleryRef = useRef<HTMLDivElement | undefined>();
  const itemsRef = useRef<Item[] | any[]>([]);

  const topWidth = useRef<number>(0);
  const bottomWidth = useRef<number>(0);

  // Animation values
  const rafRef = useRef<number>(0);

  const mouse = useRef<{
    downX: number;
    upX: number;
    currentX: number;
  }>({
    downX: 0,
    upX: 0,
    currentX: 0,
  });

  const currentX = useRef<number>(0);
  const targetX = useRef<number>(0);
  const lastX = useRef<number>(0);

  const drag = useRef<boolean>(false);
  const autoplay = useRef<boolean>(true);
  const first = useRef<boolean>(true);

  const direction = useRef<string | undefined>("");

  // Cursor takeover
  const cursorRef = useRef<HTMLButtonElement | undefined>();
  const currentCursorPosition = useRef<{ x: number; y: number }>({
    x: windowSize.width / 2,
    y: windowSize.height / 2,
  });
  const targetCursorPosition = useRef<{ x: number; y: number }>({
    x: windowSize.width / 2,
    y: windowSize.height / 2,
  });

  /**
   * Methods
   */

  // Measure top and bottom gallery total widths
  const measureWidths = () => {
    const itemWidth = itemsRef.current[0].bounds.width;
    topWidth.current = itemWidth * topItems.current.length;
    bottomWidth.current = itemWidth * bottomItems.current.length;
  };

  // Create item to handle animation properties
  const createItem = (el: HTMLElement, id: number, isBottom: boolean) => {
    let existing = false;

    itemsRef.current.forEach((item) => {
      if (item.id === id) existing = true;
    });

    if (el && !existing) {
      const bounds = el.getBoundingClientRect();
      itemsRef.current.push({
        id,
        el,
        bounds,
        x: 0,
        offset: 0,
        translate: 0,
        isBefore: false,
        isAfter: false,
        isBottom: isBottom,
      });
    }
  };

  /**
   * Event handlers
   */
  const handleMouseMove = (e: any) => {
    const x = e.changedTouches ? e.changedTouches[0].clientX : e.clientX;

    // Get coords for cursor takeover
    if (isDesktop && cursorRef.current) {
      const bounds = galleryRef.current.getBoundingClientRect();
      targetCursorPosition.current = { x, y: e.pageY - window.scrollY - bounds.top };
    }

    if (!drag.current) return;

    // Offset position by intitial autoplay distance
    let autoplayDist = 0;

    if (first.current) {
      first.current = false;
      autoplayDist = targetX.current;
    }

    // Map mouse position to drag position

    targetX.current =
      mouse.current.upX + (x - mouse.current.downX) * DRAG_SPEED * -1 + autoplayDist;
  };

  const handleMouseDown = (e: any) => {
    autoplay.current = false;
    drag.current = true;
    mouse.current.downX = e.changedTouches ? e.changedTouches[0].clientX : e.clientX;

    if (cursorRef.current && !cursorRef.current.classList.contains("is-down")) {
      cursorRef.current.classList.add("is-down");
    }
  };

  const handleMouseUp = () => {
    drag.current = false;
    mouse.current.upX = targetX.current;

    if (cursorRef.current && cursorRef.current.classList.contains("is-down")) {
      cursorRef.current.classList.remove("is-down");
    }
  };

  const handleLinkEnter = () => {
    if (cursorRef.current) {
      cursorRef.current.classList.add("is-hidden");
    }
  };

  const handleLinkLeave = () => {
    if (cursorRef.current) {
      cursorRef.current.classList.remove("is-hidden");
    }
  };

  /**
   * Animation
   */
  const updateCursor = () => {
    if (cursorRef.current) {
      currentCursorPosition.current.x = lerp(
        currentCursorPosition.current.x,
        targetCursorPosition.current.x,
        EASE_CURSOR
      );
      currentCursorPosition.current.y = lerp(
        currentCursorPosition.current.y,
        targetCursorPosition.current.y,
        EASE_CURSOR
      );

      cursorRef.current.style.transform = `translate3d(
        ${currentCursorPosition.current.x}px,
        ${currentCursorPosition.current.y}px,
        0px)`;
    }
  };

  const update = useCallback(() => {
    // Autoplay
    if (autoplay.current) {
      targetX.current += AUTOPLAY_SPEED;
    }

    // Lerp drag + progress position
    currentX.current = lerp(currentX.current, targetX.current, EASE_DRAG);

    // Calculate drag direction
    if (currentX.current > lastX.current) {
      direction.current = "right";
    } else {
      direction.current = "left";
    }

    // Translate top and bottom gallery items
    if (itemsRef.current.length) {
      for (let i = 0; i < itemsRef.current.length; i++) {
        const item = itemsRef.current[i];

        // Set position and offset/change direction if bottom row
        item.x =
          item.bounds.x -
          (item.isBottom ? -currentX.current : currentX.current) -
          item.offset -
          (item.isBottom ? bottomWidth.current - windowSize.width : 0);

        // Calculate if item is after or before viewport
        item.isBefore = item.x + item.bounds.width < 0;
        item.isAfter = item.x - item.bounds.width > windowSize.width;

        // Move item to end or beginning of row
        if (direction.current === "right" && (item.isBottom ? item.isAfter : item.isBefore)) {
          if (item.isBottom) {
            item.offset += bottomWidth.current;
          } else {
            item.offset -= topWidth.current;
          }

          item.isBefore = false;
          item.isAfter = false;
        }

        // Move item to end or beginning of row
        if (direction.current === "left" && (item.isBottom ? item.isBefore : item.isAfter)) {
          if (item.isBottom) {
            item.offset -= bottomWidth.current;
          } else {
            item.offset += topWidth.current;
          }

          item.isBefore = false;
          item.isAfter = false;
        }

        // Finally animate it
        item.translate = item.x - item.bounds.x;
        item.el.style.transform = `translate3d(${Math.floor(
          (item.translate * 100) / 100
        )}px, 0, 0)`;
      }
    }

    // Keep track of last position for direction
    lastX.current = currentX.current;

    // Animate cursor
    updateCursor();

    rafRef.current = requestAnimationFrame(update);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize]);

  /**
   * Helpers
   */
  const lerp = (a: number, b: number, t: number) => a + (b - a) * t;

  /**
   * Effects
   */

  // Measure items on resize and get total widths
  useEffect(() => {
    if (!itemsRef.current.length) return;

    if (initialWidth.current !== windowSize.width) {
      itemsRef.current.forEach((item) => {
        const bounds = item.el.getBoundingClientRect();
        item.bounds.width = bounds.width;
        item.bounds.x = bounds.x - item.translate;
        item.offset = 0;
        item.translate = 0;
        item.isBefore = false;
        item.isAfter = false;
      });
      measureWidths();
    } else {
      measureWidths();
    }
  }, [windowSize]);

  // Start raf loop only when in viewport
  useEffect(() => {
    if (inView && !rafRef.current) {
      rafRef.current = requestAnimationFrame(update);
    } else if (rafRef.current) {
      cancelAnimationFrame(rafRef.current);
      rafRef.current = 0;
    }

    return () => {
      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  /**
   * Render
   */
  const renderGallery = (items: FeaturedGalleryItem[], isBottom: boolean) => (
    <Styled.Gallery cardType={cardType}>
      {items?.length > 0 &&
        items.map((item: FeaturedGalleryItem, idx: number) => {
          const sharedCardProps = {
            type: item._type,
            idx,
            isBottom,
            bottomItems,
            createItem,
            handleLinkEnter,
            handleLinkLeave,
            slug: item.slug,
          };

          if (item._type === ClassType) {
            return (
              <ClassCard
                title={item.title}
                routing_media={item.routing_media}
                thumbnail_image={item.thumbnail_image}
                {...sharedCardProps}
                key={idx}
              />
            );
          } else if (item._type === PartnershipType) {
            return (
              <PartnerCard routing_media={item.routing_media} {...sharedCardProps} key={idx} />
            );
          }

          return <></>;
        })}
    </Styled.Gallery>
  );

  const eventHandlers =
    cardType === PartnershipType
      ? {
          onTouchMove: handleMouseMove,
          onTouchStart: handleMouseDown,
          onTouchEnd: handleMouseUp,
        }
      : {
          onMouseMove: handleMouseMove,
          onMouseDown: handleMouseDown,
          onMouseUp: handleMouseUp,
          onTouchMove: handleMouseMove,
          onTouchStart: handleMouseDown,
          onTouchEnd: handleMouseUp,
        };

  return (
    <Styled.GalleryContainer ref={galleryRef} {...eventHandlers} cardType={cardType}>
      {renderGallery(topItems.current, false)}
      {renderGallery(bottomItems.current, true)}

      {isDesktop && cardType !== PartnershipType && (
        <Styled.Cursor ref={cursorRef}>
          <DragSVG />
        </Styled.Cursor>
      )}
    </Styled.GalleryContainer>
  );
};

export default GalleryWrap;
