import { useCallback, useEffect, useState } from "react";
import ReactModal from "react-modal";
import cn from "classnames";
import { omit } from "lodash-es";
import debounce from "lodash-es/debounce";
import * as qs from "query-string";
import * as request from "superagent";
import styled from "styled-components";
import { APPLICATION_NODE } from "@utils/constants";
import { useIsFirstRender } from '@utils/useIsFirstRender';
import { ReactComponent as CloseIcon } from "@images/close-white.svg";
import { ModalProps } from "./Modal.model";
import { events } from "@providers/analytics/analytics";
import { useUserProfile } from "@providers/profile";

interface ModalVideoProps extends ModalProps {
  closeModal: () => void;
  videoId: string;
  full?: boolean;
  srcOverride?: string;
  videoParams?: {
    h: string;
    autoplay: number;
    autopause: number;
  };
}

const ModalVideo = ({
  mounted,
  className,
  closeModal,
  videoId,
  full,
  videoParams,
  srcOverride,
}: ModalVideoProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [desiredWidth, setDesiredWidth] = useState<number>(
    typeof window !== "undefined" ? window.innerWidth : 0
  );
  const [videoState, setVideoState] = useState({
    src: "",
    height: 0,
    width: 0,
  });
  const desiredHeight = desiredWidth / (videoState.width / videoState.height);

  const isFirstRender = useIsFirstRender();
  const { profileState: { authState } } = useUserProfile();

  const onResize = useCallback(() => {
    let w = window.innerWidth * 0.8;
    if (!full && w > 640) w = 640; // set max width to 740
    if (full && w <= 640) w = window.innerWidth * 0.95;
    setDesiredWidth(w);
  }, [full, setDesiredWidth]);

  const loadOEmbed = useCallback(async () => {
    const oEmbedParams = qs.stringify({ ...omit(videoParams, "h"), responsive: true });
    const videoUrl = encodeURIComponent(
      `https://vimeo.com/${videoId}${videoParams?.h ? `/${videoParams?.h}?` : "?"}${oEmbedParams}`
    );
    const url = `https://vimeo.com/api/oembed.json?url=${videoUrl}`;
    const { body } = await request.get(url).set("Accept", "application/json").send();

    const playerParams = qs.stringify({
      h: videoParams?.h,
      autoplay: videoParams?.autoplay,
      autopause: videoParams?.autopause,
    });
    const parsedSource = `https://player.vimeo.com/video/${videoId}?${playerParams}`;
    const src = srcOverride || parsedSource;
    setVideoState({
      src,
      height: body.height,
      width: body.width,
    });
    setLoading(true);
  }, [setLoading, srcOverride, videoId, videoParams]);

  useEffect(() => {
    if (typeof document !== "undefined") {
      ReactModal.setAppElement(document.getElementById(APPLICATION_NODE));
    }
  }, []);

  useEffect(() => {
    const debouncedOnResize = debounce(onResize, 250);
    onResize();
    if (videoId) loadOEmbed();
    window.addEventListener("resize", debouncedOnResize);
    return () => {
      window.removeEventListener("resize", debouncedOnResize);
    };
  }, [loading, loadOEmbed, onResize, videoId]);

  useEffect(() => {
    if (!isFirstRender) {
      const segmentEvent = mounted ? events.modal.opened : events.modal.closed;
      segmentEvent(authState.value, 'ModalVideo');
    }
  // Don't want to run when isFirstRender changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mounted]);

  return (
    <ReactModal
      isOpen={mounted}
      onRequestClose={closeModal}
      contentLabel={"Open Modal"}
      overlayClassName={"llls__modal__overlay"}
      className={cn("llls__modal__content", className)}>
      <VideoWrapper>
        <ModalContentInner>
          <ModalCloseBtn aria-label="Close Modal" onClick={closeModal}>
            <CloseIcon />
          </ModalCloseBtn>
          {loading && (
            <iframe
              title="videoFrame"
              src={videoState.src}
              width={desiredWidth}
              height={desiredHeight}
              allow="autoplay; fullscreen"
              allowFullScreen
            />
          )}
        </ModalContentInner>
      </VideoWrapper>
    </ReactModal>
  );
};

export default ModalVideo;

const VideoWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const ModalContentInner = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const ModalCloseBtn = styled.button`
  cursor: pointer;
  width: 1rem;
  height: 1rem;
  margin-bottom: 1.5rem;
`;
