import * as React from "react";
import * as Styled from "./Categories.styled";
import { CategoriesSchema } from "./Categories.model";

import Button from "@components/ui/button";
import Video from "@components/primitives/video";
import useMediaQuery from "@hooks/useMediaQuery";
import { Device } from "@styles/constants";

const { useState, useRef } = React;

const Categories: React.FC<{ sanity: CategoriesSchema }> = ({ sanity }) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [hover, setHover] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement | undefined>();
  const isDesktop = useMediaQuery(Device.mediumUp);

  const handleMouseEnter = (idx: number) => {
    setHover(true);
    setActiveIndex(idx);
  };

  return (
    <Styled.Categories ref={ref}>
      <Styled.Container className="grid-container" id="categories">
        <Styled.Grid className="grid-x">
          <Styled.HeadingContainer className="small-12 medium-8 large-8">
            {sanity.heading && <Styled.Heading2>{sanity.heading}</Styled.Heading2>}
            {isDesktop && sanity.cta?.url && (
              <Styled.ButtonWrapper>
                <Button text={sanity.cta.text} href={sanity.cta.url} dark />
              </Styled.ButtonWrapper>
            )}
          </Styled.HeadingContainer>

          <Styled.Links className="small-12 medium-4 large-4">
            <ul
              onMouseLeave={() => {
                setHover(false);
              }}>
              {sanity.classes?.map(({ title, slug }, idx) => {
                return (
                  <li key={idx}>
                    <Styled.Link
                      href={`/classes/${slug?.current}`}
                      hover={hover}
                      active={activeIndex === idx}
                      onMouseEnter={() => {
                        handleMouseEnter(idx);
                      }}>
                      {title}
                    </Styled.Link>
                  </li>
                );
              })}
            </ul>
          </Styled.Links>
          {!isDesktop && sanity.cta?.url && (
            <Styled.ButtonWrapper>
              <Button text={sanity.cta.text} href={sanity.cta.url} dark />
            </Styled.ButtonWrapper>
          )}
        </Styled.Grid>
      </Styled.Container>

      <Styled.Background>
        {sanity.classes.map(({ workout_clip, lifestyle_clip }, idx: number) => {
          const source =
            sanity.show_lifestyle_clips && lifestyle_clip ? lifestyle_clip.url : workout_clip?.url;
          if (source) {
            return (
              <Styled.Media key={idx} className={activeIndex === idx ? "active" : "inactive"}>
                <Video src={source} stop={activeIndex !== idx ? true : false} />
              </Styled.Media>
            );
          }

          return null;
        })}
      </Styled.Background>
    </Styled.Categories>
  );
};

export default Categories;
