import styled, { css } from "styled-components";
import {
  Heading1CSS,
  Heading3CSS,
  BodyMediumCSS,
  Eyebrow,
  PortableTextWrapper,
  SmallBodyMediumCSS,
} from "@styles/typography";
import { Theme, HeadingSize, StyledTheme } from "../FiftyFifty.model";
import { rem } from "@styles/helpers";
import { Color, Device } from "@styles/constants";

export const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--foreground-color);
  height: ${({ theme: { name } }) => (name === Theme.HARDWARE_INTRO ? "100%" : null)};

  ${Eyebrow} {
    ${({ theme: { name } }: StyledTheme) => css`
      color: ${name === Theme.LIGHT ? Color.grayBrand : Color.white};
    `}
  }
`;

export const Heading = styled.h2<{ size?: HeadingSize }>`
  margin-bottom: 1rem;

  ${({ size }) => {
    if (size === HeadingSize.H1) return Heading1CSS;
    if (size === HeadingSize.H3) return Heading3CSS;
  }}
`;

export const TextWrapper = styled(PortableTextWrapper)`
  margin-top: ${({ theme: { name } }) => (name === Theme.HARDWARE_INTRO ? "auto" : null)};
  margin-bottom: ${({ theme: { increaseRichTextSpacing } }) =>
    increaseRichTextSpacing ? rem(40) : rem(30)};

  h1:first-child,
  h2:first-child,
  h3:first-child,
  h4:first-child,
  h5:first-child,
  h6:first-child {
    margin-top: ${rem(14)};
  }
`;

export const List = styled.ul`
  list-style: none;
  width: 100%;
  margin-left: 0;
  margin-bottom: 2.5rem;

  @media ${Device.mediumUp} {
    margin-bottom: 2.5rem;
  }
`;

export const ListItem = styled.li`
  display: flex;
  flex-flow: row nowrap;
  column-gap: 0.75rem;
  align-items: center;
  padding: 1rem 0;
  border-top: 1px solid ${Color.grayBrand};

  &:last-of-type {
    border-bottom: 1px solid ${Color.grayBrand};
  }
`;

export const IconContainer = styled.div`
  width: 1.5rem;
  flex-shrink: 0;
`;

export const ItemText = styled(PortableTextWrapper)`
  ${SmallBodyMediumCSS};

  @media ${Device.largeUp} {
    ${BodyMediumCSS};
  }
`;

export const ButtonContainer = styled.div`
  width: ${({ theme: { name } }) => (name !== Theme.HARDWARE_INTRO ? "100%" : null)};
  align-self: flex-start;
`;
