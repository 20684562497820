import { useState } from "react";
import ModalVideo from "@components/modals/ModalVideo";
import { parseVideo } from "@utils/parseVideo";
import Button from "@components/ui/button";
import ButtonMedia from "@components/ui/button-media";
import AspectRatio from "@components/primitives/aspect-ratio";
import Image from "@components/primitives/image";
import { IntroSchema } from ".";
import * as Styled from "./Intro.styled";
import { Eyebrow } from "@styles/typography";
import { FadeIn, SlideInText } from "@components/ui/animations";
import { ComponentResolverAnimationProps } from "@components/component-resolver";

export interface IntroProps {
  sanity: IntroSchema;
}

const Intro = ({
  inView,
  sanity: {
    eyebrow,
    eyebrow_image,
    heading_size,
    heading,
    cta_dark,
    cta_light,
    video_button_text,
    video_poster,
    video,
    video_hash,
  },
}: IntroProps & ComponentResolverAnimationProps) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [videoInfo, setVideoInfo] = useState<{ type: string; id: string }>({
    type: "",
    id: "",
  });

  const handlePlayClick = (e: MouseEvent) => {
    e.preventDefault();
    if (!video) return;
    setVideoInfo(parseVideo(video));
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Styled.Intro>
        <div className="grid-container">
          <Styled.Eyebrow>
            {eyebrow && (
              <Eyebrow>
                <SlideInText visible={inView}>{eyebrow}</SlideInText>
              </Eyebrow>
            )}
            {eyebrow_image?.url && (
              <FadeIn visible={inView}>
                <Image asset={eyebrow_image} widthOnScreen={[166, 32]} alt={eyebrow_image?.alt} />
              </FadeIn>
            )}
          </Styled.Eyebrow>
          <Styled.Text size={heading_size}>
            <SlideInText visible={inView} addDelay={[eyebrow]}>
              {heading}
            </SlideInText>
          </Styled.Text>
          <FadeIn visible={inView} delay={0.5} addDelay={[eyebrow]}>
            <Styled.Buttons>
              {cta_dark && <Button text={cta_dark.text} href={cta_dark.url} />}
              {cta_light && <Button text={cta_light.text} href={cta_light.url} invert />}
            </Styled.Buttons>
          </FadeIn>
        </div>
      </Styled.Intro>

      {video_poster?.url && video && (
        <Styled.IntroVideo>
          <div className="grid-container">
            <FadeIn visible={inView} delay={1} addDelay={[eyebrow]}>
              <AspectRatio ratio={16 / 9}>
                <Image asset={video_poster} widthOnScreen={[100]} alt={video_poster?.alt} />
              </AspectRatio>
              <ButtonMedia text={video_button_text} onClick={handlePlayClick} />
            </FadeIn>
          </div>
        </Styled.IntroVideo>
      )}

      <ModalVideo
        className="intro-modal-video"
        mounted={modalOpen}
        videoId={videoInfo.id}
        videoParams={{
          h: video_hash,
          autoplay: 1,
          autopause: 0,
        }}
        closeModal={handleCloseModal}
        full
      />
    </>
  );
};

export default Intro;
