import { useEffect, useRef } from "react";
import mirrorBackground from "@images/mirror-background.png";
import { CloudinaryAsset } from "@models/cloudinary-asset.model";
import { ScrollSection } from "../Scroll.model";
import * as Styled from "./MirrorOverlays.styled";

export interface MirrorOverlayProps {
  image: CloudinaryAsset;
  video?: CloudinaryAsset;
  foreground?: boolean;
  shouldAnimate?: boolean;
}

const MirrorOverlay = ({ image, video, foreground = false, shouldAnimate }: MirrorOverlayProps) => {
  const videoRef = useRef<HTMLVideoElement | undefined>();

  useEffect(() => {
    if (!videoRef.current) return;
    if (shouldAnimate) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [shouldAnimate]);

  return (
    <Styled.StyledMirrorOverlay $foreground={foreground}>
      {video ? (
        <video ref={videoRef} src={video.url} muted playsInline disablePictureInPicture loop />
      ) : (
        <img src={image.url} alt="Mirror UI" />
      )}
    </Styled.StyledMirrorOverlay>
  );
};

export interface MirrorOverlaysProps {
  sections: ScrollSection[];
  activeIndex: number | undefined;
}

const MirrorOverlays = ({ sections, activeIndex }: MirrorOverlaysProps) => {
  return (
    <>
      {sections.length > 0 &&
        sections.map((section: ScrollSection, idx: number) => {
          return (
            <Styled.StyledContainer
              key={idx}
              initial={{ opacity: 0, visibility: "hidden" }}
              animate={
                activeIndex === idx
                  ? { opacity: 1, visibility: "visible" }
                  : { opacity: 0, visibility: "hidden" }
              }>
              <MirrorOverlay
                image={section.mirror_image}
                video={section.mirror_video}
                shouldAnimate={activeIndex === idx}
              />

              {section.mirror_foreground_image?.url && (
                <MirrorOverlay image={section.mirror_foreground_image} foreground />
              )}
            </Styled.StyledContainer>
          );
        })}
      <Styled.StyledMirrorOverlaysBackground
        initial={{ opacity: 0, visibility: "hidden" }}
        animate={
          typeof activeIndex !== "undefined"
            ? { opacity: 1, visibility: "visible" }
            : { opacity: 0, visibility: "hidden" }
        }>
        <img src={mirrorBackground.src} alt="" />
      </Styled.StyledMirrorOverlaysBackground>
    </>
  );
};

export default MirrorOverlays;
