import styled from "styled-components";
import { Color, Device } from "@styles/constants";
import {
  Eyebrow as GlobalEyebrow,
  Heading1CSS,
  Heading2CSS,
  Heading3CSS,
  SmallBodyMedium,
  XSmallBodyMediumCSS,
} from "@styles/typography";
import { AbsoluteCenterXCSS } from "@styles/layout";
import { rem } from "@styles/helpers";
import { globalCtaDesktop, globalCtaMobile } from "../GlobalCta.styled";

export const ComparePlan = styled.div`
  position: relative;
`;

export const Tag = styled(GlobalEyebrow)`
  ${AbsoluteCenterXCSS}
  top: ${rem(-20)};
  font-size: ${rem(16)};
  padding: ${rem(10)} ${rem(16)} ${rem(5)};
  color: ${Color.white};
  margin-bottom: 0;
  white-space: nowrap;
  z-index: 1;
  border-radius: 2px;
  background-color: ${Color.blackBrand};

  @media ${globalCtaMobile} {
    font-size: ${rem(14)};
    padding: ${rem(11)} ${rem(10)} ${rem(6)};
  }
`;

export const ImageWrap = styled.div`
  overflow: hidden;

  @media ${globalCtaDesktop} {
    border-radius: 10px 10px 0 0;
  }
`;

export const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${rem(16)};

  @media ${globalCtaDesktop} {
    padding: ${rem(24)} ${rem(40)} ${rem(16)};
  }
`;

export const PlanName = styled(GlobalEyebrow)`
  margin-bottom: ${rem(20)};

  @media ${globalCtaMobile} {
    margin-bottom: ${rem(10)};
  }

  @media ${Device.small} {
    text-align: center;
    max-width: 80%;
  }
`;

export const Price = styled.span`
  ${Heading2CSS}
  line-height: 1;

  @media ${globalCtaMobile} {
    ${Heading3CSS}
  }

  span {
    ${Heading3CSS}
  }
`;

export const Description = styled(SmallBodyMedium)`
  color: ${Color.grayBrand};
  text-align: center;
  margin-bottom: 0;

  @media ${globalCtaMobile} {
    margin-top: ${rem(10)};
    min-height: 41px;
  }

  @media ${Device.small} {
    ${XSmallBodyMediumCSS}
  }
`;

export const CtaWrap = styled.div`
  margin-top: ${rem(20)};

  @media ${Device.small} {
    a {
      ${XSmallBodyMediumCSS}
      padding: ${rem(15)};
    }
  }
`;
