import styled from "styled-components";
import { Device, Ease, Spacing, Color } from "@styles/constants";
import { AbsoluteCoverCSS, AbsoluteCenterXCSS } from "@styles/layout";
import { LargeBodyRegularCSS } from "@styles/typography";

export const ProductHeroSection = styled.div`
  position: relative;
  height: auto;
  overflow: hidden;

  @media ${Device.largeUp} {
    height: calc(100vh - var(--header-height) - var(--banner-height));
  }
`;

export const Heading = styled.div`
  h1 {
    text-align: left;
    margin-bottom: 0;
  }
`;

export const Body = styled.div`
  p {
    ${LargeBodyRegularCSS}
    max-width: 25ch;
    margin-bottom: 0;
    margin-top: 30px;

    @media ${Device.largeUp} {
      margin-top: 40px;
    }
  }
`;

export const Button = styled.div`
  margin-top: 2.5rem;
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: baseline;

  > a {
    margin-left: 20px;
  }
`;

export const Content = styled.div<{ delay?: number }>`
  > div {
    @media ${Device.largeUp} {
      opacity: 0;
      transform: translateY(60px);
      animation: fade-in-up 1.5s ${Ease.outFast} forwards;
      animation-delay: ${({ delay }) => delay && delay}s;

      &:nth-child(2) {
        animation-delay: calc(${({ delay }) => delay && delay}s + 0.1s);
      }

      &:nth-child(3) {
        animation-delay: calc(${({ delay }) => delay && delay}s + 0.2s);
      }

      &:nth-child(4) {
        animation-delay: calc(${({ delay }) => delay && delay}s + 0.3s);
      }
    }
  }

  @keyframes fade-in-up {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const VideoIntro = styled.div<{ play?: boolean }>`
  position: relative;
  height: calc(100vh - var(--header-height) - var(--banner-height));

  @media ${Device.largeUp} {
    ${AbsoluteCoverCSS}
    left: calc(-450px + 8vw);
    width: calc(100% + 26vw);
    opacity: ${({ play }) => (play ? "1" : "0")};
    // transition: opacity ${Ease.duration} ${Ease.out};
  }

  video,
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const VideoLoop = styled.div<{ introEnded: boolean }>`
  position: relative;
  margin-top: 40px;

  @media ${Device.largeUp} {
    position: absolute;
    top: 0;
    right: 0.6vw;
    width: 50%;
    margin-top: 0;
    height: 100%;
    opacity: ${({ introEnded }) => (introEnded ? "1" : "0")};
    // transition: opacity ${Ease.duration} ${Ease.out};
  }

  video,
  img {
    ${AbsoluteCoverCSS}
    display: block;
    object-fit: cover;
  }

  &.medium-7 {
    width: calc(58.33333% + 0.625rem + ((100vw - 1200px) * 0.5));
  }
`;

export const Foreground = styled.div`
  height: 100%;
  width: 100%;
  margin-top: 40px;
  margin-bottom: ${Spacing.xSmall};

  @media ${Device.largeUp} {
    margin-top: 0;
  }

  .grid-container {
    width: 100%;
    height: 100%;
  }

  .grid-x {
    height: 100%;
    flex-wrap: wrap;

    @media ${Device.largeUp} {
      flex-wrap: nowrap;
    }
  }
`;

export const Explore = styled.button<{ delay?: number }>`
  ${AbsoluteCenterXCSS}
  bottom: 30px;
  color: ${Color.blackBrand};
  text-align: center;
  z-index: 1;
  cursor: pointer;

  @media ${Device.largeUp} {
    bottom: 70px;
    opacity: 0;
    transform: translateY(60px);
    animation: fade-in-up 1.5s ${Ease.outFast} forwards;
    animation-delay: calc(${({ delay }) => delay && delay}s + 0.3s);
  }

  svg {
    margin: 7px auto 0;
    width: 9px;
    height: 9px;
    fill: ${Color.blackBrand};
  }
`;
