import { Sanity } from "@models/sanity.model";

export const NewsletterType = "newsletter";

export interface NewsletterSchema extends Sanity.Document {
  theme_style: Sanity.ThemeColor;
  body: string;
  email_placeholder: string;
  email_success: string;
  email_failure: string;
  ticker_text: string;
  ticker_large: boolean;
  ticker_large_mobile: boolean;
}

export const NEWSLETTER_PROJECTION = `{
  theme_style,
  body,
  email_placeholder,
  email_success,
  email_failure,
  ticker_text,
  ticker_large,
  ticker_large_mobile
}`;
