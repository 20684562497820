import { Sanity } from "@models/sanity.model";
import {
  SanitySyncProductDocument,
  sanitySyncProductDocumentProjection,
} from "@models/product.model";
import { BundleGlobalItems } from "./bundle/Bundle.model";

export const BundlesType = "bundles";

export interface BundleProduct {
  _key: string;
  bundled_product: SanitySyncProductDocument;
  product_tag: string;
}

export interface BundlesSchema extends Sanity.Document {
  theme?: {
    theme_style: Sanity.ThemeColor;
    headline_size: Sanity.HeadlineSize;
    content_position: Sanity.HeadlinePosition;
  };
  eyebrow: string;
  heading: string;
  body: string;
  bundle_products: Array<BundleProduct>;
  bundle_item: BundleGlobalItems;
  footnote?: {
    heading: string;
    body: Sanity.PortableText;
  };
}

export const BUNDLES_PROJECTION = `{
  theme,
  eyebrow,
  heading,
  body,
  bundle_products[]{
    _key,
    bundled_product->${sanitySyncProductDocumentProjection()},
    product_tag,
  },
  bundle_item,
  footnote,
}`;
