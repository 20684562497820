import * as React from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import useMediaQuery from "@hooks/useMediaQuery";
import { Device } from "@styles/constants";
import Button from "@components/ui/button";
// import BackButton from "@components/ui/back-button"; -- MIR-554
import { Class } from "@models/class.model";
import { Instructor, InstructorType } from "@models/instructor.model";
import { Partnership } from "@models/partnership.model";

import { ReactComponent as CaretSVG } from "@images/caret.svg";

import Filter from "./filter";
import * as Styled from "./Catalog.styled";
import { CatalogSchema } from "./Catalog.model";

const { useRef, useState } = React;

export type CatalogItem = Instructor | Class | Partnership;

export interface CatalogProps {
  sanity: CatalogSchema;
}

const Catalog = ({ sanity }: CatalogProps) => {
  const { asPath } = useRouter();
  const isDesktop = useMediaQuery(Device.largeUp);
  const [open, setOpen] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [activeFilter, setActiveFilter] = useState<string>("View All");
  const activeFilterRef = useRef<string>("View All");
  const { title, content_collection, background_video, background_image, mobile_background_image } =
    sanity;
  {
    /* TO DO: Decide to keep or remove filter functionality -- currently not used */
  }
  let filters: any[] = [];
  let filtersEnabled = false;

  // TO DO: Add to sanity?
  const backUrl = asPath === "/classes" ? "/workouts" : "/";
  let titlePrefix = "";

  const pathParts = asPath.split("?");
  const baseSlug = pathParts[0];
  const currentQuery = pathParts[1];

  // TO DO: Add to Sanity?
  switch (asPath) {
    case "/classes":
      titlePrefix = "Explore";
      break;
    case "/instructors":
      titlePrefix = "Meet Our";
      break;
    case "/partners":
      titlePrefix = "Explore Mirror";
      break;
    default:
      titlePrefix = "";
  }

  const handleFilterClick = (filter: string) => {
    if (isDesktop) {
      setActiveFilter(filter);
    } else {
      setDisabled(false);
      activeFilterRef.current = filter;
    }
  };

  const renderBackground = () => {
    const props = {
      muted: true,
      loop: true,
      playsInline: true,
      autoPlay: true,
      disablePictureInPicture: true,
    };

    if (background_video?.url) {
      return <video src={background_video?.url} {...props} />;
    }

    if (!isDesktop && mobile_background_image?.url) {
      return <img src={mobile_background_image.url} alt={mobile_background_image.alt || title} />;
    } else if (background_image?.url) {
      return <img src={background_image.url} alt={background_image.alt || title} />;
    }

    return null;
  };

  return (
    <Styled.Container>
      {/* TO DO: Decide to keep or remove filter functionality -- currently not used */}
      {!isDesktop && filtersEnabled && (
        <Styled.Topbar
          open={open}
          onClick={() => {
            setOpen(!open);
          }}>
          <div className="grid-container">
            <div>
              {title}: <Styled.ActiveFilter>{activeFilter}</Styled.ActiveFilter>
            </div>
            <CaretSVG />
          </div>
        </Styled.Topbar>
      )}

      <Styled.Sidebar>
        <div className="grid-container">
          {/* MIR-554 hidding back button, we'll bring it back in the future. <BackButton href={backUrl} /> */}

          {/* TO DO: Decide to keep or remove filter functionality -- currently not used */}
          {filtersEnabled && (
            <Styled.Filters open={!isDesktop ? open : true}>
              <div className="grid-container">
                <Styled.FilterWrap>
                  <Styled.Title>
                    {titlePrefix} {title}
                  </Styled.Title>

                  <Styled.List>
                    {filters?.length > 0 &&
                      filters.map((filter, idx) => {
                        return (
                          <Filter key={idx} category={filter} handleClick={handleFilterClick} />
                        );
                      })}

                    <Filter category={"View All"} handleClick={handleFilterClick} defaultChecked />
                  </Styled.List>
                </Styled.FilterWrap>

                {!isDesktop && (
                  <Styled.FilterButtons>
                    <Button
                      text="Apply"
                      dark
                      disabled={disabled}
                      onClick={() => {
                        setOpen(false);
                        setActiveFilter(activeFilterRef.current);
                      }}
                    />
                    <Button
                      text="Cancel"
                      dark
                      invert
                      onClick={() => {
                        setOpen(false);
                      }}
                    />
                  </Styled.FilterButtons>
                )}
              </div>
            </Styled.Filters>
          )}

          <Styled.CategoryList>
            {content_collection?.length > 0 &&
              content_collection.map((item: CatalogItem, idx: number) => {
                const viewAllFilterActive = activeFilter !== "View All";
                const instructorOneFilterActive =
                  item._type === InstructorType && activeFilter !== item?.tags[0];
                const constructedUrl = currentQuery
                  ? `${baseSlug}${item.slug.current}?${currentQuery}`
                  : `${baseSlug}${item.slug.current}`;
                if (viewAllFilterActive && instructorOneFilterActive) return null;
                return (
                  <Styled.Item key={idx}>
                    <Link href={constructedUrl}>{item.title}</Link>
                  </Styled.Item>
                );
              })}
          </Styled.CategoryList>
        </div>
      </Styled.Sidebar>

      <Styled.Background>{renderBackground()}</Styled.Background>
    </Styled.Container>
  );
};

export default Catalog;
