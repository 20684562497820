import { Color, Device, Ease, Font } from "@styles/constants";
import { rem } from "@styles/helpers";
import { AbsoluteCoverCSS } from "@styles/layout";
import { Heading2CSS } from "@styles/typography";
import styled, { css } from "styled-components";

export const Categories = styled.div`
  background-color: ${Color.black};
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;

  &::before {
    content: "";
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: 56.25%;
  }

  &::after {
    content: "";
    display: table;
    clear: both;
  }
`;

export const Container = styled.div`
  position: relative;
  padding-top: ${rem(80)};
  padding-bottom: ${rem(80)};
  width: 100%;
  z-index: 1;
`;

export const Background = styled.div`
  ${AbsoluteCoverCSS}

  &::after {
    ${AbsoluteCoverCSS}
    content: '';
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
    opacity: 0;
  }
`;

export const Grid = styled.div`
  position: relative;
  overflow: hidden;
`;

export const HeadingContainer = styled.div`
  @media ${Device.mediumUp} {
    align-self: center;
  }
`;

export const Heading2 = styled.h2`
  ${Heading2CSS}
  color: ${Color.white};
  text-align: left;
  max-width: 10ch;
`;

export const Links = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  margin-top: ${rem(40)};

  @media ${Device.largeUp} {
    align-items: flex-end;
    margin-top: 0;
  }

  ul {
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul + a,
  ul + button {
    margin: 40px 0 60px;

    @media ${Device.largeUp} {
      margin: 40px 0;
    }
  }
`;

export const Link = styled.a<{ hover: boolean; active: number }>`
  display: block;
  color: ${Color.white};
  text-decoration: none;
  transition: color ${Ease.duration} ${Ease.out};

  font-family: ${Font.medium};
  font-size: ${rem(28)};
  line-height: 1.1;
  margin-bottom: ${rem(8)};

  &:hover,
  &:focus-visible {
    color: ${Color.white};
    text-decoration: underline;
  }

  ${({ hover, active }) =>
    hover &&
    css`
      color: ${!active && Color.grayInactive};
    `};
`;

export const Media = styled.div`
  ${AbsoluteCoverCSS}
  opacity: 0;
  visibility: hidden;
  transition: opacity ${Ease.duration} ${Ease.out}, visibility 0s linear ${Ease.duration};

  div {
    width: 100%;
    height: 100%;
    padding-bottom: 0;
  }

  &.active {
    opacity: 1;
    visibility: visible;
    transition: opacity ${Ease.duration} ${Ease.out}, visibility 0s linear 0s;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const TrialTrigger = styled.div<{ desktopOnly: boolean }>`
  display: none;

  @media ${Device.mediumUp} {
    display: block;
    margin-top: 2.5rem;
  }
`;

export const ButtonWrapper = styled.div`
  flex-basis: 100%;
  margin-top: ${rem(60)};
  z-index: 1;

  @media ${Device.mediumUp} {
    display: none;
  }
`;
