import { motion } from "framer-motion";
import styled from "styled-components";
import { Ease, Color } from "@styles/constants";

export const Highlights = styled.div<{ visible?: boolean }>`
  position: sticky;
  top: 0;
  margin-top: -100vh;
  padding-top: 200px;
  height: 100vh;
  width: 100%;
  opacity: ${({ visible }) => (visible ? "1" : "0")};
  transform: ${({ visible }) => (visible ? "translateY(-60px)" : "none")};
  transition: opacity 0.5s ${Ease.out}, transform 0.5s ${Ease.out};
`;

export const Container = styled(motion.div)`
  position: relative;
  width: 100%;
`;

export const List = styled.ul`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  width: 100%;
  margin: 0 auto;
`;

export const Item = styled(motion.div)`
  position: relative;
  color: white;
  cursor: pointer;
  display: none;

  &.active {
    display: block;
    animation: fade-in 0.5s ${Ease.out} forwards;
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

export const Heading = styled.h3`
  position: relative;
  text-align: center;
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 0;
`;

export const MobileProgressWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const MobileProgress = styled.button`
  position: relative;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${Color.greenAsh};

  &::before {
    content: "";
    position: absolute;
    top: -4px;
    left: -4px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid ${Color.white};
    opacity: 0;
  }

  &.active {
    background-color: ${Color.white};

    &::before {
      opacity: 1;
    }
  }

  span {
    display: block;
    width: 100%;
    height: 100%;
    background-color: ${Color.white};
  }

  & + & {
    margin-left: 20px;
  }
`;

export const HighlightBody = styled(motion.div)`
  padding-left: var(--padding);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -1%;
  max-width: 35ch;
  text-align: center;
  overflow: hidden;
  margin: 10px auto 0;
`;
