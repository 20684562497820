import styled from "styled-components";
import { motion } from "framer-motion";
import { AbsoluteCenterXCSS } from "@styles/layout";

export const FullWidthVideo = styled.div``;

export const VideoContainer = styled.div`
  ${AbsoluteCenterXCSS}
  bottom: 10vh;
  width: 100%;
  max-height: 55vh;
  overflow: hidden;
  display: flex;
  align-items: center;
`;

export const Video = styled(motion.div)`
  width: 100%;
`;
