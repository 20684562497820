import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import { Ease } from "@styles/constants";
import { AbsoluteCenterXCSS, AbsoluteCoverCSS } from "@styles/layout";

export const StyledMirrorHeroContainer = styled(motion.div)`
  ${AbsoluteCenterXCSS}
  width: 320px;
  will-change: transform;
  bottom: 0;
  transform-origin: center 30%;

  @media screen and (min-height: 850px) {
    width: 350px;
  }

  @media screen and (min-height: 1024px) {
    bottom: 5vh;
  }

  &::before {
    content: "";
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: 200%;
  }

  &::after {
    content: "";
    display: table;
    clear: both;
  }
`;

export const StyledMirrorHero = styled.div<{ visible: boolean }>`
  ${AbsoluteCoverCSS}

  margin-top: 3px;
  margin-left: -3px;
  margin-bottom: -4px;
  padding: 66px 60px 66px 66px;

  @media screen and (min-height: 850px) {
    padding: 72px 64px 70px 71px;
  }

  div,
  img,
  video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${({ visible }) =>
    visible
      ? css`
          opacity: 1;
          visibility: visible;
          transition: opacity ${Ease.duration} ${Ease.out};
        `
      : css`
          opacity: 0;
          visibility: hidden;
          transition: opacity ${Ease.duration} ${Ease.out}, visibility 0s linear ${Ease.duration};
        `};
`;
