import { Sanity } from "@models/sanity.model";
import cn from "classnames";
import * as React from "react";
import * as Styled from "./Footnotes.styled";
import PortableText from "@components/portable-text";

export interface FootnotesProps {
  theme_style?: Sanity.ThemeColor;
  footnotes?: Array<{
    copy?: Sanity.PortableText;
    reference_mark?: string;
  }>;
  className?: string;
}

const Footnotes = ({ theme_style, footnotes, className }: FootnotesProps) => (
  <Styled.FootnoteSection className={cn(className)} theme_style={theme_style}>
    <div className="grid-container">
      <Styled.UnstyledList>
        {footnotes &&
          footnotes.map(({ copy, reference_mark }, index) => (
            <Styled.Footnote key={index}>
              {reference_mark && <sup>{reference_mark}</sup>}
              {copy && <PortableText value={copy} />}
            </Styled.Footnote>
          ))}
      </Styled.UnstyledList>
    </div>
  </Styled.FootnoteSection>
);

export default Footnotes;
