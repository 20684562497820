import { useRef, useState, useLayoutEffect, useEffect } from "react";
import { useScroll, useTransform } from "framer-motion";
import { Sanity } from "@models/sanity.model";
import useWindowSize from "@hooks/useWindowSize";
import { FIXED_NAVBAR_HEIGHT } from "@styles/constants";
import getRootOffset from "@utils/getRootOffset";
import * as Styled from "./Section.styled";
import PortableText from "@components/portable-text";

export interface SectionProps {
  id?: string;
  index: number;
  type?: string;
  heading?: string;
  body?: Sanity.PortableText;
  bodyPosition?: string;
  shouldAnimate: boolean;
  setActiveIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const Section = ({
  id,
  index,
  type,
  heading,
  body,
  bodyPosition,
  shouldAnimate,
  setActiveIndex,
}: SectionProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [position, setPosition] = useState({
    start: 0,
    middle: 0,
    end: 0,
  });

  const { height } = useWindowSize();

  const { scrollY } = useScroll();

  const opacity = useTransform(scrollY, [position.start, position.middle, position.end], [0, 1, 0]);
  const y = useTransform(scrollY, [position.start, position.middle, position.end], [50, 0, -50]);

  useLayoutEffect(() => {
    if (!containerRef.current) return;

    const bounds = getRootOffset(containerRef.current);
    const yOffset = bounds.top - FIXED_NAVBAR_HEIGHT;

    setPosition({
      start: yOffset - height * 1.6,
      middle: yOffset - height * 0.8,
      end: yOffset + height * 0.6,
    });
  }, [height]);

  useEffect(() => {
    const unsubscribeScrollY = scrollY.onChange((y) => {
      const yRefOffsetTop = index === 0 && y <= position.start;
      const yRefOffsetBottom =
        containerRef.current &&
        y > position.start + position.end + containerRef.current.offsetHeight;
      const yRefLastSection =
        containerRef.current && y > position.end + containerRef.current.offsetHeight;

      if (y > position.start && y < position.end) {
        setActiveIndex(index);
      } else if (yRefOffsetTop || yRefOffsetBottom) {
        setActiveIndex(0);
      } else if (yRefLastSection) {
        setActiveIndex(undefined);
      }
    });

    return () => {
      unsubscribeScrollY();
    };
  }, [index, position, scrollY, setActiveIndex]);

  return (
    <Styled.StyledSection id={id || ""} ref={containerRef} sectionType={type ?? ""}>
      <Styled.StyledText style={shouldAnimate ? { display: "block" } : { display: "none" }}>
        {heading && (
          <Styled.StyledHeading style={{ opacity, y }}>
            <div className="grid-container">
              <h2>{heading}</h2>
            </div>
          </Styled.StyledHeading>
        )}

        {body && (
          <Styled.StyledBody style={{ opacity, y }} $bodyPosition={bodyPosition}>
            <div className="grid-container">
              <Styled.StyledBodyContent>
                <PortableText value={body} />
              </Styled.StyledBodyContent>
            </div>
          </Styled.StyledBody>
        )}
      </Styled.StyledText>
    </Styled.StyledSection>
  );
};

export default Section;
