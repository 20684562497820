import { Suspense } from "react";
import { schemaToProps } from "./schema-props";
import { modules } from "./modules";
import { Sanity } from "@models/sanity.model";
import { SanitySyncProductDocument } from "@models/product.model";
import { QueryModalTriggerProps } from "@components/query-modal/query-modal-trigger";
import { SectionSpacing } from "@styles/spacing";
import useInViewAnimation from "@hooks/useInViewAnimation";

export interface ComponentResolverProps {
  schema: Sanity.Document;
  queryModalProps?: QueryModalTriggerProps;
  product?: SanitySyncProductDocument;
  sectioningContext: Sanity.SectioningContext;
}

export type ComponentResolverAnimationProps = {
  inView?: boolean;
};

const ComponentResolver = ({ schema, sectioningContext, ...rest }: ComponentResolverProps) => {
  const { ref, inView } = useInViewAnimation();

  if (modules[schema._type]) {
    const Component = modules[schema._type];
    return (
      <Suspense fallback={null}>
        <SectionSpacing ref={ref} context={sectioningContext}>
          <Component
            inView={inView}
            {...schemaToProps(schema)}
            context={sectioningContext}
            {...rest}
          />
        </SectionSpacing>
      </Suspense>
    );
  }

  return process.env.NODE_ENV !== "production" ? (
    <div>Schema type does not resolve to a component: {schema._type}</div>
  ) : (
    <></>
  );
};

export default ComponentResolver;
