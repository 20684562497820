import { CloudinaryAsset, CLOUDINARY_ASSET_PROJECTION } from "@models/cloudinary-asset.model";
import { Sanity } from "@models/sanity.model";

export const ListCardsType = "list_cards";

export interface ListCard {
  heading: string;
  image: CloudinaryAsset;
  list_items: Array<{
    heading: string;
    copy: Sanity.PortableText;
    icon: CloudinaryAsset;
  }>;
}

export interface ListCardsSchema extends Sanity.Document {
  eyebrow?: string;
  heading: string;
  subheading?: string;
  cards: ListCard[];
}

export const LIST_CARDS_PROJECTION = `{
  eyebrow,
  heading,
  subheading,
  cards[] {
    heading,
    image ${CLOUDINARY_ASSET_PROJECTION},
    list_items[] {
      heading,
      copy,
      icon ${CLOUDINARY_ASSET_PROJECTION},
    }
  },
}`;
