import * as React from "react";
import * as Styled from "./ListCards.styled";
import { ListCardsSchema, ListCard } from "./ListCards.model";
import Card from "./card";

export interface ListCardsProps {
  sanity: ListCardsSchema;
}

const ListCards = ({ sanity: { eyebrow, heading, subheading, cards } }: ListCardsProps) => (
  <div className="grid-container">
    <Styled.Header>
      {eyebrow && <Styled.Eyebrow>{eyebrow}</Styled.Eyebrow>}
      {heading && <Styled.Heading>{heading}</Styled.Heading>}
      {subheading && <Styled.Subheading>{subheading}</Styled.Subheading>}
    </Styled.Header>
    <Styled.ItemGrid>
      {cards?.length > 0 && cards.map((card: ListCard, i: number) => <Card {...card} key={i} />)}
    </Styled.ItemGrid>
  </div>
);

export default ListCards;
