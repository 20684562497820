import styled, { css } from "styled-components";
import {
  EyebrowCSS,
  Heading2CSS,
  XSmallBodyRegularCSS,
  XSmallBodyMediumCSS,
} from "@styles/typography";
import { AbsoluteCoverCSS } from "@styles/layout";
import { Color, Device } from "@styles/constants";

interface StyledMirrorProps {
  withMirrorStand?: boolean;
}

export interface StyledVideoProps {
  videoLoaded: boolean;
}

const MirrorSpacing = {
  bottomSpacingM: "280px",
  bottomSpacingD: "217px",
};

export const Background = styled.div`
  ${AbsoluteCoverCSS}
  overflow: hidden;
  background-color: ${Color.black};

  &::after {
    content: "";
    ${AbsoluteCoverCSS}
    background: ${Color.black};
    opacity: 0.4;
  }

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
`;

export const Eyebrow = styled.span`
  ${EyebrowCSS}
  color: ${Color.grayFill};
  width: 100%;

  @media ${Device.mediumDown} {
    font-size: 12px;
  }

  @media ${Device.small} {
    text-align: center;
  }
`;

export const Footnote = styled.p`
  ${XSmallBodyRegularCSS}
  width: 100%;
  margin-top: 1.875rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: ${Color.white};

  @media ${Device.small} {
    text-align: center;
  }

  @media ${Device.mediumUp} {
    margin-top: 3.125rem;
  }
`;

export const LeftHeading = styled.h1`
  ${Heading2CSS}
  text-align: left;
  color: ${Color.white};
  margin-bottom: 1.875rem;

  @media ${Device.small} {
    text-align: center;
  }

  @media ${Device.mediumDown} {
    font-size: 36px;
  }
`;

export const Button = styled.div`
  width: 100%;
  margin-top: 0;

  @media ${Device.small} {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const ExploreButton = styled.button`
  ${XSmallBodyMediumCSS}
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  color: ${Color.white};
  cursor: pointer;
  margin-top: 40px;

  span {
    margin: 0 auto 2px;
  }

  svg {
    fill: ${Color.white};
    width: 14px;
  }
`;

export const TextSection = styled.div<StyledMirrorProps>`
  flex-direction: column;
  flex: 1 0 20%;
  display: flex;
  justify-content: center;
  text-align: left;
  align-items: flex-start;

  ${({ withMirrorStand }) =>
    withMirrorStand &&
    css`
      && {
        @media ${Device.medium} {
          padding-right: 48px;
        }

        @media ${Device.largeUp} {
          justify-content: flex-end;
          padding-bottom: 72px;
        }

        h2 {
          @media ${Device.medium} {
            max-width: 35vw;
          }
        }
      }
    `}

  @media ${Device.small} {
    text-align: center;
  }
`;

export const ImageContainer = styled.div<StyledVideoProps>`
  opacity: ${({ videoLoaded }) => (videoLoaded ? 1 : 0)};
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  margin-top: 2.5rem;
  padding-bottom: min(61.33vw, 264px);
  transition: opacity 0.2s linear;

  @media ${Device.mediumUp} {
    margin-top: 0;
    padding-bottom: 0;
    align-items: center;
    justify-content: flex-end;
  }
`;

export const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  flex-direction: row;

  @media ${Device.small} {
    flex-direction: column;
    padding-top: 60px;
  }

  @media ${Device.mediumUp} {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  @media ${Device.xlargeUp} {
    padding-left: 0;
  }
`;

export const CenteredPhone = styled.div`
  display: flex;
  justify-content: center;
  height: auto;
  width: auto;
  flex: 1;
  position: absolute;

  img {
    position: absolute;
    max-width: 72%;
    top: -3%;
    right: 11%;

    @media ${Device.mediumUp} {
      max-width: 60%;
      right: -8.6%;
    }

    @media ${Device.largeUp} {
      max-width: 72%;
    }
  }

  video {
    display: block;
    width: 100%;
    height: 100%;
    max-width: calc(55.3% + 1px);
    border-radius: 0.625rem;

    @media ${Device.mediumUp} {
      max-width: calc(46.3% + 1px);
      margin-left: 52%;
    }

    @media ${Device.largeUp} {
      max-width: calc(55.3% + 1px);
      margin-left: 38.3%;
    }
  }
`;

export const HeroFiftySection = styled.div<StyledMirrorProps>`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: min(calc(45.33vw + 7.5rem), 315px);

  ${({ withMirrorStand }) =>
    withMirrorStand &&
    css`
      && {
        --mainnav-height: 120px;
        height: calc(100vh - var(--mainnav-height));
        margin-bottom: ${MirrorSpacing.bottomSpacingM};

        @media only screen and (max-width: 24em) {
          margin-bottom: 100%;
        }

        @media ${Device.largeUp} {
          --mainnav-height: var(--banner-height);
          margin-bottom: ${MirrorSpacing.bottomSpacingD};
        }

        @media ${Device.mediumDown} and (min-height: 960px) {
          height: 600px;
        }

        @media ${Device.largeUp} and (min-height: 960px) {
          height: 660px;
        }
      }
    `}

  @media ${Device.mediumUp} {
    min-height: 37.5rem;
    margin-bottom: 0;
  }
`;
