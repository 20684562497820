import { motion } from "framer-motion";
import styled from "styled-components";
import { Ease, Color } from "@styles/constants";

export const Highlights = styled.div<{ visible?: boolean }>`
  position: sticky;
  top: 0;
  margin-top: -100vh;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  opacity: ${({ visible }) => (visible ? "1" : "0")};
  transform: ${({ visible }) => (visible ? "translateY(0)" : "60px")};
  transition: opacity 0.5s ${Ease.out}, transform 0.5s ${Ease.out};
`;

export const Container = styled(motion.div)`
  position: relative;
  width: 100%;
`;

export const List = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  // border-left: 2px solid rgba(255, 255, 255, 0.5);
  margin: 0 40px;
`;

export const Item = styled(motion.button)`
  --padding: 22px;

  position: relative;
  color: white;
  cursor: pointer;

  & + & {
    margin-top: 40px;
  }
`;

export const Heading = styled.h3`
  position: relative;
  padding-left: var(--padding);
  text-align: left;
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 0;
`;

export const Progress = styled.span<{ duration?: number }>`
  position: absolute;
  left: -2px;
  height: 100%;
  background-color: ${Color.grayInactive};
  width: 2px;
  overflow: hidden;

  span {
    display: block;
    width: 100%;
    height: 100%;
    background-color: ${Color.white};
    transform: translateY(-101%);
    opacity: 0;
    animation: none;
  }

  &.active span {
    opacity: 1;
    animation: progress ${({ duration }) => duration && duration}ms linear;
  }

  @keyframes progress {
    0% {
      transform: translateY(-101%);
    }

    100% {
      transform: translateY(0%);
    }
  }
`;

export const HighlightBody = styled(motion.div)`
  padding-left: var(--padding);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -1%;
  max-width: 35ch;
  text-align: left;
  height: 0;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 0;
`;
