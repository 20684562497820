import styled from "styled-components";
import { rem } from "@styles/helpers";
import { Heading4CSS } from "@styles/typography";
import { RatioOptions } from "../TimedTabs.model";
import { Device } from "@styles/constants";

export const MobileCarousel = styled.div`
  ${({ theme: { media_ratio } }) =>
    media_ratio === RatioOptions.FOUR_TO_FIVE &&
    `
      @media ${Device.medium} {
        max-width: ${rem(500)};
        margin: 0 auto;
      }
  `}
`;

export const MobileSlide = styled.div<{ active?: boolean }>`
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: opacity 0.25s linear;
`;

export const PaginationContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin: ${rem(32)} 0 ${rem(22)};
  column-gap: ${rem(12)};
  z-index: var(--top-content-index);
`;

export const PaginationButton = styled.button<{ $selected: boolean }>`
  position: relative;
  width: ${rem(16)};
  height: ${rem(16)};
  background: radial-gradient(
    at center,
    ${({ $selected }) => ($selected ? "var(--foreground)" : "var(--inactive-button)")},
    ${({ $selected }) => ($selected ? "var(--foreground)" : "var(--inactive-button)")} 4px,
    transparent 4.5px,
    transparent
  );
  z-index: var(--top-content-index);
`;

export const TabPanelContainer = styled.div`
  text-align: center;
`;

export const MobileTabHeading = styled.h3`
  ${Heading4CSS}
  margin-bottom: ${rem(10)};
`;

export const MobileCopy = styled.div`
  ${({ theme: { media_ratio } }) =>
    media_ratio === RatioOptions.FOUR_TO_FIVE &&
    `
      width: 75vw;
      margin: 0 auto;
      
      @media ${Device.medium} {
        max-width: ${rem(450)};
      }
    `}
`;
