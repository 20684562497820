import { CloudinaryAsset, CLOUDINARY_ASSET_PROJECTION } from "./cloudinary-asset.model";
import { client } from "@utils/sanity-client";
import { Sanity } from "@models/sanity.model";
import { localized } from "@utils/groq";
import createPaths from "@utils/next/createPaths";

export const ClassType = "workout";

export interface Class extends Sanity.Page {
  body: Sanity.PortableText;
  hero_media: CloudinaryAsset[];
  accordian: Sanity.Accordion;
  workout_clip: CloudinaryAsset;
  lifestyle_clip: CloudinaryAsset;
  routing_media: CloudinaryAsset;
  thumbnail_image: CloudinaryAsset;
}

export const CLASS_PROJECTION = `{
  _id,
  _type,
  seo,
  slug,
  title,
  body,
  tags,
  accordian -> {
    _id,
    sections[] {
      _key,
      title
    }
  },
  lifestyle_clip ${CLOUDINARY_ASSET_PROJECTION},
  workout_clip ${CLOUDINARY_ASSET_PROJECTION},
  routing_media ${CLOUDINARY_ASSET_PROJECTION},
  hero_media[] ${CLOUDINARY_ASSET_PROJECTION},
  thumbnail_image ${CLOUDINARY_ASSET_PROJECTION}
}`;

export const getClassesPaths = async () => {
  const pages = await getClasses();
  return createPaths(pages);
};

export const getClasses = async (): Promise<Class[]> => {
  return await client.fetch(`*[_type == '${ClassType}' && ${localized}] ${CLASS_PROJECTION}`);
};

export const getClass = async (slug: string): Promise<Class> => {
  return await client.fetch(
    `*[_type == '${ClassType}' && ${localized} && slug.current == $slug][0] ${CLASS_PROJECTION}`,
    { slug }
  );
};
