import styled from "styled-components";
import { rem } from "@styles/helpers";
import { Color, Device } from "@styles/constants";
import { SmallBodyMediumCSS } from "@styles/typography";

export const CompareTable = styled.table`
  --radius: 10px;
  --headline-padding-top: ${rem(70)};
  --grid-columns: 1.98fr minmax(${rem(250)}, 1fr) minmax(${rem(250)}, 1fr);
  margin-bottom: 0;

  @media ${Device.mediumDown} {
    display: none;
  }

  tr {
    display: grid;
    grid-template-columns: var(--grid-columns);
  }

  th {
    text-align: left;
    font-weight: normal;
  }

  thead {
    background-color: transparent;
    border: 0;

    tr {
      background-color: transparent;
    }
  }
`;

export const TableCell = styled.td`
  --border: 1px solid ${Color.goldAsh};
  border-right: var(--border);
  display: flex;
  justify-content: center;
  align-items: center;

  &:first-of-type {
    background-color: ${Color.blueAsh};
    border-left: var(--border);
  }

  p {
    margin-bottom: 0;
    max-width: 80%;
    text-align: center;
  }
`;

export const Section = styled.tbody`
  background-color: transparent;
  border: 0;

  &:first-of-type {
    --headline-padding-top: 0;
  }

  &:last-of-type {
    tr:last-of-type {
      ${TableCell} {
        border-bottom: 1px solid ${Color.goldAsh};

        &:first-of-type {
          border-radius: 0 0 0 var(--radius);
        }

        &:last-of-type {
          border-radius: 0 0 var(--radius) 0;
        }
      }
    }
  }
`;

export const SectionHeadline = styled.th`
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: var(--grid-columns);
  padding: 0;

  h2,
  &::before,
  &::after {
    padding: var(--headline-padding-top) 0 ${rem(30)};
  }

  &::before,
  &::after {
    content: "";
    position: relative;
    border-right: 1px solid ${Color.goldAsh};
  }

  &::before {
    order: 2;
    border-left: 1px solid ${Color.goldAsh};
    background-color: ${Color.blueAsh};
  }

  &::after {
    order: 3;
  }
`;

export const CompareListItem = styled.tr`
  && {
    background: none;
  }

  &:not(:first-of-type) {
    border-top: 1px solid ${Color.goldAsh};
  }
`;

export const ItemHeadline = styled.th`
  ${SmallBodyMediumCSS}
  padding: ${rem(17)} 0;
  display: grid;
  grid-template-columns: ${rem(24)} 1fr;
  grid-gap: ${rem(5)} ${rem(10)};
  align-items: center;
  max-width: ${rem(410)};
  padding-right: ${rem(10)};
`;

export const PlanDetails = styled.th`
  padding: 0;
  border: 1px solid ${Color.goldAsh};
  border-bottom: none;

  &:first-of-type {
    background-color: ${Color.blueAsh};
    border-radius: var(--radius) 0 0 0;
    grid-column: 2;
  }

  &:last-of-type {
    border-radius: 0 var(--radius) 0 0;
    grid-column: 3;
    border-left: none;
  }
`;
