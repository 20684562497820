import * as React from "react";
import AspectRatio from "@components/primitives/aspect-ratio";
import Image from "@components/primitives/image";
import Button from "@components/ui/button";
import * as Styled from "./PurchaseBlock.styled";
import { PurchaseBlockSchema } from "./PurchaseBlock.model";
import PortableText from "@components/portable-text";

export interface PurchaseBlockProps {
  sanity: PurchaseBlockSchema;
}

const PurchaseBlock = ({ sanity: { eyebrow, heading, body, cta, image } }: PurchaseBlockProps) => (
  <div className="grid-container">
    <Styled.ItemGrid>
      {image?.url && (
        <Styled.ImageContainer className="small-12 large-4">
          <AspectRatio ratio={378 / 491}>
            <Image asset={image} widthOnScreen={[100, 40]} loading="lazy" alt="" />
          </AspectRatio>
        </Styled.ImageContainer>
      )}
      <Styled.TextContainer className="small-12 large-7">
        <Styled.Header>
          {eyebrow && <Styled.Eyebrow>{eyebrow}</Styled.Eyebrow>}
          {heading && <Styled.Heading>{heading}</Styled.Heading>}
        </Styled.Header>
        {body && (
          <Styled.PortableTextWrapper>
            <PortableText value={body} />
          </Styled.PortableTextWrapper>
        )}
        {cta?.url && (
          <div>
            <Button text={cta.text} href={cta.url} />
          </div>
        )}
      </Styled.TextContainer>
    </Styled.ItemGrid>
  </div>
);

export default PurchaseBlock;
