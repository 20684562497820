import styled from "styled-components";
import { Color, Device } from "@styles/constants";
import {
  EyebrowCSS,
  PortableTextWrapper,
  SmallBodyMediumCSS,
  XSmallBodyRegularCSS,
} from "@styles/typography";

export const Card = styled.article`
  --inline-padding: 1.5rem;
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid ${Color.grayBorder};

  @supports (grid-template-rows: subgrid) {
    display: grid;
    grid-template-rows: subgrid;
    grid-row: span 5;
    row-gap: 0;
  }
`;

export const Figure = styled.figure`
  display: block;
  text-align: center;
`;

export const Caption = styled.h3`
  ${EyebrowCSS}
  margin: 2rem 0;
  padding: 0 var(--inline-padding);
  color: ${Color.blackBrand};

  @media ${Device.mediumUp} {
    margin: 1.875rem 0;
  }
`;

export const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0 var(--inline-padding) 1.5rem;

  @supports (grid-template-rows: subgrid) {
    display: grid;
    grid-template-rows: subgrid;
    grid-row: span 4;
    row-gap: 0;
  }
`;

export const ListItem = styled.li`
  padding: 1.5rem 0;
  border-top: 1px solid ${Color.grayBorder};

  &:last-of-type {
    padding-bottom: 0;
  }
`;

export const ItemLiner = styled.div`
  display: grid;
  grid-template-columns: 1.5rem 1fr;
  gap: 0.25rem 1rem;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
`;

export const ItemHeading = styled.h4`
  ${SmallBodyMediumCSS}
  margin-bottom: 0;
  text-align: initial;
`;

export const ItemCopy = styled(PortableTextWrapper)`
  grid-column: 2;
  margin: 0;

  p {
    ${XSmallBodyRegularCSS}
  }
`;
