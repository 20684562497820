import { CLOUDINARY_ASSET_PROJECTION } from "@models/cloudinary-asset.model";
import { Instructor } from "@models/instructor.model";
import { Partnership } from "@models/partnership.model";
import { Sanity } from "@models/sanity.model";

export const CardCarouselType = "card_carousel";

export type CarouselCardType = Instructor | Partnership;

export interface CardCarouselSchema extends Sanity.Document {
  eyebrow?: string;
  heading: string;
  copy?: string;
  cta: Sanity.Cta;
  cards: Array<CarouselCardType>;
  anchor_link?: string;
}

export const CARD_CAROUSEL_PROJECTION = `{
  eyebrow,
  heading,
  copy,
  cta,
  cards[]->{
    ...,
    routing_media ${CLOUDINARY_ASSET_PROJECTION},
    hero_media[] ${CLOUDINARY_ASSET_PROJECTION},
  },
  anchor_link
}`;
