import { CloudinaryAsset, CLOUDINARY_ASSET_PROJECTION } from "@models/cloudinary-asset.model";
import { Sanity } from "@models/sanity.model";

export const JumbotronType = "jumbotron";

export interface JumbotronSchema extends Sanity.Document {
  eyebrow?: string;
  heading: string;
  cta: Sanity.Cta;
  body?: string;
  background_image?: CloudinaryAsset;
  foreground_image?: CloudinaryAsset;
  foreground_video?: CloudinaryAsset;
}

export const JUMBOTRON_PROJECTION = `{
  eyebrow,
  heading,
  cta,
  body,
  background_image ${CLOUDINARY_ASSET_PROJECTION},
  foreground_image ${CLOUDINARY_ASSET_PROJECTION},
  foreground_video ${CLOUDINARY_ASSET_PROJECTION},
}`;
