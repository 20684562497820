import Link from "next/link";
import AspectRatio from "@components/primitives/aspect-ratio";
import Image from "@components/primitives/image";
import { Sanity } from "@models/sanity.model";
import { CloudinaryAsset } from "@models/cloudinary-asset.model";
import { GalleryCardProps } from "../Gallery.model";
import { partnerRoute } from "@utils/routes";
import * as Styled from "../Gallery.styled";

interface PartnerCardProps extends GalleryCardProps {
  routing_media: CloudinaryAsset;
  slug: Sanity.Slug;
}

const PartnerCard = ({
  type,
  routing_media,
  slug,
  createItem,
  idx,
  isBottom,
  bottomItems,
}: PartnerCardProps) => (
  <Styled.GalleryItem
    ref={(el: HTMLElement) =>
      createItem(el, isBottom ? idx + bottomItems.current.length : idx, isBottom)
    }
    $type={type}>
    <Link
      href={{
        pathname: partnerRoute,
        query: { slug: slug?.current },
      }}></Link>
    <AspectRatio ratio={0.8}>
      {routing_media?.url && (
        <Image
          asset={routing_media}
          alt={routing_media?.alt}
          widthOnScreen={50}
          draggable={false}
        />
      )}
    </AspectRatio>
  </Styled.GalleryItem>
);

export default PartnerCard;
