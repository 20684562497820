import { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { Device } from "@styles/constants";
import { useInView } from "react-intersection-observer";
import { Sanity } from "@models/sanity.model";
import useMediaQuery from "@hooks/useMediaQuery";
import Button from "@components/ui/button";
import CarouselTabs from "./carousel-tabs";
import Tabs from "./tabs";
import { TabType, TimedTabsSchema, RatioOptions } from "./TimedTabs.model";
import * as Styled from "./TimedTabs.styled";

export interface TimedTabsProps {
  sanity: TimedTabsSchema;
}

export enum TimerStatus {
  INITIAL = "INITIAL",
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}

export type UserInteractionDispatch = (index: number) => void;
const onUserInteraction = (
  index: number,
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>,
  timerStatus: TimerStatus,
  setTimerStatus: React.Dispatch<React.SetStateAction<TimerStatus>>
) => {
  setActiveIndex(index);
  if (timerStatus) {
    setTimerStatus(TimerStatus.DISABLED);
  }
};

export type TimerInteractionDispatch = () => void;
const onTimerInteraction = (
  activeIndex: number,
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>,
  tabs: TabType[]
) => {
  const next = activeIndex + 1 === tabs.length ? 0 : activeIndex + 1;
  setActiveIndex(next);
};

const TimedTabs = ({ sanity }: TimedTabsProps) => {
  const { theme, eyebrow, heading, copy, tabs, animation_duration, anchor_link, cta } = sanity;
  const carouselFormat = useMediaQuery(Device.mediumDown);
  const [activeIndex, setActiveIndex] = useState(0);
  const [timerStatus, setTimerStatus] = useState(TimerStatus.INITIAL);
  const { ref, inView } = useInView();
  const themeConfig = {
    theme_style: theme?.theme_style || Sanity.ThemeColor.LIGHT,
    headline_size: theme?.headline_size || Sanity.HeadlineSize.H1,
    content_position: theme?.content_position || Sanity.HeadlinePosition.LEFT,
    media_ratio: theme?.media_ratio || RatioOptions.ONE_TO_ONE,
  };
  const infoBlockFields = {
    eyebrow,
    headline: heading,
    headline_size: themeConfig.headline_size,
    copy,
  };

  const hasInfo = eyebrow || heading || copy;

  const ratioArr = themeConfig.media_ratio.split(":");
  const ratioObj = {
    width: parseInt(ratioArr[0]),
    height: parseInt(ratioArr[1]),
  };

  useEffect(() => {
    if (timerStatus === TimerStatus.INITIAL && inView) {
      setTimerStatus(TimerStatus.ENABLED);
    }
  }, [inView]);

  const drilledProps = {
    dark: themeConfig.theme_style === Sanity.ThemeColor.DARK,
    ratio: ratioObj,
    tabs,
    animation_duration,
    activeIndex,
    setActiveIndex,
    timerStatus,
    setTimerStatus,
    onUserInteraction: (index: number) =>
      onUserInteraction(index, setActiveIndex, timerStatus, setTimerStatus),
    onTimerInteraction: () => onTimerInteraction(activeIndex, setActiveIndex, tabs),
  };

  return (
    <ThemeProvider theme={themeConfig}>
      <Styled.Section id={anchor_link} ref={ref}>
        {hasInfo && (
          <Styled.InfoBlock {...infoBlockFields} headline_size={themeConfig.headline_size} />
        )}
        {carouselFormat ? <CarouselTabs {...drilledProps} /> : <Tabs {...drilledProps} />}
        {cta?.url && (
          <Styled.ButtonWrapper className="grid-container">
            <Button text={cta.text} href={cta.url} dark={drilledProps.dark} />
          </Styled.ButtonWrapper>
        )}
      </Styled.Section>
    </ThemeProvider>
  );
};

export default TimedTabs;
