import { CloudinaryAsset, CLOUDINARY_ASSET_PROJECTION } from "@models/cloudinary-asset.model";
import { Sanity } from "@models/sanity.model";

export const HeroFeaturedType = "hero_featured";

export interface HeroFeaturedSchema extends Sanity.Document {
  eyebrow: string;
  heading: string;
  cta_text: string;
  background_video: CloudinaryAsset;
  images: CloudinaryAsset[];
  video_button_text: string;
  video: string;
  video_hash: string;
}

export const HERO_FEATURED_PROJECTION = `{
  eyebrow,
  heading,
  cta_text,
  background_video ${CLOUDINARY_ASSET_PROJECTION},
  images[] ${CLOUDINARY_ASSET_PROJECTION},
  video_button_text,
  video,
  video_hash
}`;
