import AspectRatio from "@components/primitives/aspect-ratio";
import Image from "@components/primitives/image";
import * as Styled from "./HeroSlim.styled";
import { HeroSlimSchema } from "./HeroSlim.model";

export interface HeroSlimProps {
  sanity: HeroSlimSchema;
}

const HeroSlim: React.FC<HeroSlimProps> = ({ sanity }) => {
  const { heading, background_image } = sanity;

  return (
    <Styled.Background>
      <Styled.Image>
        <Image asset={background_image} widthOnScreen={[100]} alt={background_image.alt} />
      </Styled.Image>
      {heading && <Styled.Heading>{heading}</Styled.Heading>}
    </Styled.Background>
  );
};

export default HeroSlim;
