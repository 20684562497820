import { CloudinaryAsset, CLOUDINARY_ASSET_PROJECTION } from "@models/cloudinary-asset.model";
import { Sanity } from "@models/sanity.model";

export const InfoBlockType = "info_block";

export interface InfoBlockSchema extends Partial<Sanity.Document> {
  text_alignment?: Sanity.HeadlinePosition;
  eyebrow?: string;
  headline: string;
  headline_size?: Sanity.HeadlineSize;
  subheadline?: string;
  cta?: Sanity.Cta;
  background_image?: CloudinaryAsset;
  background_image_mobile?: CloudinaryAsset;
  background_video?: CloudinaryAsset;
  foreground_image?: CloudinaryAsset;
  foreground_image_mobile?: CloudinaryAsset;
  foreground_video?: CloudinaryAsset;
}

export interface ContentProps {
  className?: string;
  text_alignment?: Sanity.HeadlinePosition;
  eyebrow?: string;
  headline?: string;
  headline_size?: Sanity.HeadlineSize;
  subheadline?: string;
  copy?: Sanity.PortableText;
  cta?: Sanity.Cta;
}

export interface ContainedProps extends ContentProps {
  children?: Array<React.ReactNode>;
}

export interface FullWidthProps extends ContentProps {
  children?: React.ReactNode;
}

export const INFO_BLOCK_PROJECTION = `{
  text_alignment,
  eyebrow,
  headline,
  headline_size,
  subheadline,
  cta,
  background_image ${CLOUDINARY_ASSET_PROJECTION},
  background_image_mobile ${CLOUDINARY_ASSET_PROJECTION},
  background_video ${CLOUDINARY_ASSET_PROJECTION},
  foreground_image ${CLOUDINARY_ASSET_PROJECTION},
  foreground_image_mobile ${CLOUDINARY_ASSET_PROJECTION},
  foreground_video ${CLOUDINARY_ASSET_PROJECTION},
  anchor_label,
  anchor_link
}`;
