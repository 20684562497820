import { Sanity } from "@models/sanity.model";

export const FootnotesType = "footnotes";

export interface FootnotesSchema extends Sanity.Document {
  theme_style?: Sanity.ThemeColor;
  footnotes?: Array<{
    reference_mark: string;
    copy: Sanity.PortableText;
  }>;
}

export const FOOTNOTES_PROJECTION = `{
  theme_style,
  footnotes[] {
    title,
    reference_mark,
    copy
  }
}`;
