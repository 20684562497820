import styled from "styled-components";
import { rem } from "@styles/helpers";
import { Device } from "@styles/constants";
import { Sanity } from "@models/sanity.model";
import { Heading1CSS, Heading3CSS, Heading2CSS, SmallBodyRegularCSS } from "@styles/typography";
import { ImageTileTheme } from "@components/specs-cells";

export const ContentContainer = styled.div<{ alignment?: Sanity.HeadlinePosition }>`
  display: flex;
  flex-direction: column;
  ${({ alignment, theme: { heading_position } }) => {
    const align = heading_position || alignment;

    return `
      align-items: ${align === Sanity.HeadlinePosition.CENTER ? "center" : "flex-start"};
      text-align: ${align};
    `;
  }}
`;

interface HeadlineProps {
  alignment?: Sanity.HeadlinePosition;
  headlineSize?: Sanity.HeadlineSize;
  theme?: ImageTileTheme;
}

export const Headline = styled.h2<HeadlineProps>`
  ${({ headlineSize, theme: { heading_size } }) => {
    const size = heading_size || headlineSize;
    if (size === Sanity.HeadlineSize.H1) return Heading1CSS;
    if (size === Sanity.HeadlineSize.H3) return Heading3CSS;
    return Heading2CSS;
  }}
`;

export const Subheadline = styled.p`
  ${SmallBodyRegularCSS};
  margin-top: ${rem(16)};
  margin-bottom: 0;
  max-width: ${rem(780)};
`;

export const Copy = styled.div`
  margin-top: ${rem(16)};
  max-width: ${rem(580)};

  && {
    ${SmallBodyRegularCSS}
  }
`;

export const Cta = styled.div`
  margin-top: ${rem(40)};
`;

export const Children = styled.div`
  &:not(:empty) {
    margin-top: ${rem(40)};

    @media ${Device.mediumUp} {
      margin-top: ${rem(60)};
    }
  }
`;

export const FullWidthGrid = styled.div`
  padding: 0;
  max-width: 100%;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  padding-left: 0;
  padding-right: 0;
`;
