import * as React from "react";
import { Color } from "@styles/constants";
import Jumbotron from "@components/jumbotron/Jumbotron";
import { ProgramBlocksSchema } from "./ProgramBlocks.model";
import { ProgramGrid, JumbotronText } from "./ProgramBlocks.styled";
import ProgramBlock from "./ProgramBlock";

interface ProgramBlockGridProps {
  sanity: ProgramBlocksSchema;
}

const ProgramBlockGrid = ({ sanity }: ProgramBlockGridProps) => (
  <>
    <Jumbotron
      id="programs"
      title={sanity.heading}
      eyebrow={sanity.eyebrow}
      cta={sanity.cta}
      background="transparent"
      margin="0"
      padding="0"
      color={Color.blackBrand}>
      <JumbotronText color={Color.blackBrand}>{sanity.body}</JumbotronText>
    </Jumbotron>

    <ProgramGrid className="grid-container">
      {sanity.programs?.length > 0 &&
        sanity.programs.map(
          ({ icon, eyebrow, heading, blurb, background_color, background_image }, idx: number) => (
            <ProgramBlock
              key={idx}
              background={background_color}
              icon={icon}
              eyebrow={eyebrow}
              title={heading}
              caption={blurb}
              image={background_image}
            />
          )
        )}
    </ProgramGrid>
  </>
);

export default ProgramBlockGrid;
