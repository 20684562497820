import { useEffect, useRef, useState } from "react";
import { useScroll, useTransform } from "framer-motion";
import AspectRatio from "@components/primitives/aspect-ratio";
import Button from "@components/ui/button";
import Image from "@components/primitives/image";
import useMediaQuery from "@hooks/useMediaQuery";
import useWindowSize from "@hooks/useWindowSize";
import { useSiteState } from "@hooks/useSiteState";
import { Device } from "@styles/constants";
import getRootOffset from "@utils/getRootOffset";
import * as Styled from "./FullBleedBanner.styled";
import { FullBleedBannerSchema } from "./FullBleedBanner.model";
import { FadeIn, SlideInText } from "@components/ui/animations";
import { ComponentResolverAnimationProps } from "@components/component-resolver";

const PARALLAX_OFFSET = 150;

// TODO: Make a parallax component to share with full bleed components
export interface FullBleedBannerProps {
  sanity: FullBleedBannerSchema;
}

const FullBleedBanner = ({
  inView,
  sanity,
}: FullBleedBannerProps & ComponentResolverAnimationProps) => {
  const { heading, image, primary_cta, secondary_cta } = sanity;

  const containerRef = useRef(null);

  const isDesktop = useMediaQuery(Device.mediumUp);
  const windowSize = useWindowSize();
  const { scrollY } = useScroll();
  const { isReady } = useSiteState();

  const [position, setPosition] = useState({ start: 0, end: 0 });

  const parallaxY = useTransform(
    scrollY,
    [position.start, position.end],
    [-PARALLAX_OFFSET, PARALLAX_OFFSET]
  );

  useEffect(() => {
    if (!containerRef.current || !isDesktop) return;

    setTimeout(() => {
      const bounds = getRootOffset(containerRef.current);
      setPosition({
        start: bounds.top - windowSize.height,
        end: bounds.top + containerRef.current.offsetHeight,
      });
    }, 0);
  }, [isReady, windowSize]);

  /**
   * Render
   */

  return (
    <Styled.FullBleedBanner>
      <Styled.Container ref={containerRef}>
        <FadeIn visible={inView}>
          {isDesktop ? (
            <AspectRatio ratio={16 / 9}>
              <Styled.ParallaxImage
                offset={PARALLAX_OFFSET}
                style={{ y: isDesktop ? parallaxY : 0 }}>
                {image?.url && <img src={image.url} alt={heading} loading="lazy" />}
              </Styled.ParallaxImage>
            </AspectRatio>
          ) : (
            <Styled.MobileImage>
              <Image asset={image} widthOnScreen={[100]} alt={heading} />
            </Styled.MobileImage>
          )}
        </FadeIn>

        <Styled.Content>
          <div className="grid-container">
            {heading && (
              <Styled.Heading>
                <SlideInText visible={inView} center delay={0.5}>
                  {heading}
                </SlideInText>
              </Styled.Heading>
            )}
            <FadeIn visible={inView} delay={1}>
              <Styled.Buttons>
                {primary_cta?.text && (
                  <Button text={primary_cta.text} href={primary_cta.url} dark />
                )}

                {secondary_cta?.text && (
                  <Button text={secondary_cta.text} href={secondary_cta.url} dark invert />
                )}
              </Styled.Buttons>
            </FadeIn>
          </div>
        </Styled.Content>
      </Styled.Container>
    </Styled.FullBleedBanner>
  );
};

export default FullBleedBanner;
