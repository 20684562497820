import { Device } from "@styles/constants";
import { rem } from "@styles/helpers";
import styled from "styled-components";

export const Container = styled.div<{ refLoaded: boolean }>`
  position: relative;
  justify-self: center;
  align-self: center;
  height: calc(530 / 725 * 100%);
  width: calc(270 / 580 * 100%);
  max-width: ${rem(270)};
  max-height: ${rem(530)};
  opacity: ${({ refLoaded }) => (refLoaded ? 1 : 0)};
  transition: opacity 0.2s linear;
`;

export const CenteredDevice = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  .cloudinary-format {
    overflow: hidden;
    position: absolute;
    left: 0;
    height: 100%;
    border-radius: 1.5rem;
    padding: 5% 8%;
    object-fit: contain;

    @media ${Device.mediumUp} {
      border-radius: 3rem;
    }
  }
`;

export const Bezel = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
