import * as React from "react";
import ProgressIndicator from "../progress-indicator/ProgressIndicator";
import { TimerInteractionDispatch, TimerStatus, UserInteractionDispatch } from "../TimedTabs";
import { PaginationButton as StyledButton } from "../carousel-tabs/CarouselTabs.styled";

export interface PaginationButtonProps {
  selected: boolean;
  duration: number;
  timerStatus: TimerStatus;
  onClick: UserInteractionDispatch;
  onTimerInteraction?: TimerInteractionDispatch;
}

const PaginationButton = ({
  selected,
  duration,
  timerStatus,
  onClick,
  onTimerInteraction,
}: PaginationButtonProps) => (
  <StyledButton
    type="button"
    $selected={selected}
    aria-selected={selected}
    onClick={() => onClick(0)}>
    {selected && (
      <ProgressIndicator
        duration={duration}
        timerStatus={timerStatus}
        onTimerInteraction={onTimerInteraction}
        radius={8}
        strokeWidth={1}
      />
    )}
  </StyledButton>
);

export default PaginationButton;
