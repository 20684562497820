import { CloudinaryAsset, CLOUDINARY_ASSET_PROJECTION } from "@models/cloudinary-asset.model";
import { Sanity } from "@models/sanity.model";

export const IntroType = "intro";

export enum HeadingSize {
  H2 = "h2",
  H3 = "h3",
}

export interface IntroSchema extends Sanity.Document {
  eyebrow?: string;
  eyebrow_image?: CloudinaryAsset;
  heading_size?: HeadingSize;
  heading: string;
  cta_dark: Sanity.Cta;
  cta_light: Sanity.Cta;
  video_button_text?: string;
  video_poster?: CloudinaryAsset;
  video: string;
  video_hash: string;
}

export const INTRO_PROJECTION = `{
  eyebrow,
  eyebrow_image ${CLOUDINARY_ASSET_PROJECTION},
  heading_size,
  heading,
  cta_dark,
  cta_light,
  video_button_text,
  video_poster ${CLOUDINARY_ASSET_PROJECTION},
  video,
  video_hash
}`;
