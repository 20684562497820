import { useRef, useEffect } from "react";
import { AnimationPlaybackControls, animate } from "framer-motion";
import { StyledCounter } from "./Counter.styled";

export interface CounterProps {
  from: number;
  to: number;
  shouldAnimate: boolean;
}

const Counter = ({ from, to, shouldAnimate }: CounterProps) => {
  const ref = useRef<HTMLDivElement | undefined>();

  useEffect(() => {
    if (!ref.current) return;

    let controls: AnimationPlaybackControls;

    if (shouldAnimate) {
      controls = animate(from, to, {
        duration: 1,
        onUpdate(value) {
          ref.current.textContent = String(Math.floor(value));
        },
      });
    } else {
      ref.current.textContent = String(Math.floor(from));
    }

    return () => {
      if (controls) controls.stop();
    };
  }, [shouldAnimate]);

  return <StyledCounter ref={ref}>{from}</StyledCounter>;
};

export default Counter;
