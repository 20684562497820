import * as React from "react";
import { LargeBodyRegular } from "@styles/typography";
import * as Styled from "./HeadingGrid.styled";
import { HeadingGridSchema } from "./HeadingGrid.model";

export interface HeadingGridProps {
  sanity: HeadingGridSchema;
}

const HeadingGrid = ({ sanity }: HeadingGridProps) => {
  const { headings } = sanity || {};

  return (
    <div className="grid-container">
      {headings.length > 0 && (
        <Styled.List>
          {headings.map((item) => (
            <Styled.ListItem key={item._key}>
              <Styled.Heading>{item.heading}</Styled.Heading>
              <LargeBodyRegular>{item.body}</LargeBodyRegular>
            </Styled.ListItem>
          ))}
        </Styled.List>
      )}
    </div>
  );
};

export default HeadingGrid;
