import Button from "@components/ui/button";
import { AccessoriesLandingHeroSchema } from "./LandingHero.model";
import * as Styled from "./LandingHero.styled";

export interface AccessoriesLandingHeroProps {
  sanity: AccessoriesLandingHeroSchema;
}

const AccessoriesLandingHero = ({ sanity }: AccessoriesLandingHeroProps) => {
  const { heading, body, cta, background_image } = sanity;

  return (
    <Styled.HeroContainer>
      <Styled.HeroTextContainer>
        <Styled.Title>{heading}</Styled.Title>
        {body && <Styled.SubTitle>{body}</Styled.SubTitle>}
        {cta?.url && (
          <Styled.ButtonContainer>
            <Button text={cta.text} href={cta.url} />
          </Styled.ButtonContainer>
        )}
      </Styled.HeroTextContainer>
      {background_image?.url && <Styled.BgImage url={background_image.url} />}
    </Styled.HeroContainer>
  );
};

export default AccessoriesLandingHero;
