import styled from "styled-components";
import { Color, Device } from "@styles/constants";
import { rem } from "@styles/helpers";

export const Cell = styled.div`
  margin-bottom: ${rem(32)};
`;

export const Content = styled.div`
  padding: ${rem(32)};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  min-height: 100%;
  display: grid;
`;

export const Header = styled.div`
  position: relative;
  padding-bottom: ${rem(16)};
  margin-bottom: ${rem(16)};

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 75%;
    border-bottom: 1px solid ${Color.blackBrand};
  }

  h4 {
    margin-bottom: ${rem(8)};

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const AddressPhone = styled.div`
  margin-bottom: ${rem(16)};

  address,
  p {
    font-size: ${rem(16)};
    margin-bottom: ${rem(5)};

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const Hours = styled.div`
  font-size: ${rem(16)};
`;

export const ButtonContainer = styled.div`
  width: 100%;
  margin-top: ${rem(30)};
`;
