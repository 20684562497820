import { Heading4, SmallBodyRegular } from "@styles/typography";
import { ReactComponent as InfoIcon } from "@images/information-circle.svg";
import { ReactComponent as CheckSVG } from "@images/checkmark_compare.svg";
import Image from "@components/primitives/image";
import ComparePlan from "../compare-plan";
import * as GlobalCta from "../GlobalCta.styled";
import { CompareProps } from "../GlobalCta.model";
import * as Styled from "./CompareTable.styled";

const CompareTable = ({ compare_headline, compare_plans, compare_items }: CompareProps) => {
  return (
    <Styled.CompareTable aria-labelledby="compareHeadline">
      <thead>
        <tr>
          <Styled.TableHeadline scope="column">
            {compare_headline && (
              <Heading4 as="h2" id="compareHeadline">
                {compare_headline}
              </Heading4>
            )}
          </Styled.TableHeadline>
          {compare_plans.map((plan, index) => (
            <Styled.PlanDetails key={index} scope="col">
              <ComparePlan plan={plan} />
            </Styled.PlanDetails>
          ))}
        </tr>
      </thead>
      <tbody>
        {compare_items.map((item, index) => {
          const plans = [item.plans.plan_digital, item.plans.plan_hardware];

          return (
            <Styled.CompareListItem key={index}>
              <Styled.ItemHeadline scope="row">
                {item.icon?.url && (
                  <GlobalCta.Icon>
                    <Image
                      asset={item.icon}
                      alt={item.icon.alt}
                      widthOnScreen={[20]}
                      aria-hidden="true"
                    />
                  </GlobalCta.Icon>
                )}
                <span>{item.item_headline}</span>
                {item.info && (
                  <Styled.InfoWrap>
                    <Styled.InfoTrigger aria-label="View more info">
                      <InfoIcon />
                    </Styled.InfoTrigger>
                    <Styled.InfoModal>{item.info}</Styled.InfoModal>
                  </Styled.InfoWrap>
                )}
              </Styled.ItemHeadline>
              {plans.map((plan, index) => {
                return (
                  <Styled.TableCell key={index}>
                    {plan.compare_description ? (
                      <SmallBodyRegular>{plan.compare_description}</SmallBodyRegular>
                    ) : (
                      plan.compare_active && (
                        <GlobalCta.Checkmark>
                          <CheckSVG />
                        </GlobalCta.Checkmark>
                      )
                    )}
                  </Styled.TableCell>
                );
              })}
            </Styled.CompareListItem>
          );
        })}
      </tbody>
    </Styled.CompareTable>
  );
};

export default CompareTable;
