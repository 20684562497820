import styled from "styled-components";
import { Color, Device } from "@styles/constants";
import {
  Eyebrow,
  Heading3CSS,
  SmallBodyMedium,
  SmallBodyRegularCSS,
  XSmallBodyMediumCSS,
} from "@styles/typography";
import { AbsoluteCenterXCSS } from "@styles/layout";
import { rem } from "@styles/helpers";

export const ComparePlan = styled.div`
  position: relative;
  padding-top: ${rem(90)};

  @media ${Device.mediumDown} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: ${rem(60)};
  }
`;

export const ImageWrap = styled.div`
  max-width: ${rem(225)};
  width: 100%;
  margin: 0 auto;

  @media ${Device.largeUp} {
    ${AbsoluteCenterXCSS}
    top: ${rem(-125)};
    max-width: ${rem(160)};
    width: 90%;
    overflow: hidden;
  }
`;

export const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${Device.largeUp} {
    padding: 0 ${rem(15)} ${rem(16)};
  }

  @media ${Device.xlargeUp} {
    padding: 0 ${rem(38)} ${rem(16)};
  }
`;

export const PlanName = styled(Eyebrow)`
  margin-bottom: ${rem(10)};

  @media ${Device.mediumDown} {
    text-align: center;
    max-width: 60%;
    margin: 0 auto ${rem(10)};
  }
`;

export const Price = styled.span`
  ${Heading3CSS}
`;

export const Description = styled(SmallBodyMedium)`
  color: ${Color.grayBrand};
  text-align: center;
  margin: ${rem(10)} 0 0;

  @media ${Device.mediumDown} {
    ${SmallBodyRegularCSS}
    color: ${Color.blackBrand};
  }
`;

export const CtaWrap = styled.div`
  margin-top: ${rem(20)};
  width: 100%;

  a {
    @media ${Device.small} {
      ${XSmallBodyMediumCSS}
      padding: ${rem(15)};
    }

    @media ${Device.mediumUp} {
      display: block;
      text-align: center;
    }
  }
`;
