import Link from "next/link";
import AspectRatio from "@components/primitives/aspect-ratio";
import Image from "@components/primitives/image";
import { Partnership } from "@models/partnership.model";
import { partnerRoute } from "@utils/routes";

import * as Styled from "../CardCarousel.styled";

type PartnerSlideProps = {
  partner: Partnership;
};

const PartnerSlide = ({ partner }: PartnerSlideProps) => {
  return (
    <>
      <Link
        href={{
          pathname: partnerRoute,
          query: { slug: partner?.slug?.current },
        }}
        aria-label={partner?.title}
      />
      {partner?.routing_media?.url && (
        <Styled.SlideImage>
          <AspectRatio ratio={4 / 5}>
            <Image
              asset={partner.routing_media}
              widthOnScreen={[100]}
              alt={partner.routing_media.alt}
            />
          </AspectRatio>
        </Styled.SlideImage>
      )}
      <Styled.SlideInfo>
        {partner?.title && <h3>{partner.title}</h3>}
        {partner?.quote && <p>{partner.quote}</p>}
      </Styled.SlideInfo>
    </>
  );
};

export default PartnerSlide;
