import { CloudinaryAsset, CLOUDINARY_ASSET_PROJECTION } from "@models/cloudinary-asset.model";
import { Sanity } from "@models/sanity.model";

export const ValuePropsType = "value_props";

export enum ValuePropsColumns {
  THREE = 3,
  FOUR = 4,
}

export interface ValueProp {
  title: string;
  content: Sanity.PortableText;
  modal_button_text?: string;
  modal_content?: Sanity.PortableText;
  image?: CloudinaryAsset;
}

export interface ValuePropsSchema extends Sanity.Document {
  [x: string]: any;
  theme?: {
    theme_style: Sanity.ThemeColor;
    mobile_accordion: boolean;
  };
  eyebrow?: string;
  heading?: string;
  props: Array<ValueProp>;
  cta?: Sanity.Cta;
}

export const VALUE_PROPS_PROJECTION = `{
  theme,
  eyebrow,
  heading,
  props[] {
    _key,
    image ${CLOUDINARY_ASSET_PROJECTION},
    title,
    content,
    modal_content,
    modal_button_text
  },
  cta
}`;
