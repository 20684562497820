import styled from "styled-components";

import { Device, Color, Font, Ease } from "@styles/constants";
import { BodyMediumCSS, EyebrowCSS } from "@styles/typography";
import { AbsoluteCoverCSS } from "@styles/layout";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;

  @media ${Device.largeUp} {
    max-height: 800px;
    height: calc(100vh - var(--header-height) - var(--banner-height));
    flex-wrap: nowrap;
  }
`;

export const Background = styled.div`
  ${AbsoluteCoverCSS}
  width: 100%;
  flex: 0 0 100%;
  background: ${Color.blackBrand};

  @media ${Device.mediumDown} {
    &::after {
      content: "";
      ${AbsoluteCoverCSS}
      background: ${Color.blackBrand};
      opacity: 0.3;
    }
  }

  img,
  video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media ${Device.largeUp} {
    position: relative;
    height: 100%;
    width: 70%;
    flex: 0 0 70%;
  }
`;

export const Topbar = styled.div`
  ${BodyMediumCSS}
  position: relative;
  width: 100%;
  color: ${Color.white};
  background: ${Color.blackBrand};
  z-index: 1;
  height: 60px;
  display: flex;
  align-items: center;

  .grid-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  svg {
    transform: ${({ open }: { open: boolean }) => `rotate(${open ? "-180deg" : "0deg"})`};
    transition: transform ${Ease.duration} ${Ease.out};
    transform-origin: center;
    width: 20px;
    height: 15px;
    margin-bottom: 5px;
    fill: ${Color.white};
  }
`;

export const ActiveFilter = styled.span`
  text-decoration: underline;
  color: ${Color.grayInactive};
`;

export const Sidebar = styled.div`
  position: relative;
  min-height: 547px;
  height: calc(100vh - var(--header-height) - var(--banner-height) - 60px);
  width: 100%;
  flex: 0 0 100%;
  z-index: 1;
  padding: 20px 0 0;
  overflow: hidden;

  .grid-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    height: 100%;
  }

  @media ${Device.largeUp} {
    min-height: auto;
    height: 100%;
    width: 30%;
    flex: 0 0 30%;
    background: ${Color.blackBrand};
    padding: 20px 60px 0;

    .grid-container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 175px;
    background: linear-gradient(180deg, #181818 18.54%, rgba(24, 24, 24, 0) 81.46%);
    transform: rotate(-180deg);
    pointer-events: none;
  }
`;

export const Filters = styled.div`
  display: ${({ open }: { open: boolean }) => (open ? "block" : "none")};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: ${Color.white};
  background: ${Color.blackBrand};
  border-top: 1px solid ${Color.grayBrand};
  padding-top: 30px;
  z-index: 1;

  .grid-container {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
  }

  @media ${Device.largeUp} {
    position: relative;
    margin: 60px 0 0;
    height: auto;
    background: transparent;
    border-top: 0;
    padding-top: 0;

    .grid-container {
      display: block;
      padding-left: 0;
      padding-right: 0;
      height: auto;
    }
  }
`;

export const FilterWrap = styled.div``;

export const FilterButtons = styled.div`
  margin-top: auto;
  margin-bottom: 40px;
  width: 100%;

  button {
    width: 100%;
    margin-left: 0 !important;

    @media ${Device.mediumUp} {
      width: auto;

      + button {
        margin-left: 20px !important;
      }
    }
  }
`;

export const Title = styled.span`
  ${EyebrowCSS}
`;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const CategoryList = styled(List)`
  margin-top: 20px;
  overflow-y: auto;
  width: 100%;

  @media ${Device.largeUp} {
    margin-top: 60px;
  }

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: ${Color.grayBrand};
    border: 1px solid ${Color.blackBrand};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${Color.white};
    border-radius: 20px;
    border: 3px solid ${Color.white};
    height: 230px;
  }
`;

export const Item = styled.li`
  &:last-child {
    padding-bottom: 150px;
  }

  > a {
    font-family: ${Font.semibold};
    font-size: 24px;
    line-height: 1.6;
    letter-spacing: -0.03em;
    color: ${Color.white};
    text-decoration: none;

    &:hover,
    &:focus-within {
      color: ${Color.white};
      text-decoration: underline;
    }
  }
`;
