import { CloudinaryAsset, CLOUDINARY_ASSET_PROJECTION } from "@models/cloudinary-asset.model";
import { Sanity } from "@models/sanity.model";

export const TrialEmailCaptureType = "trial_email_capture";

export interface TrialEmailCaptureSchema extends Sanity.Document {
  set_account_email?: {
    title?: string;
    heading?: string;
    body?: string;
    media?: CloudinaryAsset;
  };
  download_the_app?: {
    title?: string;
    heading?: string;
    body?: string;
    mobile_body?: string;
    app_store_url?: string;
    google_play_url?: string;
    onelink_url?: string;
    media?: CloudinaryAsset;
  };
}

export const TRIAL_EMAIL_CAPTURE_PROJECTION = `{
  set_account_email {
    title,
    heading,
    body,
    media ${CLOUDINARY_ASSET_PROJECTION},
  },
  download_the_app {
    title,
    heading,
    body,
    mobile_body,
    app_store_url,
    google_play_url,
    onelink_url,
    media ${CLOUDINARY_ASSET_PROJECTION},
  }
}`;
