import AccessoryCard from "../accessory-card";
import { AccessoriesSectionSchema } from "./Section.model";
import * as Styled from "./Section.styled";

export interface AccessoriesSectionProps {
  sanity: AccessoriesSectionSchema;
}

const AccessoriesSection = ({ sanity }: AccessoriesSectionProps) => {
  const { anchor_label, eyebrow, heading, accessories } = sanity;

  return (
    <div className="grid-container" id={anchor_label}>
      {eyebrow && <Styled.Eyebrow>{eyebrow}</Styled.Eyebrow>}
      <Styled.Heading>{heading}</Styled.Heading>

      {accessories?.length > 0 && (
        <Styled.CardsGrid>
          {accessories.map((accessory) => (
            <AccessoryCard key={accessory._id} productDocument={accessory} />
          ))}
        </Styled.CardsGrid>
      )}
    </div>
  );
};

export default AccessoriesSection;
