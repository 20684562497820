import { localized } from "@utils/groq";
import { client } from "@utils/sanity-client";
import { CloudinaryAsset, CLOUDINARY_ASSET_PROJECTION } from "./cloudinary-asset.model";
import { Sanity } from "@models/sanity.model";
import createPaths from "@utils/next/createPaths";

export const PartnershipType = "partnership";

export interface Partnership extends Sanity.Page {
  routing_media: CloudinaryAsset;
  hero_media: CloudinaryAsset[];
  quote: string;
  body: Sanity.PortableText;
  tag_eyebrow: string;
  tags: string[];
  social: Sanity.Social;
}

export const PARTNERSHIP_PROJECTION = `{
  _id,
  _type,
  seo,
  slug,
  title,
  body,
  quote,
  social,
  tag_eyebrow,
  tags,
  hero_media[] ${CLOUDINARY_ASSET_PROJECTION},
  routing_media ${CLOUDINARY_ASSET_PROJECTION}
}`;

export const getPartnershipsPaths = async () => {
  const pages = await getPartnerships();
  return createPaths(pages);
};

export const getPartnerships = async (): Promise<Partnership[]> => {
  return await client.fetch(
    `*[_type == '${PartnershipType}' && ${localized}] ${PARTNERSHIP_PROJECTION}`
  );
};

export const getPartnership = async (slug: string): Promise<Partnership> => {
  return await client.fetch(
    `*[_type == '${PartnershipType}' && ${localized} && slug.current == $slug][0] ${PARTNERSHIP_PROJECTION}`,
    { slug }
  );
};
