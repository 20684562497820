import { AccessoriesLandingHeroType } from "@components/accessories/landing-hero";
import { CatalogType } from "@components/catalog";
import { FootnotesType } from "@components/footnotes";
import { HeroFeaturedType } from "@components/hero-featured";
import { HeroFiftyType } from "@components/hero-fifty";
import { HeroPrimaryType } from "@components/hero-primary";
import { HeroSlimType } from "@components/hero-slim";
import { LifestyleTabsType } from "@components/lifestyle-tabs";
import { NewsletterType } from "@components/newsletter";
import { ProductHeroType } from "@components/product-hero";
import { ScrollType } from "@components/scroll";
import { TimedTabsType } from "@components/timed-tabs";
import { Sanity } from "@models/sanity.model";
import styled, { css } from "styled-components";
import { Color, Device, Spacing } from "./constants";
import { rem } from "./helpers";

/**
 * Modules that don't require section spacing
 */
export const exemptModules = [
  NewsletterType,
  FootnotesType,
  AccessoriesLandingHeroType,
  ScrollType,
  LifestyleTabsType,
  ProductHeroType,
  CatalogType,
];
export const topPaddingExemptModules = [
  HeroFeaturedType,
  HeroFiftyType,
  HeroPrimaryType,
  HeroSlimType,
];

export const exemptThemes = {
  [TimedTabsType]: [Sanity.ThemeColor.DARK],
};

export const SectionSpacing = styled.section<{
  context: Sanity.SectioningContext;
}>`
  ${({ context: { previousTheme, currentTheme, nextTheme, index, _type } }) => {
    let topCoefficient = 1;
    let bottomCoefficient = 1;
    const matchesPreviousTheme = previousTheme === currentTheme;
    const matchesNextTheme = nextTheme === currentTheme;
    const themeNotEqual = currentTheme !== nextTheme;
    /**
     * If the current module isn't the same theme as the one above it,
     * double its top padding.
     */
    if (!matchesPreviousTheme && previousTheme) topCoefficient = 2;
    /**
     * If the current module isn't the same theme as the one below it,
     * double its bottom padding.
     */
    if (!matchesNextTheme) bottomCoefficient = 2;
    /**
     * If the module or the current theme has been marked as exempt from section spacing,
     * set its top and bottom padding to 0.
     */
    if (exemptModules.includes(_type) || exemptThemes[_type]?.includes(currentTheme)) {
      topCoefficient = 0;
      bottomCoefficient = 0;
    }
    /**
     * If the hero module nextTheme equal to dark or vice versa,
     * set its bottom padding to 0.
     */
    if (index === 0 && themeNotEqual) {
      bottomCoefficient = 0;
    }
    /**
     * Apply dark background color to the entire section including it's top and bottom spacing, if applicable.
     * Remove top/bottom padding from the first and last marketing module on a page, respectively.
     */

    return css`
      position: relative;
      background-color: ${currentTheme === Sanity.ThemeColor.DARK ? Color.blackBrand : null};
      padding-top: ${topCoefficient ? rem(Spacing.sectionMobile * topCoefficient) : null};
      padding-bottom: ${bottomCoefficient ? rem(Spacing.sectionMobile * bottomCoefficient) : null};

      @media ${Device.mediumUp} {
        padding-top: ${topCoefficient ? rem(Spacing.sectionDesktop * topCoefficient) : null};
        padding-bottom: ${
          bottomCoefficient ? rem(Spacing.sectionDesktop * bottomCoefficient) : null
        };
      }

      ${() =>
        index === 0 &&
        topPaddingExemptModules.includes(_type) &&
        `
            &:first-of-type {
              padding-top: 0;
            }
          `}}

      &:last-of-type {
        padding-bottom: ${currentTheme === Sanity.ThemeColor.DARK ? "0" : null};
      }
    `;
  }}
`;
