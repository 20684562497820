import { useRef, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import SwiperCore, { A11y, Autoplay, Pagination, EffectFade } from "swiper";
import { QuoteCarouselSchema, QuoteType } from "./QuoteCarousel.model";
import * as Styled from "./QuoteCarousel.styled";

SwiperCore.use([A11y, Autoplay, Pagination, EffectFade]);

const QuoteCarousel = ({ timer_duration, quotes }: QuoteCarouselSchema) => {
  const { ref, inView } = useInView();

  const paginationRef = useRef<HTMLElement[] | undefined>();
  const autoplayDelay = useRef<number>(timer_duration ? timer_duration * 1000 : 6000);

  const swiperElRef = useRef<HTMLDivElement | undefined>();
  const swiperInstanceRef = useRef<any | undefined>();

  const handlePaginationKeyUp = (e: any) => {
    if (!swiperInstanceRef.current) return;
    if (e.keyCode === 13) {
      e.preventDefault();
      const { index } = e.target.dataset;
      swiperInstanceRef.current.slideTo(index);
    }
  };

  useEffect(() => {
    if (!swiperElRef.current) return;

    swiperInstanceRef.current = new SwiperCore(swiperElRef.current, {
      effect: "fade",
      slidesPerView: 1,
      autoHeight: true,
      a11y: {
        prevSlideMessage: "Previous press slide",
        nextSlideMessage: "Next press slide",
      },
      autoplay: {
        delay: autoplayDelay.current,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet: (index, className) => {
          return `<div class="${className}" data-index="${index}"><span></span></div>`;
        },
      },
    });

    swiperInstanceRef.current.autoplay.stop();

    // Have to manually apply pagination listeners for keyboard control

    paginationRef.current = swiperInstanceRef.current.el.querySelectorAll(
      ".swiper-pagination-bullet"
    );

    if (paginationRef.current.length) {
      paginationRef.current.forEach((pagination) => {
        pagination.addEventListener("keyup", handlePaginationKeyUp);
      });
    }

    return () => {
      if (paginationRef.current.length) {
        paginationRef.current.forEach((pagination) => {
          pagination.removeEventListener("keyup", handlePaginationKeyUp);
        });
      }

      if (swiperInstanceRef.current) {
        swiperInstanceRef.current.destroy();
      }
    };
  }, []);

  // Start autoplay when in view

  useEffect(() => {
    if (!swiperInstanceRef.current) return;

    if (inView) {
      swiperInstanceRef.current.autoplay.start();
    } else {
      swiperInstanceRef.current.autoplay.stop();
    }
  }, [inView]);

  return (
    <Styled.QuoteCarousel autoplayDelay={autoplayDelay.current} inView={inView} ref={ref}>
      <div className="swiper-container" ref={swiperElRef}>
        <div className="swiper-wrapper">
          {quotes?.length &&
            quotes.map(({ _key, quote, source }: QuoteType) => {
              return (
                <div className="swiper-slide" key={_key}>
                  <Styled.QuoteContainer>
                    <Styled.Quote className="h2">{quote}</Styled.Quote>
                    <cite>
                      <Styled.Reviewer>{source}</Styled.Reviewer>
                    </cite>
                  </Styled.QuoteContainer>
                </div>
              );
            })}
        </div>
        <div className="swiper-pagination"></div>
      </div>
    </Styled.QuoteCarousel>
  );
};

export default QuoteCarousel;
