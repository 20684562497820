import { Shopify } from "@models/shopify.model";
/**
 * @desc Finds the first variant available for sale.
 * @param variants {ProductVariantConnection} - variants.edges get the variants of a product.
 * @returns {(Shopify.ProductVariant)} The first available `variant`
 */
export function firstAvailableVariant(product: Shopify.Product) {
  if (!product || !product?.variants?.edges.length) return null;
  const { variants } = product;
  const variantNodes = variants?.edges.map((edge) => edge.node).flat();
  return variantNodes.find((variant) => variant.availableForSale);
}
