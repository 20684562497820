import { BundlesType } from "@components/bundles/Bundles.model";
import { CardCarouselType } from "@components/card-carousel/CardCarousel.model";
import { CarouselFiftyType } from "@components/carousel-fifty/CarouselFifty.model";
import { ClassesOverviewType } from "@components/classes-overview/ClassesOverview.model";
import { DigitalTrialInstructorsType } from "@components/digital-trial-instructors/DigitalTrialInstructors.model";
import { DigitalTrialQuoteCarouselType } from "@components/digital-trial-quote-carousel/DigitalTrialQuoteCarousel.model";
import { FiftyFiftyType } from "@components/fifty-fifty/FiftyFifty.model";
import { FootnotesType } from "@components/footnotes/Footnotes.model";
import { HeroFiftyType } from "@components/hero-fifty/HeroFifty.model";
import { InfoBlockType } from "@components/info-block/InfoBlock.model";
import { ReviewCarouselType } from "@components/reviews/review-carousel";
import { TickerType } from "@components/ticker/Ticker.model";
import { WorkoutsType } from "@components/workouts/Workouts.model";
import { Sanity } from "@models/sanity.model";

/**
 * @desc Maps Sanity schemas to component props based on the `_type` of the provided
 *     schema.
 * @param schema - A Sanity marketing module schema.
 * @returns object
 */
export const schemaToProps = (schema: Sanity.Document) => {
  switch (schema._type) {
    case ClassesOverviewType: {
      return {
        headings: schema.headings,
      };
    }

    case WorkoutsType: {
      const schemaWithFeaturedItems: { [x: string]: any } = {};

      Object.keys(schema).forEach((key) => {
        schemaWithFeaturedItems[key === "featured_workouts" ? "featured_items" : key] = schema[key];
      });

      return {
        sanity: {
          ...schemaWithFeaturedItems,
        },
      };
    }

    case CardCarouselType:
    case ReviewCarouselType:
    case BundlesType:
    case InfoBlockType:
    case TickerType:
    case HeroFiftyType:
    case CarouselFiftyType:
    case FiftyFiftyType:
    case FootnotesType:
    case DigitalTrialInstructorsType:
    case DigitalTrialQuoteCarouselType: {
      return {
        ...schema,
      };
    }

    default: {
      return {
        sanity: schema,
      };
    }
  }
};
