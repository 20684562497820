import { useEffect, useState } from "react";
import { SanitySyncProductDocument, SanitySyncVariant } from "@models/product.model";
import Button from "@components/ui/button";
import { shopRoute } from "@utils/routes";
import SelectVariant from "@components/select-variant";
import AddToCartButton from "@components/ui/add-to-cart-button";
import * as Styled from "./AccessoryCard.styled";
import { useShopifyCart } from "@providers/cart/CartProvider";
import { useShopifyProduct } from "@providers/product-provider";
import { useUserProfile } from "@providers/profile";
import { firstAvailableVariant } from "@utils/firstAvailableVariant";
import { formatPrice, productDisplayPrice } from "@utils/pricing";
import { CheckoutInfoProps } from "@components/product/accessory-product";
import { Shopify } from "@models/shopify.model";
import { getWorkingVariants } from "@utils/getWorkingVariants";

export interface AccessoryCardProps {
  productDocument: SanitySyncProductDocument;
}

const AccessoryCard = ({ productDocument }: AccessoryCardProps) => {
  const { addItem, setCartVisible } = useShopifyCart();
  const { state: productState, dispatch, getShopifyProduct } = useShopifyProduct();
  const { profileState: { authState} } = useUserProfile();
  const { slug, featuredImage, title, priceRange, handle } = productDocument;
  const storefrontProduct = productState.products[handle];
  const variants: Array<Shopify.ProductVariant | SanitySyncVariant> = getWorkingVariants(
    storefrontProduct,
    productDocument
  );
  const hasMultipleVariants = variants.length > 1;
  const defaultVariant = firstAvailableVariant(storefrontProduct);
  const [variant, setVariant] = useState<string>("");
  const defaultCheckout = {
    variant_id: defaultVariant?.id ?? "",
    price: defaultVariant
      ? formatPrice(Number(defaultVariant?.price?.amount))
      : productDisplayPrice(priceRange),
  };
  const [checkoutInfo, setCheckoutInfo] = useState<CheckoutInfoProps>(defaultCheckout);
  const learnMoreTxt = "Learn More";
  const disabledCartBtn = hasMultipleVariants && !variant;

  useEffect(() => {
    const fetchProduct = async () => await getShopifyProduct(dispatch, productDocument.handle);
    fetchProduct();
  }, [dispatch, getShopifyProduct, productDocument.handle]);

  useEffect(() => {
    if (checkoutInfo.variant_id === variant) return;
    const activeVariant = variants.find(({ id }) => id === variant);

    if (activeVariant) {
      let price: string = "";

      if (typeof activeVariant.price === "string") {
        price = activeVariant.price;
      } else {
        price = activeVariant.price.amount;
      }

      setCheckoutInfo({
        variant_id: activeVariant?.id,
        price: formatPrice(Number(price)),
      });
    } else {
      setCheckoutInfo(defaultCheckout);
    }
  }, [variant]);

  useEffect(() => {
    const needsVariantAssigned = !hasMultipleVariants && storefrontProduct && !variant;
    if (needsVariantAssigned) setVariant(defaultVariant?.id);
  }, [storefrontProduct]);

  const handleVariantChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setVariant(e.target.value);
  };

  const handleClickAddToCart = (variantId: string) => {
    addItem(
      authState,
      {
        variantId,
        quantity: 1,
        customAttributes: [
          { 
            key: '_productType', // prepend an underscore to the key to stop the attribute from dispalying in shopify checkout pages
            value: 'accessory'
          }
        ]
      }
    );

    setCartVisible(true);
  };

  return (
    <Styled.Article id={slug?.current}>
      {featuredImage?.src && <Styled.CardImg src={featuredImage.src} alt="" />}

      <Styled.CardContent>
        <Styled.CardTitle>{title}</Styled.CardTitle>
        <Styled.Divider />
        <Styled.CardPrice>
          {variant ? `${checkoutInfo.price}` : productDisplayPrice(priceRange)}
        </Styled.CardPrice>
        <Styled.Divider />
        {hasMultipleVariants && (
          <SelectVariant
            id={productDocument._id}
            title={title}
            variants={variants}
            value={variant}
            onChange={handleVariantChange}
          />
        )}
      </Styled.CardContent>

      <Styled.CardActions>
        <Button
          text={learnMoreTxt}
          invert
          href={{ pathname: shopRoute, query: { slug: slug?.current } }}
        />

        <AddToCartButton
          onClick={() => handleClickAddToCart(checkoutInfo.variant_id)}
          disabled={disabledCartBtn}
          productId={checkoutInfo.variant_id}
        />
      </Styled.CardActions>
    </Styled.Article>
  );
};

export default AccessoryCard;
