import styled from "styled-components";
import { Color, Device } from "@styles/constants";
import { rem } from "@styles/helpers";

export const MenuBar = styled.div`
  background-color: ${Color.black100};
  color: ${Color.white};
  height: ${rem(132)};

  button {
    color: ${Color.blackBrand};
    background-color: ${Color.white};
  }

  ul {
    color: ${Color.blackBrand};
    margin: 0;
    padding: ${rem(2)} ${rem(9)};
    list-style-type: none;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  @media ${Device.mediumDown} {
    flex-direction: column;
    justify-content: center;
    row-gap: ${rem(10)};
  }
`;
