import { CloudinaryAsset, CLOUDINARY_ASSET_PROJECTION } from "@models/cloudinary-asset.model";
import { Sanity } from "@models/sanity.model";

export const AccessoriesLandingHeroType = "accessories_landing_hero";

export interface AccessoriesLandingHeroSchema extends Sanity.Document {
  heading: string;
  body?: string;
  cta?: Sanity.Cta;
  background_image?: CloudinaryAsset;
}

export const ACCESSORIES_LANDING_HERO_PROJECTION = `{
  heading,
  body,
  cta,
  background_image ${CLOUDINARY_ASSET_PROJECTION}
}`;
