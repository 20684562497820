import styled, { css } from "styled-components";
import { Device } from "@styles/constants";
import { AbsoluteCoverCSS } from "@styles/layout";

const Asset = styled.div`
  ${AbsoluteCoverCSS}

  video,
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

interface ContainerProps {
  ratio?: number;
  ratioMobile?: number;
}

const Container = styled.div<ContainerProps>`
  position: relative;
  width: 100%;
  height: 0;

  ${({ ratio, ratioMobile }) =>
    ratioMobile
      ? css`
          padding-bottom: ${(1 / ratioMobile) * 100}%;

          @media ${Device.mediumUp} {
            padding-bottom: ${(1 / ratio) * 100}%;
          }
        `
      : css`
          padding-bottom: ${(1 / ratio) * 100}%;
        `};
`;

const AspectRatio: React.FC<{
  children?: any;
  ratio: number;
  ratioMobile?: number;
}> = ({ children, ratio, ratioMobile = null }) => (
  <Container ratio={ratio} ratioMobile={ratioMobile}>
    <Asset>{children}</Asset>
  </Container>
);

export default AspectRatio;
