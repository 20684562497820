import { motion } from "framer-motion";
import styled from "styled-components";
import { Color, Device } from "@styles/constants";
import { AbsoluteCoverCSS } from "@styles/layout";

export const Container = styled(motion.div)`
  ${AbsoluteCoverCSS}

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Intro = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background-color: ${Color.blackBrand};

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 0.2s linear;

    &.visible {
      opacity: 1;
    }
  }
`;

export const Background = styled.div`
  position: sticky;
  height: 100vh;
  width: 100%;
  top: calc(var(--banner-height) + var(--header-height));
  left: 0;
  overflow: hidden;

  @media ${Device.mediumUp} {
    top: 0;
  }
`;

export const HighlightBackground = styled.div<{ shouldPlay?: boolean }>`
  ${AbsoluteCoverCSS}

  opacity: ${({ shouldPlay }) => (shouldPlay ? 1 : 0)};
  visibility: ${({ shouldPlay }) => (shouldPlay ? "visible" : "hidden")};
`;
