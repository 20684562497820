import { SanitySyncProductDocument, SanitySyncVariant } from "@models/product.model";
import { Shopify } from "@models/shopify.model";
import { getVariantNodes } from "./getVariantNodes";
/**
 * @desc Returns the Storefront Product's variants if available.
 *     Otherwise, returns the Product Document's variants.
 * @param {Shopify.Product} storefrontProduct
 * @param {SanitySyncProductDocument} productDocument
 * @returns {Shopify.ProductVariant[] | SanitySyncVariant[]}
 */
export const getWorkingVariants = (
  storefrontProduct: Shopify.Product,
  productDocument: SanitySyncProductDocument
) => {
  if (!productDocument) throw new Error(`A productDocument must be provided.`);
  const storefrontVariants = getVariantNodes(storefrontProduct);

  return [storefrontVariants, productDocument.variants].find((source) => {
    if (source.length > 0) return source;
  });
};
