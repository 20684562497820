import { Device } from "@styles/constants";
import styled from "styled-components";
import { ColumnPosition } from "../FiftyFifty.model";
import { rem } from "@styles/helpers";

export const ImageColumn = styled.div<{ position: ColumnPosition }>`
  @media ${Device.mediumDown} {
    ${({ position }) =>
      position === ColumnPosition.FIRST ? "margin-bottom" : "margin-top"}: ${rem(40)};
  }
`;

export const ImageContainer = styled.div`
  display: grid;
  height: 100%;

  > * {
    grid-area: 1 / -1;
  }

  video {
    display: block;
    width: 100%;
  }
`;
