import styled, { css } from "styled-components";
import { Device, Font, Color, Ease } from "@styles/constants";
import { XSmallBodyMediumCSS } from "@styles/typography";

export const HeroContainer = styled.div`
  display: flex;
  align-items: center;
  background: linear-gradient(360deg, #eaeaea 0%, rgba(255, 255, 255, 0) 100%);

  @media ${Device.mediumDown} {
    flex-direction: column;
  }
`;

export const HeroTextContainer = styled.div`
  margin: 0 0 20px 100px;
  @media ${Device.mediumDown} {
    margin: 20px;
  }
`;

export const Title = styled.h1`
  text-align: left;
  margin-bottom: 1rem;
`;

export const SubTitle = styled.div`
  font-size: 16px;
  padding-right: 300px;

  @media ${Device.small} {
    font-size: 24px;
    padding-right: 0px;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 2.5rem;

  button,
  a {
    background-color: ${Color.white};
    color: ${Color.blackBrand};
    border: 1px solid ${Color.blackBrand};
  }
`;

export const BgImage = styled.div<{ url: string }>`
  background-image: url("${(props) => props.url}");
  width: 100%;
  height: 600px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${Device.small} {
    height: 400px;
  }
`;
