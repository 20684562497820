import { Device, Color } from "@styles/constants";
import styled, { css } from "styled-components";
import { StyledTheme, Theme } from "./FiftyFifty.model";

export const Section = styled.div`
  ${({ theme: { name } }: StyledTheme) => css`
    --foreground-color: ${name === Theme.LIGHT ? Color.blackBrand : Color.white};
  `}
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media ${Device.largeUp} {
    justify-content: space-between;
    flex-direction: row;

    ${({ theme: { name } }) => name !== Theme.HARDWARE_INTRO && `align-items: center;`};
  }
`;
