import styled, { css } from "styled-components";
import { Column } from "@styles/grid";
import { rem } from "@styles/helpers";
import { Eyebrow, Heading1CSS, Heading2, Heading2CSS } from "@styles/typography";
import { Color, Device } from "@styles/constants";

export const Jumbotron = styled(Column)<{ margin?: string }>`
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  margin: ${({ margin }) => margin || `${rem(140)} 0`};

  @media ${Device.small} {
    margin: ${({ margin }) => margin || `${rem(40)} 0`};
    padding: ${rem(80)} 0;
  }
`;

export const EyebrowText = styled(Eyebrow)`
  align-self: flex-start;
  margin-bottom: ${rem(20)};
`;

export const titleStyles = css`
  color: ${Color.white};
  text-align: left;
  margin-left: 0;
  margin-bottom: 0;
`;

export const Title = styled(Heading2)`
  ${Heading1CSS}
  ${titleStyles}

  @media ${Device.small} {
    ${Heading2CSS}
  }
`;

export const SubTitle = styled(Heading2)`
  ${titleStyles}
`;

export const LeftColumn = styled(Column)`
  margin-bottom: 0;
  margin-top: auto;
`;

export const BodyColumn = styled(Column)`
  margin-bottom: 0;

  @media ${Device.small} {
    margin-bottom: ${rem(20)};
  }

  p:last-child {
    margin-top: ${rem(20)};
    margin-bottom: 0;
  }

  * {
    color: inherit;
    justify-content: space-between;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: ${rem(40)};

  @media ${Device.mediumDown} {
    margin-top: ${rem(20)};
  }
`;
