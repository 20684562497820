import styled from "styled-components";
import { Font, Device } from "@styles/constants";

export const TextBlockWrapper = styled.div`
  // Styles specific to legal pages.
  &.content-page {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: ${Font.semibold};
      text-transform: uppercase;
      letter-spacing: 0.4rem;
      text-align: center;

      @media ${Device.small} {
        letter-spacing: 0.2rem;
      }
    }

    h1 {
      font-size: 3rem;
      margin-bottom: 2rem;

      @media ${Device.small} {
        font-size: 1.75rem;
      }
    }

    h2 {
      font-size: 2rem;
      margin-bottom: 25px;

      @media ${Device.small} {
        font-size: 1.75rem;
      }
    }

    h3 {
      font-size: 1.5rem;
      letter-spacing: 0.2rem;
      margin-bottom: 1rem;

      @media ${Device.small} {
        font-size: 1.25rem;
        letter-spacing: 0.1rem;
      }
    }

    h4 {
      font-size: 1rem;
      letter-spacing: 0.2rem;
      margin-bottom: 1rem;
    }

    h5 {
      letter-spacing: 0.2rem;
      margin-bottom: 1rem;
    }

    h6 {
      letter-spacing: 0.1rem;
      margin-bottom: 1rem;
    }
  }
`;
