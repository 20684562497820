import styled from "styled-components";
import { Color, Spacing, Device } from "@styles/constants";
import { Eyebrow as GlobalEyebrow, SmallBodyRegular } from "@styles/typography";
import { AbsoluteCoverCSS } from "@styles/layout";
import { rem } from "@styles/helpers";
import GlobalFootnotes from "@components/footnotes/Footnotes";

export const globalCtaDesktop = "only screen and (min-width: 70em)";
export const globalCtaMobile = "only screen and (max-width: 69.9375em)";

export const GlobalCta = styled.div<{
  darkImage: boolean;
  previousDarkTheme: boolean;
}>`
  --mobile-padding: 0 ${rem(14)};

  ${({ darkImage, previousDarkTheme }) => {
    let topCoefficient = previousDarkTheme ? 0 : 2;

    return (
      darkImage &&
      `
        background: ${Color.blackBrand};
        padding-top: ${rem(230 - Spacing.sectionDesktop * topCoefficient)};

        @media ${globalCtaMobile} {
          padding-top: ${rem(80 - Spacing.sectionMobile * topCoefficient)};
        }
      `
    );
  }}
`;

export const BackgroundWrap = styled.div<{
  darkImage: boolean;
  previousDarkTheme: boolean;
}>`
  position: absolute;
  top: var(--top, 0);
  left: 0;
  right: 0;
  max-height: ${rem(810)};
  overflow: hidden;

  &::after {
    content: "";
    ${AbsoluteCoverCSS}
    background: ${Color.black};
    opacity: 0.6;
  }

  ${({ darkImage, previousDarkTheme }) =>
    darkImage &&
    previousDarkTheme &&
    `
    --top: ${rem(Spacing.sectionDesktop)};

    @media ${globalCtaMobile} {
      --top: ${rem(Spacing.sectionMobile)};
    }
  `}
`;

export const HeadingWrap = styled.div<{ dark: boolean }>`
  color: ${({ dark }) => (dark ? Color.white : Color.blackBrand)};
  position: relative;
  z-index: 1;

  @media ${Device.xlargeUp} {
    max-width: ${rem(800)};
  }

  @media ${globalCtaMobile} {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
  }
`;

export const Eyebrow = styled(GlobalEyebrow)<{ dark: boolean }>`
  ${({ dark }) => dark && `color: ${Color.white};`}
`;

export const CompareWrap = styled.div`
  position: relative;
  background-color: ${Color.white};
  border-radius: 10px;
  border: 1px solid ${Color.goldAsh};
  padding: ${rem(35)} ${rem(40)};
  margin-top: ${rem(60)};

  @media ${globalCtaMobile} {
    padding: ${rem(30)} 0;
    margin: ${rem(40)} auto 0;
    max-width: ${rem(800)};
  }
`;

export const CompareHeader = styled.div`
  text-align: center;
  padding: var(--mobile-padding);

  @media ${globalCtaDesktop} {
    position: absolute;
    width: calc(33.33% - ${rem(40)});
    max-width: ${rem(320)};
    text-align: left;
    padding: 0;
  }
`;

export const Copy = styled(SmallBodyRegular)`
  margin-top: ${rem(20)};

  @media ${globalCtaMobile} {
    margin: ${rem(10)} auto 0;
    max-width: ${rem(540)};
  }
`;

export const CtaWrap = styled.div<{ isMobileCta?: boolean }>`
  margin-top: ${rem(24)};

  @media ${globalCtaMobile} {
    text-align: center;
    margin-top: ${rem(30)};

    ${({ isMobileCta }) => isMobileCta && `padding: var(--mobile-padding);`}
  }
`;

export const Icon = styled.div`
  display: flex;
  width: ${rem(24)};
  margin-right: ${rem(10)};

  @media ${globalCtaMobile} {
    margin-right: ${rem(5)};
  }
`;

export const Checkmark = styled.div`
  width: ${rem(19)};
  height: ${rem(14)};
`;

export const Footnotes = styled(GlobalFootnotes)`
  padding-top: ${rem(40)};
  padding-bottom: 0;

  @media ${Device.mediumUp} {
    padding-bottom: ${rem(30)};
  }
`;
