import styled from "styled-components";
import { Color, Device } from "@styles/constants";
import { Heading4CSS, SmallBodyMediumCSS } from "@styles/typography";

export const Article = styled.article`
  border: 1px solid ${Color.grayInactive};
  background-color: ${Color.white};
  display: flex;
  flex-direction: column;
`;

export const CardImg = styled.img`
  display: block;
  width: 100%;
  object-fit: cover;
`;

export const CardContent = styled.div`
  padding: 20px 20px 0;
`;

export const CardTitle = styled.h2`
  ${SmallBodyMediumCSS}
  text-align: left;
`;

export const Divider = styled.div`
  border: 1px solid ${Color.goldAsh};
  margin: 20px 0;
  opacity: 0.4;

  @media ${Device.small} {
    margin: 10px 0;
  }
`;

export const CardPrice = styled.p`
  ${Heading4CSS}
`;

export const CardActions = styled.div`
  padding: 0 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;
