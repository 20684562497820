import * as React from "react";
import Ratings from "react-ratings-declarative";
import round from "lodash-es/round";
import Slider from "react-slick";
import AspectRatio from "@components/primitives/aspect-ratio";
import Button from "@components/ui/button";
import ReviewModal from "@components/modals/ReviewModal";
import ReadMore from "@components/reviews/read-more";
import * as Styled from "./ReviewCarousel.styled";
import { Color } from "@styles/constants";
import { ReviewCarouselSchema } from "./ReviewCarousel.model";

const { useEffect, useRef, useState } = React;

const ReviewCarousel = ({
  reviews,
  heading,
  primary_cta_text,
  secondary_cta,
}: ReviewCarouselSchema) => {
  const [activeCategory, setActiveCategory] = useState<string>("Quality");
  const [mounted, setMounted] = useState<boolean>(false);
  const [currentSlides, setCurrentSlides] = useState<any[]>([]);

  const tabRef = useRef<HTMLElement | undefined>();
  const categoriesRef = useRef<HTMLElement | undefined>();
  const categories = useRef<{
    [key: string]: {
      title: string;
      tabEl: HTMLElement | undefined;
    };
  }>({});

  // Category button listener

  const handleCategoryClick = (e: React.MouseEvent<HTMLElement>) => {
    const { category } = e.currentTarget.dataset;
    if (!category || category === activeCategory) return;
    setActiveCategory(category);
  };

  // Animate active tab bar

  const moveTab = (el: HTMLElement) => {
    if (!tabRef.current) return;
    const scale = el.offsetWidth / categoriesRef.current.offsetWidth;
    tabRef.current.style.transform = `translateX(${el.offsetLeft}px) scaleX(${scale * 100}%)`;
  };

  const getCurrentSlides = () => {
    const slides: any[] = [];
    reviews.forEach((review, i) => {
      // Get categories
      if (review.category && !categories.current[review.category]) {
        if (i === 0) setActiveCategory(review.category);

        categories.current[review.category] = {
          title: review.category,
          tabEl: undefined,
        };
      }

      // Render only active category reviews
      if (activeCategory === review.category) {
        slides.push(review);
      }
    });
    setCurrentSlides(slides);
  };

  // Move tab when category changed

  useEffect(() => {
    const category = categories.current[activeCategory];
    if (category?.tabEl) {
      moveTab(category.tabEl);
    }
    getCurrentSlides();
  }, [activeCategory, mounted]);

  // Keep track when DOM ready

  useEffect(() => {
    getCurrentSlides();
    setMounted(true);
  }, []);

  return (
    <Styled.ReviewsCarousel>
      <div className="grid-container">
        <Styled.Header>{heading && <Styled.Heading>{heading}</Styled.Heading>}</Styled.Header>

        {/* Render categories once we render cms nodes */}

        {mounted && categories.current && (
          <Styled.Categories>
            <Styled.CategoriesWrap ref={categoriesRef}>
              <ul>
                {Object.keys(categories.current).map((key, idx: number) => (
                  <li
                    key={idx}
                    ref={(el) => {
                      if (el) categories.current[key].tabEl = el;
                    }}
                    className={key === activeCategory ? "is-active" : ""}>
                    <button data-category={key} onClick={handleCategoryClick}>
                      {categories.current[key] ? categories.current[key].title : ""}
                    </button>
                  </li>
                ))}
              </ul>

              <Styled.Tabs>
                <Styled.Tab ref={tabRef} />
              </Styled.Tabs>
            </Styled.CategoriesWrap>
          </Styled.Categories>
        )}
      </div>

      {currentSlides.length && (
        <Slider
          dots={false}
          arrows={false}
          slidesToShow={3}
          infinite={currentSlides.length >= 3}
          centerMode={true}
          touchThreshold={100}
          centerPadding={"165px"}
          responsive={[
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                centerPadding: "100px",
              },
            },
            {
              breakpoint: 639,
              settings: {
                slidesToShow: 1,
                centerPadding: "30px",
              },
            },
          ]}>
          {currentSlides.map(
            ({ star_rating, title, content, user, location, category, image }, i) => {
              return (
                <Styled.CarouselSlide data-index={i} key={i}>
                  <Styled.CarouselSlideInner>
                    <div>
                      <Ratings
                        rating={parseInt(star_rating)}
                        widgetRatedColors={Color.blackBrand}
                        widgetSpacings="3px">
                        {[1, 2, 3, 4, 5].map((i) => (
                          <Ratings.Widget key={i} widgetDimension="20px" />
                        ))}
                      </Ratings>
                    </div>

                    <Styled.Title>{title}</Styled.Title>
                    <Styled.Body>
                      <ReadMore charLimit={70} ellipsis lineClamp>
                        {content}
                      </ReadMore>
                    </Styled.Body>

                    <Styled.Caption>
                      <span>{user}</span> — {location}
                    </Styled.Caption>

                    <Styled.Image>
                      <AspectRatio ratio={1}>
                        {image && <img src={image.url} alt={title} loading="lazy" />}
                      </AspectRatio>
                    </Styled.Image>
                  </Styled.CarouselSlideInner>
                </Styled.CarouselSlide>
              );
            }
          )}
        </Slider>
      )}

      <Styled.Buttons className="grid-container">
        {primary_cta_text && <ReviewModal cta_text={primary_cta_text} />}
        {secondary_cta?.url && <Button text={secondary_cta.text} href={secondary_cta.url} invert />}
      </Styled.Buttons>
    </Styled.ReviewsCarousel>
  );
};

export default ReviewCarousel;
